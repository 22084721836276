import { UI_ACTION_TYPES } from './ui.types';
import {
    editProfileSettingsService,
    fetchProfileSettingsService,
    getClientsVsDataService,
    getAverageClientsService,
    getClientsReferringDataService,
    getAdherenceToCPPDataService,
    getInteractionsByTypeService,
    getRecentlyAddedClientsService,
    getAcceptedRejectedRatioService,
    getTopReferrersService,
    fetchStaffBasedOnFilterService
} from '../../services/uiService';

export const saveMaterialTableData = (tableData) => {
    return {
        type: UI_ACTION_TYPES.SAVE_MATERIAL_TABLE_DATA,
        payload: tableData
    };
};

export const showGlobalSnackBar = (snackBarDetails) => ({
    type: UI_ACTION_TYPES.SHOW_GLOBAL_SNACKBAR,
    payload: snackBarDetails
});

export const closeGlobalSnackBar = (snackBarDetails) => ({
    type: UI_ACTION_TYPES.CLOSE_GLOBAL_SNACKBAR,
    payload: snackBarDetails
});

export const setIsFormInvalid = (formStatus) => ({
    type: UI_ACTION_TYPES.IS_FORM_INVALID,
    payload: formStatus
});

export const setFilesSelectedFromAttachmentModal = (localFiles) => ({
    type: UI_ACTION_TYPES.SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL,
    payload: localFiles
});

export const removeIndivigualFileItems = (newFileItemsAfterRemoving) => ({
    type: UI_ACTION_TYPES.REMOVE_INDIVIGUAL_FILE_ITEMS,
    payload: newFileItemsAfterRemoving
});

export const openCloseAttachmentModal = (openOrClose) => ({
    type: UI_ACTION_TYPES.OPEN_CLOSE_ATTACHMENT_MODAL,
    payload: openOrClose
});

export const setTotalSizeOfDriveAndLocalFiles = (totalSize) => ({
    type: UI_ACTION_TYPES.SET_TOTAL_FILE_SIZE_OF_DRIVE_AND_LOCAL_FILES,
    payload: totalSize
});

export const setActionName = (payload) => ({
    type: UI_ACTION_TYPES.SET_ACTION_NAME,
    payload: payload
});
export const setFilesSelectedFromAttachmentModalIthActionName = (payload) => ({
    type: UI_ACTION_TYPES.SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL_WITH_ACTION_NAME,
    payload: payload
});

export const setRecordedData = (payload) => ({
    type: UI_ACTION_TYPES.SET_RECORDED_DATA,
    payload: payload
});

export const removeRecordingItem = (recordingItemId) => ({
    type: UI_ACTION_TYPES.REMOVE_RECORDING_ITEM,
    payload: recordingItemId
});

export const fetchProfileSettingsAsync = (currentUserId) => {
    return dispatch => {
        fetchProfileSettingsService(dispatch, currentUserId);
    };
};

export const editProfileSettingsAsync = (newProfileSettingsDetails) => {
    return dispatch => {
        editProfileSettingsService(dispatch, newProfileSettingsDetails);
    };
};

export const clearErrorMsg = (type) => ({
    type: type
});

export const getClientsVsDataAsync = (filterData) => {
    return dispatch => {
        getClientsVsDataService(dispatch,filterData);
    };
};

export const getAverageClientsAsync = (filterData) => {
    return dispatch => {
        getAverageClientsService(dispatch, filterData);
    };
};

export const getClientsReferringDataAsync = (filterData) => {
    return dispatch => {
        getClientsReferringDataService(dispatch, filterData);
    };
};

export const getAdheranceToCPPDataAsync = (filterData) => {
    return dispatch => {
        getAdherenceToCPPDataService(dispatch, filterData);
    };
};

export const getInteractionsByTypeAsync = (filterData) => {
    return dispatch => {
        getInteractionsByTypeService(dispatch, filterData);
    };
};

export const getRecentlyAddedClientsAsync = (filterData) => {
    return dispatch => {
        getRecentlyAddedClientsService(dispatch, filterData);
    };
};

export const getAcceptedRejectedRatioAsync = (filterData) => {
    return dispatch => {
        getAcceptedRejectedRatioService(dispatch, filterData);
    };
};

export const getTopReferrersAsync = (filterData) => {
    return dispatch => {
        getTopReferrersService(dispatch, filterData);
    };
};

export const removeUIErrorMsgs = (errorField) => ({
    type : UI_ACTION_TYPES.REMOVE_ERROR_MESSAGES,
    payload : errorField
});

export const fetchStaffBasedOnFilterAsync = (url) => {
    return dispatch => {
        fetchStaffBasedOnFilterService(dispatch, url);
    };
};