import React from 'react'
import { Paper as MuiPaper } from '@material-ui/core';


const Paper = ({ elevation=2, className, children, square=false }) => {
    return (
        <MuiPaper elevation={elevation} square={square} className={`customPaper ${className ? className : ''}`}>
            {children}
        </MuiPaper>
    )
}

export default Paper;
