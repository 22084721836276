import { takeLatest , put } from 'redux-saga/effects';
import { MAIL_ACTION_TYPES } from '../mail/mail.types';
import { axiosGet, handleOnError, handleOnSuccess } from '../../utils/api';


export function* getMailsFromFolderAsyncStart({ payload })
{
    // put is an effect used instead of dispatch function
    try {
        // const data = yield axiosGet(`mail/?folder=${payload}&limit=10`);
        const data = yield axiosGet('mail/',{
            params : {...payload}
        });
        yield put(handleOnSuccess(data,MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_SUCCESS));
    }
    catch ( error )
    {
        yield put(handleOnError(error.response, MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_FAILURE));
    }
}

export function* getMailsFromFolderStart()
{
    // this will ignore all previous pending api requests and will only take the last api request
    yield takeLatest(
        MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_START,
        getMailsFromFolderAsyncStart
    );
}





export function* getMailDetailsAsyncStart({ payload })
{
    // put is an effect used instead of dispatch function
    try {
        const data = yield axiosGet(`mail/?mail=${payload}`);
        yield put(handleOnSuccess(data,MAIL_ACTION_TYPES.GET_MAIL_DETAILS_SUCCESS));
    }
    catch ( error )
    {
        yield put(handleOnError(error.response, MAIL_ACTION_TYPES.GET_MAIL_DETAILS_FAILURE));
    }
}

export function* getMailDetailsStart()
{
    // this will ignore all previous pending api requests and will only take the last api request
    yield takeLatest(
        MAIL_ACTION_TYPES.GET_MAIL_DETAILS_START,
        getMailDetailsAsyncStart
    );
}