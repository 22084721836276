import React, { useEffect, useState } from 'react'
import { axiosGet } from '../../utils/api'
import './Subitems.scss'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { NavLink , Link,useHistory } from 'react-router-dom';
import { isNOTNullOrUndefined } from '../../utils/utils';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientTypeData, setCurrentClientType } from '../../redux/dashboard/dashboard.actions';


const ProfileManagementSub = () => {
  const dispatch = useDispatch()
  const {client_type_data,is_Fetching_client_type,current_client_type} = useSelector(state => state.dashboard);

  const history = useHistory()


  const navigate= (id)=>{
    if(isNOTNullOrUndefined(id)){
      dispatch(setCurrentClientType(id))
      history.push({ pathname: "/profile-management", state: id });
    }else{
      dispatch(setCurrentClientType(''))
      history.push('/profile-management')
    }
  }
  useEffect(() => {

    dispatch(fetchClientTypeData())
    return () => {
      dispatch(setCurrentClientType(''))
    }
    
  }, [])

 
  
  
  return (
    <div className='sub-items'>
      {client_type_data?.filter(item => item.actions).map(i =><div onClick={() =>navigate(i?.client_type?.id)} className='sub-item' key={i?.client_type?.id}>
        <KeyboardDoubleArrowRightIcon style={{fontSize:'13px',color: current_client_type === i?.client_type?.id ? '#1BC5DC': '#ccc',marginRight:'9px'}} />
        <p className='item-label'>{i?.client_type?.name}</p>
      </div>)}
      <div className='sub-item' onClick={() =>navigate("")} >
        <KeyboardDoubleArrowRightIcon style={{fontSize:'13px',color:current_client_type === '' ? '#1BC5DC':'#ccc',marginRight:'9px'}} />
        <p className='item-label'>all</p>
      </div>

    </div>
  )
}

export default ProfileManagementSub