import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger'; 
import rootReducer from './rootReducer';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';



const sagaMiddleware = createSagaMiddleware();


const middlewares = [sagaMiddleware, thunk];


// Remove logger plugin from production built
// if(process.env.NODE_ENV === 'development')
// {
//     middlewares.push(logger);
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


middlewares.push(logger);

const enhancers = [composeWithDevTools()];
export const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(...middlewares)));
sagaMiddleware.run(rootSaga);


export const persistor = persistStore(store);



export default { store, persistor };