
import { CHOOSE_PRODUCT, FEE_TYPE, REASON_FUNCTION, RECOMMENDED_PRODUCTS, SEARCH_CLIENT_RESULTS, SEARCH_PRODUCTS, SELECTED_CLIENT, SELECTED_CLIENT_PRODUCTS, SUMMARY_PRODUCTS, SUSPENSE_ACCOUNT_IDS,MONTHLY_ISSUE } from "./FeeManagementClient.types"

export const clientResults = (data) =>{
    return {
        type : SEARCH_CLIENT_RESULTS,
        data:data
    }
}

export const selectedclient = (data) =>{
    return {
        type : SELECTED_CLIENT,
        data:data
    }
}
export const selectedClientProducts = (data) =>{
    return {
        type : SELECTED_CLIENT_PRODUCTS,
        data:data
    }
}

export const searchProducts = (data) =>{
    return {
        type : SEARCH_PRODUCTS,
        data:data
    }
}
export const fetchFeeType = (data) =>{
    return {
        type : FEE_TYPE,
        data:data
    }
}
export const recommendedProducts = (data) =>{
    return {
        type : RECOMMENDED_PRODUCTS,
        data:data
    }
}
export const summaryProducts = (data) =>{
    return {
        type : SUMMARY_PRODUCTS,
        data:data
    }
}
export const getChooseProduct = (data) =>{
    return {
        type : CHOOSE_PRODUCT,
        data:data
    }
}
export const getReasonForFunction = (data) =>{
    return {
        type : REASON_FUNCTION,
        data:data
    }
}
export const GetIdsFeeMatching = (data) =>{
    return {
        type : SUSPENSE_ACCOUNT_IDS,
        data:data
        
    }
}
export const GetIdSelectedProviderSuperUser = (data) =>{
    return {
        type : SUSPENSE_ACCOUNT_IDS,
        data:data
        
    }
}
export const cacheFeeMonth = (data) => {
    return {
         type: MONTHLY_ISSUE,
         data: data  // for clearing all reducers
    }
}