import React, { useState, useEffect, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Table from '../../../components/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    getClientTaskInstrumentsRecomendedList,
    clearClientTaskRecommendationList
    } from '../../../redux/draft/draft.actions';
import appConfig from '../../../appConfig.json';
import NumberFormat from 'react-number-format';
import './DraftRecommendationSummaryTaskList.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Button from '../../Button/Button';
import { fetchPut } from '../../../services/FeeMatchingService';
import { Checkbox } from '@material-ui/core';
import { useSnackbar } from 'notistack'


const TransferTooltip = withStyles((theme) => ({
    tooltip: {
    //   backgroundColor: theme.palette.common.white,
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 220,
    },
}))(Tooltip);


const DraftRecommendationSummaryTaskList = () => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch  = useDispatch();
    const history   = useHistory();
    const clientId  = history.location.state?.clientId;
    const taskId=history.location.state?.taskId
    const clientTaskListrecommendation    = useSelector(state => state.draft.clientTaskListrecommendation);
    const userToken = useSelector((state) => state.auth.currentUserData.token);

    const isLoadingClientTaskRecommendation   = useSelector(state => state.draft.isLoadingClientTaskRecommendation);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (e, id, type) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                [type]: e?.target?.checked || false
            }
        }));
    };
    useEffect(() => {
        // Redirect to client-management page if client id is not present
        if(!taskId){
            history.push('/client-management');
        }
        dispatch(getClientTaskInstrumentsRecomendedList(taskId))
        return () => {
            setTimeout(() => {
                dispatch(clearClientTaskRecommendationList())
            }, 50);
        }
    },[]);
    useEffect(()=>{
      if(clientTaskListrecommendation?.length>0){
        clientTaskListrecommendation.forEach((row)=>{
             setCheckedItems(prevState => ({
            ...prevState,
            [row.id]: {
                initial: row.initialfee_administrator,
                ongoing: row.ongoingfee_administrator
            }
        }));
        })
       
      }
    },[clientTaskListrecommendation])
      const updateFeeStatus=async(rowId)=>{
        try{
            const fetchUrl="instrumentFeestatusUpdate"
            const item = checkedItems[rowId] || {};
        const initialChecked = item?.initial?"True": "False";
        const ongoingChecked = item?.ongoing?"True": "False";
        const requstBody={initial:initialChecked,ongoing:ongoingChecked,id:rowId,client_task_id:taskId}
          const response=await fetchPut(userToken,fetchUrl,requstBody,true)
          if(response.status){
            enqueueSnackbar(response.message, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
              })
          }
        }
        catch(e){
            enqueueSnackbar(e.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
              });
        }
      }
    const getFunctionName = (function_list) => { // Function may have multiple items - Multiselect
       const completeFunctionList=[{
        "id": 1,
        "name": "Transfer",
        "type": "Function"
    },
    {
        "id": 2,
        "name": "Lump sum",
        "type": "Function"
    },
    {
        "id": 3,
        "name": "Regular contribution",
        "type": "Function"
    },
    {
        "id": 4,
        "name": "Withdrawal",
        "type": "Function"
    },
    {
        "id": 5,
        "name": "Fund switch",
        "type": "Function"
    },
    {
        "id": 6,
        "name": "Review",
        "type": "Function"
    },  {
        "id": 7,
        "name": "Employee Regular contribution",
        "type": "Function"
    },
    {
        "id": 8,
        "name": "Employer Regular contribution",
        "type": "Function"
    },
    {
     "id": 9,
     "name":"Employee Lump sum",
     "type":"Function",
    },
    {
        "id": 10,
        "name":"Employer Lump sum",
        "type":"Function",
    }
]
        let fn_name = '';
        let extraFn = '';
        
            completeFunctionList.forEach((item) => {
                if(item.id === function_list[0] && item.type ==="Function"){
                    fn_name = item.name
                }})
         
            // extraFn = (function_list.length > 1) ? ' +'+(function_list.length -1) : '';
        
        return fn_name ? fn_name + extraFn : '';
    }
    const getTransferredItems = (row) => {
        if(!_.isEmpty(row.map_transfer_from)){
            let transferredArray = _.filter(clientTaskListrecommendation, item => _.includes(row.map_transfer_from, item.id));
            let response = transferredArray.map((item, index)=>{
                return (
                    <p key={index}>
                        {
                            item.recomended_instrument + " - " + getFunctionName(item.function_list) + " (" + appConfig.currency.currency_symbol + (item.amount ? item.amount : 0) +")"
                        }
                    </p>
                )
            })
            return (<>{response}</>)
        }
    }
    return(
        <section className="DraftSummaryTableSection">
            <div style={{ height: "5px" }}>{(isLoadingClientTaskRecommendation) && <LinearProgress />}</div>
            <Paper className="draftSummaryTableWrapper">
            <Table className="mainTable" removeLastRowBorder={false}>
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell align="left" className="customTh providerTh">Provider</TableCell>
                        <TableCell align="center" className="customTh transferTh">Transfer</TableCell>
                        <TableCell align="left" className="customTh productTypeTh">Product Type</TableCell>
                        <TableCell align="left" className="customTh functionTh">Function</TableCell>
                        <TableCell align="left" className="customTh newExistingTh">New/Existing</TableCell>
                        <TableCell align="right" className="customTh amountTh">Existing Amount</TableCell>
                        <TableCell align="right" className="customTh amountTh">Contribution Amount</TableCell>
                        <TableCell align="right" className="customTh initialTh">Initial Fee</TableCell>
                        <TableCell align="right" className="customTh ongoingTh">Ongoing Fee</TableCell>
                        <TableCell align="right" className="customTh initialTh">Initial Fee Fund</TableCell>
                        <TableCell align="right" className="customTh ongoingTh">Ongoing Fee Fund</TableCell>
                        <TableCell align="right" className="customTh ongoingTh">Fee status update</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clientTaskListrecommendation.map((row, index) => {
                        return (
                            <TableRow hover tabIndex={-1} key={row.id} >
                                <TableCell align="left"  className="new">
                                    {row.recomended_instrument}
                                </TableCell>
                                <TableCell align="center">
                                    {!_.isEmpty(row.map_transfer_from) &&
                                        <TransferTooltip placement="right" className="hasTransferDetails" title={getTransferredItems(row)}>
                                            <SyncAltIcon className="transferIcon"/>
                                        </TransferTooltip>
                                    }
                                </TableCell>
                                <TableCell align="left">{row.product_type}</TableCell>
                                <TableCell align="left">{getFunctionName(row.function_list)}</TableCell>
                                <TableCell align="left">{row.provider_type}</TableCell>
                                <TableCell align="right">
                                    {
                                        !isNaN(row.provider_type) 
                                        ? 
                                        <NumberFormat
                                            value={row.amount}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={appConfig.currency.currency_symbol}
                                            decimalScale={2}
                                            renderText={(formattedValue)=> {
                                                // if formatting fails result will be empty, in this case show default value
                                                if(!formattedValue)
                                                {
                                                    return row.amount;
                                                }
                                                return formattedValue;
                                            }}
                                            fixedDecimalScale={false}
                                        />
                                        : 
                                        <NumberFormat
                                            value={row.amount}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={appConfig.currency.currency_symbol}
                                            decimalScale={2}
                                            renderText={(formattedValue)=> {
                                                    // if formatting fails result will be empty, in this case show default value
                                                if(!formattedValue)
                                                {
                                                    return row.amount;
                                                }else{
                                                    return formattedValue;
                                                }
                                            }}
                                            fixedDecimalScale={false}
                                        />
                                        
                                    }
                                </TableCell>
                                <TableCell align="right">
                                    {
                                        !isNaN(row.provider_type) 
                                        ? 
                                        <NumberFormat
                                            value={row.contribution_value}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={appConfig.currency.currency_symbol}
                                            decimalScale={2}
                                            renderText={(formattedValue)=> {
                                                // if formatting fails result will be empty, in this case show default value
                                                if(!formattedValue)
                                                {
                                                    return row.contribution_value;
                                                }
                                                return formattedValue;
                                            }}
                                            fixedDecimalScale={false}
                                        />
                                        : 
                                        <NumberFormat
                                            value={row.contribution_value}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={appConfig.currency.currency_symbol}
                                            decimalScale={2}
                                            renderText={(formattedValue)=> {
                                                    // if formatting fails result will be empty, in this case show default value
                                                if(!formattedValue)
                                                {
                                                    return row.contribution_value;
                                                }else{
                                                    return formattedValue;
                                                }
                                            }}
                                            fixedDecimalScale={false}
                                        />
                                        
                                    }
                                </TableCell>
                                <TableCell align="right">{row.initial_fee}%</TableCell>
                                <TableCell align="right">{row.ongoing_fee}%</TableCell>
                                <TableCell align="right">
                                        <Checkbox
                                            checked={checkedItems?.[row.id]?.initial || false}
                                            onChange={(e) => handleCheckboxChange(e, row.id, 'initial')}
                                            size="small"
                                            name="initial"
                                            style={{ paddingLeft: 0 }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Checkbox
                                            checked={checkedItems?.[row.id]?.ongoing || false}
                                            onChange={(e) => handleCheckboxChange(e, row.id, 'ongoing')}
                                            size="small"
                                            name="ongoing"
                                            style={{ paddingLeft: 0 }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button bgColor="secondary" size="small" onClick={() => updateFeeStatus(row.id)}>Update</Button>
                                    </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            
        </Paper>
        </section>
    )
}
export default DraftRecommendationSummaryTaskList
