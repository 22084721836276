import React from 'react';
import { Box } from '@material-ui/core';
import './TabPanel.scss';


const TabPanel = ({
    children,
    value,
    index, 
    disablePresetTheme = false,
    className=null,
    ...otherProps
}) => {
    return (
        <div
            className={`
                ${disablePresetTheme ? '' : 'customTabContentWrapper'} 
                tabContent
            `}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...otherProps}
        >
            { value === index && <Box>{children}</Box> }
        </div>
    );
}

export default TabPanel;
