import { PERSISTED_REDUCER_ACTION_TYPES } from './persistedReducer.types';

export const saveSurveyValues = (values) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.SAVE_SURVEY_VALUES,
    payload : values
})

export const updateSavedSurveyValues = (fieldName) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.UPDATE_SAVED_SURVEY_VALUES,
    payload : fieldName
})

export const clearSavedSurveyValues = () => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.CLEAR_SAVED_SURVEY_VALUES,
})



export const setAssetSectionFormStatus = (statusDetails) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.SET_ASSETS_SECTION_FORM_STATUS,
    payload : statusDetails
})

export const clearAssetSectionFormStatus = () => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.CLEAR_ASSETS_SECTION_FORM_STATUS
})

export const removeFromSavedSurveyValues = (fieldName) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.REMOVE_FROM_SAVED_SURVEY_VALUES,
    payload : fieldName
})

export const saveLocalValues = (localData) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.SAVE_LOCAL_VALUES,
    payload : localData
})
export const clearLocalValues = () => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.CLEAR_LOCAL_VALUES,
})
export const saveOfflineCatId = (categoryId) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.SAVE_UPDATED_CATEGORY_OFFLINE,
    payload : categoryId
})
export const clearOfflineCatId = () => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.CLEAR_UPDATED_CATEGORY_OFFLINE,
})
export const saveOnlineCatId = (categoryId) => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.SAVE_UPDATED_CATEGORY_ONLINE,
    payload : categoryId
})
export const clearOnlineCatId = () => ({
    type : PERSISTED_REDUCER_ACTION_TYPES.CLEAR_UPDATED_CATEGORY_ONLINE,
})