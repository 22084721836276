import React, { useEffect, useState } from "react";
import "../../../Style/selectClient.css";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import Layout from "../../../hoc/LayoutHOC/LayoutHOC";
import { useDispatch, useSelector } from "react-redux";
import { feeManagement, searchClient,addClient } from "../../../redux/routing/routing.actions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import SearchClient from "./SearchClient"
import AddClient from "./AddNewClient"
const SelectClient = (props) => {
  const dispatch = useDispatch();
  const role = useSelector(
    (state) => state.auth.currentUserData.logged_in_user_type
  );
  const isSuperAdmin = (role == "SuperAdmin") 
  const { enqueueSnackbar } = useSnackbar();
  const [showSerchClient, setShowSerchClient] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  return (
    <>{showSerchClient?
                          <SearchClient
                            fn={props.fn}
                            ln={props.ln}
                            updateFn={props.updateFn}
                            />:
        showAddClient?
                          <AddClient
                          fn={props.fn}
                          ln={props.ln}
                          updateFn={props.updateFn}
                          />:
        <PageWrapper
     
        >
          <div className="selectClientScreen">
           
            <div  onClick={()=>dispatch(feeManagement())} className="leftArrowSection">
              <ArrowBackIcon
                style={{ color: "white", paddingTop: "1px", height: "40px" }}
              />
            </div>
            <div className="selectBox">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h1 className="selectTitle fw-bold">Select Client</h1>
                <p className="selectContent py-1">
                  Is it for an Existing client or to add a new client in David?
                </p>
              </div>
              <div className="selectButtons d-flex justify-content-around">
                  <button onClick={()=>{
                    if(props.updateFn){
                      setShowSerchClient(true)
                           
                    }
                    else{
                    dispatch(searchClient())
                  }}} className="selectButton">
                    <p className="selectButtonText">EXISTING</p>
                  </button>
              
                <button onClick={()=>{
                  if(props.updateFn){
                    setShowAddClient(true)
                         
                  }
                  else{
                  if(isSuperAdmin){
                    enqueueSnackbar('You are not authorized to perform this action.', {
                      variant: 'error',
                      anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                      },
                    })
                  }else{
                    dispatch(addClient())}
                  }
                }
                }className="selectButton">
                  <p className="selectButtonText">NEW</p>
                </button>
              </div>
            </div>
          </div>
        </PageWrapper>
 }
    </>
  );
};
export default SelectClient;
