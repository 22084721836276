import { REFERRAL_MNGMT_ACTION_TYPES } from './referralManagement.types';
import {
    fetchCompaniesService,
    listClientsService,
    addClientsService,
    addParallelClientsService,
    fetchReferrerNameService,
    editClientService,
    fetchClientDetailsWithIdService,
    fetchRecentlyAddedClientsService,
    fetchRecentlyAddedByAdvisorService,
    searchClientsService,
    fetchAdvisorsService,
    bulkAddClientService,
    sendBulkReferralEmailsService,
    getClientDataSummeryService,
    fetchClientType
} from '../../services/referralManagementService';





export const removeErrorMessage = () => ({
    type: REFERRAL_MNGMT_ACTION_TYPES.REMOVE_ERROR_MESSAGE,
})
export const setSelectedOneNull = () => ({
    type: "selectedOneNull"
})

export const removeSuccessMessage = () => ({
    type: REFERRAL_MNGMT_ACTION_TYPES.REMOVE_SUCCESS_MESSAGE,
})

export const clearAddErrorData = () => ({
    type: REFERRAL_MNGMT_ACTION_TYPES.CLEAR_ADD_ERROR_DATA,
})


export const addClientsAsync = (clientData) => {
    return dispatch => {
        addClientsService(dispatch, clientData)
    }
}
export const addParallelClientsAsync = (clientData) => {
    return dispatch => {
        addParallelClientsService(dispatch, clientData)
    }
}

export const editClientAsync = (newClientDetails,client_id) => {
    return dispatch => {
        editClientService(dispatch, newClientDetails,client_id)
    }
}


export const fetchClientDetailsWithIdAsync = (client_id) => {
    return dispatch => {
        fetchClientDetailsWithIdService(dispatch, client_id)
    }
}


export const listClientsAsync = (params) => {
    return dispatch => {
        listClientsService(dispatch, params);
    }
}

export const fetchCompaniesAsync = (searchString, isAdvisorCompany=false) => {
    let formattedString = searchString ? searchString.toString().trim() : '';
    return dispatch => {
        fetchCompaniesService(dispatch, formattedString, isAdvisorCompany)
    }
}
export const fetchClientListAsync = () => {
    return dispatch => {
        fetchClientType(dispatch)
    }
}
export const fetchReferrerNameAsync = (searchString) => {
    let formattedString = searchString ? searchString.toString().trim() : '';
    return dispatch => {
        fetchReferrerNameService(dispatch, formattedString)
    }
}


export const fetchRecentlyAddedClientsAsync = () => {
    return dispatch => {
        fetchRecentlyAddedClientsService(dispatch)
    }
}


export const fetchRecentlyAddedByAdvisorAsync = () => {
    return dispatch => {
        fetchRecentlyAddedByAdvisorService(dispatch)
    }
}


export const searchClientsAsync = (searchData) => {
    return dispatch => {
        searchClientsService(dispatch, searchData)
    }
}

export const fetchAdvisorListAsync = () => {
    return dispatch => {
        fetchAdvisorsService(dispatch)
    }
}

export const bulkAddClientAsync = (clientsData,userToken,handleRedirection) => {
    return dispatch => {
        bulkAddClientService(dispatch, clientsData,userToken,handleRedirection)
    }
}


export const removeBulkAddErrorMessage = () => ({
    type : REFERRAL_MNGMT_ACTION_TYPES.REMOVE_BULK_ADD_ERROR_MESSAGE
})

export const removeBulkAddSuccessMessage = () => ({
    type : REFERRAL_MNGMT_ACTION_TYPES.REMOVE_BULK_ADD_SUCCESS_MESSAGE
})

export const sendBulkReferralEmailsAsync = (selectedClientDetails) => {
    return dispatch => {
        sendBulkReferralEmailsService(dispatch, selectedClientDetails)
    }
}


export const removeSendBulkReferralEmailsSuccessMsg = () => ({
    type : REFERRAL_MNGMT_ACTION_TYPES.REMOVE_SEND_BULK_REFERRAL_EMAIL_SUCCESS_MSG
})
export const removeSendBulkReferralEmailsErrorMsg = () => ({
    type : REFERRAL_MNGMT_ACTION_TYPES.REMOVE_SEND_BULK_REFERRAL_EMAIL_ERROR_MSG
})


export const getClientDataSummeryAsync = () => dispatch => getClientDataSummeryService(dispatch);

export const clearReferralReducerStateValue = (stateValue) => ({
    type : REFERRAL_MNGMT_ACTION_TYPES.CLEAR_REFERRAL_REDUCER_STATE_VALUE,
    payload : stateValue
})