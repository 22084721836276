import {
    fetchClientDetails,
    fetchClientDocuments,
    fetchActivityFlow,
    fetchRecentClientDocuments,
    fetchClientDescription,
    fetchVulnerableStatus
} from '../../services/clientService';
import {CLIENT_ACTION_TYPES} from './client.types';

export const getClientDetails = (clientId) => {
    return dispatch => {
        fetchClientDetails(dispatch, clientId)
    }
}
export const removeClientData = () => {
    return {
       type : "REMOVE_CLIENT_DATA"
    }
}
export const getClientDescription = (clientId) => {
    return dispatch => {
        fetchClientDescription(dispatch, clientId)
    }
}
export const getClientVulnerableStatus = (clientId) => {
    return dispatch => {
        fetchVulnerableStatus(dispatch, clientId)
    }
}
export const removeClientVulnerableStatus = () => {
    return {
       type : "REMOVE_CLIENT_VULNERABLE_STATUS"
    }
}
export const getClientDocuments = (clientId) => {
    return dispatch => {
        fetchClientDocuments(dispatch, clientId)
    }
}

export const getActivityFlow = (clientId) => {
    return dispatch => {
        fetchActivityFlow(dispatch, clientId)
    }
}

/**Funtion to fetch recent documents only */
export const getRecentClientDocuments = (clientId, fetchRecent, task_id) => {
    return dispatch => {
        fetchRecentClientDocuments(dispatch, clientId, fetchRecent, task_id)
    }
}


export const emailExistAction = (payload) => ({
    type : CLIENT_ACTION_TYPES.SAVE_EMAIL_EXIST,
    payload: payload
})

export const phoneExistAction = (payload) => ({
    type : CLIENT_ACTION_TYPES.SAVE_PHONE_EXIST,
    payload: payload
})

export const clearDataExist = () => ({
    type: CLIENT_ACTION_TYPES.CLEAR_DATA_EXIST
})