import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GetAppIcon from '@material-ui/icons/GetApp';
import BackupIcon from '@material-ui/icons/Backup';
import Table from '../../components/Table/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import './DocumentList.scss';
import sort from "../../assets/Sort.png";
import SearchDefault from "../../assets/Search_default.png";
import { getFileNameFromLink, formatDocName, formatIsoDateString } from '../../utils/utils';
import moment from 'moment';
import { DOC_TYPES } from '../../utils/constants';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: '#32c2d9',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);

const DocumentList = ({
    documents,
    onUploadClick = (doc_type, instrumentId) => null,
    onDownloadClick = (url, filename) => null,
    showType = true,
    showDate = true,
    showActions = false,
    showDownloadIcon = false,
    showUploadIcon = false,
    firstCharsLength = 3,
    lastCharsLength = 3,
    extRequired = true,
    showTooltip = true
}) => {
    const [showSearch, setShowSearch] = useState(false);
    const docTypeMap = useMemo(()=>DOC_TYPES.reduce((map, type) => {
        map[type.name] = type.id;
        return map;
    }, {}));
    const [alphabetsort, setAlphabetsort] = useState([]);
    const [isSorted,setIsSorted]=useState({doc:false,date:false})
    const handleSearch = (event) => {
       const value= event.target.value
        if(value==""){
            setAlphabetsort(documents) 
        }
        else{
            setAlphabetsort(documents.filter(item =>
                value?item.document_name.toLowerCase().includes(value.toLowerCase()):true
        ))
        }
        
    };

    useEffect(() => {
        setAlphabetsort(documents);
    }, [documents]);

    const sortFunc = (accordingTo) => {
        if (accordingTo === "docName") {
            if(isSorted?.doc){
                setAlphabetsort(documents);
             }
             else{
                let newtable = [...alphabetsort].sort((a, b) =>
                    a.document_name.localeCompare(b.document_name)
                );
                setAlphabetsort(newtable);
             }
             
            setIsSorted(prev=>({...prev,doc:!prev.doc}))
        }
       else if (accordingTo === "type") {
            if(isSorted?.type){
                setAlphabetsort(documents);
             }
             else{
                const newtable = documents.sort((a, b) => {
                    const docTypeA = docTypeMap[a.doc_label] || 0;
                    const docTypeB = docTypeMap[b.doc_label] || 0;
                    return docTypeA - docTypeB;
                });
                setAlphabetsort(newtable);
             }
             
            setIsSorted(prev=>({...prev,doc:!prev.doc}))
        }
         else if (accordingTo === "createdDate") {
             if(isSorted?.date){
                setAlphabetsort(documents);
             }
             else{
                let newtable = [...alphabetsort].sort((a, b) => {
                    const timeA = new Date(a.create_time).getTime();
                    const timeB = new Date(b.create_time).getTime();
                    return timeA - timeB;
                });
                setAlphabetsort(newtable);
             }
            
             setIsSorted(prev=>({...prev,date:!prev.date}))
        }
    };

    

    return (
        <Table className="documentListTable">
            <TableHead className="tableHead">
                <TableRow style={{ backgroundColor: "#32c2d9" }}>
                    <TableCell align="left" style={{ color: "white" }}>
                        <span onClick={() => { setShowSearch(!showSearch) }}>
                            <img
                                style={{
                                    height: "1.5rem",
                                    width: "1.5rem",
                                    marginBottom: "3px"
                                }}
                                src={SearchDefault}
                                alt=""
                            />
                        </span>Documents <span onClick={() => { sortFunc("docName") }}>
                            <img
                                style={{ marginLeft: "5px", height: "1rem", width: "1rem", cursor: "pointer" }}
                                src={sort}
                                alt=""
                            />
                        </span>
                        {showSearch ? <input id="search" type="text" onChange={handleSearch} /> : null}
                    </TableCell>
                    {showType && <TableCell align="left" style={{ color: "white" }}>Type<img
                    style={{ marginLeft: "5px", height: "1rem", width: "1rem", cursor: "pointer" }}src={sort}
                    alt=""
                    onClick={() => { sortFunc("type") }}
                  /></TableCell>}
                    {showDate && <TableCell align="left" style={{ color: "white" }}>Date
                        <img
                            style={{ marginLeft: "5px", height: "1rem", width: "1rem", cursor: "pointer" }}
                            onClick={() => { sortFunc("createdDate") }}
                            src={sort}
                            alt=""
                        />
                    </TableCell>}
                    {showActions && <TableCell align="left" style={{ color: "white" }}>Actions</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody >
                {
                    alphabetsort && alphabetsort.length > 0 ? (
                        <>
                            {alphabetsort.map((item, index) => (
                                <TableRow key={index} className="documentDetailsWrapper">
                                    <TableCell scope="row">
                                        <div className="documentListName">
                                            <AssignmentIcon className="icon documentIcon" />
                                            {showTooltip ? (
                                                <LightTooltip placement="right" className="infoTooltip" title={getFileNameFromLink(item.document_name)}>
                                                    <span>{formatDocName(getFileNameFromLink(item.document_name), firstCharsLength, lastCharsLength, extRequired)}</span>
                                                </LightTooltip>
                                            ) : (
                                                <span>{formatDocName(getFileNameFromLink(item.document_name), firstCharsLength, lastCharsLength, extRequired)}</span>
                                            )}
                                        </div>
                                    </TableCell>
                                    {
                                        showType &&
                                        <TableCell scope="row">{item?.doc_label ? item?.doc_label : 'NA'}</TableCell>
                                    }
                                    {
                                        showDate &&
                                        <TableCell scope="row">{formatIsoDateString(item.create_time)}</TableCell>
                                    }
                                    {
                                        showActions &&
                                        <TableCell scope="row">
                                            <div className="documentListActions">
                                                {
                                                    showDownloadIcon &&
                                                    <span onClick={() => onDownloadClick(item.doc, item.document_name)} className="cursor-pointer">
                                                        <GetAppIcon className="icon downloadIcon" />
                                                    </span>
                                                }
                                                {
                                                    showUploadIcon &&
                                                    <span onClick={() => { onUploadClick(item.doc_type, item?.client_instrument_id) }} className="cursor-pointer">
                                                        <BackupIcon className="icon uploadIcon" />
                                                    </span>
                                                }
                                            </div>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow >
                            <TableCell colSpan={2}>
                                <p style={{color:"red"}}>No documents found!</p>
                            </TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    );
}

export default DocumentList;
