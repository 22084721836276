import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import './ErrorBoundary.scss';
import appConfig from '../../appConfig';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import {PUSH_ERROR_LOG_TO_SERVER} from '../../utils/endPoint';
import { getPath } from '../../utils/api';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    const {token} = this.props;

    // Code to push error logs to server
    remote.apply(log, {
      format: remote.json,
      url: getPath(PUSH_ERROR_LOG_TO_SERVER),
      headers: {
        Authtoken: 'Token ' + token,
        type: 'application/json'
      },
      interval: 30000
    });
    log.enableAll();
    // Code to push error logs to server - End
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // log the error to our server with loglevel
    log.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="errorBoundary">
          <ErrorIcon className="icon errorIcon" />
          <h1 className="customHead heading">Something Went Wrong</h1>
          <p className="customDescription subHeading">{appConfig.messages.general_error_message}</p>
        </div>
      );
    }
    return this.props.children;
  }
}


export default ErrorBoundary;