import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../hoc/LayoutHOC/LayoutHOC";
import "../../../Style/searchClient.css";
import TextFieldBox from "../SmallComponents/TextFieldBox";
import close from "../../../assets/Close popup.png";
import {
  searchClientResults,
  selectclient,
} from "../../../redux/routing/routing.actions";
import PageWrapper from "../../PageWrapper/PageWrapper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SearchClientApi } from "../../../services/clientService";
import { clientResults } from "../../../redux/FeeManagementClient/FeeManagementClient.action";
import PageLoader from "../../PageLoader/PageLoader";

const SearchClient = (props) => {
  const dispatch = useDispatch();

  const userToken = useSelector((state) => state.auth.currentUserData.token);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [error, setError] = useState(false);

  let checkFields = () => {
    const insuranceId = document.getElementById("insuranceId").value;
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phone = document.getElementById("phone").value;
    const email = document.getElementById("email").value;

    if (
      insuranceId.length !== 0 ||
      firstName.length !== 0 ||
      lastName.length !== 0 ||
      phone.length !== 0 ||
      insuranceId.length !== 0 ||
      email.length !==0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
//   let formInput = document.getElementById("firstName");
//   formInput.addEventListener("keyup", (event)=> {
//   if (event.code === 'Enter') {
//    event.preventDefault();
//    document.getElementById("submitBtn").click();
//   }
// });
const  handleSubmit = async (e)=> {
  e.preventDefault();
  setIsLoading(true);

  const insuranceId =
    document.getElementById("insuranceId").value;
  const firstName =
    document.getElementById("firstName").value;
  const lastName =
    document.getElementById("lastName").value;
  const phone = document.getElementById("phone").value;
  const email = document.getElementById("email").value;
  let name; 
  name = firstName ? firstName : '';
  name = lastName ? name +' '+lastName : name; 
  let data = await SearchClientApi(
    userToken,
    `${name}`,
    email,
    phone,
    insuranceId
  );

  dispatch(clientResults(data.data));
  setIsLoading(false);
  if (data.data.length > 0) {
    dispatch(searchClientResults());
  } else {
    setDataFound(true);
  }
}

  return (
    <>{props.updateFn==true?  <PageWrapper>
      <div className="SearchClientScreen">
        <div
          onClick={() => dispatch(selectclient())}
          className="leftArrowSection"
        >
          <ArrowBackIcon
            style={{ color: "white", paddingTop: "1px", height: "40px" }}
          />
        </div>
        <div className="SearchClientBox">
          <div className="searchClientTitle d-flex justify-content-between align-items-center">
            <h5 className="fw-bold m-0">Search Client</h5>
            <img onClick={()=>dispatch(selectclient())} src={close} alt="" />
          </div>
          <hr className="m-0" />

          <div className="textFieldContainer">
            <form id="searchForm" onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between flex-wrap py-2">
              <TextFieldBox
                label="First Name"
                inputId="firstName"
                value={props.fn}
                error={error}
                setIsDisabled={checkFields}
                
              />
              <TextFieldBox
                label="Last Name"
                inputId="lastName"
                value={props.ln}
                error={error}
                setIsDisabled={checkFields}
              />
            </div>
            <div className="d-flex justify-content-between flex-wrap py-2">
            <TextFieldBox
                label="E-mail"
                inputId="email"
                error={error}
                setIsDisabled={checkFields}
                
              />
              <TextFieldBox
                label="Phone"
                inputId="phone"
                error={error}
                setIsDisabled={checkFields}
              />
            </div>
            <div className="d-flex justify-content-between py-2">
             
              <TextFieldBox
                error={error}
                label="National Insurance ID"
                inputId="insuranceId"
                setIsDisabled={checkFields}
                // type="number"
                
              />
            </div>
            <div className="d-flex justify-content-between py-2">
              <div></div>

              <button
              type="submit"
              id="submitBtn"
                style={{ opacity: isDisabled ? 0.5 : 1 }}
                onClick={handleSubmit}
                disabled={isDisabled}
                className="searchBtn"
              >
                SEARCH
              </button>
            </div>
            <div
              style={{
                display: isLoading ? "flex" : "none",
                justifyContent: "center",
              }}
              className=""
            >
              <PageLoader/>
            </div>
            <div
              style={{
                display: dataFound ? "flex" : "none",
                justifyContent: "center",
              }}
              className=""
            >
              No data found!
            </div>
            </form>
          </div>
        </div>
      </div>
    </PageWrapper>:
      <Layout>
        <PageWrapper>
          <div className="SearchClientScreen">
            <div
              onClick={() => dispatch(selectclient())}
              className="leftArrowSection"
            >
              <ArrowBackIcon
                style={{ color: "white", paddingTop: "1px", height: "40px" }}
              />
            </div>
            <div className="SearchClientBox">
              <div className="searchClientTitle d-flex justify-content-between align-items-center">
                <h5 className="fw-bold m-0">Search Client</h5>
                <img onClick={()=>dispatch(selectclient())} src={close} alt="" />
              </div>
              <hr className="m-0" />

              <div className="textFieldContainer">
                <form id="searchForm" onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between flex-wrap py-2">
                  <TextFieldBox
                    label="First Name"
                    inputId="firstName"
                    error={error}
                    setIsDisabled={checkFields}
                    
                  />
                  <TextFieldBox
                    label="Last Name"
                    inputId="lastName"
                    error={error}
                    setIsDisabled={checkFields}
                  />
                </div>
                <div className="d-flex justify-content-between flex-wrap py-2">
                <TextFieldBox
                    label="E-mail"
                    inputId="email"
                    error={error}
                    setIsDisabled={checkFields}
                    
                  />
                  <TextFieldBox
                    label="Phone"
                    inputId="phone"
                    error={error}
                    setIsDisabled={checkFields}
                  />
                </div>
                <div className="d-flex justify-content-between py-2">
                 
                  <TextFieldBox
                    error={error}
                    label="National Insurance ID"
                    inputId="insuranceId"
                    setIsDisabled={checkFields}
                    // type="number"
                    
                  />
                </div>
                <div className="d-flex justify-content-between py-2">
                  <div></div>

                  <button
                  type="submit"
                  id="submitBtn"
                    style={{ opacity: isDisabled ? 0.5 : 1 }}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                    className="searchBtn"
                  >
                    SEARCH
                  </button>
                </div>
                <div
                  style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                  }}
                  className=""
                >
                  <PageLoader/>
                </div>
                <div
                  style={{
                    display: dataFound ? "flex" : "none",
                    justifyContent: "center",
                  }}
                  className=""
                >
                  No data found!
                </div>
                </form>
              </div>
            </div>
          </div>
        </PageWrapper>
      </Layout>
}</>
  );
};
export default SearchClient;
