export const INSTRUMENT_ACTION_TYPES = {

    FETCH_RECENTLY_ADDED_INSTRUMENTS_START : 'FETCH_RECENTLY_ADDED_INSTRUMENTS_START',
    FETCH_RECENTLY_ADDED_INSTRUMENTS_SUCCESS : 'FETCH_RECENTLY_ADDED_INSTRUMENTS_SUCCESS',
    FETCH_RECENTLY_ADDED_INSTRUMENTS_FAILURE : 'FETCH_RECENTLY_ADDED_INSTRUMENTS_FAILURE',

    FETCH_SEARCH_INSTRUMENTS_START : 'FETCH_SEARCH_INSTRUMENTS_START',
    FETCH_SEARCH_INSTRUMENTS_SUCCESS : 'FETCH_SEARCH_INSTRUMENTS_SUCCESS',
    FETCH_SEARCH_INSTRUMENTS_FAILURE : 'FETCH_SEARCH_INSTRUMENTS_FAILURE',

    FETCH_INSTRUMENTS_START : 'FETCH_INSTRUMENTS_START',
    FETCH_INSTRUMENTS_SUCCESS : 'FETCH_INSTRUMENTS_SUCCESS',
    FETCH_INSTRUMENTS_FAILURE : 'FETCH_INSTRUMENTS_FAILURE',
    CLEAR_INSTRUMENT_LIST: 'CLEAR_INSTRUMENT_LIST',

    POST_INSTRUMENTS_START : 'POST_INSTRUMENTS_START',
    POST_INSTRUMENTS_SUCCESS : 'POST_INSTRUMENTS_SUCCESS',
    POST_INSTRUMENTS_FAILURE : 'POST_INSTRUMENTS_FAILURE',

    FETCH_CLIENT_INSTRUMENT_START : 'FETCH_CLIENT_INSTRUMENT_START',
    FETCH_CLIENT_INSTRUMENT_SUCCESS : 'FETCH_CLIENT_INSTRUMENT_SUCCESS',
    FETCH_CLIENT_INSTRUMENT_FAILURE : 'FETCH_CLIENT_INSTRUMENT_FAILURE',

    FETCH_CLIENT_INSTRUMENT_COUNT_START: 'FETCH_CLIENT_INSTRUMENT_COUNT_START',
    FETCH_CLIENT_INSTRUMENT_COUNT_SUCCESS: 'FETCH_CLIENT_INSTRUMENT_COUNT_SUCCESS',
    FETCH_CLIENT_INSTRUMENT_COUNT_FAILURE: 'FETCH_CLIENT_INSTRUMENT_COUNT_FAILURE',

    UPDATE_CLIENT_INSTRUMENT_TYPE_START: 'UPDATE_CLIENT_INSTRUMENT_TYPE_START',
    UPDATE_CLIENT_INSTRUMENT_TYPE_SUCCESS: 'UPDATE_CLIENT_INSTRUMENT_TYPE_SUCCESS',
    UPDATE_CLIENT_INSTRUMENT_TYPE_FAILURE: 'UPDATE_CLIENT_INSTRUMENT_TYPE_FAILURE',

    UPDATE_FROM_BACK_DATA:'UPDATE_FROM_BACK_DATA',
    CLEAR_UPDATE_FROM_BACK_DATA:'CLEAR_UPDATE_FROM_BACK_DATA',
    
    DOCUMENT_UPLOAD_START: 'DOCUMENT_UPLOAD_START',
    DOCUMENT_UPLOAD_SUCCESS: 'DOCUMENT_UPLOAD_SUCCESS',
    DOCUMENT_UPLOAD_FAILURE: 'DOCUMENT_UPLOAD_FAILURE',

    LOA_DOWNLOAD_START: 'LOA_DOWNLOAD_START',
    LOA_DOWNLOAD_SUCCESS: 'LOA_DOWNLOAD_SUCCESS',
    LOA_DOWNLOAD_FAILURE: 'LOA_DOWNLOAD_FAILURE',

    FETCH_EMAIL_PREVIEW_START: 'FETCH_EMAIL_PREVIEW_START',
    FETCH_EMAIL_PREVIEW_SUCCESS: 'FETCH_EMAIL_PREVIEW_SUCCESS',
    FETCH_EMAIL_PREVIEW_FAILURE: 'FETCH_EMAIL_PREVIEW_FAILURE',
    CLEAR_EMAIL_TEMPLATE_PREVIEW: 'CLEAR_EMAIL_TEMPLATE_PREVIEW',

    POST_BULK_EMAIL_START: 'POST_BULK_EMAIL_START',
    POST_BULK_EMAIL_SUCCESS: 'POST_BULK_EMAIL_SUCCESS',
    POST_BULK_EMAIL_FAILURE: 'POST_BULK_EMAIL_FAILURE',

    FETCH_CLIENT_LOA_START: 'FETCH_CLIENT_LOA_START',
    FETCH_CLIENT_LOA_SUCCESS: 'FETCH_CLIENT_LOA_SUCCESS',
    FETCH_CLIENT_LOA_FAILURE: 'FETCH_CLIENT_LOA_FAILURE',

    DELETE_CLIENT_LOA_START: 'DELETE_CLIENT_LOA_START',
    DELETE_CLIENT_LOA_SUCCESS: 'DELETE_CLIENT_LOA_SUCCESS',
    DELETE_CLIENT_LOA_FAILURE: 'DELETE_CLIENT_LOA_FAILURE',

    CLEAR_CLIENT_LOA: 'CLEAR_CLIENT_LOA',
    CLEAR_BULK_EMAIL_ERROR: 'CLEAR_BULK_EMAIL_ERROR',
    CLEAR_DOCUMENT_UPLOAD_ERROR: 'CLEAR_DOCUMENT_UPLOAD_ERROR',
    CLEAR_DELETE_LOA_ERROR: 'CLEAR_DELETE_LOA_ERROR',
    CLEAR_DOCUMENT_UPLOAD_MESSAGE: 'CLEAR_DOCUMENT_UPLOAD_MESSAGE',
    CLEAR_BULK_REMINDER_ERROR : 'CLEAR_BULK_REMINDER_ERROR',


    FETCH_REMINDER_LIST_START : 'FETCH_REMINDER_LIST_START',
    FETCH_REMINDER_LIST_SUCCESS : 'FETCH_REMINDER_LIST_SUCCESS',
    FETCH_REMINDER_LIST_FAILURE : 'FETCH_REMINDER_LIST_FAILURE',
    
    SAVE_SNOOZE_DATA_START : 'SAVE_SNOOZE_DATA_START',
    SAVE_SNOOZE_DATA_SUCCESS : 'SAVE_SNOOZE_DATA_SUCCESS',
    SAVE_SNOOZE_DATA_FAILURE : 'SAVE_SNOOZE_DATA_FAILURE',
    REMOVE_SAVE_SNOOZE_DATA_SUCCESS_MSG : 'REMOVE_SAVE_SNOOZE_DATA_SUCCESS_MSG',
    REMOVE_SAVE_SNOOZE_DATA_ERROR_MSG : 'REMOVE_SAVE_SNOOZE_DATA_ERROR_MSG',


    FETCH_FILTERED_REMINDER_DATA_START : 'FETCH_FILTERED_REMINDER_DATA_START',
    FETCH_FILTERED_REMINDER_DATA_SUCCESS : 'FETCH_FILTERED_REMINDER_DATA_SUCCESS',
    FETCH_FILTERED_REMINDER_DATA_FAILURE : 'FETCH_FILTERED_REMINDER_DATA_FAILURE',


    REMOVE_REMINDER_END_DATE_START : 'REMOVE_REMINDER_END_DATE_START',
    REMOVE_REMINDER_END_DATE_SUCCESS : 'REMOVE_REMINDER_END_DATE_SUCCESS',
    REMOVE_REMINDER_END_DATE_FAILURE : 'REMOVE_REMINDER_END_DATE_FAILURE',
    CLEAR_NEW_REMINDER_DATA : 'CLEAR_NEW_REMINDER_DATA',
    REMOVE_REMINDER_SUCCESS_MSG : 'REMOVE_REMINDER_SUCCESS_MSG',
    REMOVE_REMINDER_ERROR_MSG : 'REMOVE_REMINDER_ERROR_MSG',



    FETCH_PENDING_WITH_DATA_START : 'FETCH_PENDING_WITH_DATA_START',
    FETCH_PENDING_WITH_DATA_SUCCESS : 'FETCH_PENDING_WITH_DATA_SUCCESS',
    FETCH_PENDING_WITH_DATA_FAILURE : 'FETCH_PENDING_WITH_DATA_FAILURE',

    POST_BULK_REMINDER_START : 'POST_BULK_REMINDER_START',
    POST_BULK_REMINDER_SUCCESS : 'POST_BULK_REMINDER_SUCCESS',
    POST_BULK_REMINDER_FAILURE : 'POST_BULK_REMINDER_FAILURE',


    PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START : 'PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START',
    PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS : 'PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS',
    PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE : 'PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE',
    CLEAR_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS_MSG : 'CLEAR_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS_MSG',

    FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_START : 'FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_START',
    FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_SUCCESS : 'FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_SUCCESS',
    FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_FAILURE : 'FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_FAILURE',


    SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START : 'SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START',
    SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS : 'SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS',
    SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE : 'SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE',


    GET_PRODUCT_COMPARISON_DATA_START : 'GET_PRODUCT_COMPARISON_DATA_START',
    GET_PRODUCT_COMPARISON_DATA_SUCCESS : 'GET_PRODUCT_COMPARISON_DATA_SUCCESS',
    GET_PRODUCT_COMPARISON_DATA_FAILURE : 'GET_PRODUCT_COMPARISON_DATA_FAILURE',

    POST_EXTRACTED_DATA_START : 'POST_EXTRACTED_DATA_START',
    POST_EXTRACTED_DATA_SUCCESS : 'POST_EXTRACTED_DATA_SUCCESS',
    POST_EXTRACTED_DATA_FAILURE : 'POST_EXTRACTED_DATA_FAILURE',

    DELETE_PRODUCT_START : 'DELETE_PRODUCT_START',
    DELETE_PRODUCT_SUCCESS : 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILURE : 'DELETE_PRODUCT_FAILURE',

    CLEAR_INSTRUMENT_SUMMARY_LIST: 'CLEAR_INSTRUMENT_SUMMARY_LIST',
    CLEAR_CLIENT_INSTRUMENTS : 'CLEAR_CLIENT_INSTRUMENTS',
    CLEAR_PRODUCT_COMPARISON_DATA : 'CLEAR_PRODUCT_COMPARISON_DATA',


    SEARCH_PROVIDERS: 'SEARCH_PROVIDERS',
    ADD_PRODUCTS_INSTRUMENTS: 'ADD_PRODUCTS_INSTRUMENTS' 

}