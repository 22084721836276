import * as Yup from 'yup';


export const addClientSchema = Yup.object().shape({
    
    first_name: Yup.string()
        .required('First name is required.')
        .max(60, 'Maximum characters upto 60.')
        .matches(/^[A-Za-z ]*$/, 'First name should not contain special characters or numbers'),
    last_name: Yup.string()
        .required('Last name is required.')
        .max(60, 'Maximum characters upto 60.')
        .matches(/^[A-Za-z ]*$/, 'Last name should not contain special characters or numbers'),
  
    phone_number: Yup.string()
        .required('Mobile number is required.')
        .matches(/^[0-9\+\ ]+$/, {
            message: 'Mobile number must contain only numeric characters.',
            excludeEmptyString: true
        })
        .max(13, 'Maximum characters upto 13.')
        .min(10, 'Must have minimum 10 characters.'),
    email: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email.')
        .max(100, 'Maximum characters upto 100.'),
        ni_number: Yup.string()
        .required('Insurance Id is required.'),
     client_type: Yup.string()
     .required('Client Type is required.'),
});
