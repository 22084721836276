import React, { useState, useEffect } from 'react';
import Header from '../../components/Layout/Header/Header';
import Sidebar from '../../components/Layout/Sidebar/Sidebar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import './LayoutHOC.scss';


window.resizeWindowBinded = function(setIsSidebarExpanded, setIsMobileMenuExpanded){
    if(window.innerWidth < 802)
    {
        setIsSidebarExpanded(false);
    }

    if(window.innerWidth > 600)
    {
        setIsMobileMenuExpanded(false);
    }
}


const LayoutHOC = ({ children, className, padding=0 }) => {

    // --- Starts  : When window width is resized -- 


    useEffect(() => {
        
        // Section Starts :: sidebar menu will be turned off from 600 and below screens, no need to manage seperatly for mobile as its width is zero below 400 -- 

        const innerWidth = window.innerWidth;
        if(innerWidth <= 768 )
        {
            setIsSidebarExpanded(false);
        }

        // Section Starts :: sidebar menu will be turned off from 600 and below screens, no need to manage seperatly for mobile as its width is zero below 400 -- 

        var resizeWindow = window.resizeWindowBinded.bind(null, setIsSidebarExpanded, setIsMobileMenuExpanded);
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
      }, []);

    // --- Ends  : When window width is resized -- 




    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            position: 'relative',
            background : '#edf1f2',
            minHeight : '100vh',
            width: '100%'
        }
    }));


    const classes = useStyles();
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useState(false);
    const handleDrawerOpen = () => {
        const innerWidth = window.innerWidth;
        if(innerWidth <= 768)
        {
            setIsMobileMenuExpanded(prevState => !prevState);
            return;
        }
        setIsSidebarExpanded(prevState => !prevState);
    };



    

    return (
        <div className={`${classes.root} ${className ? className : ''}`}>
            <CssBaseline />
            <Sidebar 
                handleDrawerOpen={handleDrawerOpen} 
                isSidebarExpanded={isSidebarExpanded} 
                isMobileMenuExpanded={isMobileMenuExpanded}
                setIsMobileMenuExpanded={setIsMobileMenuExpanded}
            />
            <main className={`mainLayout ${classes.content}`}>
                <div className={classes.toolbar} />
                <Header handleDrawerOpen={handleDrawerOpen} isSidebarExpanded={isSidebarExpanded}/>
                <div className={className}>
                    {children} 
                </div>
            </main>
        </div>
    );
}



export default LayoutHOC;