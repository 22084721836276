export const SEARCH_CLIENT_RESULTS = "SEARCH_CLIENT_RESULTS"
export const SELECTED_CLIENT = "SELECTED_CLIENT"
export const SELECTED_CLIENT_PRODUCTS = "SELECTED_CLIENT_PRODUCTS"
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS"
export const FEE_TYPE = "FEE_TYPE"
export const RECOMMENDED_PRODUCTS = "RECOMMENDED_PRODUCTS"
export const SUMMARY_PRODUCTS = "SUMMARY_PRODUCTS"
export const CHOOSE_PRODUCT = "CHOOSE_PRODUCT"
export const REASON_FUNCTION = "REASON_FUNCTION"
export const SUSPENSE_ACCOUNT_IDS = "SUSPENSE_ACCOUNT_IDS"
export const MONTHLY_ISSUE = "MONTHLY_ISSUE"
