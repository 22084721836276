import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Layout from '../../hoc/LayoutHOC/LayoutHOC';
import DraftRecommendationSummaryTaskList from '../../components/DraftRecommendation/DraftRecommendationSummaryTaskList/DraftRecommendationSummaryTaskList';
import ClientProfile from '../../components/ReferralManagement/ClientProfile/ClientProfile';
import { useSelector } from 'react-redux';
import './DraftRecommendationSummaryTaskListPage.scss';
import AuthorizationHOC from '../../hoc/AuthorizationHOC/AuthorizationHOC';

const DraftRecommendationSummaryTaskListPage = () => {
    const clientDetails = useSelector(state => state.client?.clientDetails);
    // const clientName = (clientDetails.id) ? clientDetails?.user?.first_name+" "+clientDetails?.user?.last_name : 'Client';
    // const clientText = clientName+"'s Profile";
    const firstName = (clientDetails.id) ? clientDetails?.user?.first_name : '';
    const clientText = firstName+"'s Profile";
    const breadCrumbData = [
        { name: 'Task Management', path: '/tasks' },
        { name: 'Fund Status', path: '' },
    ]
    return (
        <Layout padding={0}>
            <ClientProfile clientProfileOnly={true}/>
            <PageWrapper
                breadCrumbData={breadCrumbData}
                heading="Recommendation Summary"
                type2
                className="DraftRecommendationSummaryWrapper"
            >
                <DraftRecommendationSummaryTaskList />
            </PageWrapper>
        </Layout>
    )
}

export default AuthorizationHOC(['DRAFT-RECOMMENDATION-LIST-VIEW'])(DraftRecommendationSummaryTaskListPage);
