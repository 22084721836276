import { DRAFT_ACTION_TYPES } from './draft.types';
import { excludeTransferredProduct } from './draft.utils';

const INITIAL_STATE = {
    isLoadingRecommendation : false,
    isLoadingFee : false,
    isLoadingFund : false,
    isLoadingReason: false,
    isLoadingNotes : false,
    isLoadingComment : false,
    isLoadingPutComment : false,
    isLoadingGetInstrumentFee : false,
    isLoadingPutInstrumentFee : false,
    isLoadingCloneInstrument : false,
    isLoadingDeleteInstrument : false,
    isLoadingSRDocument : false,
    isLoadingChecklist : false,
    isLoadingUpload : false,
    isLoadingChecklistUpdate : false,
    isLoadingDraftDoc : false,
    isLoadingConfirmClient : false,
    isLoadingNotesAnsUpdate : false,
    isLoadingSubTotalAnnual:false,
    recommendation : [],
    clientTaskListrecommendation:[],
    isLoadingClientTaskRecommendation:false,
    subTotalAnnual:"",
    feeReconciliationList : [],
    feeDetails : [],
    fundDetails : [],
    reasonDetails : [],
    notes : [],
    comment : [],
    instrumentFee : {},
    cloneInstrument : [],
    srDocument:{},
    checklist : [],
    draftDocuments : [],
    generateSRSuccessMessage: null,
    subTotalAnnualErrorMessage:null,
    confirmClientTaskId: null,
    clientTaskListrecommendationErrorMessage:null,
    notesAnsUpdateMsg : null,
    notesAnsUpdateErrorMsg : null,
    instrumentFeePutErrorMessage: null,
    commentPutErrorMessage: null,
    uploadSuccessMessage: null,
    uploadErrorMessage: null,
    generateSRErrorMessage: null,
    confirmClientSuccessMessage: null,
    confirmClientErrorMessage: null,
    checklistUpdateSuccessMessage : null
}
const draftReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {

        case DRAFT_ACTION_TYPES.FETCH_RECOMMENDATION_START:
            return {
                ...state,
                isLoadingRecommendation: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_RECOMMENDATION_SUCCESS:
            return {
                ...state,
                isLoadingRecommendation: false,
                recommendation: payload.data,
                feeReconciliationList: excludeTransferredProduct(payload.data),
            }
        case DRAFT_ACTION_TYPES.FETCH_RECOMMENDATION_FAILURE:
            return {
                ...state,
                isLoadingRecommendation: false,
                recommendationErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.FETCH_CLIENT_TASK_RECOMMENDATION_START:
            return {
                ...state,
                isLoadingClientTaskRecommendation: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_CLIENT_TASK_RECOMMENDATION_SUCCESS:
            return {
                ...state,
                isLoadingClientTaskRecommendation: false,
                clientTaskListrecommendation: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_CLIENT_TASK_RECOMMENDATION_FAILURE:
            return {
                ...state,
                isLoadingClientTaskRecommendation: false,
                clientTaskListrecommendationErrorMessage: payload.message,
            }
            case DRAFT_ACTION_TYPES.FETCH_SUB_TOTAL_ANNUAL_START:
            return {
                ...state,
                isLoadingSubTotalAnnual: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_SUB_TOTAL_ANNUAL_SUCCESS:
            return {
                ...state,
                isLoadingSubTotalAnnual: false,
                subTotalAnnual: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_SUB_TOTAL_ANNUAL_FAILURE:
            return {
                ...state,
                isLoadingSubTotalAnnual: false,
                subTotalAnnualErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.FETCH_FEE_START:
            return {
                ...state,
                isLoadingFee: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_FEE_SUCCESS:
            return {
                ...state,
                isLoadingFee: false,
                feeDetails: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_FEE_FAILURE:
            return {
                ...state,
                isLoadingFee: false,
                feeDetailsErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.FETCH_FUND_START:
            return {
                ...state,
                isLoadingFund: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_FUND_SUCCESS:
            return {
                ...state,
                isLoadingFund: false,
                fundDetails: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_FUND_FAILURE:
            return {
                ...state,
                isLoadingFund: false,
                fundDetailsErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.FETCH_REASON_START:
            return {
                ...state,
                isLoadingReason: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_REASON_SUCCESS:
            return {
                ...state,
                isLoadingReason: false,
                reasonDetails: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_REASON_FAILURE:
            return {
                ...state,
                isLoadingReason: false,
                reasonDetailsErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.FETCH_NOTES_START:
            return {
                ...state,
                isLoadingNotes: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_NOTES_SUCCESS:
            return {
                ...state,
                isLoadingNotes: false,
                notes: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_NOTES_FAILURE:
            return {
                ...state,
                isLoadingNotes: false,
                notesErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.FETCH_COMMENT_START:
            return {
                ...state,
                isLoadingComment: true,
            }
        case DRAFT_ACTION_TYPES.FETCH_COMMENT_SUCCESS:
            return {
                ...state,
                isLoadingComment: false,
                comment: payload.data,
            }
        case DRAFT_ACTION_TYPES.FETCH_COMMENT_FAILURE:
            return {
                ...state,
                isLoadingComment: false,
                commentErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.PUT_COMMENT_START:
            return {
                ...state,
                isLoadingPutComment: true,
            }
        case DRAFT_ACTION_TYPES.PUT_COMMENT_SUCCESS:
            return {
                ...state,
                isLoadingPutComment: false,
            }
        case DRAFT_ACTION_TYPES.PUT_COMMENT_FAILURE:
            return {
                ...state,
                isLoadingPutComment: false,
                commentPutErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.GET_INSTRUMENT_FEE_START:
            return {
                ...state,
                isLoadingGetInstrumentFee: true,
            }
        case DRAFT_ACTION_TYPES.GET_INSTRUMENT_FEE_SUCCESS:
            return {
                ...state,
                isLoadingGetInstrumentFee: false,
                instrumentFee: payload.data
            }
        case DRAFT_ACTION_TYPES.GET_INSTRUMENT_FEE_FAILURE:
            return {
                ...state,
                isLoadingGetInstrumentFee: false,
                instrumentFeeGetErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.PUT_INSTRUMENT_FEE_START:
            return {
                ...state,
                isLoadingPutInstrumentFee: true,
            }
        case DRAFT_ACTION_TYPES.PUT_INSTRUMENT_FEE_SUCCESS:
            return {
                ...state,
                isLoadingPutInstrumentFee: false,
            }
        case DRAFT_ACTION_TYPES.PUT_INSTRUMENT_FEE_FAILURE:
            return {
                ...state,
                isLoadingPutInstrumentFee: false,
                instrumentFeePutErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.CLONE_INSTRUMENT_START:
            return {
                ...state,
                isLoadingCloneInstrument: true,
            }
        case DRAFT_ACTION_TYPES.CLONE_INSTRUMENT_SUCCESS:
            return {
                ...state,
                isLoadingCloneInstrument: false,
                cloneInstrument: payload.data
            }
        case DRAFT_ACTION_TYPES.CLONE_INSTRUMENT_FAILURE:
            return {
                ...state,
                isLoadingCloneInstrument: false,
                cloneInstrumentErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.DELETE_INSTRUMENT_START:
            return {
                ...state,
                isLoadingDeleteInstrument: true,
            }
        case DRAFT_ACTION_TYPES.DELETE_INSTRUMENT_SUCCESS:
            return {
                ...state,
                isLoadingDeleteInstrument: false,
            }
        case DRAFT_ACTION_TYPES.DELETE_INSTRUMENT_FAILURE:
            return {
                ...state,
                isLoadingDeleteInstrument: false,
                deleteInstrumentErrorMessage: payload.message,
            }

        case DRAFT_ACTION_TYPES.CLEAR_ERROR_MESSAGES:
            return {
                ...state,
                instrumentFeePutErrorMessage: null,
                commentPutErrorMessage: null,
                uploadSuccessMessage: null,
                uploadErrorMessage: null,
                generateSRSuccessMessage: null,
                generateSRErrorMessage: null,
                confirmClientSuccessMessage: null,
                confirmClientErrorMessage: null,
                checklistUpdateSuccessMessage : null
            }

        case DRAFT_ACTION_TYPES.GET_SR_START:
            return {
                ...state,
                isLoadingSRDocument: true,
            }
        case DRAFT_ACTION_TYPES.GET_SR_SUCCESS:
            return {
                ...state,
                isLoadingSRDocument: false,
                srDocument: payload.data,
            }
        case DRAFT_ACTION_TYPES.GET_SR_FAILURE:
            return {
                ...state,
                isLoadingSRDocument: false,
                srDocumentErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.GET_CHECKLIST_START:
            return {
                ...state,
                isLoadingChecklist: true,
            }
        case DRAFT_ACTION_TYPES.GET_CHECKLIST_SUCCESS:
            return {
                ...state,
                isLoadingChecklist: false,
                checklist: payload.data,
            }
        case DRAFT_ACTION_TYPES.GET_CHECKLIST_FAILURE:
            return {
                ...state,
                isLoadingChecklist: false,
                checklistErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.UPDATE_CHECK_LIST:
            return {
                ...state,
                checklist : payload
            }  
        case DRAFT_ACTION_TYPES.SR_UPLOAD_START:
            return {
                ...state,
                isLoadingUpload: true,
            }
        case DRAFT_ACTION_TYPES.SR_UPLOAD_SUCCESS:
            return {
                ...state,
                isLoadingUpload: false,
                uploadSuccessMessage:payload.message
            }
        case DRAFT_ACTION_TYPES.SR_UPLOAD_FAILURE:
            return {
                ...state,
                isLoadingUpload: false,
                uploadErrorMessage: (payload.data.hasOwnProperty('file') && payload.data?.file.length > 0) ? payload.data?.file[0] : null,
            }
        case DRAFT_ACTION_TYPES.PUT_CHECKLIST_START:
            return {
                ...state,
                isLoadingChecklistUpdate: true,
            }
        case DRAFT_ACTION_TYPES.PUT_CHECKLIST_SUCCESS:
            return {
                ...state,
                isLoadingChecklistUpdate: false,
                checklistUpdateSuccessMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.PUT_CHECKLIST_FAILURE:
            return {
                ...state,
                isLoadingChecklistUpdate: false,
                checklistUpdateErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.POST_GENERATE_SR_START:
            return {
                ...state,
                isLoadingGenerateSR: true,
            }
        case DRAFT_ACTION_TYPES.POST_GENERATE_SR_SUCCESS:
            return {
                ...state,
                isLoadingGenerateSR: false,
                generateSRSuccessMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.POST_GENERATE_SR_FAILURE:
            return {
                ...state,
                isLoadingGenerateSR: false,
                generateSRErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.GET_DRAFT_DOC_START:
            return {
                ...state,
                isLoadingDraftDoc: true,
            }
        case DRAFT_ACTION_TYPES.GET_DRAFT_DOC_SUCCESS:
            return {
                ...state,
                isLoadingDraftDoc: false,
                draftDocuments: payload.data,
            }
        case DRAFT_ACTION_TYPES.GET_DRAFT_DOC_FAILURE:
            return {
                ...state,
                isLoadingDraftDoc: false,
                draftDocumentsErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.POST_CLIENT_CONFIRM_START:
            return {
                ...state,
                isLoadingConfirmClient: true,
            }
        case DRAFT_ACTION_TYPES.POST_CLIENT_CONFIRM_SUCCESS:
            return {
                ...state,
                isLoadingConfirmClient: false,
                confirmClientTaskId: payload.task_id,
                confirmClientSuccessMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.POST_CLIENT_CONFIRM_FAILURE:
            return {
                ...state,
                isLoadingConfirmClient: false,
                confirmClientErrorMessage: payload.message,
            }
        case DRAFT_ACTION_TYPES.CLEAR_SR_DETAILS:
            return {
                ...state,
                srDocument: {}
            }

        case DRAFT_ACTION_TYPES.PUT_NOTES_ANSWER_START:
            return {
                ...state,
                isLoadingNotesAnsUpdate: true,
            }
        case DRAFT_ACTION_TYPES.PUT_NOTES_ANSWER_SUCCESS:
            return {
                ...state,
                isLoadingNotesAnsUpdate: false,
                notesAnsUpdateMsg: payload.message,
            }
        case DRAFT_ACTION_TYPES.PUT_NOTES_ANSWER_FAILURE:
            return {
                ...state,
                isLoadingNotesAnsUpdate: false,
                notesAnsUpdateErrorMsg: payload.message,
            }
        case DRAFT_ACTION_TYPES.CLEAR_RECOMMENDATION_LIST:
            return {
                ...state,
                recommendation: [],
                feeReconciliationList: [],
            }
        case DRAFT_ACTION_TYPES.CLEAR_CLIENT_TASK_RECOMMENDATION_LIST:
            return {
                ...state,
                clientTaskListrecommendation: [],
            }
        default:
                return state;
        }
}
export default draftReducer;