import { SURVEY_ACTION_TYPES } from './surveyForms.types';
//import surveyDummy from '../../services/surveyDummy.json';
import { getFormattedOptions, getFormattedSurvey } from '../../utils/utils';
import { pluckAssetSectionData,
    getAnsweredCount,
    addBlobUrl,
    pluckStructureToPush,
    getSavedValuesOnLoad,
    getCheckedValues,
    getSurveyButtonResponse,
    getEmailList,
    getPreviusAmount
} from './surveyForms.utils';



const INITIAL_STATE = {
    isLoadingSurveyForm: false,
    isLoadingPartnerSurveyForm:false,
    //surveyForm:surveyData.data,
    //surveyForm:getFormattedSurvey(surveyDummy.data),
    surveyForm: [],
    partnerSurveyForm:[],
    docDataSurveyForm:{},
    isDocConversionLoading:false,
    isLoadingOptions: false,
    optionsList: {},
    isLoadingPostSurveyForm: false,
    postSurveyResponse: null,
    surveyStatus: [],
    emailList:[],
    previousPolicyAmount:[],
    answeredQuestions: 0,
    isLoadingSurveyStatus: false,
    isLoadingPostRecord: false,
    postRecordErrorMessage: null,
    postRecordResponse: null,
    soundRecordList : [],
    isLoadingDeleteRecord: false,
    deleteResponse: null,
    surveyFormAssetSectionSubLabelsArray:[],
    partnerSurveyFormAssetSectionSubLabelsArray:[],
    surveyFormAssetSectionStructureToPush : {},
    countryList:[],
    savedValues:[],
    savedPartnerValues:[],
    checkedPartnerValues:[],
    checkedValues:[],
    surveyButton:[],
    surveyButtonSuccessMessage: null,
    surveyButtonErrorMessage: null
}
const surveyReducer = (state = INITIAL_STATE, { type, payload, fieldName }) => {
    switch (type)
    {

    

        case SURVEY_ACTION_TYPES.FETCH_DOC_CONVERSION_SURVEY_FORM_START:
            return {
                ...state,
                isDocConversionLoading:true
            }
        case SURVEY_ACTION_TYPES.FETCH_DOC_CONVERSION_SURVEY_FORM:
            return {
                ...state,
                docDataSurveyForm: payload.data,
                isDocConversionLoading:false
            }
        case SURVEY_ACTION_TYPES.CLEAR_FETCH_DOC_CONVERSION_SURVEY_FORM:
            return {
                ...state,
                docDataSurveyForm: {},
                isDocConversionLoading:false
            }
        case SURVEY_ACTION_TYPES.FETCH_SURVEYFORM_START:
            return {
                ...state,
                isLoadingSurveyForm: true,
            }
        case SURVEY_ACTION_TYPES.FETCH_SURVEYFORM_SUCCESS:
            let formattedSurveyData = getFormattedSurvey(payload.data);
            return {
                ...state,
                isLoadingSurveyForm: false,
                surveyForm: formattedSurveyData,
                surveyFormAssetSectionSubLabelsArray: pluckAssetSectionData(payload.data),
                // surveyFormAssetSectionStructureToPush : pluckStructureToPush(pluckAssetSectionData(payload.data))
                savedValues: getSavedValuesOnLoad(formattedSurveyData),
                checkedValues: getCheckedValues(formattedSurveyData)
            }
        case SURVEY_ACTION_TYPES.FETCH_SURVEYFORM_FAILURE:
            return {
                ...state,
                isLoadingSurveyForm: false,
                surveyFormErrorMessage: payload.message,
            }

            case SURVEY_ACTION_TYPES.FETCH_PARTNER_SURVEYFORM_START:
                return {
                    ...state,
                    isLoadingPartnerSurveyForm: true,
                }
            case SURVEY_ACTION_TYPES.FETCH_PARTNER_SURVEYFORM_SUCCESS:
                let formattedPartnerSurveyData = getFormattedSurvey(payload.data);
                return {
                    ...state,
                    isLoadingPartnerSurveyForm: false,
                    partnerSurveyForm: formattedPartnerSurveyData,
                    partnerSurveyFormAssetSectionSubLabelsArray: pluckAssetSectionData(payload.data),
                    // surveyFormAssetSectionStructureToPush : pluckStructureToPush(pluckAssetSectionData(payload.data))
                    savedPartnerValues: getSavedValuesOnLoad(formattedPartnerSurveyData),
                    checkedPartnerValues: getCheckedValues(formattedPartnerSurveyData)
                }
            case SURVEY_ACTION_TYPES.FETCH_PARTNER_SURVEYFORM_FAILURE:
                return {
                    ...state,
                    isLoadingPartnerSurveyForm: false,
                    partnerSurveyFormErrorMessage: payload.message,
                }

        case SURVEY_ACTION_TYPES.MODIFY_SURVEYFORM_START:
            return {
                ...state,
                isLoadingSurveyForm: true,
            }
        case SURVEY_ACTION_TYPES.MODIFY_SURVEYFORM_SUCCESS:
            return {
                ...state,
                isLoadingSurveyForm: false,
                surveyForm: payload.data
            }
        case SURVEY_ACTION_TYPES.MODIFY_SURVEYFORM_FAILURE:
            return {
                ...state,
                isLoadingSurveyForm: false,
                surveyForm: [...state.surveyForm, ...payload.data],
                surveyFormErrorMessage: payload.message,
            }

        case SURVEY_ACTION_TYPES.POST_SURVEYFORM_START:
            return {
                ...state,
                isLoadingPostSurveyForm: true,
            }
        case SURVEY_ACTION_TYPES.POST_SURVEYFORM_SUCCESS:
            return {
                ...state,
                isLoadingPostSurveyForm: false,
                postSurveyResponse: payload.status,
                surveyForm: getFormattedSurvey(payload.data),
                surveyFormAssetSectionSubLabelsArray: pluckAssetSectionData(payload.data),
                savedValues: getSavedValuesOnLoad(getFormattedSurvey(payload.data)),
                checkedValues: getCheckedValues(getFormattedSurvey(payload.data))
            }
        case SURVEY_ACTION_TYPES.POST_SURVEYFORM_FAILURE:
            return {
                ...state,
                isLoadingPostSurveyForm: false,
                postSurveyErrorMessage: payload.message,
            }

        case SURVEY_ACTION_TYPES.GET_SELECTABLE_START:
            return {
                ...state,
                isLoadingOptions: true,
            }
        case SURVEY_ACTION_TYPES.GET_SELECTABLE_SUCCESS:
            return {
                ...state,
                isLoadingOptions: false,
                optionsList: getFormattedOptions(payload.data, fieldName)
            }
        case SURVEY_ACTION_TYPES.GET_SELECTABLE_FAILURE:
            return {
                ...state,
                isLoadingOptions: false,
                getOptionlistErrorMessage: payload.message,
            }

        case SURVEY_ACTION_TYPES.FETCH_SURVEYSTATUS_START:
            return {
                ...state,
                isLoadingSurveyStatus: true,
            }
        case SURVEY_ACTION_TYPES.FETCH_SURVEYSTATUS_SUCCESS:
            return {
                ...state,
                isLoadingSurveyStatus: false,
                surveyStatus: payload.data,
                answeredQuestions: getAnsweredCount(payload.data)
            }
        case SURVEY_ACTION_TYPES.FETCH_SURVEYSTATUS_FAILURE:
            return {
                ...state,
                isLoadingSurveyStatus: false,
                surveyStatusErrorMessage: payload.message,
                answeredQuestions: 0
            }
            case SURVEY_ACTION_TYPES.FETCH_EMAIL_START:
                return {
                    ...state,
                    isLoadingEmailList: true,
                }
            case SURVEY_ACTION_TYPES.FETCH_EMAIL_SUCCESS:
                return {
                    ...state,
                    isLoadingEmailList: false,
                    emailList:payload.data,
                    answeredQuestions: getEmailList(payload.data)
                }
            case SURVEY_ACTION_TYPES.FETCH_EMAIL_FAILURE:
                return {
                    ...state,
                    isLoadingEmailList: false,
                    emailListErrorMessage: payload.message,
                    answeredQuestions: 0
                }
  
                    case SURVEY_ACTION_TYPES.FETCH_PREVIUS_AMOUNT_START:
                        return {
                            ...state,
                            isLoadingClientList: true,
                        }
                    case SURVEY_ACTION_TYPES.FETCH_PREVIUS_AMOUNT_SUCCESS:
                        return {
                            ...state,
                            isLoadingClientList: false,
                            previousPolicyAmount:payload.data,
                            answeredQuestions: getPreviusAmount(payload.data)
                        }
                    case SURVEY_ACTION_TYPES.FETCH_PREVIUS_AMOUNT_FAILURE:
                        return {
                            ...state,
                            isLoadingClientList: false,
                            clientListErrorMessage: payload.message,
                            answeredQuestions: 0
                        }

        case SURVEY_ACTION_TYPES.SAVE_SOUND_RECORDING_START:
            return {
                ...state,
                isLoadingPostRecord: true,
            }
        case SURVEY_ACTION_TYPES.SAVE_SOUND_RECORDING_SUCCESS:
            return {
                ...state,
                isLoadingPostRecord: false,
                postRecordResponse: payload
            }
        case SURVEY_ACTION_TYPES.SAVE_SOUND_RECORDING_FAILURE:
            return {
                ...state,
                isLoadingPostRecord: false,
                postRecordErrorMessage: payload.message
            }


        case SURVEY_ACTION_TYPES.GET_SOUND_RECORDING_START:
            return {
                ...state,
                isLoadingGetRecord: true,
            }
        case SURVEY_ACTION_TYPES.GET_SOUND_RECORDING_SUCCESS:
            return {
                ...state,
                isLoadingGetRecord: false,
                soundRecordList : addBlobUrl(payload.data)
            }
        case SURVEY_ACTION_TYPES.GET_SOUND_RECORDING_FAILURE:
            return {
                ...state,
                isLoadingGetRecord: false,
                getRecordErrorMessage: payload.message
            }

        case SURVEY_ACTION_TYPES.DELETE_SOUND_RECORDING_START:
            return {
                ...state,
                isLoadingDeleteRecord: true,
            }
        case SURVEY_ACTION_TYPES.DELETE_SOUND_RECORDING_SUCCESS:
            return {
                ...state,
                deleteResponse: payload,
                isLoadingDeleteRecord: false,
            }
        case SURVEY_ACTION_TYPES.DELETE_SOUND_RECORDING_FAILURE:
            return {
                ...state,
                isLoadingDeleteRecord: false,
                deleteRecordErrorMessage: payload.message
            }

        case SURVEY_ACTION_TYPES.CLEAR_SOUND_RECORDING_MESSAGES:
            return {
                ...state,
                postRecordResponse: null,
                postRecordErrorMessage: null,
                deleteResponse: null
            }

        case SURVEY_ACTION_TYPES.CLEAR_SURVEY_DATA:
            return {
                ...state,
                surveyForm: [],
                savedValues: [],
                checkedValues: [],
                surveyFormAssetSectionSubLabelsArray: [],
                postSurveyResponse: null
            }
        case SURVEY_ACTION_TYPES.CLEAR_PARTNER_SURVEY_DATA:
                return {
                    ...state,
                    partnerSurveyForm: [],
                    partnerSurveyFormAssetSectionSubLabelsArray: [],
                    savedPartnerValues: [],
                    checkedPartnerValues: [],
                    postSurveyResponse: null
                }

        case SURVEY_ACTION_TYPES.GET_COUNTRY_LIST_START:
            return {
                ...state,
                isLoadingCountryList: true,
            }

        case SURVEY_ACTION_TYPES.GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                isLoadingCountryList: false,
                countryList: payload.data,
                //optionsList: getFormattedOptions(payload.data, fieldName)
            }

        case SURVEY_ACTION_TYPES.GET_COUNTRY_LIST_FAILURE:
            return {
                ...state,
                isLoadingCountryList: false,
            }

        case SURVEY_ACTION_TYPES.POST_SURVEY_BUTTON_CLICK_START:
            return {
                ...state,
                isLoadingButtonClick: true,
            }
        case SURVEY_ACTION_TYPES.POST_SURVEY_BUTTON_CLICK_SUCCESS:
            return {
                ...state,
                isLoadingButtonClick: false,
                surveyButton: getSurveyButtonResponse(payload, fieldName, state.surveyButton),
                surveyButtonSuccessMessage: payload.message,
            }
        case SURVEY_ACTION_TYPES.POST_SURVEY_BUTTON_CLICK_FAILURE:
            return {
                ...state,
                isLoadingButtonClick: false,
                surveyButtonErrorMessage: payload.message,
            }
        case SURVEY_ACTION_TYPES.CLEAR_BUTTON_CLICK_MESSAGES:
            return {
                ...state,
                surveyButtonErrorMessage: null,
                surveyButtonSuccessMessage: null,
                isLoadingButtonClick: false,
                surveyButton:[]
            }

        default:
            return state;
    }
}


export default surveyReducer;