import React, { useState, useEffect } from 'react'
import Modal from '../../../components/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import FileBrowser from '../../../components/FileBrowser/FileBrowser';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../components/Button/Button';
import Tab from '../../TabsComponent/Tab/Tab';
import Tabs from '../../TabsComponent/Tabs/Tabs';
import TabPanel from '../../TabsComponent/TabPanel/TabPanel';
import FileBrowseWithDragAndDrop from '../../FileBrowseWithDragAndDrop/FileBrowseWithDragAndDrop';
import { useSnackbar } from 'notistack';
import { setFilesSelectedFromAttachmentModal, setTotalSizeOfDriveAndLocalFiles, setFilesSelectedFromAttachmentModalIthActionName, setActionName } from '../../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';
import { getInternalDriveFoldersAsync } from '../../../redux/mail/mail.action';
import { useSelector } from 'react-redux';
import CircularLoader from '../../CircularLoader/CircularLoader';
import './FileAttachmentModalComponent.scss';




const FileAttachmentModalComponent = ({
    open,
    handleClose,
    size,
    fileTypeErrorMessage,
    fileSizeErrorMessage,
    accept,
    maxSize,
    enableCapDrive=true,
    multiple=true
}) => {

    const dispatch = useDispatch();
    const [driveFiles, setDriveFiles] = useState([]);
    const [value, setValue] = useState(0);
    const [ isSelectDisabled, setSelectDisabled ] = useState( false );
    const { enqueueSnackbar } = useSnackbar();
    const internalDriveFoldersData = useSelector(state => state.mail.internalDriveFoldersData);
    const isInternalDriveFoldersFetching = useSelector(state => state.mail.isInternalDriveFoldersFetching);
    const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
    const actionName = useSelector(state => state.ui.actionName);


    const handleChange = (event, newValue) => {
        //  if 1 its cap drive
        if (newValue === 1)
        {
            dispatch(getInternalDriveFoldersAsync());
        }
        setValue(newValue);
    };



    const selectDriveFilesHandler = () => {
       
        const fileDriveItemsToSameFormat = [] 
        let totalDriveItemsFileSize = 0;
        driveFiles.forEach(item => {
            if (item.item_type === 'file')
            {
                // since local files picked and drive files picked are kept in same array in ui reducer, items pushed should be objects 
                fileDriveItemsToSameFormat.push({
                    name: item.item_name,
                    fileId: item.item_id,
                    item_path: item.item_path,
                    item_size: item.item_size
                })
                totalDriveItemsFileSize += totalDriveItemsFileSize + item.item_size; 
            }
        })
        
        // prevent user from selecting file larger than maxsize from capdrive
        if(totalDriveItemsFileSize > maxSize)
        {   
            enqueueSnackbar(fileSizeErrorMessage, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
        else if(fileDriveItemsToSameFormat.length > 0)
        {
            dispatch(setFilesSelectedFromAttachmentModal(fileDriveItemsToSameFormat))

            // if there is action name set new files :: only dependant on mail replay,replayall,forward
            if(actionName)
            {  
                dispatch(setFilesSelectedFromAttachmentModalIthActionName({
                    actionName : actionName,
                    files : fileDriveItemsToSameFormat,
                }))
                dispatch(setActionName(null)) // clear action name on when modal closes on picking drive files
            }
            // if there is action name set new files :: only dependant on mail replay,replayall,forward

            handleClose();
        }
    }

    
    // Section Starts :: Prevent user from selecting folder -- 
    useEffect(()=>{
        driveFiles.some( item => {
            if(item.item_type === 'folder')
            {
                setSelectDisabled(true);
                return true;
            }
            else
            {
                setSelectDisabled(false);
            }
        })
    },[driveFiles])
    // Section Starts :: Prevent user from selecting folder -- 
    


     // Section Starts :: recalculate total file size when new files are added and removed -- 
     useEffect(() => {
      
        const driveFileItems = [];
        let newDriveItemsFileSize = 0;
        const localFileAttachments = [];
        let newLocalAttachmentFileSize = 0;
        filesSelectedFromAttachmentModal.forEach( fileItem => {
            if (fileItem instanceof File)
            {
                localFileAttachments.push(fileItem);
                newLocalAttachmentFileSize += fileItem.size;
            }
            else
            {
                driveFileItems.push(fileItem);
                newDriveItemsFileSize += fileItem.item_size;
            }
        })

        dispatch(setTotalSizeOfDriveAndLocalFiles({
            totalCapDriveFileSize : newDriveItemsFileSize,
            totalLocalFileSize : newLocalAttachmentFileSize,
            totalAttachmentFileSize : newDriveItemsFileSize + newLocalAttachmentFileSize
        }))


    },[filesSelectedFromAttachmentModal])

    // Section Starts :: recalculate total file size when new files are added and removed -- 


 


    return (
        <Modal open={open} handleClose={handleClose} size={size} className="fileAttachmentModal">
            <DialogContent>

                <Tabs value={value} onChange={handleChange} aria-label="file browser">
                    <Tab label="Upload from computer" />
                    {enableCapDrive && <Tab label="My drive" />}
                </Tabs>

                {/*  Browse from local system */}
                <TabPanel value={value} index={0}>
                    <FileBrowseWithDragAndDrop
                        placeholder={<p>Drag and drop files or <span className="highlighter">Browse</span></p>}
                        accept={accept}
                        className="filePicker"
                        multiple={multiple}
                        onDropAccepted={(acceptedFiles) => {
                            dispatch(setFilesSelectedFromAttachmentModal(acceptedFiles));
                            handleClose();


                            // if there is action name set new files :: only dependant on mail replay,replayall,forward
                            if(actionName)
                            {
                                dispatch(setFilesSelectedFromAttachmentModalIthActionName({
                                    actionName : actionName,
                                    files : acceptedFiles,
                                }))
                                dispatch(setActionName(null)) // clear action name on when modal closes on picking drive files
                            }
                            // if there is action name set new files :: only dependant on mail replay,replayall,forward

                            dispatch({ type : 'SET_ACTION_NAME', payload : null }) // clear action name on when modal closes on picking local files
                        }}
                        onDropRejected={(props) => {
                            if (props.length > 0)
                            {
                                const errors = props[0].errors
                                let wrongFileTypeErrorMsg;
                                let fileSizeErrorMsg;
                                let errorArray = [];
                                if (errors)
                                {
                                    errors.forEach(errorItem => {
                                        if(errorItem.code === 'file-invalid-type')
                                        {  
                                            // if message passed used it else use default message 
                                            if(fileTypeErrorMessage)
                                            {
                                                wrongFileTypeErrorMsg = fileTypeErrorMessage;
                                                errorArray.push(fileTypeErrorMessage);
                                            }
                                            else
                                            {
                                                wrongFileTypeErrorMsg = errorItem.message;
                                                errorArray.push(errorItem.message);
                                            }
                                           
                                        }
                                        else if(errorItem.code === 'file-too-large')
                                        {
                                            // if message passed used it else use default message
                                            if(fileSizeErrorMessage)
                                            {
                                                fileSizeErrorMsg = fileSizeErrorMessage;
                                                errorArray.push(fileSizeErrorMessage);
                                            }
                                            else
                                            {
                                                fileSizeErrorMsg = errorItem.message;
                                                errorArray.push(errorItem.message);
                                            }
                                            
                                        }
                                    });
                                }
                                // const errorMessageToShow = `${wrongFileTypeErrorMsg ? wrongFileTypeErrorMsg + ' and' : ''} ${fileSizeErrorMsg ? fileSizeErrorMsg : ''}`;
                                const errorMessageToShow = errorArray.toString()
                                enqueueSnackbar(errorMessageToShow, {
                                    variant: 'error',
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                })
                            }

                        }}
                        maxSize={maxSize}
                    />

                </TabPanel>


                {/* CAP internal drive */}
                <TabPanel value={value} index={1}>
                    {
                        (isInternalDriveFoldersFetching && internalDriveFoldersData)
                        ?
                        <div className="fileBrowserWrapper">
                            <FileBrowser
                                fileData={internalDriveFoldersData}
                                setDriveFiles={setDriveFiles}
                                setSelectDisabled={setSelectDisabled}
                            />
                            <DialogActions className="modalFooter">
                                <Button onClick={handleClose} color="primary" customStyle={{ background: 'white' }} width='150px'>
                                    Cancel
                                </Button>
                                <Button 
                                    onClick={selectDriveFilesHandler} 
                                    color="primary" 
                                    autoFocus 
                                    bgColor="secondary" 
                                    width='150px'
                                    disabled={isSelectDisabled}
                                >
                                    SELECT
                                </Button>
                            </DialogActions>
                        </div>
                        :
                        <div className="loaderWrapper">
                            <CircularLoader className="fileAttachmentLoader" size={20} />
                        </div>
                    }
                </TabPanel>
            </DialogContent>
        </Modal>
    )
}

export default FileAttachmentModalComponent;
