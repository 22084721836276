import {
    fetchRecentProviders,
    fetchSearchProviders,
    fetchInstrumentForClient,
    postInstrumentForClient,
    fetchClientInstrumentInfo,
    fetchClientInstrumentCount,
    patchClientInstrumentType,
    uploadClientDocument,
    fetcEmailTemplateForPreview,
    postBulkEmail,
    fetchClientLoaDoc,
    deleteClientLoaDoc,
    fetchReminderListService,
    saveSnoozeDataService,
    fetchFilteredReminderDataService,
    removeReminderEndDateService,
    fetchPendingWithDataService,
    postBulkReminder,
    pickPossibleProductsForDraftRecommendationService,
    fetchPossibleRecommendedProductsService,
    saveProductsForDraftRecommendationService,
    getProductComparisonDataService,
    postExtractionDataService,
    deleteProductService,
    updatedFromBackData,
} from '../../services/instrumentService';


import { INSTRUMENT_ACTION_TYPES } from '../../redux/instrument/instrument.types';

export const getRecentProvider = () => {
    return dispatch => {
        fetchRecentProviders(dispatch)
    }
}

export const searchProvider = (key) => {
    return dispatch => {
        fetchSearchProviders(dispatch, key)
    }
}

export const getInstruments = (providerId, clientId) => {
    return dispatch => {
        fetchInstrumentForClient(dispatch, providerId, clientId)
    }
}


export const postInstrument = (paramsArray) => {
    return dispatch => {
        postInstrumentForClient(dispatch, paramsArray)
    }
}

export const getClientInstruments = (clientId,fee_management=false) => {
    return dispatch => {
        fetchClientInstrumentInfo(dispatch, clientId,fee_management)
    }
}
export const getUpdatedFromBackData = (data) => {
    return dispatch => {
        updatedFromBackData(dispatch, data)
    }
}
export const clearUpdatedFromBackDataAction= () => ({
    type:INSTRUMENT_ACTION_TYPES.CLEAR_UPDATE_FROM_BACK_DATA
})

export const getClientInstrumentCount = (clientId) => {
    return dispatch => {
        fetchClientInstrumentCount(dispatch, clientId)
    }
}

export const updateClientInstrumentType = (instrumentId, instrumentType) => {
    return dispatch => {
        patchClientInstrumentType(dispatch, instrumentId, instrumentType)
    }
}

export const uploadDocument = (instrumentId, clientDoc, docId = '', fromDraftDocs=false, isTaskDoc = false) => {
    return dispatch => {
        uploadClientDocument(dispatch, instrumentId, clientDoc, docId, fromDraftDocs, isTaskDoc)
    }
}

export const getEmailTemplatePreview = (instrumentId, emailTemplateId) => {
    return dispatch => {
        fetcEmailTemplateForPreview(dispatch, instrumentId, emailTemplateId)
    }
}

export const removeEmailTemplateData = () => ({
    type: INSTRUMENT_ACTION_TYPES.CLEAR_EMAIL_TEMPLATE_PREVIEW,
})

export const sendBulkEmail = (mailId, instrumentList) => {
    return dispatch => {
        postBulkEmail(dispatch, mailId, instrumentList)
    }
}

export const viewClientLoa = (loaId) => {
    return dispatch => {
        fetchClientLoaDoc(dispatch, loaId)
    }
}

export const deleteClientLoa = (instrumentId, loaId) => {
    return dispatch => {
        deleteClientLoaDoc(dispatch, instrumentId, loaId)
    }
}

export const removeInstrumentList = () => ({
    type: INSTRUMENT_ACTION_TYPES.CLEAR_INSTRUMENT_LIST,
})

export const removeClientLoa = () => ({
    type: INSTRUMENT_ACTION_TYPES.CLEAR_CLIENT_LOA,
})

export const clearErrorMessage = (TYPE) => ({
    type: TYPE
})

// export const fetchReminderListAsync = (filterOptions) => {
//     return dispatch => {
//         fetchReminderListService(dispatch, filterOptions)
//     }
// }


export const saveSnoozeDataAsync = (snoozeDetails) => {
    return dispatch => {
        saveSnoozeDataService(dispatch, snoozeDetails)
    }
}
export const removeSaveSnoozeDataSuccessMsg = (TYPE) => ({
    type: INSTRUMENT_ACTION_TYPES.REMOVE_SAVE_SNOOZE_DATA_SUCCESS_MSG
})

export const removeSaveSnoozeDataErrorMsg = (TYPE) => ({
    type: INSTRUMENT_ACTION_TYPES.REMOVE_SAVE_SNOOZE_DATA_ERROR_MSG
})


export const removeReminderEndDateAsync = (snoozeDetails) => {
    return dispatch => {
        removeReminderEndDateService(dispatch, snoozeDetails)
    }
}



export const fetchFilteredReminderDataAsync = (reminderDetails) => {
    return dispatch => {
        fetchFilteredReminderDataService(dispatch, reminderDetails)
    }
}


export const fetchPendingWithDataAsync = (searchString) => {
    return dispatch => {
        fetchPendingWithDataService(dispatch, searchString)
    }
}


export const clearNewReminderData = () => ({
    type : INSTRUMENT_ACTION_TYPES.CLEAR_NEW_REMINDER_DATA
})

export const removeReminderErrorMsg = () => ({
    type : INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_ERROR_MSG
})
export const removeReminderSuccessMsg = () => ({
    type : INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_SUCCESS_MSG
})



export const sendBulkReminder = (mailId, instrumentList) => {
    return dispatch => {
        postBulkReminder(dispatch, mailId, instrumentList)
    }
}


export const pickPossibleProductsForDraftRecommendationAsync = (instrumentsAndClientId) => {
    return dispatch => {
        pickPossibleProductsForDraftRecommendationService(dispatch, instrumentsAndClientId)
    }
}


export const fetchPossibleRecommendedProductsAsync = (clientId) => {
    return dispatch => {
        fetchPossibleRecommendedProductsService(dispatch, clientId)
    }
}


export const saveProductsForDraftRecommendationAsync = (productDetails) => {
    return dispatch => {
        saveProductsForDraftRecommendationService(dispatch, productDetails)
    }
}


export const getProductComparisonDataAsync = (productList) => {
    return dispatch => {
        getProductComparisonDataService(dispatch, productList)
    }
}

export const updateExtractionDataService = (postData) => {
    return dispatch => {
        postExtractionDataService(dispatch, postData)
    }
}

export const deleteProduct = (productId) => {
    return dispatch => {
        deleteProductService(dispatch, productId)
    }
}

export const removeInstrumentSummaryList = () => ({
    type: INSTRUMENT_ACTION_TYPES.CLEAR_INSTRUMENT_SUMMARY_LIST,
})

export const clearClientInstruments = () => ({
    type: INSTRUMENT_ACTION_TYPES.CLEAR_CLIENT_INSTRUMENTS,
})

export const clearProductComparisonData = () => ({
    type: INSTRUMENT_ACTION_TYPES.CLEAR_PRODUCT_COMPARISON_DATA
})
export const saveSelectedProducts = () => {
    return{
    type: "saveSelectedProductList"
}}
export const fetchProductSummary = (data) => ({
    type: INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_SUCCESS,
    payload:{data}
})

