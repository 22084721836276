export const dummy = {

    "status code": 200,

    "status": true,

    "message": "Success",

    "data": [

        {

            "id": 1,

            "client": {

                "id": 1,

                "user": {

                    "id": 11,

                    "username": "gayathri@gmail.com",

                    "first_name": "gayathri",

                    "last_name": "Juliet",

                    "email": "gayathri@gmail.com",

                    "phone_number": "4563343456",

                    "user_type": "Client"

                },

                "phone_number": "4563343456",

                "referred_by_id": 9,

                "referred_date": "2022-01-24",

                "company": "giglabz",

                "enable_cold_calling": false,

                "referred_user_first_name": "Ajay",

                "referred_user_last_name": "Jayakumar",

                "referred_user_email": "amal.mohanan@giglabz.com",

                "referred_user_phone_number": "",

                "company_id": 85,

                "net_worth": "0.00",

                "pre_contract_percent": 40,

                "atp_percent": 0,

                "post_contract_percent": 0,

                "is_confirmed_client": false,

                "created_by": 8,

                "current_task_id": null,

                "is_survey_updated": false,

                "advisor": "Amal Mohanan",

                "ni_number": null,

                "is_existing": false,

                "is_new_client": true,

                "is_Exist_FeeNotManaged": false,

                "is_advisor_agreed": true,

                "client_type": "Gold",

                "joint_client": false,

                "relative_client": null,

                "parallelly_added": false,

                "aum": 60

            },

            "is_deleted": false,

            "create_time": "2023-08-02T11:43:42.927831Z",

            "updated_time": "2023-08-08T08:54:10.573344Z",

            "current_color": "008000",

            "last_email_sent": "2023-07-02T11:43:42.927831Z"

        },

        {

            "id": 2,

            "client": {

                "id": 2,

                "user": {

                    "id": 12,

                    "username": "mikasa12@gnail.com",

                    "first_name": "Mikasa",

                    "last_name": "Ackerman",

                    "email": "mikasa12@gnail.com",

                    "phone_number": "8967564567",

                    "user_type": "Client"

                },

                "phone_number": "8967564567",

                "referred_by_id": null,

                "referred_date": null,

                "company": "TEST COMPANY",

                "enable_cold_calling": false,

                "referred_user_first_name": null,

                "referred_user_last_name": null,

                "referred_user_email": null,

                "referred_user_phone_number": "",

                "company_id": 3,

                "net_worth": "0.00",

                "pre_contract_percent": 60,

                "atp_percent": 0,

                "post_contract_percent": 0,

                "is_confirmed_client": false,

                "created_by": 7,

                "current_task_id": null,

                "is_survey_updated": false,

                "advisor": "Bibin J",

                "ni_number": null,

                "is_existing": true,

                "is_new_client": false,

                "is_Exist_FeeNotManaged": false,

                "is_advisor_agreed": false,

                "client_type": "Gold",

                "joint_client": false,

                "relative_client": null,

                "parallelly_added": false,

                "aum": 60

            },

            "is_deleted": false,

            "create_time": "2023-08-02T11:43:49.495054Z",

            "updated_time": "2023-08-08T08:54:10.579528Z",

            "current_color": "008000",

            "last_email_sent": "2023-07-02T11:43:49.495054Z"

        },

        {

            "id": 3,

            "client": {

                "id": 3,

                "user": {

                    "id": 13,

                    "username": "luffy12@gnail.com",

                    "first_name": "Monkey",

                    "last_name": "D Luffy",

                    "email": "luffy12@gnail.com",

                    "phone_number": "8878675789",

                    "user_type": "Client"

                },

                "phone_number": "8878675789",

                "referred_by_id": null,

                "referred_date": null,

                "company": "TEST COMPANY",

                "enable_cold_calling": false,

                "referred_user_first_name": null,

                "referred_user_last_name": null,

                "referred_user_email": null,

                "referred_user_phone_number": "",

                "company_id": 3,

                "net_worth": "0.00",

                "pre_contract_percent": 50,

                "atp_percent": 0,

                "post_contract_percent": 0,

                "is_confirmed_client": false,

                "created_by": 7,

                "current_task_id": null,

                "is_survey_updated": true,

                "advisor": "Bibin J",

                "ni_number": null,

                "is_existing": true,

                "is_new_client": false,

                "is_Exist_FeeNotManaged": false,

                "is_advisor_agreed": false,

                "client_type": "Gold",

                "joint_client": false,

                "relative_client": null,

                "parallelly_added": false,

                "aum": 90

            },

            "is_deleted": false,

            "create_time": "2023-08-02T11:43:57.484917Z",

            "updated_time": "2023-08-08T08:54:10.581526Z",

            "current_color": "#90EE90",

            "last_email_sent": "2023-07-22T11:43:57.484917Z"

        }
    ]
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to get a random color code (replace with your implementation)
function getColorCode() {
    // Replace this with your logic to get a random color code
    const sortOptions = [
        '#90EE90',
        '#008000',
        '#013220',
        '#FF0000',
        '#8B0000',
    ]
    
    return sortOptions[Math.floor(Math.random() * sortOptions.length)];
}


export const GenerateData = (limit) => {
    const dataArray = [];

    // Generate 15 objects with dummy data
    for (let i = 0; i < limit; i++) {
        let dum = getRandomInt(1, 10)
        const dummyObject = {
            name: `Dummy User ${i}`,
            size:dum ,
            aum:dum * 10000,
            color: getColorCode(),
            last_email_sent: new Date().toISOString(),
            email: `dummyuser${i}@example.com`,
            client_id: i + 1
        };

        dataArray.push(dummyObject);
    }
    return dataArray
}