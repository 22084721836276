import { FEE_MANAGEMENT, LIST_PRODUCT, MONTHLY_ISSUED, PENDING_ISSUED, SEARCH_CLIENT, ADD_CLIENT, SEARCH_CLIENT_REUSLTS, SELECT_CLIENT, PRODUCT_SUMMARY, PRODUCT_LIST, FEE_MATCHING , FEE_SPLIT,UPDATE_VALUE} from "./routing.types";

const INITIAL_STATE = {

    updateValue: new Date(),

}
const routingReducer = (state = INITIAL_STATE, action) => {




    switch (action.type) {
        case FEE_MANAGEMENT:
            state = FEE_MANAGEMENT
            return state;
        case SELECT_CLIENT:
            state = SELECT_CLIENT
            return state;
        case SEARCH_CLIENT:
            state = SEARCH_CLIENT
            return state;
        case ADD_CLIENT:
            state = ADD_CLIENT
            return state;
        case SEARCH_CLIENT_REUSLTS:
            state = SEARCH_CLIENT_REUSLTS
            return state;
        case MONTHLY_ISSUED:
            state = MONTHLY_ISSUED
            return state;
        case PENDING_ISSUED:
            state = PENDING_ISSUED
            return state;
        case PRODUCT_SUMMARY:
            state = PRODUCT_SUMMARY
            return state;
        case PRODUCT_LIST:
            state = PRODUCT_LIST
            return state;
        case FEE_MATCHING:
            state = FEE_MATCHING
            return state;
        case LIST_PRODUCT:
            state = LIST_PRODUCT
            return state;
        case FEE_SPLIT:
            state = FEE_SPLIT
            return state;
     
        case UPDATE_VALUE:
            state = UPDATE_VALUE
                return {
                  ...state,
                  updateValue : action.data
                };

        default:
            return state

    }



}
export default routingReducer