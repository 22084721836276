import { axiosGet } from '../../utils/api'
import { DASHBOARD_ACTION_TYPES } from './dashboard.types'

export const setCurrentClientType = payload => ({
  type: DASHBOARD_ACTION_TYPES.SET_CURRENT_CLIENT_TYPE,
  payload: payload
})

export const setCurrentTab = payload => ({
  type: DASHBOARD_ACTION_TYPES.SET_CURRENT_TAB,
  payload: payload
})

export const setClientTypeData = data => ({
  type: DASHBOARD_ACTION_TYPES.SET_CLIENT_TYPE_DATA,
  payload: data
})

export const setIsClientTypeLoading = data => ({
  type: DASHBOARD_ACTION_TYPES.SET_IS_CLIENT_TYPE_DATA_FETCHING,
  payload: data
})

export const fetchClientTypeData = () => {
  return async dispatch => {
    dispatch(setIsClientTypeLoading(true))

    axiosGet('client-aum-configuration/').then(res => {
      if (res.data.data) {
        dispatch(setClientTypeData(res.data.data))
        dispatch(setIsClientTypeLoading(false))
      } else {
        // console.log(res, 'res')
      }
    })
  }
}

export const setSocialMediumData = data => ({
  type: DASHBOARD_ACTION_TYPES.SET_SOCIAL_MEDIUMS_DATA,
  payload: data
})

export const setIsSocialMediumsFetching = data => ({
  type: DASHBOARD_ACTION_TYPES.SET_IS_SOCIAL_MEDIUM_DATA_FETCHING,
  payload: data
})

export const fetchSocialMediums = () => {
  return async dispatch => {
    dispatch(setIsSocialMediumsFetching(true))

    axiosGet('add-socialmedia-type/').then(res => {
      if (res.data.data) {
        dispatch(setSocialMediumData(res.data.data))
        dispatch(setIsSocialMediumsFetching(false))
      } else {
        // console.log(res, 'res')
      }
    })
  }
}
