import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from '@material-ui/core/styles';




const TextFieldBox = (props) => {
  const { label,name,value,onChange,error,helperText,inputId,className } = props;


const useStyles = makeStyles(theme => {
  return {
      root: {

          '& label.Mui-focused': {
              color: theme.palette.secondary.main
          },
          // outlined text field border color changing
          '& .MuiOutlinedInput-root': {

              '&.Mui-disabled': {
                  '& fieldset': {
                      borderColor: theme.palette.common.themeGrayLight
                  }
              },

              '&.Mui-focused fieldset': {
                  borderColor: theme.palette.secondary.main
              },
          },
          // in case of standard text field
          '& .MuiInput-underline': {
              '&::before': {
                  borderBottom: `1px solid #eeeeee`
              },
              '&::after': {
                  borderBottom: `2px solid ${theme.palette.secondary.main}`
              },
              '&.Mui-error': {
                  '&::after': {
                      borderBottom: `2px solid ${theme.palette.error.main}`
                  }
              },
              '&:hover:not(.Mui-disabled):before': {
                  borderBottom: `2px solid ${theme.palette.secondary.main}`
              },
          },
      }
  }
});

const customStyles = useStyles();

  return (
    <>
      <div style={{ padding: "10px 0" }}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "30ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
          error={error}
         // helperText="Please fill atleast one field!"
            size="small"
            id={inputId}
            label={label}
            variant="outlined"
           value={value}
           name={name}
           onChange={onChange}
           helperText={helperText}
           className={`
           ${className}
           ${customStyles.root}
       `}
          />
        </Box>
      </div>
    </>
  );
};
export default TextFieldBox;
