import { CLIENT_ACTION_TYPES } from "../redux/client/client.types";
import {
  CLIENT_DETAILS,
  CLIENT_DOCUMENTS,
  CLIENT_DESCRIPTION,
  CLIENT_ACTIVITY_FLOW,
  CLIENT_NEXT_MEETING,
  CLIENT_VULNERABLE_STATUS,
} from "../utils/endPoint";
import {
  axiosPost,
  axiosGet,
  axiosPut,
  axiosDelete,
  axiosPatch,
  handleOnError,
  handleOnSuccess,
  handleActionStart,
} from "../utils/api";
import axios from "axios";
import { useSelector } from "react-redux";

export const fetchClientDetails = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(CLIENT_ACTION_TYPES.FETCH_CLIENT_START));
    const data = await axiosGet(CLIENT_DETAILS(clientId));
    dispatch(handleOnSuccess(data, CLIENT_ACTION_TYPES.FETCH_CLIENT_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, CLIENT_ACTION_TYPES.FETCH_CLIENT_FAILURE)
    );
  }
};
export const fetchClientDescription = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(CLIENT_ACTION_TYPES.FETCH_CLIENT_DESC_START));
    const data = await axiosGet(CLIENT_DESCRIPTION(clientId));
    dispatch(handleOnSuccess(data, CLIENT_ACTION_TYPES.FETCH_CLIENT_DESC_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, CLIENT_ACTION_TYPES.FETCH_CLIENT_DESC_FAILURE)
    );
  }
};
export const fetchVulnerableStatus = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(CLIENT_ACTION_TYPES.FETCH_VULNERABLE_STATUS_START));
    const data = await axiosGet(CLIENT_VULNERABLE_STATUS(clientId));
    dispatch(handleOnSuccess(data, CLIENT_ACTION_TYPES.FETCH_VULNERABLE_STATUS_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response?.hasOwnProperty("data")?err.response:{...err.response,data:[]}, CLIENT_ACTION_TYPES.FETCH_VULNERABLE_STATUS_FAILURE)
    );
  }
};
export const fetchClientDocuments = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(CLIENT_ACTION_TYPES.FETCH_CLIENT_DOCS_START));
    const data = await axiosGet(CLIENT_DOCUMENTS(clientId));
    dispatch(
      handleOnSuccess(data, CLIENT_ACTION_TYPES.FETCH_CLIENT_DOCS_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, CLIENT_ACTION_TYPES.FETCH_CLIENT_DOCS_FAILURE)
    );
  }
};

export const fetchRecentClientDocuments = async (
  dispatch,
  clientId,
  fetchRecent,
  task_id
) => {
  try {
    dispatch(
      handleActionStart(CLIENT_ACTION_TYPES.FETCH_RECENT_CLIENT_DOCS_START)
    );
    const data = await axiosGet(
      CLIENT_DOCUMENTS(clientId, fetchRecent, task_id)
    );
    dispatch(
      handleOnSuccess(
        data,
        CLIENT_ACTION_TYPES.FETCH_RECENT_CLIENT_DOCS_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        CLIENT_ACTION_TYPES.FETCH_RECENT_CLIENT_DOCS_FAILURE
      )
    );
  }
};

export const fetchActivityFlow = async (dispatch, clientId) => {
  try {
    dispatch(
      handleActionStart(CLIENT_ACTION_TYPES.FETCH_CLIENT_ACTIVITY_START)
    );
    const data = await axiosGet(CLIENT_ACTIVITY_FLOW(clientId));
    dispatch(
      handleOnSuccess(data, CLIENT_ACTION_TYPES.FETCH_CLIENT_ACTIVITY_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        CLIENT_ACTION_TYPES.FETCH_CLIENT_ACTIVITY_FAILURE
      )
    );
  }
};

export   const SearchClientApi = async (userToken, name, email, phone,insuranceId) => {   
   return  await fetch(
        `${process.env.REACT_APP_BASE_URL}clients/?name=${name}&email=${email}&mob_number=${phone}&company=&referred_by=&referred_date=&recently_added_client_list=&limit=10&offset=0&enable_cold_calling=false&ni_number=${insuranceId}`,
  
        {
          method: "GET",
          mode: "cors",
          headers: {
            authToken: `Token ${userToken}`,
          },
        }
      ).then(res=> res.json()).then(data => data);
};


export   const searchProductsResults = async (userToken,search) => {
  return  await fetch(
       `${process.env.REACT_APP_BASE_URL}instrument-providers/?search=${search}`,
 
       {
         method: "GET",
         mode: "cors",
         headers: {
           authToken: `Token ${userToken}`,
         },
       }
     ).then(res=> res.json()).then(data => data);
};