import React from 'react';
import { Tab as MuiTab, makeStyles, createStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => createStyles({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.common.TextMain,
        '&$selected': {
            color: theme.palette.common.TextMain,
        },
        '&:focus': {
            color: theme.palette.common.TextMain,
            fontWeight: theme.typography.fontWeightMedium,
        },
    }
}));




const Tab = ({
    disableRipple = true,
    usePresetTheme = true,
    ...otherProps
}) => {
    const classes = useStyles();
    return (
        <MuiTab 
            className={usePresetTheme ? classes.root : ''} 
            disableRipple={disableRipple} 
            {...otherProps}
        />
    )
}




export default Tab;