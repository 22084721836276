import { useEffect, useRef } from "react";

// Function to get previous state of a variable
function usePrevious(value) {
    const loadingRef = useRef();
    useEffect(() => {
        loadingRef.current = value;
    });
    return loadingRef.current;
}

export default usePrevious;