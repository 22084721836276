import appConfig from '../../appConfig';
import { isArray } from 'lodash';
import { EXTRACTION_EXCLUDE_TEXT } from '../../utils/constants';

export const formatInstrumentList  = (instrument) => {
    const formattedInstrument = instrument && instrument.map( item => {
        return {
            id: item.id,
            instrument_description: item.instrument_description,
            instrument_name: item.instrument_name,
            provider_id: item.provider_id,
            provider_type: 2, //default product type => third party
        }
    });
    return formattedInstrument;
}



export const formattedRecommendedItems = ( data ) => {
    const formattedData = data.map( items => {
        return {
            ...items,
            extracted_data : isArray(items.extracted_data) ? items.extracted_data.map( extractedDataItem => {
                return {
                    ...extractedDataItem,
                    extracted_value : (extractedDataItem.extracted_value && EXTRACTION_EXCLUDE_TEXT.includes(extractedDataItem.extracted_value.toLowerCase())) ? null : extractedDataItem.extracted_value
                }
            }) : items.extracted_data
        }
    });
    return formattedData;
}
