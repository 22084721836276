import { DASHBOARD_ACTION_TYPES } from './dashboard.types'

const INITIAL_STATE = {
  current_client_type: '',
  currentTab: 'client_details',
  // type_config
  client_type_data: [],
  is_Fetching_client_type: true,

  social_medium_data: [],
  is_Fetching_social_mediums: true
}

const dashboardReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DASHBOARD_ACTION_TYPES.SET_CURRENT_CLIENT_TYPE:
      return {
        ...state,
        current_client_type: payload
      }

    case DASHBOARD_ACTION_TYPES.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload
      }

    case DASHBOARD_ACTION_TYPES.SET_CLIENT_TYPE_DATA:
      return {
        ...state,
        client_type_data: payload
      }
    case DASHBOARD_ACTION_TYPES.SET_IS_CLIENT_TYPE_DATA_FETCHING:
      return {
        ...state,
        is_Fetching_client_type: payload
      }

    case DASHBOARD_ACTION_TYPES.SET_SOCIAL_MEDIUMS_DATA:
      return {
        ...state,
        social_medium_data: payload
      }
    case DASHBOARD_ACTION_TYPES.SET_IS_SOCIAL_MEDIUM_DATA_FETCHING:
      return {
        ...state,
        is_Fetching_social_mediums: payload
      }

    default:
      return state
  }
}

export default dashboardReducer
