import { INSTRUMENT_ACTION_TYPES } from "../redux/instrument/instrument.types";
import {
  RECENT_PROVIDERS,
  SEARCH_PROVIDERS,
  LIST_INSTRUMENTS,
  ADD_INSTRUMENTS,
  CLIENT_INSTRUMENT_INFO,
  CLIENT_INSTRUMENT_TYPE_UPDATE,
  CLIENT_INSTRUMENT_COUNT,
  CLIENT_LOA_UPLOAD,
  CLIENT_LOA_REPLACE,
  CLIENT_EMAIL_PREVIEW,
  CLIENT_BULK_EMAIL,
  CLIENT_LOA_VIEW,
  CLIENT_LOA_DELETE,
  CLIENT_BULK_REMINDER,
  EXTRACTED_DATA_POST,
  CLIENT_PRODUCT_DELETE,
  DRAFT_INSTRUMENT_FEE_POST
} from "../utils/endPoint";
import {
  axiosPost,
  axiosGet,
  axiosPut,
  axiosDelete,
  axiosPatch,
  handleOnError,
  handleOnSuccess,
  handleActionStart,
} from "../utils/api";
import axios from "axios";
import { getClientInstruments } from "../redux/instrument/instrument.actions";

export const fetchRecentProviders = async (dispatch) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.FETCH_RECENTLY_ADDED_INSTRUMENTS_START
      )
    );
    const data = await axiosGet(RECENT_PROVIDERS);
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_RECENTLY_ADDED_INSTRUMENTS_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_RECENTLY_ADDED_INSTRUMENTS_FAILURE
      )
    );
  }
};

export const fetchSearchProviders = async (dispatch, searchKey) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.FETCH_SEARCH_INSTRUMENTS_START)
    );
    const data = await axiosGet(SEARCH_PROVIDERS(searchKey));
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_SEARCH_INSTRUMENTS_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_SEARCH_INSTRUMENTS_FAILURE
      )
    );
  }
};

export const fetchInstrumentForClient = async (
  dispatch,
  providerId,
  clientId
) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.FETCH_INSTRUMENTS_START)
    );
    const data = await axiosGet(LIST_INSTRUMENTS(providerId, clientId));
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.FETCH_INSTRUMENTS_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_INSTRUMENTS_FAILURE
      )
    );
  }
};

export const postInstrumentForClient = async (dispatch, paramsArray) => {
  try {
    dispatch(handleActionStart(INSTRUMENT_ACTION_TYPES.POST_INSTRUMENTS_START));
    const data = await axiosPost(ADD_INSTRUMENTS, paramsArray);
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.POST_INSTRUMENTS_SUCCESS)
    );
    dispatch(getClientInstruments(paramsArray[0].client_id));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.POST_INSTRUMENTS_FAILURE
      )
    );
  }
};

export const fetchClientInstrumentInfo = async (dispatch, clientId,fee_management) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_START)
    );
    const data = await axiosGet(CLIENT_INSTRUMENT_INFO(clientId,fee_management));
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_FAILURE
      )
    );
  }
};
export const updatedFromBackData=async (dispatch,data)=>{
    dispatch(
    handleOnSuccess(
      data,
      INSTRUMENT_ACTION_TYPES.UPDATE_FROM_BACK_DATA
    )
  );
  
}
export const fetchClientInstrumentCount = async (dispatch, clientId) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_COUNT_START
      )
    );
    const data = await axiosGet(CLIENT_INSTRUMENT_COUNT(clientId));
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_COUNT_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_COUNT_FAILURE
      )
    );
  }
};

export const patchClientInstrumentType = async (
  dispatch,
  instrumentId,
  instrumentType
) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.UPDATE_CLIENT_INSTRUMENT_TYPE_START
      )
    );
    const data = await axiosPatch(CLIENT_INSTRUMENT_TYPE_UPDATE(instrumentId), {
      provider_type: instrumentType,
    });
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.UPDATE_CLIENT_INSTRUMENT_TYPE_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.UPDATE_CLIENT_INSTRUMENT_TYPE_FAILURE
      )
    );
  }
};
export const uploadClientDocPost = async (instrumentId) => {
await axiosPost(
  DRAFT_INSTRUMENT_FEE_POST(instrumentId)
)
}
export const uploadClientDocument = async (
  dispatch,
  instrumentId,
  clientDoc,
  docId,
  fromDraftDocs,
  isTaskDoc
) => {
  let uploadResponse = "";
  try {
    dispatch(handleActionStart(INSTRUMENT_ACTION_TYPES.DOCUMENT_UPLOAD_START));
    if (docId) {
      uploadResponse = await axiosPut(
        CLIENT_LOA_REPLACE(docId, instrumentId, fromDraftDocs, isTaskDoc),
        clientDoc
      );
    } else {
      uploadResponse = await axiosPost(
        CLIENT_LOA_UPLOAD(instrumentId, fromDraftDocs, isTaskDoc),
        clientDoc
      );
    }
    dispatch(
      handleOnSuccess(
        uploadResponse,
        INSTRUMENT_ACTION_TYPES.DOCUMENT_UPLOAD_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.DOCUMENT_UPLOAD_FAILURE
      )
    );
  }
};

export const fetcEmailTemplateForPreview = async (
  dispatch,
  instrumentId,
  emailTemplateId
) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.FETCH_EMAIL_PREVIEW_START)
    );
    const data = await axiosGet(
      CLIENT_EMAIL_PREVIEW(instrumentId, emailTemplateId)
    );
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.FETCH_EMAIL_PREVIEW_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_EMAIL_PREVIEW_FAILURE
      )
    );
  }
};

export const postBulkEmail = async (dispatch, mailId, instrumentList) => {
  try {
    dispatch(handleActionStart(INSTRUMENT_ACTION_TYPES.POST_BULK_EMAIL_START));
    const data = await axiosPost(CLIENT_BULK_EMAIL(mailId), instrumentList);
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.POST_BULK_EMAIL_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.POST_BULK_EMAIL_FAILURE
      )
    );
  }
};

export const fetchClientLoaDoc = async (dispatch, loaId) => {
  try {
    dispatch(handleActionStart(INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_LOA_START));
    const data = await axiosGet(CLIENT_LOA_VIEW(loaId));
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_LOA_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_LOA_FAILURE
      )
    );
  }
};

export const deleteClientLoaDoc = async (dispatch, instrumentId, loaId) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.DELETE_CLIENT_LOA_START)
    );
    const data = await axiosDelete(CLIENT_LOA_DELETE(instrumentId, loaId));
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.DELETE_CLIENT_LOA_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.DELETE_CLIENT_LOA_FAILURE
      )
    );
  }
};

/*
    Service to save snoozed data
*/
export const saveSnoozeDataService = async (dispatch, snoozeDetails) => {
  try {
    dispatch(handleActionStart(INSTRUMENT_ACTION_TYPES.SAVE_SNOOZE_DATA_START));
    const { id, snooze_duration_unit, snooze_duration } = snoozeDetails;
    const url = `pending-actions/${id}/?snooze_enable=true`;
    const data = await axiosPut(url, {
      snooze_duration_unit: snooze_duration_unit,
      snooze_duration: snooze_duration,
    });
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.SAVE_SNOOZE_DATA_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.SAVE_SNOOZE_DATA_FAILURE
      )
    );
  }
};

/*
    Service to fetch filtered reminder data
*/
export const fetchFilteredReminderDataService = async (
  dispatch,
  reminderDetails
) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.FETCH_FILTERED_REMINDER_DATA_START
      )
    );
    // const { clientid, state, pending_with, due_date } = reminderDetails;
    const data = await axiosGet("pending-actions/", {
      params: {
        ...reminderDetails,
      },
    });
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_FILTERED_REMINDER_DATA_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_FILTERED_REMINDER_DATA_FAILURE
      )
    );
  }
};

/*
    Service to remove reminder end date
*/
export const removeReminderEndDateService = async (dispatch, snoozeDetails) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_END_DATE_START)
    );
    const { id, snooze_duration_unit } = snoozeDetails;
    const url = `pending-actions/${id}/?snooze_enable=false`;
    const data = await axiosPut(url, {
      snooze_duration: 0,
      snooze_duration_unit: snooze_duration_unit,
    });
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_END_DATE_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_END_DATE_FAILURE
      )
    );
  }
};

/*
    Service to fetch pending with data
    eg: used in autocomplete selectbox in reminder
*/
export const fetchPendingWithDataService = async (dispatch, searchString) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.FETCH_PENDING_WITH_DATA_START)
    );
    const data = await axiosGet("reminder/pending-with/", {
      params: {
        search: searchString,
      },
    });
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_PENDING_WITH_DATA_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_PENDING_WITH_DATA_SUCCESS
      )
    );
  }
};

export const postBulkReminder = async (dispatch, mailId, instrumentList) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.POST_BULK_REMINDER_START)
    );
    const data = await axiosPost(CLIENT_BULK_REMINDER(mailId), instrumentList);
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.POST_BULK_REMINDER_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.POST_BULK_REMINDER_FAILURE
      )
    );
  }
};

/*
    Service to set possible recommended products
*/
export const pickPossibleProductsForDraftRecommendationService = async (
  dispatch,
  instrumentsAndClientId
) => {
  try {
    const { instrumentIds, clientId } = instrumentsAndClientId;
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START
      )
    );
    const data = await axiosPost(
      `client-instruments-info/bulk_recommend/?client_id=${clientId}`,
      {
        instrument_list: instrumentIds,
      }
    );
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS
      )
    );
  } catch (err) {
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE
      )
    );
  }
};

/*
    Service to fetch possible recommended products that was picked
*/
export const fetchPossibleRecommendedProductsService = async (
  dispatch,
  clientId
) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_START
      )
    );
    // const data = await axiosGet(`client-instruments-info/?&client_id=${clientId}&is_recommended=true`);
    const data = await axiosGet(`save_extracted/?client_id=${clientId}`);
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_SUCCESS
      )
    );
  } catch (err) {
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_FAILURE
      )
    );
  }
};

/*
    Service to save picked products for draft recommendation
*/
export const saveProductsForDraftRecommendationService = async (
  dispatch,
  productDetails
) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START
      )
    );
    const { instrumentIdList, advisorId, clientId,description} = productDetails;
        const data = await axiosPost("instrument-recommended/", {
      instrument_id: instrumentIdList,
      advisor_id: advisorId,
      client_id: clientId,
      description:description,
    });
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS
      )
    );
  } catch (err) {
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE
      )
    );
  }
};

/*
    Service to fetch data for product comparison
*/
export const getProductComparisonDataService = async (
  dispatch,
  productList
) => {
  try {
    dispatch(
      handleActionStart(
        INSTRUMENT_ACTION_TYPES.GET_PRODUCT_COMPARISON_DATA_START
      )
    );
    const productListToCommaString = productList.reduce((accumulator, item) => {
      return `${accumulator},${item}`;
    });
    const data = await axiosGet(
      `save_extracted/?instrument_list=${productListToCommaString}`
    );
    dispatch(
      handleOnSuccess(
        data,
        INSTRUMENT_ACTION_TYPES.GET_PRODUCT_COMPARISON_DATA_SUCCESS
      )
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.GET_PRODUCT_COMPARISON_DATA_FAILURE
      )
    );
  }
};

export const postExtractionDataService = async (dispatch, postData) => {
  try {
    dispatch(
      handleActionStart(INSTRUMENT_ACTION_TYPES.POST_EXTRACTED_DATA_START)
    );
    const data = await axiosPost(EXTRACTED_DATA_POST, postData);
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.POST_EXTRACTED_DATA_SUCCESS)
    );
  } catch (err) {
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.POST_EXTRACTED_DATA_FAILURE
      )
    );
  }
};

export const deleteProductService = async (dispatch, productId) => {
  try {
    dispatch(handleActionStart(INSTRUMENT_ACTION_TYPES.DELETE_PRODUCT_START));
    const data = await axiosDelete(CLIENT_PRODUCT_DELETE(productId));
    dispatch(
      handleOnSuccess(data, INSTRUMENT_ACTION_TYPES.DELETE_PRODUCT_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        INSTRUMENT_ACTION_TYPES.DELETE_PRODUCT_FAILURE
      )
    );
  }
};

export const getProducts = async (userToken, providerId, clientId) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}instruments/?provider_id=${providerId}&client_id=${clientId}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const getFeeType = async (userToken) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}fund-details/`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};
export const policyDuplicationChecker = async (policy,userToken) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/duplicate-policy?policy=${policy}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const selectProduct = async (userToken, clientId, feeManagement,row) => {

  let param = '';
  param  = feeManagement ? `${param}&fee_management=${feeManagement}` : '';
  
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}instrument-recommended/?client_id=${clientId}${param}${row}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const getReasons = async (userToken, functionId) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}reason-details/?function=${functionId}&product_type=Pension`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const recommendProducts = async (
  userToken,
  instrumentList,
  clientId,
  feeManagement
) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}client-instruments-info/bulk_recommend/?client_id=${clientId}&fee_management=${feeManagement}`,

    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        instrument_list: instrumentList,
      }),
      headers: {
        authToken: `Token ${userToken}`,
        "content-type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const addRecommendProducts = async (
  userToken,
  instrumentId,
  advisorId,
  clientId
) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}instrument-recommended/`,

    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        instrument_id: instrumentId,
        advisor_id: advisorId,
        client_id: clientId,
      }),
      headers: {
        authToken: `Token ${userToken}`,
        "content-type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const onSaveProduct = async (
  userToken,
  advisorId,
  currentUserDataId,
  amount,
  instrumentInfo,
  dfmFee,
  functionList,
  initialFee,
  mapTransferFrom,
  ongoingFee,
  reason,
  selectedInstrumentId,
  policy,
  contributionValue,
  initial_fee_amount,
  is_updated_backdata_page,
  providerstatementinfo_id,
  policy_no_in_missing_data,
  regular_contribution_no_of_months ,
  Existing_value,
  fund_risk
) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}instrument-recommended/${selectedInstrumentId}/`,

    {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        advisor_id: advisorId ? advisorId : currentUserDataId,
        amount: amount,
        initial_fee_amount: initial_fee_amount,
        client_instrumentinfo: instrumentInfo,
        dfm_fee: dfmFee,
        function_list: functionList,
        initial_fee: initialFee,
        map_transfer_from: mapTransferFrom,
        ongoing_fee: ongoingFee,
        reason: reason,
        policy_number: policy,
        regular_contribution_no_of_months : 1,
        contribution_value:contributionValue?contributionValue:0,
        Existing_value:0,
        gross_value:0,
        fund_risk:fund_risk,
        ...(is_updated_backdata_page!=null?{is_updated_backdata_page:is_updated_backdata_page,
        providerstatementinfo_id:providerstatementinfo_id,
        policy_no_in_missing_data:policy_no_in_missing_data}:{})
      }),
      headers: {
        authToken: `Token ${userToken}`,
        "content-type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};
