import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { FormControl, TextField } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../Style/ModalMatch.css"
import '../../../assets/scss/customstyle.scss';
import draftToHtml from 'draftjs-to-html';
import { fetchGetEmail } from "../../../services/FeeMatchingService"
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import htmlToDraft from 'html-to-draftjs';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import './Dashboardmail.scss'
import { axiosPost } from "../../../utils/api";


function DashbaordMailView(props) {
  const currentUserEmail = useSelector(state => state.auth.currentUserData.email);

  // Default content
  const defaultContent = `<p>Hi ${props?.client_details?.first_name}</p>\n </br> <p>I hope you're well.</p> \n </br> <p>It'd be good to arrange a catch up to review the financial plan and let you know how things have been evolving.Are you around over the next couple of weeks to meet, either in person or via Zoom?</p> </br> <p>Best Regards,</p></br> <p>${props?.client_details?.advisor}</p>   <div style="max-width:560px; margin: 0 auto; border-top: 1px solid #ddd; padding: 20px;">
  <div style="float: left; width: 28%;">
    <p style="font-family:'Roboto', sans-serif;font-size:12px; padding: 0;color:#8A8A8A; text-align:left; font-size: 10px; margin: 0; padding: 0;">Powered by<br>
      <img style="width: 57px;" src="{{static_image_path}}/david-logo.png" />
    </p>
  </div>
  <div style="float: left;box-sizing: border-box;">
    <p style="font-family:'Roboto', sans-serif;font-size:12px; padding: 0;color:#8A8A8A; text-align:left; font-size: 11px; margin: 10px 0 0 0; padding: 0;">
      View in Browser | Privacy Policy</p>
  </div>
  <div style="clear: both;"></div>
</div>`;

  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(defaultContent))))

  const [valueTo, setValueTo] = useState(props?.client_details?.email || "");
  const [valueCc, setValueCc] = useState("");
  const [valueBcc, setValueBcc] = useState("");
  const [valueSubject, setValueSubject] = useState("Catch Up");
  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  
 var EmailBody = (`<!DOCTYPE html>\r\n<html>\r\n<head>\r\n<title>Page Title</title>\r\n</head>\r\n<body><div>${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</div></body></html>`)
  const validateForm = () => {
    const newErrors = {};

    // Validate the "To" field
    if (!valueTo) {
      newErrors.valueTo = "To field is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueTo)) {
      newErrors.valueTo = "Invalid email address";
    }

    // Validate the "Subject" field
    if (!valueSubject) {
      newErrors.valueSubject = "Subject is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitMatch = async () => {
    if (!validateForm()) return;

    const mailObj = {
      client_id: props?.client_details?.client_id,
      message_sender: [currentUserEmail],
      message_to: valueTo.split(";"),
      message_body: EmailBody,
      message_cc: valueCc.split(";"),
      message_bcc: valueBcc.split(";"),
      message_subject: valueSubject
    };

    await axiosPost('SendClientMailProfile/', mailObj)
      .then((res) => {
        if (res.status) {
          props.afterSuccessfullySent();
          enqueueSnackbar('Mail sent successfully.', { variant: 'success',anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }, });
        } else {
          enqueueSnackbar(res.message, { variant: 'error',anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }, });
        }
      })
      .catch(() => {
        enqueueSnackbar("Mail has not been send. Please check the mail id(s) or contact Administator.", { variant: 'error' ,anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },});
      });
  };

  return (
    <div className="mail-modal-body">

<div className="mail-modal-bottom">
              <button
               type="button"
                onClick={props.close}
                className="matchCancelButton btn"
              >
                DISCARD
              </button>
              <button
                type="button"
                onClick={submitMatch}
                className="matchSubmitButton btn"
              >
                SEND
              </button>
            </div>
            <div style={{ height: '94%', overflowX: 'hidden', overflowY: 'auto', width: '100%', paddingTop: '20px' }}>
      <Formik
        initialValues={{ valueTo, valueCc, valueBcc, valueSubject }}
        onSubmit={() => submitMatch()}
      >
        {() => (
          
          <Form noValidate>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  label="To"
                  fullWidth
                  value={valueTo}
                  onChange={(e) => setValueTo(e.target.value)}
                  error={!!errors.valueTo}
                  helperText={errors.valueTo}
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  label="CC"
                  fullWidth
                  value={valueCc}
                  onChange={(e) => setValueCc(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  label="BCC"
                  fullWidth
                  value={valueBcc}
                  onChange={(e) => setValueBcc(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  label="Subject"
                  fullWidth
                  value={valueSubject}
                  onChange={(e) => setValueSubject(e.target.value)}
                  error={!!errors.valueSubject}
                  helperText={errors.valueSubject}
                />
              </Grid>
            </Grid>

            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(editorState) => setEditorState(editorState)}
            />

          </Form>
        )}
      </Formik>
      </div>
    </div>
  );
}

export default DashbaordMailView;
