import { CLIENT_ACTION_TYPES } from './client.types';

const INITIAL_STATE = {
    isLoadingClientDetails: false,
    isLoadingClientDescription:false,
    isLoadingClientVulnerableStatus:false,
    clientDescriptionErrorMessage:null,
    clientDescription:[],
    isLoadingClientDocs: false,
    isLoadingClientActivity: false,
    clientDetails: {},
    clientVulnerableStatus:{},
    clientDocs: [],
    clientActivity: {},

    isClientListFetching : false,
    clientListErrorMsg: null,
    clientList : [],

    isLoadingRecentClientDocs : false,
    recentClientDocs : [],
    emailExist : null,
    phoneExist : null
}

const clientReducer = (state = INITIAL_STATE, { type, payload }) => {

    switch (type)
    {

        case CLIENT_ACTION_TYPES.FETCH_CLIENT_START:
            return {
                ...state,
                isLoadingClientDetails: true,
            }
        case "REMOVE_CLIENT_DATA":
            return {
                ...state,
                clientDetails:{}
                
            }
            case CLIENT_ACTION_TYPES.REMOVE_CLIENT_VULNERABLE_STATUS:
                return {
                    ...state,
                    clientVulnerableStatus:{}
                    
                }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_SUCCESS:
            return {
                ...state,
                isLoadingClientDetails: false,
                clientDetails: payload.data
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_FAILURE:
            return {
                ...state,
                isLoadingClientDetails: false,
                clientDetailsErrorMessage: payload.message,
            }
        case CLIENT_ACTION_TYPES.FETCH_VULNERABLE_STATUS_START:
                return {
                    ...state,
                    isLoadingClientVulnerableStatus: true,
                }
        case CLIENT_ACTION_TYPES.FETCH_VULNERABLE_STATUS_SUCCESS:
                return {
                    ...state,
                    isLoadingClientVulnerableStatus: false,
                    clientVulnerableStatus: payload.data
                }
        case CLIENT_ACTION_TYPES.FETCH_VULNERABLE_STATUS_FAILURE:
                return {
                    ...state,
                    isLoadingClientVulnerableStatus: false,
                    clientVulnerableStatus:{}
                }    
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_DESC_START:
            return {
                ...state,
                isLoadingClientDescription: true,
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_DESC_SUCCESS:
            return {
                ...state,
                isLoadingClientDescription: false,
                clientDescription: payload.data
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_DESC_FAILURE:
            return {
                ...state,
                isLoadingClientDescription: false,
                clientDescriptionErrorMessage: payload.message,
            }    

        case CLIENT_ACTION_TYPES.FETCH_CLIENT_DOCS_START:
            return {
                ...state,
                isLoadingClientDocs: true,
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_DOCS_SUCCESS:
            return {
                ...state,
                isLoadingClientDocs: false,
                clientDocs: payload.data
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_DOCS_FAILURE:
            return {
                ...state,
                isLoadingClientDocs: false,
                clientDocsErrorMessage: payload.message,
            }

        case CLIENT_ACTION_TYPES.FETCH_CLIENT_ACTIVITY_START:
            return {
                ...state,
                isLoadingClientActivity: true,
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_ACTIVITY_SUCCESS:
            return {
                ...state,
                isLoadingClientActivity: false,
                clientActivity: payload.data
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoadingClientActivity: false,
                clientActivityErrorMessage: payload.message,
            }



        case CLIENT_ACTION_TYPES.FETCH_CLIENT_LIST_START:
            return {
                ...state,
                isClientListFetching: true,
            }
        case CLIENT_ACTION_TYPES.FETCH_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                isClientListFetching: false,
                clientList : payload.data,
            }

        case CLIENT_ACTION_TYPES.FETCH_RECENT_CLIENT_DOCS_START:
            return {
                ...state,
                isLoadingRecentClientDocs: true,
            }
        case CLIENT_ACTION_TYPES.FETCH_RECENT_CLIENT_DOCS_SUCCESS:
            return {
                ...state,
                isLoadingRecentClientDocs: false,
                recentClientDocs: payload.data
            }
        case CLIENT_ACTION_TYPES.FETCH_RECENT_CLIENT_DOCS_FAILURE:
            return {
                ...state,
                isLoadingRecentClientDocs: false,
                clientDocsErrorMessage: payload.message,
            }

        case CLIENT_ACTION_TYPES.SAVE_EMAIL_EXIST:
            return {
                ...state,
                emailExist : payload
            }
        case CLIENT_ACTION_TYPES.SAVE_PHONE_EXIST:
            return {
                ...state,
                phoneExist : payload
            }
        case CLIENT_ACTION_TYPES.CLEAR_DATA_EXIST:
            return {
                ...state,
                emailExist: null,
                phoneExist: null,
            }

        default:
            return state;
    }
}

export default clientReducer;