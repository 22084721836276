import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '../../components/Button/Button';
import './PageWrapper.scss';


// Note: top prop is padding top value 

const themeBlue = '#56d5e6';

// --- Starts  : type prop notes  -- 

// if type1 page content will have low padding bottom
// if type2 page content will have medium padding bottom
// if type3 page content will have 0 padding bottom

const pageContentPaddingDecider = (type1, type2, type3) => {
    let pageContentClass;
    if (type1)
    {
        pageContentClass = 'pageContentlow';
    }
    else if (type2)
    {
        pageContentClass = 'pageContentMedium';
    }
    else if (type3)
    {
        pageContentClass = 'pageContentMedium';
    }
    else
    {
        pageContentClass = 'pageContentlow';
    }
    return pageContentClass;
}


// --- Ends  : type prop notes  -- 




const PageWrapper = ({ top,bgWhite, breadCrumbData, heading, subhead, children, className, disablePresetTheme=false, type1, type2, type3 }) => {

    const style = {
        paddingTop: top ? top : '',
        backgroundColor: bgWhite? '#fff':''
    }

    const pageContentClass = pageContentPaddingDecider(type1, type2, type3);
    return (
        <div className={`${disablePresetTheme ? 'pageWrapperNoStyles' : 'pageWrapper'} ${className}`} 
            style={style}
        >
            <div className="pageHeaderWrapper">

                
                {/* Show only if heading passed */}
                {
                    heading &&
                    <div className="pageHeaderDetails">
                        {/* Note : consider changing to typography :: remove this if changing to typography - starts  */}
                        <h3 className="customHead">
                            {heading ? heading : 'No heading passed '}
                        </h3>
                        {
                            subhead && <p className="customDescription">{subhead}</p>
                        }

                        {/* Note :: consider changing to typography :: remove this if changing to typography - ends  */}
                    </div>
                }
                
                {/* show breadcrumbs only if passed */}
                {
                    breadCrumbData &&
                    <div className="breadCrumbs">
                        <Breadcrumbs aria-label="breadcrumb">
                            {
                                breadCrumbData.map((breadCrumbItem, i) => {
                                    const lastItemofArray = breadCrumbData.length - 1;
                                    return (
                                        <Button
                                            key={i}
                                            linkTo={breadCrumbItem.path}
                                            buttonType="navlink"
                                            color={i === lastItemofArray ? themeBlue : '#777777'} // if last item change color
                                            linkRequired={i === lastItemofArray ? false : true}
                                        >
                                            {breadCrumbItem.name}
                                        </Button>
                                    )
                                })
                            }
                        </Breadcrumbs>
                    </div>
                }

               
            </div>
            <div className={`${pageContentClass} pageContent`}>
                {children}
            </div>
        </div>
    )
}

export default PageWrapper;
