export const CALENDAR_ACTION_TYPES = {
    FETCH_CLIENTS_WITH_CLIENT_NAME_START : 'FETCH_CLIENTS_WITH_CLIENT_NAME_START',
    FETCH_CLIENTS_WITH_CLIENT_NAME_SUCCESS : 'FETCH_CLIENTS_WITH_CLIENT_NAME_SUCCESS',
    FETCH_CLIENTS_WITH_CLIENT_NAME_FAILURE : 'FETCH_CLIENTS_WITH_CLIENT_NAME_FAILURE',

    ADD_EVENT_START : 'ADD_EVENT_START',
    ADD_EVENT_SUCCESS : 'ADD_EVENT_SUCCESS',
    ADD_EVENT_FAILURE : 'ADD_EVENT_FAILURE',
    CLEAR_ADD_EVENT_ERROR_MESSAGE : 'CLEAR_ADD_EVENT_ERROR_MESSAGE',
    CLEAR_ADD_EDIT_EVENT_SUCCESS_MESSAGE : 'CLEAR_ADD_EDIT_EVENT_SUCCESS_MESSAGE',

    FETCH_EVENT_DATA_START : 'FETCH_EVENT_DATA_START',
    FETCH_EVENT_DATA_SUCCESS : 'FETCH_EVENT_DATA_SUCCESS',
    FETCH_EVENT_DATA_FAILURE : 'FETCH_EVENT_DATA_FAILURE',

    MARK_PARTICIPATION_START : 'MARK_PARTICIPATION_START',
    MARK_PARTICIPATION_SUCCESS : 'MARK_PARTICIPATION_SUCCESS',
    MARK_PARTICIPATION_FAILURE : 'MARK_PARTICIPATION_FAILURE',

    EDIT_EVENT_START : 'EDIT_EVENT_START',
    EDIT_EVENT_SUCCESS : 'EDIT_EVENT_SUCCESS',
    EDIT_EVENT_FAILURE : 'EDIT_EVENT_FAILURE',
    REMOVE_EVENT_DATA : 'REMOVE_EVENT_DATA',
    CLEAR_EDIT_EVENT_ERROR_MESSAGE : 'CLEAR_EDIT_EVENT_ERROR_MESSAGE',
    
    DELETE_EVENT_START: 'DELETE_EVENT_START',
    DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',

    FETCH_EVENT_LIST_START: 'FETCH_EVENT_LIST_START',
    FETCH_EVENT_LIST_SUCCESS: 'FETCH_EVENT_LIST_SUCCESS',
    FETCH_EVENT_LIST_FAILURE: 'FETCH_EVENT_LIST_FAILURE',

    FETCH_MEETING_SCHEDULE_START : 'FETCH_MEETING_SCHEDULE_START',
    FETCH_MEETING_SCHEDULE_SUCCESS : 'FETCH_MEETING_SCHEDULE_SUCCESS',
    FETCH_MEETING_SCHEDULE_FAILURE : 'FETCH_MEETING_SCHEDULE_FAILURE',
}