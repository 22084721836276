import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import './PageNotFound.scss';
import pageNotFoundIcon from '../../assets/icons/page_not_found_icon.svg';

const PageNotFound = () => {
    const history = useHistory();
    return (
        <div className="pageNotFoundMessage">
            <img src={pageNotFoundIcon} className="pageNotFoundIcon" alt="404 page not found"/>
            <h1 className="customHead heading">Page not found.</h1>
            <p className="customDescription subHeading">The page your looking for does not exist.</p>
            <Button
                buttonType="button"
                bgColor="secondary"
                type="button"
                onClick={() => history.push('/')}
                className="backBtn"
            >
                Back to Dashboard
            </Button>
        </div>
    )
}

export default PageNotFound;
