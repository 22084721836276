import React, { useMemo } from 'react'
import './NameCircle.scss';


const NameCircle = ({ name, size, background, style }) => {
    const styles = useMemo(() => {
        return {
            width: size ? size : '40px',
            height: size ? size : '40px',
            background: background,
            ...style
        }
    },[])


    return (
        <div className="nameCircle" style={styles}>
            {name}
        </div>
    )
}

export default NameCircle
