import { REFERRAL_MNGMT_ACTION_TYPES } from './referralManagement.types';
import { clientListFormatter, advisorListFormatter, firstNameLastNameCombiner } from './referralManagement.utils';
import { validationErrorFormatter } from '../../utils/utils';

const INITIAL_STATE = {
    isLoading: false,
    companyList: [],
    errorData: null,
    
    isLoading: false,
    clienttypeList: [],
    errorData: null,

    clientList: [],
    isClientListFetching : false,
    clientListErrorMsg : null,

    referrerNameList: [],
    clientDataById: null,
    recentlyAddedClients: [],
    advisorRecentlyAddedClients: [],
    successData: null,
    ParallelsuccessData: null,
    isSubmiting: false,
    clientSearchResults: null,
    advisorList: [],

    bulkAddClientLoading: false,
    bulkAddClientData: null,
    bulkAddClientError: null,

    sendBulkReferralsFetching: false,
    sendBulkReferralsSuccessMsg: null,
    sendBulkReferralsErrorMsg: null,

    clientListCount : null,
    clientListOffset : null,

    isClientProfileSummeryFetching : false,
    clientProfileSummeryData : {},
    clientProfileSummeryErrMsg : null,


    selecetedOne:null,
    selecetedParallelOne:null
}



const referralReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {

        case REFERRAL_MNGMT_ACTION_TYPES.LIST_CLIENTS_START:
            return {
                ...state,
                isClientListFetching: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.LIST_CLIENTS_SUCCESS:
            return {
                ...state,
                clientList: clientListFormatter(payload.data),
                isClientListFetching: false,
                clientListErrorMsg: null,
                clientListCount:payload?.count ? payload.count : 0,
                clientListOffset:payload?.offset ? payload.offset : 0
            }
        case REFERRAL_MNGMT_ACTION_TYPES.LIST_CLIENTS_FAILURE:
            return {
                ...state,
                isClientListFetching: false,
                clientListErrorMsg: payload.message
            }






        case REFERRAL_MNGMT_ACTION_TYPES.ADD_CLIENT_START:
            return {
                ...state,
                isSubmiting: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.ADD_CLIENT_SUCCESS:
            return {
                ...state,
                successData: payload.message,
                isSubmiting: false,
                selecetedOne:payload

            }
        case "selectedOneNull":
            return {
                ...state,
               
                selecetedOne:null

            }
        case REFERRAL_MNGMT_ACTION_TYPES.ADD_CLIENT_FAILURE:

            return {
                ...state,
                addErrorData: {
                    message: payload.message === 'Validation error' ? null : payload.message, // message null if validation error else this will also show up along with field errors
                    errorFields: validationErrorFormatter(payload.data)
                },
                isSubmiting: false
            }
            case REFERRAL_MNGMT_ACTION_TYPES.ADD_PARALLEL_CLIENT_START:
                return {
                    ...state,
                    isSubmiting: true,
                }
            case REFERRAL_MNGMT_ACTION_TYPES.ADD_PARALLEL_CLIENT_SUCCESS:
                return {
                    ...state,
                    ParallelsuccessData: payload[0].message,
                    isSubmiting: false,
                    selecetedParallelOne:payload
    
                }
            
            case REFERRAL_MNGMT_ACTION_TYPES.ADD_PARALLEL_CLIENT_FAILURE:
    
                return {
                    ...state,
                    addErrorData: {
                        message: payload.message === 'Validation error' ? null : payload.message, // message null if validation error else this will also show up along with field errors
                        errorFields: validationErrorFormatter(payload.data)
                    },
                    isSubmiting: false
                }

        case REFERRAL_MNGMT_ACTION_TYPES.CLEAR_ADD_ERROR_DATA:

            return {
                ...state,
                addErrorData: {},
                isSubmiting: false
            }


        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_COMPANIES_START:
            return {
                ...state,
                isLoading: true
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                companyList: [...payload.data]
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_COMPANIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: payload.message
            }

            case REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_TYPE_START:
                return {
                    ...state,
                    isLoading: true
                }

            case REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_TYPE_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    clienttypeList: [...payload.data]
                }
                case REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_TYPE_FAILURE:
                    return {
                        ...state,
                        isLoading: false,
                        errorData: payload.message
                    }

        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_REFERRER_NAME_START:
            return {
                ...state,
                isLoading: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_REFERRER_NAME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                referrerNameList: firstNameLastNameCombiner(payload.data)
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_REFERRER_NAME_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: payload.message
            }



        case REFERRAL_MNGMT_ACTION_TYPES.EDIT_CLIENT_START:
            return {
                ...state,
                isSubmiting: true,
                editSuccessMessage: null,
                editErrorData: null
            }
        case REFERRAL_MNGMT_ACTION_TYPES.EDIT_CLIENT_SUCCESS:
            return {
                ...state,
                isSubmiting: false,
                editSuccessMessage: payload.message
            }
        case REFERRAL_MNGMT_ACTION_TYPES.EDIT_CLIENT_FAILURE:
            return {
                ...state,
                isSubmiting: false,
                editErrorData: {
                    message: payload.message, // message null if validation error else this will also show up along with field errors
                    errorFields: validationErrorFormatter(payload.data)
                },

            }

        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_DETAILS_WITH_ID_START:
            return {
                ...state,
                isLoading: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_DETAILS_WITH_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clientDataById: payload.data
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_DETAILS_WITH_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: payload.message
            }



        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_CLIENTS_START:
            return {
                ...state,
                recentlyAddedClientsLoading: true,
                errorData: null
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_CLIENTS_SUCCESS:
            return {
                ...state,
                recentlyAddedClientsLoading: false,
                recentlyAddedClients: [...payload.data]
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_CLIENTS_FAILURE:
            return {
                ...state,
                recentlyAddedClientsLoading: false,
                errorData: payload.message
            }


        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_BY_ADVISOR_START:
            return {
                ...state,
                isLoading: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_BY_ADVISOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                advisorRecentlyAddedClients: [...payload.data]
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_BY_ADVISOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: payload.message
            }


        case REFERRAL_MNGMT_ACTION_TYPES.SEARCH_CLIENTS_START:
            return {
                ...state,
                isLoading: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.SEARCH_CLIENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clientSearchResults: clientListFormatter(payload.data),
                clientListCount:payload?.count ? payload.count : 0,
                clientListOffset:payload?.offset ? payload.offset : 0
            }
        case REFERRAL_MNGMT_ACTION_TYPES.SEARCH_CLIENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: payload.message
            }

        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_ADVISOR_START:
            return {
                ...state,
                isLoading: true
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_ADVISOR_SUCCESS:

            return {
                ...state,
                isLoading: false,
                advisorList: [...payload.data]
                //advisorList: advisorListFormatter(payload.data)
            }
        case REFERRAL_MNGMT_ACTION_TYPES.FETCH_ADVISOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: payload.message
            }



        case REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_START:
            return {
                ...state,
                bulkAddClientLoading: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_SUCCESS:
            return {
                ...state,
                bulkAddClientLoading: false,
                bulkAddClientData: payload.data,
                bulkAddClientMessage: payload.message,
                bulkAddClientError: null
            }
        case REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_FAILURE:
            return {
                ...state,
                bulkAddClientLoading: false,
                bulkAddClientError: payload.message,
                bulkAddClientData: null
            }

        case REFERRAL_MNGMT_ACTION_TYPES.REMOVE_BULK_ADD_ERROR_MESSAGE:
            return {
                ...state,
                bulkAddClientError: null
            }

        case REFERRAL_MNGMT_ACTION_TYPES.REMOVE_BULK_ADD_SUCCESS_MESSAGE:
            return {
                ...state,
                bulkAddClientMessage: null,
                bulkAddClientData: null,
            }



        // Non Async sections

        case REFERRAL_MNGMT_ACTION_TYPES.REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                errorData: null,
                editErrorData: null
            }

        case REFERRAL_MNGMT_ACTION_TYPES.REMOVE_SUCCESS_MESSAGE:
            return {
                ...state,
                successData: null,
                editSuccessMessage: null
            }


        case REFERRAL_MNGMT_ACTION_TYPES.SEND_BULK_REFERRAL_EMAIL_START:
            return {
                ...state,
                sendBulkReferralsFetching: true,
            }
        case REFERRAL_MNGMT_ACTION_TYPES.SEND_BULK_REFERRAL_EMAIL_SUCCESS:
            return {
                ...state,
                sendBulkReferralsFetching: false,
                sendBulkReferralsSuccessMsg: payload.message
            }
        case REFERRAL_MNGMT_ACTION_TYPES.SEND_BULK_REFERRAL_EMAIL_FAILURE:
            return {
                ...state,
                sendBulkReferralsFetching: false,
                sendBulkReferralsErrorMsg: payload.message
            }


        case REFERRAL_MNGMT_ACTION_TYPES.REMOVE_SEND_BULK_REFERRAL_EMAIL_SUCCESS_MSG :
        return {
            ...state,
            sendBulkReferralsSuccessMsg: null,
        }
        
        case REFERRAL_MNGMT_ACTION_TYPES.REMOVE_SEND_BULK_REFERRAL_EMAIL_ERROR_MSG :
        return {
            ...state,
            sendBulkReferralsErrorMsg: null,
        }

        case REFERRAL_MNGMT_ACTION_TYPES.GET_CLIENT_PROFILE_SUMMERY_START:
        return {
            ...state,
             isClientProfileSummeryFetching: true,
        }
        case REFERRAL_MNGMT_ACTION_TYPES.GET_CLIENT_PROFILE_SUMMERY_SUCCESS:
        return {
            ...state,
            isClientProfileSummeryFetching: false,
            clientProfileSummeryData : payload.data,

        }
        case REFERRAL_MNGMT_ACTION_TYPES.GET_CLIENT_PROFILE_SUMMERY_FAILURE:
        return {
            ...state,
            isClientProfileSummeryFetching: false,
            clientProfileSummeryErrMsg : payload.message
        }


        case REFERRAL_MNGMT_ACTION_TYPES.CLEAR_REFERRAL_REDUCER_STATE_VALUE:
        return {
            ...state,
            [payload] : null
        }

        default:
            return state;
    }
}


export default referralReducer;