import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const TextFieldBox = (props) => {
  const { label,name,value,onChange,error,helperText } = props;
  const [valueF, setValueF] = useState(props.value);
  useEffect(()=>{
    if(props.value){
      props.setIsDisabled()
    }
  },[])

  return (
    <>
      <div style={{ padding: "10px 0" }}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "32ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
          value={valueF}
          error={props.error}
          helperText="Please fill at least one field"
            size="small"
            id={props.inputId}
            label={label}
            variant="outlined"
            onChange={(data)=>{
                props.setIsDisabled()
                setValueF(data.target.value)
            }}
            // type={props.type}
          />
        </Box>
      </div>
    </>
  );
};
export default TextFieldBox;
