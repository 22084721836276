import * as Yup from 'yup';


export const composeMailSchema = Yup.object().shape({
    to: Yup.string()
        .required('Email is required')
        .max(100, 'Maximum characters upto 100.'),
    template: Yup.string()
        .required('Email template is required')
        .max(100, 'Maximum characters upto 100.'),
    category: Yup.string()
        .required('Category is required')
        .max(100, 'Maximum characters upto 100.'),
    subject: Yup.string()
        .max(100, 'Maximum characters upto 100.'),
    company: Yup.array()
        .of(
            Yup.object().shape({
                id : Yup.number().required('Id is required'),
                name: Yup.string().required('name is required').max(100, 'Maximum characters upto 100.')
            })
        ).required('Must have Companies'), // these constraints are shown if and only if inner constraints are satisfied, 
});




export const replayMailSchema = Yup.object().shape({
    to: Yup.array().required('Email is required'), // these constraints are shown if and only if inner constraints are satisfied
});


export const emailCheckerSchema = Yup.object().shape({
     emailItem : Yup.string().email().required()
});
