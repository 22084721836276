import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@material-ui/core';
import './MultiSelectInput.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectInput({margin=null,options,setselectedValues,hiddenLabel=true,selectedValues,width=null,label,required=false,onEmptyClick,placeholder=null,emptyMessage=null}) {

  const removeDuplicatesById = (array) => {
    const idMap = new Map();
    const duplicateIds = new Set();
  
    for (const obj of array) {
      if (!idMap.has(obj.id)) {
        idMap.set(obj.id, true);
      } else {
        duplicateIds.add(obj.id);
      }
    }
  
    const uniqueArray = array.filter(obj => !duplicateIds.has(obj.id));
    return uniqueArray;
  };

  const handleChange = (event) => {
    
    let arr = removeDuplicatesById(event.target.value)
    setselectedValues(arr);
  };


  return (
    <div>
      <FormControl sx={{ m:margin?margin: 1, width: width?width:300 }}>
      {hiddenLabel&&<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                        <div style={{ fontWeight: 'bold', marginRight: 8 }} className='form-label-settings'>{label}</div>
      </div>}
     
    <InputLabel　hidden={hiddenLabel} id="demo-multiple-checkbox-label">{placeholder ? placeholder : 'Tag'}</InputLabel>
    <Select
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      multiple
      value={selectedValues}
      onChange={handleChange}
      input={<OutlinedInput{ ...(!placeholder?{notched:false}:{})} label={placeholder ? placeholder : 'Tag'} />}
      renderValue={(selected) => {
            if(selected.length>0){
              let values = selected.map(i => i.name)
              return values.join(', ')
            }else{
              return ''
            }
         
      }}
      MenuProps={MenuProps}
          displayEmpty
          placeholder={placeholder?placeholder:'Select Social Mediums'}
    >
          {options?.length > 0 ? options.map((i) => (
          <MenuItem key={i.id} value={i}>
              <Checkbox checked={selectedValues.findIndex(item =>  item?.id === i?.id) > -1} />
            <ListItemText primary={i?.name} />
          </MenuItem>
          )):<MenuItem onClick={()=>onEmptyClick()} ><ListItemText primary={emptyMessage?emptyMessage:"Please add social mediums"} />
          </MenuItem>}
    </Select>
  </FormControl>
</div>
  );
}
