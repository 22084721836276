export const CLIENT_ACTION_TYPES = {

    FETCH_CLIENT_START : 'FETCH_CLIENT_START',
    FETCH_CLIENT_SUCCESS : 'FETCH_CLIENT_SUCCESS',
    FETCH_CLIENT_FAILURE : 'FETCH_CLIENT_FAILURE',

    FETCH_CLIENT_DOCS_START : 'FETCH_CLIENT_DOCS_START',
    FETCH_CLIENT_DOCS_SUCCESS : 'FETCH_CLIENT_DOCS_SUCCESS',
    FETCH_CLIENT_DOCS_FAILURE : 'FETCH_CLIENT_DOCS_FAILURE',

    FETCH_CLIENT_DESC_START:'FETCH_CLIENT_DESC_START',
    FETCH_CLIENT_DESC_SUCCESS:'FETCH_CLIENT_DESC_SUCCESS',
    FETCH_CLIENT_DESC_FAILURE:'FETCH_CLIENT_DESC_FAILURE',

    FETCH_VULNERABLE_STATUS_START:'FETCH_VULNERABLE_STATUS_START',
    FETCH_VULNERABLE_STATUS_SUCCESS:'FETCH_VULNERABLE_STATUS_SUCCESS',
    FETCH_VULNERABLE_STATUS_FAILURE:'FETCH_VULNERABLE_STATUS_FAILURE',

    FETCH_CLIENT_ACTIVITY_START : 'FETCH_CLIENT_ACTIVITY_START',
    FETCH_CLIENT_ACTIVITY_SUCCESS : 'FETCH_CLIENT_ACTIVITY_SUCCESS',
    FETCH_CLIENT_ACTIVITY_FAILURE : 'FETCH_CLIENT_ACTIVITY_FAILURE',

    FETCH_RECENT_CLIENT_DOCS_START : 'FETCH_RECENT_CLIENT_DOCS_START',
    FETCH_RECENT_CLIENT_DOCS_SUCCESS : 'FETCH_RECENT_CLIENT_DOCS_SUCCESS',
    FETCH_RECENT_CLIENT_DOCS_FAILURE : 'FETCH_RECENT_CLIENT_DOCS_FAILURE',

    SAVE_EMAIL_EXIST : 'SAVE_EMAIL_EXIST',
    SAVE_PHONE_EXIST : 'SAVE_PHONE_EXIST',
    CLEAR_DATA_EXIST : 'CLEAR_DATA_EXIST',

    REMOVE_CLIENT_DATA : "REMOVE_CLIENT_DATA",
    REMOVE_CLIENT_VULNERABLE_STATUS : "REMOVE_CLIENT_VULNERABLE_STATUS"
}