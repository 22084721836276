import { FEE_MANAGEMENT,LIST_PRODUCT, MONTHLY_ISSUED,PENDING_ISSUED, SEARCH_CLIENT, ADD_CLIENT,SEARCH_CLIENT_REUSLTS, SELECT_CLIENT, PRODUCT_SUMMARY, PRODUCT_LIST, FEE_MATCHING,UPDATE_VALUE, FEE_SPLIT } from "./routing.types";


export const feeManagement = () =>{
    return{
        type: FEE_MANAGEMENT
    }
}
export const selectclient = () =>{
    return{
        type: SELECT_CLIENT
    }
}
export const searchClient = () =>{
    return{
        type: SEARCH_CLIENT
    }
}
export const addClient = () =>{
    return{
        type: ADD_CLIENT
    }
}
export const searchClientResults = () =>{
    return{
        type: SEARCH_CLIENT_REUSLTS
    }
}
export const monthlyIssued = () =>{
    return{
        type: MONTHLY_ISSUED
    }
}
export const pendingIssued = () =>{
    return{
        type: PENDING_ISSUED
    }
}
export const productSummary = () =>{
    return{
        type: PRODUCT_SUMMARY
    }
}
export const productList = () =>{
    return{
        type: PRODUCT_LIST
    }
}
export const listProduct = () =>{
    return{
        type: LIST_PRODUCT
    }
}
export const feeMatching = () =>{
    return{
        type: FEE_MATCHING
    }
}
export const updateValue = (newValue) =>{
    return{
        type: UPDATE_VALUE,
        data: newValue,
    }
}

export const feeSplit = () =>{
    return{
        type: FEE_SPLIT
    }
}
