import {
    fetchRecommendationList,
    fetchFeeDetails,
    fetchFundDetails,
    fetchReasonDetails,
    fetchDraftNotes,
    fetchDraftComments,
    putDraftComments,
    fetchInstrumentFee,
    putInstrumentFee,
    cloneInstrument,
    deleteInstrument,
    uploadSRDocument,
    fetchSR,
    fetchChecklist,
    putChecklistStatus,
    generateSRDocument,
    fetchDraftDocuments,
    postConfirmClient,
    putDRnotesAnswer,
    fetchSubTotalAnnual,
    fetchClientTaskInstrumentsRecomendedList
    } from '../../services/draftService';
import { DRAFT_ACTION_TYPES } from './draft.types';

export const getRecommendationList = (clientId, taskId) => {
    return dispatch => {
        fetchRecommendationList(dispatch, clientId, taskId)
    }
}
export const getClientTaskInstrumentsRecomendedList = (taskId) => {
    return dispatch => {
        fetchClientTaskInstrumentsRecomendedList(dispatch,taskId)
    }
}
export const getSubTotalAnnual = (clientId) => {
    return dispatch => {
        fetchSubTotalAnnual(dispatch, clientId)
    }
}
export const getFeeDetails = () => {
    return dispatch => {
        fetchFeeDetails(dispatch)
    }
}
export const getFundDetails = (Product_Type) => {
    return dispatch => {
        fetchFundDetails(dispatch,Product_Type)
    }
}
export const getReasonDetails = (functionIds, productType) => {
    return dispatch => {
        fetchReasonDetails(dispatch, functionIds, productType)
    }
}
export const getDraftNotes = (clientId) => {
    return dispatch => {
        fetchDraftNotes(dispatch, clientId)
    }
}
export const getDraftComments = (clientId) => {
    return dispatch => {
        fetchDraftComments(dispatch, clientId)
    }
}
export const updateDraftComments = (draftId, postData) => {
    return dispatch => {
        putDraftComments(dispatch, draftId, postData)
    }
}
export const getInstrumentFee = (instrumentId) => {
    return dispatch => {
        fetchInstrumentFee(dispatch, instrumentId)
    }
}
export const updateInstrumentFee = (instrumentId, postData) => {
    return dispatch => {
        putInstrumentFee(dispatch, instrumentId, postData)
    }
}
export const clearDraftErrorMessage = (TYPE) => ({
    type: TYPE
})
export const cloneRecommendedInstrument = (postData,instrumentId) => {
    return dispatch => {
        cloneInstrument(dispatch, postData,instrumentId)
    }
}
export const deleteRecommendedInstrument = (instrumentId) => {
    return dispatch => {
        deleteInstrument(dispatch, instrumentId)
    }
}
export const uploadSRDoc = (srDoc, fromDraftDocs=false, isTaskDoc = false) => {
    return dispatch => {
        uploadSRDocument(dispatch, srDoc, fromDraftDocs, isTaskDoc)
    }
}
export const getSR = (clientId) => {
    return dispatch => {
        fetchSR(dispatch, clientId)
    }
}
export const getChecklist = (clientId, useFor, taskId) => {
    return dispatch => {
        fetchChecklist(dispatch, clientId, useFor, taskId)
    }
}
export const updateChecklistStatus = (postData, useFor) => {
    return dispatch => {
        putChecklistStatus(dispatch, postData, useFor)
    }
}
export const generateSR = (clientId, templateId) => {
    return dispatch => {
        generateSRDocument(dispatch, clientId, templateId)
    }
}
export const getDraftDocs = (clientId) => {
    return dispatch => {
        fetchDraftDocuments(dispatch, clientId)
    }
}
export const confirmClient = (postData) => {
    return dispatch => {
        postConfirmClient(dispatch, postData)
    }
}
export const clearSRDetails = (TYPE) => ({
    type: TYPE
})

export const updateDRnotesAnswer = (clientId, drNoteId, postData) => {
    return dispatch =>{
        putDRnotesAnswer(dispatch, clientId, drNoteId, postData)
    }
}


export const updateCheckList = (payload) => ({
    type : DRAFT_ACTION_TYPES.UPDATE_CHECK_LIST,
    payload: payload
})

export const clearRecommendationList = () => ({
    type : DRAFT_ACTION_TYPES.CLEAR_RECOMMENDATION_LIST
})
export const clearClientTaskRecommendationList = () => ({
    type : DRAFT_ACTION_TYPES.CLEAR_RECOMMENDATION_LIST
})