import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeUIErrorMsgs } from "../../../redux/ui/ui.actions";

const useShowDashboardErrors = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const {
        averageAgeDataErrMsg,
        clientVsDataErrMsg,
        getClientsReferringErrMsg,
        adherenceToCppErrMsg,
        interactionByTypeDataErrMsg,
        recentlyAddedClientsErrMsg,
        acceptedRejectedRatioErrorMsg,
        topReferrersErrMsg
    } = useSelector(state => state.ui);
    useEffect(()=>{
        
        if(clientVsDataErrMsg)
        {
            enqueueSnackbar(`Number of clients vs New Clients : ${clientVsDataErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('clientVsDataErrMsg'));
                }
            });
        }
        if(averageAgeDataErrMsg)
        {
            enqueueSnackbar(`Average age of clients : ${averageAgeDataErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('averageAgeDataErrMsg'));
                }
            });
        }
        if(getClientsReferringErrMsg)
        {
            enqueueSnackbar(`Clients referring you : ${getClientsReferringErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('getClientsReferringErrMsg'));
                }
            });
        }
        if(adherenceToCppErrMsg)
        {
            enqueueSnackbar(`Adherence to CPP (%) : ${adherenceToCppErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('adherenceToCppErrMsg'));
                }
            });
        }
        if(interactionByTypeDataErrMsg)
        {
            enqueueSnackbar(`Interactions by type : ${interactionByTypeDataErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('interactionByTypeDataErrMsg'));
                }
            });
        }
        if(recentlyAddedClientsErrMsg)
        {
            enqueueSnackbar(`Recently added clients : ${recentlyAddedClientsErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('recentlyAddedClientsErrMsg'));
                }
            });
        }
        if(acceptedRejectedRatioErrorMsg)
        {
            enqueueSnackbar(`Accepted Rejected Ratio : ${acceptedRejectedRatioErrorMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('acceptedRejectedRatioErrorMsg'));
                }
            });
        }
        if(topReferrersErrMsg)
        {
            enqueueSnackbar(`Top Referrers : ${topReferrersErrMsg}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(removeUIErrorMsgs('topReferrersErrMsg'));
                }
            });
        }
    },[acceptedRejectedRatioErrorMsg, adherenceToCppErrMsg, averageAgeDataErrMsg, clientVsDataErrMsg, dispatch, enqueueSnackbar, getClientsReferringErrMsg, interactionByTypeDataErrMsg, recentlyAddedClientsErrMsg, topReferrersErrMsg]);
};

export { useShowDashboardErrors };