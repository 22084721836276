import { lazy } from 'react';


export const LoginPage = lazy(() => import('../pages/LoginPage/LoginPage'));
export const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage/ForgotPasswordPage'));
export const ResetPasswordForm = lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage'));
export const ComposeMailPage = lazy(() => import('../pages/ComposeMailPage/ComposeMailPage'));
export const MailPage = lazy(() => import('../pages/MailPage/MailPage'));
export const DashboardPage = lazy(() => import('../pages/DashboardPage/DashboardPage'));
export const Calendar = lazy(() => import('../pages/CalendarPage/CalendarPage'));
export const FeeManagement = lazy(() => import('../components/FeeManagement/FeeMangementIndex'));
export const FeematchingBrudcrum = lazy(() => import('../pages/ReferralManagementPage/FeematchingBrudcrum'));
export const FeesplitBrudcrum = lazy(() => import('../pages/ReferralManagementPage/FeesplitBrudcrum'));
export const ReportBrudcrum = lazy(() => import('../pages/ReferralManagementPage/ReportBrudcrum'));
export const SelectClientBrudcrum = lazy(() => import('../pages/ReferralManagementPage/SelectClientBrudcrum'));
export const FeePendingIssue = lazy(() => import('../pages/ReferralManagementPage/FeePendingIssuedPage'));
export const FeeMonthIssuedPage = lazy(() => import('../pages/ReferralManagementPage/FeeMonthIssuedPage'));
export const FeeManagementDashboard = lazy(() => import('../components/FeeManagement/Dashboard/FeeManagementDashboard'));
export const SearchClientResults = lazy(() => import('../components/FeeManagement/CreateFeeExpectation/SearchClientResults'));
export const SelectClient = lazy(() => import('../components/FeeManagement/CreateFeeExpectation/SelectClient'));
export const SearchClient = lazy(() => import('../components/FeeManagement/CreateFeeExpectation/SearchClient'));
export const AddClient = lazy(() => import('../components/FeeManagement/CreateFeeExpectation/AddNewClient'));
export const MonthlyIssued = lazy(() => import('../components/FeeManagement/MonthlyIssued/MonthlyIssued'));
export const PendingIssued = lazy(() => import('../components/FeeManagement/PendingIssued/PendingIssued'));
export const ReferralManagementPage = lazy(() => import('../pages/ReferralManagementPage/ReferralManagementPage'));
export const AddClientPage = lazy(() => import('../pages/AddClientPage/AddClientPage'));
export const ClientListPage = lazy(() => import('../pages/ClientListPage/ClientListPage'));
export const EditClientPage = lazy(() => import('../pages/EditClientPage/EditClientPage'));
export const ClientProfilePage = lazy(() => import('../pages/ClientProfilePage/ClientProfilePage'));
export const InstrumentPage = lazy(() => import('../pages/InstrumentPage/InstrumentPage'));
export const ProductsListPage = lazy(() => import('../pages/ProductsListPage/ProductsListPage'));
export const ProductsListPageFeeManagement = lazy(() => import('../pages/ProductsListPage/ProductListPageFeeManagement'));
export const ProductsSummaryPage = lazy(() => import('../pages/ProductsSummaryPage/ProductsSummaryPage'));
export const ProductsSummaryPageNew = lazy(() => import('../pages/ProductsSummaryPage/ProductSummaryPageNew'));
export const ChecklistPage = lazy(() => import('../pages/ChecklistPage/ChecklistPage'));
export const TaskListPage = lazy(() => import('../pages/TaskListPage/TaskListPage'));
export const ARLPage = lazy(() => import('../pages/ARLPage/ARLPage'));
export const ARLMainPage = lazy(() => import('../pages/ARLPage/ArlMainPage'));
export const TaskDetailsPage = lazy(() => import('../pages/TaskDetailsPage/TaskDetailsPage'));
export const ProfileSettingsPage = lazy(() => import('../pages/ProfileSettingsPage/ProfileSettingsPage'));
export const SurveyFormPage = lazy(() => import('../pages/SurveyFormPage/SurveyFormPage'));
export const RemindersPage = lazy(() => import('../pages/RemindersPage/RemindersPage'));
export const DraftRecommendationReportPage = lazy(() => import('../pages/DraftRecommendationReportPage/DraftRecommendationReportPage'));
export const DraftRecommendationSummaryPage = lazy(() => import('../pages/DraftRecommendationSummaryPage/DraftRecommendationSummaryPage'));
export const DraftRecommendationSummaryTaskListPage = lazy(() => import('../pages/DraftRecommendationSummaryTaskListPage/DraftRecommendationSummaryTaskListPage'));
export const FeeReconciliationPage = lazy(() => import('../pages/FeeReconciliationPage/FeeReconciliationPage'));
export const ConfirmClientPage = lazy(() => import('../pages/ConfirmClientPage/ConfirmClientPage'));
export const ExtractedDataPage = lazy(() => import('../pages/ExtractedPage/ExtractedPage'));
export const ListProductPage = lazy(() => import('../pages/ListProducts/ListProduct'));
export const Templates = lazy(() => import('../pages/TemplatesPage/TemplatesPage'));
export const uploadStatementPage = lazy(() => import('../pages/UploadStatement/UploadStatement'));

export const FeeSplit = lazy(() => import('../components/FeeManagement/FeeSplit/FeeSplit'));
export const DashboardSettingsPage = lazy(() => import('../pages/DashboardSettingsPage/DashboardSettingsPage'));
