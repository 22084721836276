import React from 'react';
import NoResultsIcon from '../../assets/images/noresults.svg';
import './NoResults.scss';

const NoResults = ({
    message="No results found",
    widthIcon=true,
    className, 
}) => {
    return (
        <div className={`NoResults ${className ? className : ''}`}>
             { widthIcon && <img className="noResultsIcon" src={NoResultsIcon} alt="no results found"/>}
            <p className="customDescription">{message}</p>
        </div>
    )
}

export default NoResults;
