import appConfig from '../../appConfig.json';
import { toLowercaseAndRemoveSpaces } from '../../utils/utils';

export const urlGenerator = (folderName) => `/mail/${folderName.replace(/ /g, '-')}`;

/* 
    helper utility used in mailIConOrderIntegrator to push folder breadcrumbs and other things to folder list object
*/
const fullUrlList = [];
let inboxId;
export const folderMetaDataGenerator = (folderItem) => {
    const folderName = folderItem.folder_name;
    const lowerCasedName = (folderName).toLowerCase(); //  --> inbox
    const url = urlGenerator(lowerCasedName); // --> "/inbox"
    const breadCrumbPath = `${url}/${folderItem.id}`; // --> "/inbox/1" where 1 is the folder id
    const fullUrl = `${url}/${folderItem.id}`; // this is only used in sidebar for comparing 
    fullUrlList.push(fullUrl); // this is only used in sidebar for comparing 
    folderItem.url = url;
    folderItem.pageTitle = `CAP - ${folderName}`; // --> CAP - Inbox
    folderItem.breadCrumbs = [
        { name: 'Dashboard', path: '/' },
        { name: 'Mail', path: breadCrumbPath },
        { name: `${folderName}`, path: breadCrumbPath },
    ]

    // need to get folder id (inbox id) in compose mail page coz left side of that page has inbox mail list
    if (lowerCasedName === 'inbox')
    {
        inboxId = folderItem.id;
    }

    return folderItem;
}

export const mailIConOrderIntegrator = (folderList) => {


    const folderListLength = folderList.length;
    folderList.forEach((folderItem, i) => {
        if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox))
        {
            folderItem.order = 2;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems))
        {
            folderItem.order = 4;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;

        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.drafts))
        {
            folderItem.order = 3;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.junk))
        {
            folderItem.order = 5;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.outbox))
        {
            folderItem.order = 6;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.archives))
        {
            folderItem.order = 7;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.spam))
        {
            folderItem.order = 8;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.deletedItems))
        {
            folderItem.order = 9;
            folderMetaDataGenerator(folderItem)
            folderItem.fullUrlList = fullUrlList;
        }
        else
        {
            // for all other dynamic folder types there will be no order and rest of the data will be generated by folderMetaDataGenerator
            folderMetaDataGenerator(folderItem)

            // for all other links it should not have same order number given above
            const list = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            for (let j = 0; j < 30; j++)
            {
                if (!list.includes(j))
                {
                    folderItem.order = j;
                }
            }



            folderItem.fullUrlList = fullUrlList;
        }


        // on the last item of loop push compose mail data to array
        if (i === (folderListLength - 1))
        {
            folderList.push({
                order: 1,
                folder_name: 'Compose Mail',
                // folderId : 2,
                id: `${inboxId}?action=compose`,
                url: '/mail/inbox',
                fullUrlList: fullUrlList,
                pageTitle: `CAP - Compose Mail`, // --> CAP - Inbox
            })
        }

    })
    folderList.sort((a, b) => {
        return (a.order > b.order) ? 1 : -1;
    })
    return folderList;
}


export const getUnreadMailsIdArray = (mailFolderList) => {
    return mailFolderList && mailFolderList.map(folderItem => {
        if (folderItem.message_is_read === false)
        {
            return folderItem.id
        }
    })
}



const sort_by = (field, reverse, primer) => {

    const key = primer ?
        function (x) {
            return primer(x[field])
        } :
        function (x) {
            return x[field]
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}


export const getInboxUnreadCount = (data) => {
    const unreadCountDetails = data.find( folderItem => {
        if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox))
        {
            return folderItem;
        }
    })
    const unreadCount = unreadCountDetails?.unread_count || 0;
    return unreadCount;
}