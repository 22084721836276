import { MAIL_ACTION_TYPES } from './mail.types';


import {
    getMailFolderListService,
    getMailsFromFolderService,
    getMailDetailsService,
    getMailFoldersService,
    searchMailRecipientsService,
    sendMailReplayService,
    composeMailService,
    fetchMailCategoriesService,
    fetchMailTemplatesService,
    getInternalDriveFoldersService,
    markMailAsReadService,
    moveMailToFolderService,
    saveOutlookSettingsService,
    fetchOutlookSettingsService,
    editOutlookSettingsService
} from '../../services/mailService';



export const removeErrorMessage = () => ({
    type: MAIL_ACTION_TYPES.REMOVE_ERROR_MESSAGE,
})

export const removeSuccessMessage = () => ({
    type: MAIL_ACTION_TYPES.REMOVE_SUCCESS_MESSAGE,
})


export const expandCollapseMailReplayBox = (payload) => ({
    type : MAIL_ACTION_TYPES.EXPAND_COLLAPSE_MAIL_REPLAY_BOX,
    payload: payload
})


export const expandAllMailReplayBox = (payload) => ({
    type : MAIL_ACTION_TYPES.EXPAND_ALL_MAIL_REPLAY_BOX,
    payload : payload
})


export const removeMailSuccessMessage = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_COMPOSE_MAIL_SUCCESS_MESSAGE
})

export const removeMailErrorMessage = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_COMPOSE_MAIL_ERROR_MESSAGE
})

export const removeMailCategoriesError = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_MAIL_CATEGORIES_ERROR_MESSAGE
})

export const clearMailReplaySuccessMessage = () => ({
    type : MAIL_ACTION_TYPES.CLEAR_MAIL_REPLAY_SUCCESS_MESSAGE
})

export const clearMailReplayErrorMessage = () => ({
    type : MAIL_ACTION_TYPES.CLEAR_MAIL_REPLAY_ERROR_MESSAGE
})



export const removeOutlookSettingsEditErrorMessage = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_EDIT_OUTLOOK_SETTINGS_ERROR_MSG
})
export const removeOutlookSettingsSaveErrorMessage = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_SAVE_OUTLOOK_SETTINGS_ERROR_MSG
})
export const removeOutlookSettingsSaveSuccessMessage = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_SAVE_OUTLOOK_SETTINGS_SUCCESS_MSG
})

export const clearTemplateData = () => ({
    type : MAIL_ACTION_TYPES.CLEAR_TEMPLATE_DATA
})



/* 
    Remove mail id from array, used to set mail as unread with out api calls
*/
export const removeMailIdFromArray = (mailId) => ({
    type : MAIL_ACTION_TYPES.REMOVE_MAIL_ID_FROM_ARRAY,
    payload : mailId
})


export const setEmailTemplateHeader = (templateHeader) => ({
    type : MAIL_ACTION_TYPES.SET_EMAIL_TEMPLATE_HEADER,
    payload : templateHeader
})
export const setEmailTemplateFooter = (templateFooter ) => ({
    type : MAIL_ACTION_TYPES.SET_EMAIL_TEMPLATE_FOOTER,
    payload : templateFooter
})
export const setEmailTemplateContent = (templateContent) => ({
    type : MAIL_ACTION_TYPES.SET_EMAIL_TEMPLATE_CONTENT, 
    payload : templateContent
})




/* 
    Action to get mail folders
*/
export const getMailFolderListAsync = () => {
    return dispatch => {
        getMailFolderListService(dispatch)
    }
}




/* 
Note :: This service is not used, api call is written as saga insted of this, as there a requirement to ignore previous api calls and take latest api call
*/
// export const getMailsFromFolderAsync = (folderId) => {
//     return dispatch => {
//         getMailsFromFolderService(dispatch, folderId)
//     }
// }

export const getMailsFromFolderAsync = ( payload ) => ({
    type : MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_START,
    payload : payload
})







/* 
    Action to get mail data of parent mail or conversation
*/
// export const getMailDetailsAsync = (parentMailId) => {
//     return dispatch => {
//         getMailDetailsService(dispatch, parentMailId)
//     }
// }

export const getMailDetailsAsync = ( parentMailId ) => ({
    type : MAIL_ACTION_TYPES.GET_MAIL_DETAILS_START,
    payload : parentMailId
})


export const searchMailRecipientsAsync = (searchTerm) => {
    return dispatch => {
        searchMailRecipientsService(dispatch, searchTerm)
    }
}



export const sendMailReplayAsync = (replayData, folderDetails=null) => {
    return dispatch => {
        sendMailReplayService(dispatch, replayData, folderDetails)
    }
}




export const composeMailAsync = (composeMailData, folderDetails=null) => {
    return dispatch => {
        composeMailService(dispatch, composeMailData, folderDetails)
    }
}



export const fetchMailCategoriesAsync = () => {
    return dispatch => {
        fetchMailCategoriesService(dispatch)
    }
}


export const fetchMailTemplatesAsync = (categoryId) => {
    return dispatch => {
        fetchMailTemplatesService(dispatch, categoryId)
    }
}



export const getInternalDriveFoldersAsync = () => {
    return dispatch => {
        getInternalDriveFoldersService(dispatch)
    }
}


export const markMailAsReadAsync = (conversationId) => {
    return dispatch => {
        markMailAsReadService(dispatch,conversationId)
    }
}



export const moveMailToFolderAsync = (folderDetails) => {
    return (dispatch, getState) => {
        moveMailToFolderService(dispatch,folderDetails);
    }
}







export const fetchOutlookSettingsAsync = () => {
    return dispatch => {
        fetchOutlookSettingsService(dispatch)
    }
}

export const saveOutlookSettingsAsync = (outlookDetails) => {
    return dispatch => {
        saveOutlookSettingsService(dispatch, outlookDetails)
    }
}

export const editOutlookSettingsAsync = (outlookDetails) => {
    return dispatch => {
        editOutlookSettingsService(dispatch, outlookDetails)
    }
}


export const removeDeletedMailFromList = (mailId) => ({
    type : MAIL_ACTION_TYPES.REMOVE_DELETED_MAIL_FROM_LIST,
    payload : mailId
})

export const markReadMailFromList = (mailId) => ({
    type : MAIL_ACTION_TYPES.MARK_READ_MAIL_FROM_LIST,
    payload : mailId
})

export const removeMailDetails = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_MAIL_DETAILS,
})

export const removeMailsInFolder = () => ({
    type : MAIL_ACTION_TYPES.REMOVE_MAILS_IN_FOLDER,
})


