import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CircularLoader from '../../components/CircularLoader/CircularLoader';
import './SurveyStatus.scss';
import dataGathering from '../../assets/icons/dataGathering.svg'
import appConfig from '../../appConfig.json';
import { getSurveyStatusData } from '../../redux/surveyForms/surveyForms.actions';
import { useSnackbar } from 'notistack';
import NotAuthorizedMessage from '../NotAuthorizedMessage/NotAuthorizedMessage';
import usePermissionCheck from '../../hooks/usePermissionCheck';

const SurveyStatus = ({clientId}) => {


    const dispatch  = useDispatch();
    const history   = useHistory();
    const { enqueueSnackbar }   = useSnackbar();
    const client_id             = clientId ? clientId : history.location.state?.clientId;
    const surveyStatusData      = useSelector(state => state.survey.surveyStatus)
    const answeredQuestions     = useSelector(state => state.survey.answeredQuestions)
    const isLoadingSurveyStatus = useSelector(state => state.survey.isLoadingSurveyStatus)
    const clientDetails         = useSelector(state => state.client?.clientDetails);
    const isLoadingPostSurveyForm = useSelector(state => state.survey.isLoadingPostSurveyForm);

    /**
     * Permission check for different activities
     * Advisor's check and SuperAdmin's checks are done here
     */
    const surveyFormViewAccess      = usePermissionCheck(['SURVEY-FORM-VIEW'], clientDetails);
    const listSurveyFormStatusAccess= true;//usePermissionCheck(['LIST-SURVEY-FORM-STATUSES'], clientDetails);

    useEffect(() => {
        dispatch(getSurveyStatusData(client_id));
    },[])

    useEffect(() => {
        if(client_id){
            dispatch(getSurveyStatusData(client_id));
        }
    },[client_id, isLoadingPostSurveyForm])

    const handleSurveyStatusClick = (step = 0) => {

        if(surveyFormViewAccess){
            history.push('/client-management/survey-form', {clientId:clientId, activeStep: step})
        }else{
            setErrorToast("You are not allowed to conduct survey for other's client!");
        }
    }
    const setErrorToast = (errMessage) => {

        enqueueSnackbar(errMessage, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
            // onClose: () => {}
        });
    }

    return (
        <div className="surveyStatusContainer">
        {(!isLoadingSurveyStatus) ? (<>
            {(answeredQuestions > 0) ? (
                <section className="surveyStatusWrapper">
                    {
                        listSurveyFormStatusAccess ?
                        <>
                        {
                            (surveyStatusData && surveyStatusData.length >0) ? (
                                surveyStatusData.map((item, index) => {
                                    const chips = []
                                    const percentageFull = 100;
                                    const percentageLow = 30;
                                    for (let i = 1; i <= item.total_mandatory_labels; i++) {
                                        var classname = "surveyChipItem "
                                        if(i > item.answered_mandatory_labels){
                                            classname += "surveyChipItemWarning"
                                        }
                                        chips.push(<span className={classname}></span>);
                                    }
                                    return (<>
                                        {clientDetails &&
                                        <Grid item sm={12} xs={12} 
                                            onClick={() => {handleSurveyStatusClick(index)}} 
                                            className={`surveyStatusContent ${(item.percentage_of_completion == percentageFull)? 'surveyStatusContentDone':''} ${(item.percentage_of_completion >= percentageLow && item.percentage_of_completion < percentageFull)? 'surveyStatusContentWarning':''}`} 
                                            key={index}
                                        >
                                            <Grid item sm={7} xs={7} className="surveyStatusItemContainer">
                                                <div className="surveyStatusItem">
                                                    <div className={`surveyStatusLabel ${(item.percentage_of_completion < percentageFull)? 'surveyStatusLabelWarning':''}`}>{item.category_name}</div>
                                                    <div className="surveyStatusChip">
                                                        {chips}
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item sm={5} xs={5} className="surveyStatusMsg">
                                                {/* <div className="surveyStatusMsg"> */}
                                                    <span className={`surveyStatusMsgCircle ${(item.percentage_of_completion < percentageFull)? 'surveyStatusMsgCircleWarning':''}`}>
                                                        {
                                                            (item.percentage_of_completion == percentageFull) ? (
                                                                <DoneAllIcon style={{fontSize:"30px"}}/>
                                                            ) : (
                                                                <span className="surveyStatusPercent">{Math.round(item.percentage_of_completion)}<span>%</span></span>
                                                            )
                                                        }
                                                    </span>
                                                    <span className={`surveyStatusMsgText ${(item.percentage_of_completion < percentageFull)? 'surveyStatusLabelWarning':''}`}>
                                                        {
                                                            (item.percentage_of_completion == percentageFull) ? (
                                                                'Congrats, all done!'
                                                            ) : (
                                                                item.days_remaining
                                                            )
                                                        }
                                                    </span>
                                                {/* </div> */}
                                            </Grid>
                                        </Grid>
                                        }
                                    </>)
                                })
                            ) : (
                                ''
                            )
                        }
                        </>
                        :
                        <NotAuthorizedMessage centered>{appConfig.messages.not_authorized_section_message}</NotAuthorizedMessage>
                }
                </section>
            ):(
                <Grid item xs={12} className="dataGatheringSection">
                    <div style={{display:"flex", alignItems:"center"}}>
                        {clientDetails &&
                        <div className="dataGatheringContent" onClick={() => {handleSurveyStatusClick()}} >
                            <img src={dataGathering} width={70} />
                            <p className="dataGatheringText">Let's Start with <br/> the Data Gathering!</p>
                        </div>
                        }
                    </div>
                </Grid>
            )}
        </>
        ):(
            <CircularLoader className="centerStyle"/>
        )}
        </div>
    )
}
export default SurveyStatus