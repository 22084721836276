import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { DESTROY_SESSION } from "./auth/auth.types";
import authReducer from './auth/auth.reducer';
import uiReducer from './ui/ui.reducer';
import referralReducer from './referralManagement/referralManagement.reducer';
import mailReducer from './mail/mail.reducer';
import calendarReducer from './calendar/calendar.reducer';
import instrumentReducer from './instrument/instrument.reducer';
import clientReducer from './client/client.reducer';
import surveyReducer from './surveyForms/surveyForms.reducer';
import persistedReducer from './persistedReducer/persistedReducer.reducer';
import tasksReducer from './tasks/tasks.reducer';
import draftReducer from './draft/draft.reducer';
import { logOutUserAsync } from '../redux/auth/auth.action';
import { store } from './store';
import routingReducer from './routing/routing.reducer';
import { FeeManagementReducer } from './FeeManagementClient/FeeManagementClient.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';




// encrypting redux store for security concerns 
const encryptor = encryptTransform({
    secretKey: "Q0FQX1NFQ1JFVF9LRVk=",
    onError(error) {
        store.dispatch(logOutUserAsync());
    },
})


// Redux persist configuration
const persistConfig = {
    key: 'root',
    storage,
    // only these reducers will be persisted,
    whitelist: ['auth','persistedReducer'],
    transforms: [encryptor], 
}


// authReducer and persistedReducer :: These are persisted reducers and date saved wont be cleared on page refresh
const appReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    referral: referralReducer,
    mail : mailReducer,
    calendar : calendarReducer,
    instrument : instrumentReducer,
    client: clientReducer,
    survey: surveyReducer,
    persistedReducer : persistedReducer,
    tasks : tasksReducer,
    draft : draftReducer,
    route : routingReducer,
    feeManagementReducer : FeeManagementReducer,
    dashboard:dashboardReducer

});


const rootReducer = (state, action) => {
    // when a DESTROY_SESSION action is dispatched it will reset redux state
    if (action.type === 'DESTROY_SESSION') {
        state = undefined;
    }
    return appReducer(state, action);
};


export default persistReducer(persistConfig, rootReducer);