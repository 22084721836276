import * as Yup from 'yup';
import moment from 'moment';

export const addEventSchema = Yup.object().shape({
    //participants : Yup.array().required('Minimum one participant required.'),
    title: Yup.string()
        .required('Title is required.')
        .max(100, 'Maximum characters up to 100.'),
    // description: Yup.string()
    //     .required('Event description is required.')
    //     .max(100, 'Maximum characters up to 100.'),
    stateDate: Yup.string().required('Title is required.'),
    participants: Yup.string().required('Participants is required.'),
    endDate: Yup.string().test(
        "endDate",
        "A valid end date required.",
        function(endDate) {
            // return (moment(endDate).diff(moment(this.parent.stateDate),'days') >= 0) ? true : false;
            if((new Date(this.parent.stateDate)) > (new Date(endDate))){
                return false;
            }
            return true;
        }
    ),
    location : Yup.string().max(250, 'Maximum 250 Character allowed.'),
    startTime : Yup.string().test(
        "startTime",
        "A valid start time required.",
        function(startTime) {
            let endTimeCorrected;
            let endTime  = this.resolve(Yup.ref('endTime'));
            if(!moment(endTime).isValid())
            {
                endTimeCorrected = new Date(parseInt(endTime))
            }
            else
            {
                endTimeCorrected = endTime;
            }

            let formattedStartDt = moment(this.parent.stateDate).format('YYYY-MM-DD');
            let formattedEndDt = moment(this.parent.endDate).format('YYYY-MM-DD');
            if(((new Date(formattedStartDt).getTime()) === (new Date(formattedEndDt).getTime())) && (new Date(startTime) > new Date(endTimeCorrected))){
                return false;
            }
            return true;
        }
    ),
    endTime: Yup.string().test(
        "endTime",
        "A valid end time required.",
        function(endTime) {
            let startTimeCorrected;
            let startTime  = this.resolve(Yup.ref('startTime'));
            if(!moment(startTime).isValid())
            {
                startTimeCorrected = new Date(parseInt(startTime))
            }
            else
            {
                startTimeCorrected = startTime;
            }

            let formattedStartDt = moment(this.parent.stateDate).format('YYYY-MM-DD');
            let formattedEndDt = moment(this.parent.endDate).format('YYYY-MM-DD');
            if(((new Date(formattedStartDt).getTime()) === (new Date(formattedEndDt).getTime())) && (new Date(endTime) < new Date(startTimeCorrected))){
                return false;
            }
            return true;
        }
    ),



});


