import React from 'react';
import CircularLoader from '../CircularLoader/CircularLoader';
import './PageLoader.scss';

const PageLoader = () => {
    return (
        <div className="PageLoader">
            <CircularLoader className="pageCirclularLoader"/>
        </div>
    )
}

export default PageLoader;
