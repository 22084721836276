

/* 
    helper utility for formatting the response to required format in the page
*/
export const clientListFormatter  = (clientData) => {
    const clientDataFormatted = clientData.map( clientItem => {
        const name = `${clientItem.user.first_name} ${clientItem.user.last_name}`;
        const referred_by_name = clientItem.referred_user_first_name ? `${clientItem.referred_user_first_name} ${clientItem.referred_user_last_name}` : 'New';
        return {
            company:  clientItem.company,
            email: clientItem.user.email,
            mobile: clientItem.phone_number,
            name: name,
            referred_by: referred_by_name,
            referred_date: clientItem.referred_date,
            clientId : clientItem.id,
            enable_cold_calling : clientItem.enable_cold_calling,
            created_by : clientItem.created_by,
            surveyform_percentage_avg:clientItem.surveyform_percentage_avg,
            pre_contract_percent: clientItem.pre_contract_percent,
            atp_percent: clientItem.atp_percent,
            post_contract_percent: clientItem.post_contract_percent,
        }
    });
    return clientDataFormatted;
}

/*
    add fullname in advisor list
*/
export const advisorListFormatter  = (advisorList, excludeId='') => {
    const advisorFormatted = advisorList.map( advisor => {
        var full_name = `${advisor.first_name} ${advisor.last_name}`;
        if(excludeId && excludeId == advisor.id){
            full_name = 'Me';
        }
        return {
            id:  advisor.id,
            full_name: full_name,
        }
    });
    return advisorFormatted;
}


/* 
    combine firstname and last name and make a new index
*/

export const firstNameLastNameCombiner  = ( optionsData ) => {
    optionsData.forEach( (optionItem, i) => {
        optionItem.fullName = `${optionItem.first_name} ${optionItem.last_name}`
    })
    return optionsData;
}
