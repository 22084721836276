import React from 'react';
import './CircularLoader.scss';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';





// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: theme.palette.secondary.main,
        animationDuration: '350ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

function CircularLoaderComponent(props) {
    const classes = useStylesFacebook();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={props.size}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={props.size}
                thickness={4}
                {...props}
            />
        </div>
    );
}



const CircularLoader = ({ 
    size=40, 
    className 
}) => {
    return (
        <div className={clsx(
            className ? className : '',
            'CircularLoader'
        )}>
            <CircularLoaderComponent size={size}/>
        </div>
    )
}

export default CircularLoader;
