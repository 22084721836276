/**
 * Section for api and other server side configuration
 */
 const { REACT_APP_OUTLOOK_REDIRECT_API, REACT_APP_EXTRACTED_DATA_API } = process.env;
 export const API_HOSTNAME = `${process.env.REACT_APP_BASE_URL}`;
 export const OUTLOOK_REDIRECT_API = REACT_APP_OUTLOOK_REDIRECT_API;
 export const EXTRACTED_DATA_API = (productId, userId) => REACT_APP_EXTRACTED_DATA_API+'?product_id='+productId+'&user_id='+userId;
 // Section for api and other server side configuration - End
 
 
 
 /* 
     Color variables for usage across the app
 */
 export const COLOR_CODES = {
     dashboardBlue : '#1AC7DE',
     dashboardOrange : '#F1CC3B',
     dashboardPink : '#FF9ABF',
     dashboardGreen : '#75C987',
 };
 
 /* 
     Calendar repeat options
 */
 export const calendarRepeatOptions = [
     { id: 1, name: 'Repeat Never', value: 'never' },
     { id: 2, name: 'Every Day', value: 'daily' },
     { id: 3, name: 'Every Week', value: 'weekly' },
     { id: 4, name: 'Every Month', value: 'monthly' },
     { id: 5, name: 'Every Year', value: 'yearly' },
     // { id : 6 , name  : 'Custom'},
 ];
 
 
 /* 
     Reminder snooze options
 */
 export const reminderOptions = [
     { id: 1, name: 'Never', value: null }, // as asked by developer 
     { id: 2, name: 'At time of event', value: 0 }, // as asked by developer 
     { id: 3, name: '15 mins Before', value: 15 },
     { id: 4, name: '30 mins Before', value: 30 }
 ];
 
 
 /* 
     Week data for calendar repeat
 */
 export const daysCircles = [
     { id: 1, name: 'Sunday', label: 'S' },
     { id: 2, name: 'Monday', label: 'M' },
     { id: 3, name: 'Tuesday', label: 'T' },
     { id: 4, name: 'Wednesday', label: 'W' },
     { id: 5, name: 'Thursday', label: 'T' },
     { id: 6, name: 'Friday', label: 'F' },
     { id: 7, name: 'Saturday', label: 'S' },
 ];
 
 export const RSVP_ACCEPTED = '2';
 export const RSVP_DECLINED = '3';
 export const RSVP_TENTATIVE = '4';
 export const RSVP_NOTRESPONDED = '5';
 export const rsvpStatus = [
     { id: '2', name: 'accepted', label: 'Accepted' },
     { id: '3', name: 'declined', label: 'Rejected' },
     { id: '4', name: 'tentative', label: 'May be' },
     { id: '5', name: 'not_responded', label: 'Not Responded' },
 ];
 
 export const MAIL_SENDING_ACTIONS = [
     { id: 1, action: 'send' },
     { id: 2, action: 'replay' },
     { id: 3, action: 'replay_all' },
     { id: 4, action: 'forward' },
 ];
 export const PRODUCT_TYPES = [
     { id: 1, name: 'Existing' },
     { id: 2, name: 'New' },
 ];
 
 /* 
     Frequency filters
 */
 export const DASHBOARD_FREQUENCY_VALUES = [
     { id: 'daily', labelText: 'Daily' },
     { id: 'weekly', labelText: 'Weekly' },
     { id: 'monthly', labelText: 'Monthly' },
 ];
 
 /* 
     Reminders page state list
 */
 export const STATE_OPTIONS = [
     { id: 'all', name: 'All' },
     { id: 'active', name: 'Active' },
 ];
 
 /* 
     Reminders page snoozed for day list
 */
 export const SNOOZE_FOR_OPTIONS = [
     { id: '1_day', name: '1 Day', unit: 'day', duration : 1 },
     { id: '2_day', name: '2 Days', unit: 'day', duration : 2 },
     { id: '3_day', name: '3 Days', unit: 'day', duration : 3 },
     { id: '4_day', name: '4 Days', unit: 'day', duration : 4 },
     { id: '5_day', name: '5 Days', unit: 'day', duration : 5 },
     { id: '1_week', name: '1 Week', unit: 'week', duration : 1 },
 ];
 
 /* 
     Task status in task listing page
 */
 export const TASK_STATUS = [
     { id: 2, name: 'In Progress' },
     { id: 1, name: 'Pending' },
     { id: 3, name: 'Completed' },
 
 ];
 
 export const DOC_TYPES = [
     { id: 1, name: 'Brochure', key: 'BROCHURE' },
     { id: 2, name: 'Bulk_csv', key: 'BULK_CSV' },
     { id: 3, name: 'LOA', key: 'LOA' },
     { id: 4, name: 'Illustration_response', key: 'ILLUSTRATION_RESPONSE' },
     { id: 5, name: 'LOA_Response', key : 'LOA_RESPONSE' },
     { id: 7, name: 'Thirdparty doc', key : 'THIRDPARTY_DOC' },
     { id: 8, name: 'Suitability_Report', key : 'SUITABILITY_REPORT' },
     { id: 9, name: 'AML', key : 'AML' },
     { id: 10, name: 'Authority to Proceed', key : 'AUTHORITY_TO_PROCEED' },
     { id: 11, name: 'ATR', key : 'ATR' },
     { id: 12, name: 'Platform Costs', key : 'PLATFORM_COSTS' },
     { id: 13, name: 'Application Summary', key : 'APPLICATION_SUMMARY' },
     { id: 14, name: 'Fund Research', key : 'FUND_RESEARCH' },
     { id: 15, name: 'Critical Yield', key : 'CRITICAL_YIELD' },
     { id: 16, name: 'Illustration', key : 'ILLUSTRATION' },
     { id: 17, name: 'Weighted average calculator', key : 'WEIGHTED_AVERAGE' }
 ];
 
 export const LOGGEDIN_USER_TYPES = {
     typeAdvisor : "Advisor",
     typeOps : "Ops",
     typeAdmin: "Admin",
     typeAdministrator: "Administrator",
     typeCompliance : "Compliance",
     typeClient : "Client",
     typeSuperAdmin : "SuperAdmin"
 };
 
 /* 
     Checklist static names
 */
 export const CHECK_LIST_STATIC_NAMES = {
     advisorchecklist : 'advisorchecklist',
     complaincechecklist : 'complaincechecklist',
     administratorchecklist : 'administratorchecklist',
 };
 
 /* 
     Type for accepted rejected 
 */
 export const ACCEPTED_REJECTED_TYPES = {
     REJECTED : 'REJECTED',
     ACCEPTED : 'ACCEPTED'
 }
 
 
 /* 
     Type for KYC success or reject
     PASS treated as success,
     REFER treated as failure,
     FAILURE treated as failure,
 */
 
 export const KYC_STATUS_TYPES = {
     PASS : 'pass',
     REFER : 'refer',
     FAILED : 'failed'
 }
 
 export const INITIAL_MARKINGS = [
     {value: 0, label: '0%'},
     {value: 1, label: '1%'},
     {value: 2, label: '2%'},
     {value: 3, label: '3%'},
     {value: 4, label: '4%'},
   ];
 export const ONGOING_MARKINGS = [
     {value: 0, label: '0%'},
     {value: 0.25, label: '0.25%'},
     {value: 0.50, label: '0.50%'},
     {value: 0.75, label: '0.75%'},
     {value: 1, label: '1%'},
   ];
 export const DFM_MARKINGS = [
     {value: 0, label: '0%'},
     {value: 0.25, label: '0.25%'},
     {value: 0.50, label: '0.50%'},
     {value: 0.75, label: '0.75%'},
     {value: 1, label: '1%'},
   ];
 
 export const EXTRACTION_EXCLUDE_TEXT = [
     "not listed",
     "not found",
     "not trained"
 ]
 