export const saveNewValues = (values, existingValuesArray) => {

    // if value already exist update it
    const itemToBeUpdated = existingValuesArray.find(valueItem => {
        if(valueItem.valueSavedFrom === values.valueSavedFrom)
        {
            valueItem.value = values.value;
            valueItem.valueSavedFor = values.valueSavedFor;
            valueItem.valueSavedFrom = values.valueSavedFrom;
            return valueItem;
        }
    });

    // if value doesn't exist it will be undefined so push it 
    if(itemToBeUpdated === undefined)
    {
        existingValuesArray.push(values)
    }

    return existingValuesArray;
}


export const updateSavedValues = (fieldAndValueDetails, existingSavedValues) => {
    existingSavedValues.forEach(valueItem => {
        if(valueItem.valueSavedFor.length > 0 && valueItem.valueSavedFor.includes(fieldAndValueDetails.fieldName))
        {
            valueItem.value = fieldAndValueDetails.value;
        }
    });
    const newExistingValues = [...existingSavedValues]; // mutating objects directly wont trigger render forcing rerender by making new array
    return newExistingValues;
}

export const removeOneFromSavedValues = (fieldName, existingSavedValues) => {
    let newExistingValues = [];
    existingSavedValues.forEach(valueItem => {
        if(valueItem.valueSavedFrom !== fieldName){
            newExistingValues.push(valueItem)
        }
    });
    return newExistingValues;
}

export const checkAndAddCategoryId = (categoryId, existingCategoryIds) => {
    if(!existingCategoryIds.includes(categoryId)){
        existingCategoryIds.push(categoryId);
    }
    return existingCategoryIds;
}