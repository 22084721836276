export const MAIL_ACTION_TYPES = {

    GET_MAIL_FOLDER_LIST_START : 'GET_MAIL_FOLDER_LIST_START',
    GET_MAIL_FOLDER_LIST_SUCCESS : 'GET_MAIL_FOLDER_LIST_SUCCESS',
    GET_MAIL_FOLDER_LIST_FAILURE : 'GET_MAIL_FOLDER_LIST_FAILURE',

    GET_MAILS_FROM_FOLDER_START : 'GET_MAILS_FROM_FOLDER_START',
    GET_MAILS_FROM_FOLDER_SUCCESS : 'GET_MAILS_FROM_FOLDER_SUCCESS',
    GET_MAILS_FROM_FOLDER_FAILURE : 'GET_MAILS_FROM_FOLDER_FAILURE',


    GET_MAIL_DETAILS_START : 'GET_MAIL_DETAILS_START',
    GET_MAIL_DETAILS_SUCCESS : 'GET_MAIL_DETAILS_SUCCESS',
    GET_MAIL_DETAILS_FAILURE : 'GET_MAIL_DETAILS_FAILURE',


    GET_MAIL_FOLDERS_START : 'GET_MAIL_FOLDERS_START',
    GET_MAIL_FOLDERS_SUCCESS : 'GET_MAIL_FOLDERS_SUCCESS',
    GET_MAIL_FOLDERS_FAILURE : 'GET_MAIL_FOLDERS_FAILURE',


    SEARCH_MAIL_RECIPIENTS_START : 'SEARCH_MAIL_RECIPIENTS_START',
    SEARCH_MAIL_RECIPIENTS_SUCCESS : 'SEARCH_MAIL_RECIPIENTS_SUCCESS',
    SEARCH_MAIL_RECIPIENTS_FAILURE : 'SEARCH_MAIL_RECIPIENTS_FAILURE',


    SEND_MAIL_REPLAY_START : 'SEND_MAIL_REPLAY_START',
    SEND_MAIL_REPLAY_SUCCESS : 'SEND_MAIL_REPLAY_SUCCESS',
    SEND_MAIL_REPLAY_FAILURE : 'SEND_MAIL_REPLAY_FAILURE',
    CLEAR_MAIL_REPLAY_ERROR_MESSAGE:'CLEAR_MAIL_REPLAY_ERROR_MESSAGE',
    CLEAR_MAIL_REPLAY_SUCCESS_MESSAGE : 'CLEAR_MAIL_REPLAY_SUCCESS_MESSAGE',

    COMPOSE_MAIL_START : 'COMPOSE_MAIL_START',
    COMPOSE_MAIL_SUCCESS : 'COMPOSE_MAIL_SUCCESS',
    COMPOSE_MAIL_FAILURE : 'COMPOSE_MAIL_FAILURE',


    FETCH_MAIL_CATEGORIES_START : 'FETCH_MAIL_CATEGORIES_START',
    FETCH_MAIL_CATEGORIES_SUCCESS : 'FETCH_MAIL_CATEGORIES_SUCCESS',
    FETCH_MAIL_CATEGORIES_FAILURE : 'FETCH_MAIL_CATEGORIES_FAILURE',

    FETCH_MAIL_TEMPLATES_START : 'FETCH_MAIL_TEMPLATES_START',
    FETCH_MAIL_TEMPLATES_SUCCESS : 'FETCH_MAIL_TEMPLATES_SUCCESS',
    FETCH_MAIL_TEMPLATES_FAILURE : 'FETCH_MAIL_TEMPLATES_FAILURE',


    GET_INTERNAL_DRIVE_FOLDERS_START : 'GET_INTERNAL_DRIVE_FOLDERS_START',
    GET_INTERNAL_DRIVE_FOLDERS_SUCCESS : 'GET_INTERNAL_DRIVE_FOLDERS_SUCCESS',
    GET_INTERNAL_DRIVE_FOLDERS_FAILURE : 'GET_INTERNAL_DRIVE_FOLDERS_FAILURE',

    EXPAND_COLLAPSE_MAIL_REPLAY_BOX : 'EXPAND_COLLAPSE_MAIL_REPLAY_BOX',
    EXPAND_ALL_MAIL_REPLAY_BOX : 'EXPAND_ALL_MAIL_REPLAY_BOX',


    REMOVE_COMPOSE_MAIL_SUCCESS_MESSAGE: 'REMOVE_COMPOSE_MAIL_SUCCESS_MESSAGE',
    REMOVE_COMPOSE_MAIL_ERROR_MESSAGE : 'REMOVE_COMPOSE_MAIL_ERROR_MESSAGE',
    REMOVE_MAIL_CATEGORIES_ERROR_MESSAGE : 'REMOVE_MAIL_CATEGORIES_ERROR_MESSAGE',




    MARK_MAIL_AS_READ_START : 'MARK_MAIL_AS_READ_START',
    MARK_MAIL_AS_READ_SUCCESS : 'MARK_MAIL_AS_READ_SUCCESS',
    MARK_MAIL_AS_READ_FAILURE : 'MARK_MAIL_AS_READ_FAILURE',



    REMOVE_MAIL_ID_FROM_ARRAY: 'REMOVE_MAIL_ID_FROM_ARRAY',

    MOVE_MAIL_TO_FOLDER_START : 'MOVE_MAIL_TO_FOLDER_START',
    MOVE_MAIL_TO_FOLDER_SUCCESS : 'MOVE_MAIL_TO_FOLDER_SUCCESS',
    MOVE_MAIL_TO_FOLDER_FAILURE : 'MOVE_MAIL_TO_FOLDER_FAILURE',



    SET_EMAIL_TEMPLATE_HEADER: 'SET_EMAIL_TEMPLATE_HEADER',
    SET_EMAIL_TEMPLATE_FOOTER: 'SET_EMAIL_TEMPLATE_FOOTER',
    SET_EMAIL_TEMPLATE_CONTENT: 'SET_EMAIL_TEMPLATE_CONTENT',



    FETCH_OUTLOOK_SETTINGS_START : 'FETCH_OUTLOOK_SETTINGS_START',
    FETCH_OUTLOOK_SETTINGS_SUCCESS : 'FETCH_OUTLOOK_SETTINGS_SUCCESS',
    FETCH_OUTLOOK_SETTINGS_FAILURE : 'FETCH_OUTLOOK_SETTINGS_FAILURE',

    SAVE_OUTLOOK_SETTINGS_START : 'SAVE_OUTLOOK_SETTINGS_START',
    SAVE_OUTLOOK_SETTINGS_SUCCESS : 'SAVE_OUTLOOK_SETTINGS_SUCCESS',
    SAVE_OUTLOOK_SETTINGS_FAILURE : 'SAVE_OUTLOOK_SETTINGS_FAILURE',
    REMOVE_SAVE_OUTLOOK_SETTINGS_SUCCESS_MSG: 'REMOVE_SAVE_OUTLOOK_SETTINGS_SUCCESS_MSG',

    
    EDIT_OUTLOOK_SETTINGS_START : 'EDIT_OUTLOOK_SETTINGS_START',
    EDIT_OUTLOOK_SETTINGS_SUCCESS : 'EDIT_OUTLOOK_SETTINGS_SUCCESS',
    EDIT_OUTLOOK_SETTINGS_FAILURE : 'EDIT_OUTLOOK_SETTINGS_FAILURE',

    REMOVE_EDIT_OUTLOOK_SETTINGS_ERROR_MSG : 'REMOVE_EDIT_OUTLOOK_SETTINGS_ERROR_MSG',
    REMOVE_SAVE_OUTLOOK_SETTINGS_ERROR_MSG : 'REMOVE_SAVE_OUTLOOK_SETTINGS_ERROR_MSG',

    CLEAR_TEMPLATE_DATA: 'CLEAR_TEMPLATE_DATA',
    REMOVE_DELETED_MAIL_FROM_LIST: 'REMOVE_DELETED_MAIL_FROM_LIST',
    MARK_READ_MAIL_FROM_LIST: 'MARK_READ_MAIL_FROM_LIST',

    REMOVE_MAIL_DETAILS : 'REMOVE_MAIL_DETAILS',
    REMOVE_MAILS_IN_FOLDER : 'REMOVE_MAILS_IN_FOLDER',
}

