import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from '@material-ui/core/styles';
import { getError, stringCaseSwitcher } from '../../utils/utils';
import Tooltip from '@mui/material/Tooltip';
import "./SearchableSelect.scss"

const useStyles = makeStyles(theme => {
    return {
        root: {
            '& label.Mui-focused': {
                color: theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-root': {
                height: "45px", 
  
                '& fieldset': {
                  height: "45px", 
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.secondary.main
                },
            },
        }
    }
});

const filter = createFilterOptions();


const SearchableSelect = ({
    field: { name, value, ...otherFieldProps },
    form: { touched, errors, values, setFieldValue, status },
    onChange,
    label,
    variant = 'outlined',
    options,
    optionLabel,
    size = "small",
    classNames,
    required,
    onInputChange,
    loading,
    withIcon = false,
    resultIcon: ResultIcon,
    creatable=false,
    selectOnFocus=false,
    onBlur,
    clearOnBlur=false,
    onClear=()=>null,
    creatableLabel=true,
    disabled=false,
    convertToCase = "",
    noOptionsText=null
}) => {

    const color = useStyles();
    const incomingValue = value;
    const errorText = getError(name, { touched, status, errors });
    const isError = (errorText) ? true : false;

    return (
        
        <Autocomplete
            {...otherFieldProps}
            onChange={(e, newValue) => {

                const value = newValue || '';  // make sure to give number,nullable if validating
                setFieldValue(name, value);

                // Running the custom on change function if passed
                if (onChange)
                {
                    onChange(e, newValue);
                }
            }}
           
           
            // disableClearable
            id={name}
            options={options}
            className={`SearchableSelect ${color.root}`}
            renderInput={(params) => {
                return (
                    <Tooltip title={label} placement="top">
                    <TextField
                        {...params}
                        className={classNames}
                        required={required}
                        label={label}
                        variant={variant}
                        error={isError}
                        helperText={errorText}
                        inputProps={{
                            ...params.inputProps,
                            value : stringCaseSwitcher(convertToCase, params.inputProps.value),
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                    </Tooltip>
                )
            }}
            size={size}
            getOptionLabel={(option) => {
                
                // Value selected with enter, right from the input
                if (typeof option === 'string')
                {
                    return stringCaseSwitcher(convertToCase, option);
                }
                // Add "xxx" option created dynamically
                if (option.inputValue)
                {
                    return stringCaseSwitcher(convertToCase, option.inputValue);
                }
                return stringCaseSwitcher(convertToCase, option[optionLabel]);
                
            }} // this is needed to show selected value 
            renderOption={(option, { inputValue }) => {
                const matches = match(stringCaseSwitcher(convertToCase, option[optionLabel] ), inputValue);
                const parts = parse(stringCaseSwitcher(convertToCase, option[optionLabel] ), matches);
                return (
                    <>
                        {withIcon && <span className="resultIcon"><ResultIcon /></span>}
                        <div className="searchableSelectResults">
                            {parts.map((part, index) => {
                                return (
                                    <span key={index} className="highLighter" style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                )
                            })}
                        </div>
                    </>
                );
            }}
            onInputChange={(e, value, reason) => {
                const casedString = stringCaseSwitcher(convertToCase, value);
                if (onInputChange && reason !== 'reset')
                {
                    onInputChange(e, casedString, reason)
                }

                // when clear icon is clicked 
                if(reason === 'clear')
                {
                    onClear();
                }
            }}
            loading={loading}


            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                let optionList = [];
                (filtered.length > 0) && filtered.forEach((item)=>{
                    optionList.push(item[optionLabel])
                })

				// Suggest the creation of a new value if creatable is true
				if (params.inputValue !== '' && creatable === true && !optionList.includes(stringCaseSwitcher(convertToCase, params.inputValue)))
				{
                    var newOptionLabel = creatableLabel ? `Add "${stringCaseSwitcher(convertToCase, params.inputValue)}"` : stringCaseSwitcher(convertToCase, params.inputValue);
                    filtered.push({
                        inputValue: stringCaseSwitcher(convertToCase, params.inputValue),
                        //[optionLabel]: `Add "${params.inputValue}"`,
                        [optionLabel]:newOptionLabel,
                        id: stringCaseSwitcher(convertToCase, params.inputValue),
                        new_value : stringCaseSwitcher(convertToCase, params.inputValue)
                    });
				}

				return filtered;
			}}
            selectOnFocus={selectOnFocus}
            onBlur={onBlur}
            clearOnBlur={clearOnBlur}
            value={incomingValue || ''}
            disabled={disabled}
            noOptionsText={noOptionsText? noOptionsText : React.ReactNode}
        />
    )
}

export default SearchableSelect;