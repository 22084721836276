export const DRAFT_ACTION_TYPES = {
    FETCH_RECOMMENDATION_START : 'FETCH_RECOMMENDATION_START',
    FETCH_RECOMMENDATION_SUCCESS : 'FETCH_RECOMMENDATION_SUCCESS',
    FETCH_RECOMMENDATION_FAILURE : 'FETCH_RECOMMENDATION_FAILURE',

    FETCH_CLIENT_TASK_RECOMMENDATION_START : 'FETCH_CLIENT_TASK_RECOMMENDATION_START',
    FETCH_CLIENT_TASK_RECOMMENDATION_SUCCESS : 'FETCH_CLIENT_TASK_RECOMMENDATION_SUCCESS',
    FETCH_CLIENT_TASK_RECOMMENDATION_FAILURE : 'FETCH_CLIENT_TASK_RECOMMENDATION_FAILURE',

    FETCH_SUB_TOTAL_ANNUAL_START : 'FETCH_SUB_TOTAL_ANNUAL_START',
    FETCH_SUB_TOTAL_ANNUAL_SUCCESS : 'FETCH_SUB_TOTAL_ANNUAL_SUCCESS',
    FETCH_SUB_TOTAL_ANNUAL_FAILURE : 'FETCH_SUB_TOTAL_ANNUAL_FAILURE',

    FETCH_FEE_START : 'FETCH_FEE_START',
    FETCH_FEE_SUCCESS : 'FETCH_FEE_SUCCESS',
    FETCH_FEE_FAILURE : 'FETCH_FEE_FAILURE',

    FETCH_FUND_START : 'FETCH_FUND_START',
    FETCH_FUND_SUCCESS : 'FETCH_FUND_SUCCESS',
    FETCH_FUND_FAILURE : 'FETCH_FUND_FAILURE',

    FETCH_REASON_START : 'FETCH_REASON_START',
    FETCH_REASON_SUCCESS : 'FETCH_REASON_SUCCESS',
    FETCH_REASON_FAILURE : 'FETCH_REASON_FAILURE',

    FETCH_COMMENT_START : 'FETCH_COMMENT_START',
    FETCH_COMMENT_SUCCESS : 'FETCH_COMMENT_SUCCESS',
    FETCH_COMMENT_FAILURE : 'FETCH_COMMENT_FAILURE',

    PUT_COMMENT_START : 'PUT_COMMENT_START',
    PUT_COMMENT_SUCCESS : 'PUT_COMMENT_SUCCESS',
    PUT_COMMENT_FAILURE : 'PUT_COMMENT_FAILURE',

    FETCH_NOTES_START : 'FETCH_NOTES_START',
    FETCH_NOTES_SUCCESS : 'FETCH_NOTES_SUCCESS',
    FETCH_NOTES_FAILURE : 'FETCH_NOTES_FAILURE',

    GET_INSTRUMENT_FEE_START : 'GET_INSTRUMENT_FEE_START',
    GET_INSTRUMENT_FEE_SUCCESS : 'GET_INSTRUMENT_FEE_SUCCESS',
    GET_INSTRUMENT_FEE_FAILURE : 'GET_INSTRUMENT_FEE_FAILURE',

    PUT_INSTRUMENT_FEE_START : 'PUT_INSTRUMENT_FEE_START',
    PUT_INSTRUMENT_FEE_SUCCESS : 'PUT_INSTRUMENT_FEE_SUCCESS',
    PUT_INSTRUMENT_FEE_FAILURE : 'PUT_INSTRUMENT_FEE_FAILURE',

    CLEAR_ERROR_MESSAGES : 'CLEAR_ERROR_MESSAGES',

    CLONE_INSTRUMENT_START : 'CLONE_INSTRUMENT_START',
    CLONE_INSTRUMENT_SUCCESS : 'CLONE_INSTRUMENT_SUCCESS',
    CLONE_INSTRUMENT_FAILURE : 'CLONE_INSTRUMENT_FAILURE',

    DELETE_INSTRUMENT_START: 'DELETE_INSTRUMENT_START',
    DELETE_INSTRUMENT_SUCCESS : 'DELETE_INSTRUMENT_SUCCESS',
    DELETE_INSTRUMENT_FAILURE : 'DELETE_INSTRUMENT_FAILURE',

    SR_UPLOAD_START : 'SR_UPLOAD_START',
    SR_UPLOAD_SUCCESS : 'SR_UPLOAD_SUCCESS',
    SR_UPLOAD_FAILURE : 'SR_UPLOAD_FAILURE',

    GET_SR_START : 'GET_SR_START',
    GET_SR_SUCCESS : 'GET_SR_SUCCESS',
    GET_SR_FAILURE : 'GET_SR_FAILURE',

    GET_CHECKLIST_START : 'GET_CHECKLIST_START',
    GET_CHECKLIST_SUCCESS : 'GET_CHECKLIST_SUCCESS',
    GET_CHECKLIST_FAILURE : 'GET_CHECKLIST_FAILURE',

    PUT_CHECKLIST_START : 'PUT_CHECKLIST_START',
    PUT_CHECKLIST_SUCCESS : 'PUT_CHECKLIST_SUCCESS',
    PUT_CHECKLIST_FAILURE : 'PUT_CHECKLIST_FAILURE',

    POST_GENERATE_SR_START : 'POST_GENERATE_SR_START',
    POST_GENERATE_SR_SUCCESS : 'POST_GENERATE_SR_SUCCESS',
    POST_GENERATE_SR_FAILURE : 'POST_GENERATE_SR_FAILURE',

    GET_DRAFT_DOC_START : 'GET_DRAFT_DOC_START',
    GET_DRAFT_DOC_SUCCESS : 'GET_DRAFT_DOC_SUCCESS',
    GET_DRAFT_DOC_FAILURE : 'GET_DRAFT_DOC_FAILURE',

    POST_CLIENT_CONFIRM_START : 'POST_CLIENT_CONFIRM_START',
    POST_CLIENT_CONFIRM_SUCCESS : 'POST_CLIENT_CONFIRM_SUCCESS',
    POST_CLIENT_CONFIRM_FAILURE : 'POST_CLIENT_CONFIRM_FAILURE',

    CLEAR_SR_DETAILS : 'CLEAR_SR_DETAILS',

    PUT_NOTES_ANSWER_START : 'PUT_NOTES_ANSWER_START',
    PUT_NOTES_ANSWER_SUCCESS : 'PUT_NOTES_ANSWER_SUCCESS',
    PUT_NOTES_ANSWER_FAILURE : 'PUT_NOTES_ANSWER_FAILURE',


    UPDATE_CHECK_LIST : 'UPDATE_CHECK_LIST',
    CLEAR_RECOMMENDATION_LIST : 'CLEAR_RECOMMENDATION_LIST',
    CLEAR_CLIENT_TASK_RECOMMENDATION_LIST : 'CLEAR_CLIENT_TASK_RECOMMENDATION_LIST'
}