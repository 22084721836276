import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// import { updateRoute } from "../redux/reducers/preferenceReducers";
import "../../../Style/addClient.css";
import TextFieldBox from "../Common/TextFieldBox";
import Layout from "../../../hoc/LayoutHOC/LayoutHOC";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  feeManagement,
  selectclient,
} from "../../../redux/routing/routing.actions";
import { useFormik } from "formik";
import { addClientsAsync, clearAddErrorData, clearReferralReducerStateValue, setSelectedOneNull } from "../../../redux/referralManagement/referralManagement.actions";
import { getClientDetails } from "../../../redux/client/client.actions";
import { addClientSchema } from "../../../schemas/feeManagementSchema";
import { selectedclient } from "../../../redux/FeeManagementClient/FeeManagementClient.action";
import { productList } from "../../../redux/routing/routing.actions";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { useSnackbar } from "notistack";
import { fetchClientListAsync } from '../../../redux/referralManagement/referralManagement.actions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const SearchClient = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const successData = useSelector((state) => state.referral.successData)
  const selectedArray = useSelector((state) => state.referral.selecetedOne)
  const errorData = useSelector((state) => state.referral.addErrorData)
  const clienttypeList = useSelector(state => state.referral.clienttypeList);
  const [isLoading,setIsLoading]=useState(false)
  const [isSubmitted,setIsSubmitted]=useState(false)
  const [onsumbmitEmailValue,setOnsumbmitEmailValue]=useState("OEV")
  const [latestEmailValue,setLatestEmailValue]=useState("LEV")

  const [initialFormData, setInitialFormData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    phone_number: "",
    email: "",
    ni_number: "",
    client_type:"",
  });

  useEffect(()=>{
    dispatch(clearAddErrorData())
    dispatch(clearReferralReducerStateValue())
    dispatch(setSelectedOneNull())
    setIsSubmitted(false)
    dispatch(fetchClientListAsync());
    
  },[])

  
  useEffect(()=>{
    
    
    if(selectedArray !==undefined ){
      setIsLoading(false)
      if(isSubmitted && selectedArray){
        dispatch(productList());
        let successMessage = "Client added successfully!"
        
        enqueueSnackbar(successMessage, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
         
        });
        dispatch(selectedclient(selectedArray.data[0].addedClient));
        console.log("errorData",errorData.errorFields);
        if(errorData?.errorFields !==undefined){
     
        
        }
      }
      else if(isSubmitted && errorData.errorFields !==undefined){
        setIsLoading(false)
        let errorMessage = "Client with this data already exists!"
        
        enqueueSnackbar(errorMessage, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
         
        });
        dispatch(clearAddErrorData())
      }
      
      
     
    }
    

    
  },selectedArray,errorData)





  
  const formik = useFormik({
    initialValues: {
      first_name: props.fn?props.fn:"",
      last_name: props.ln?props.ln:"",
      company: "",
      phone_number: "",
      email: "",
      ni_number: "",
      client_type:"",
    },
    validationSchema: addClientSchema,
    onSubmit: (values) => {
      setIsSubmitted(true)
      setIsLoading(true)
      setOnsumbmitEmailValue(values?.email)
      
      const dataToSend = {
        user: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          username: values.email,
        },
        client_type:values.client_type,
        phone_number: values.phone_number,
        company: "test",
        ni_number: values.ni_number,
        joint_client:false,
      referred_by_id:null,
      referred_date:null,
      relative_client:null,
      is_shadow_client:true,
      };
      
      dispatch(addClientsAsync(dataToSend));
      
     
     

     
    
  
   
   
      

      // alert(JSON.stringify(dataToSend, null, 2));
      
    },
  });
  // useEffect(() => {
  //     dispatch(productList())
  //      dispatch(selectedclient(selectedArray?.data[0]))
  // }, [selectedArray])

  if (selectedArray?.status==true) {
    // dispatch(getClientDetails(12))

    //dispatch(selectedclient(filterArray[0]))

    // history.push('/client-management/products-list', { "clientId": 21 })
    
  }
  const [value,setValue]=useState(formik.values.ni_number)

  const newValue=(e)=>{
    setValue(e.target.value.replace(/[^a-zA-Z0-9]/ig,'')) 

  }
  const newSitValue=()=>{
    newValue()
    formik.handleChange()
  }
 
  return (
    <>{props.updateFn==true?<div className="AddClientScreen">
    <div
      onClick={() => dispatch(selectclient())}
      className="leftArrowSection"
    >
      <ArrowBackIcon
        style={{ color: "white", paddingTop: "1px", height: "40px" }}
      />
    </div>
    <div>
      <p className="addClientPageHeading">Add Client </p>
    </div>

   {
     isLoading ? <CircularLoader/> :  <form onSubmit={formik.handleSubmit}>
     <div className="AddClientBox">
       <div className="AddClientTitle d-flex justify-content-between ">
         <p>Enter Client Details</p>
         <img src="" alt="" />
       </div>
       <hr className="mb-3 mt-0" />

       <div style={{ width: "100%" }}>
         <Grid container>
           <Grid
             sx={{ padding: "0.5rem 0" }}
             item
             xl={3}
             lg={3}
             md={6}
             sm={6}
             xs={12}
           >
             <TextFieldBox
               value={formik.values.first_name}
               onChange={formik.handleChange}
               error={
                 formik.touched.first_name &&
                 Boolean(formik.errors.first_name)
               }
               helperText={
                 formik.touched.first_name && formik.errors.first_name
               }
               name="first_name"
               label="First Name"
               size="small"
             />
           </Grid>
           <Grid
             sx={{
               // display: "flex",
               // justifyContent: "end",
               padding: "0.5rem 0",
             }}
             item
             xl={3}
             lg={3}
             md={6}
             sm={12}
             xs={12}
           >
             <TextFieldBox
               value={formik.values.last_name}
               onChange={formik.handleChange}
               error={
                 formik.touched.last_name &&
                 Boolean(formik.errors.last_name)
               }
               helperText={
                 formik.touched.last_name && formik.errors.last_name
               }
               name="last_name"
               label="Last Name"
             />
           </Grid>
           <Grid
             sx={{ padding: "0.5rem 0" }}
             item
             xl={3}
             lg={3}
             md={6}
             sm={12}
             xs={12}
           >
             <TextFieldBox 
               value={value}
               onChange={e => {newValue(e); formik.handleChange(e) }}
               error={
                 formik.touched.ni_number &&
                 Boolean(formik.errors.ni_number)
               }
               helperText={
                 formik.touched.ni_number && formik.errors.ni_number
               }
               name="ni_number"
               label="National Insurance Id"
             />
           </Grid>
           <Grid
             sx={{ padding: "0.5rem 0" }}
             item
             xl={3}
             lg={3}
             md={6}
             sm={12}
             xs={12}
           >
             {/* <TextFieldBox
               value={formik.values.ni_number}
               onChange={formik.handleChange}
               error={
                 formik.touched.ni_number &&
                 Boolean(formik.errors.ni_number)
               }
               helperText={
                 formik.touched.ni_number && formik.errors.ni_number
               }
               name="ni_number"
               label="National Insurance Id"
             /> */}
           </Grid>
           <Grid
             sx={{ padding: "0.5rem 0" }}
             item
             xl={3}
             lg={3}
             md={6}
             sm={12}
             xs={12}
           >
             <TextFieldBox
               value={formik.values.phone_number}
               onChange={formik.handleChange}
               error={
                 formik.touched.phone_number &&
                 Boolean(formik.errors.phone_number)
               }
               helperText={
                 formik.touched.phone_number &&
                 formik.errors.phone_number
               }
               name="phone_number"
               label="Phone"
             />
           </Grid>
           <Grid
             sx={{
               // display: "flex",
               // justifyContent: "end",
               padding: "0.5rem 0",
             }}
             item
             xl={3}
             lg={3}
             md={6}
             sm={12}
             xs={12}             
           >
             <TextFieldBox
               value={formik.values.email}
               onChange={formik.handleChange}
               error={
                 formik.touched.email && Boolean(formik.errors.email)
               }
               helperText={formik.touched.email && formik.errors.email}
               name="email"
               label="E-Mail"
             />
           </Grid>
           <div style={{ padding: "10px 0" }}>
           <Grid
             sx={{ "& > :not(style)": { width: "30ch",padding: "0.5rem 0"  }, }}
            
             item
             xl={3}
             lg={3}
             md={6}
             sm={12}
             xs={12}
           
           >
             
            
               
  <TextField
    size="small"
    variant="outlined"
    value={formik.values.client_type}
    label="Client Type"
    name="client_type"
    onChange={formik.handleChange}
    error={
      formik.touched.client_type &&
      Boolean(formik.errors.client_type)
    }
    helperText={
      formik.touched.client_type && formik.errors.client_type
    }
    select
  >
    {clienttypeList.map(option => {
    return (
      <MenuItem  value={option.id}>
            {option.name}<br/>
      </MenuItem>
    )
})}
   
  </TextField>

           </Grid>
           </div>
         </Grid>
       </div>
       <div className="d-flex justify-content-between pt-2">
         <p>* All fields are mandatory</p>
         <button
           // isSubmiting
           type="submit"
           //  onClick={() => dispatch(updateRoute("productsPage"))}
           className="AddBtn"
         >
           Next
         </button>
       </div>
       
     </div>
    
   </form>
   }
    
  </div>:
      <Layout>
        <div className="AddClientScreen">
          <div
            onClick={() => dispatch(selectclient())}
            className="leftArrowSection"
          >
            <ArrowBackIcon
              style={{ color: "white", paddingTop: "1px", height: "40px" }}
            />
          </div>
          <div>
            <p className="addClientPageHeading">Add Client </p>
          </div>

         {
           isLoading ? <CircularLoader/> :  <form onSubmit={formik.handleSubmit}>
           <div className="AddClientBox">
             <div className="AddClientTitle d-flex justify-content-between ">
               <p>Enter Client Details</p>
               <img src="" alt="" />
             </div>
             <hr className="mb-3 mt-0" />

             <div style={{ width: "100%" }}>
               <Grid container>
                 <Grid
                   sx={{ padding: "0.5rem 0" }}
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={6}
                   xs={12}
                 >
                   <TextFieldBox
                     value={formik.values.first_name}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.first_name &&
                       Boolean(formik.errors.first_name)
                     }
                     helperText={
                       formik.touched.first_name && formik.errors.first_name
                     }
                     name="first_name"
                     label="First Name"
                     size="small"
                   />
                 </Grid>
                 <Grid
                   sx={{
                     // display: "flex",
                     // justifyContent: "end",
                     padding: "0.5rem 0",
                   }}
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={12}
                   xs={12}
                 >
                   <TextFieldBox
                     value={formik.values.last_name}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.last_name &&
                       Boolean(formik.errors.last_name)
                     }
                     helperText={
                       formik.touched.last_name && formik.errors.last_name
                     }
                     name="last_name"
                     label="Last Name"
                   />
                 </Grid>
                 <Grid
                   sx={{ padding: "0.5rem 0" }}
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={12}
                   xs={12}
                 >
                   <TextFieldBox 
                     value={value}
                     onChange={e => {newValue(e); formik.handleChange(e) }}
                     error={
                       formik.touched.ni_number &&
                       Boolean(formik.errors.ni_number)
                     }
                     helperText={
                       formik.touched.ni_number && formik.errors.ni_number
                     }
                     name="ni_number"
                     label="National Insurance Id"
                   />
                 </Grid>
                 <Grid
                   sx={{ padding: "0.5rem 0" }}
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={12}
                   xs={12}
                 >
                   {/* <TextFieldBox
                     value={formik.values.ni_number}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.ni_number &&
                       Boolean(formik.errors.ni_number)
                     }
                     helperText={
                       formik.touched.ni_number && formik.errors.ni_number
                     }
                     name="ni_number"
                     label="National Insurance Id"
                   /> */}
                 </Grid>
                 <Grid
                   sx={{ padding: "0.5rem 0" }}
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={12}
                   xs={12}
                 >
                   <TextFieldBox
                     value={formik.values.phone_number}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.phone_number &&
                       Boolean(formik.errors.phone_number)
                     }
                     helperText={
                       formik.touched.phone_number &&
                       formik.errors.phone_number
                     }
                     name="phone_number"
                     label="Phone"
                   />
                 </Grid>
                 <Grid
                   sx={{
                     // display: "flex",
                     // justifyContent: "end",
                     padding: "0.5rem 0",
                   }}
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={12}
                   xs={12}             
                 >
                   <TextFieldBox
                     value={formik.values.email}
                     onChange={event => {
                      // Update Formik state
                      formik.handleChange(event);
              
                      // Update component state
                      setLatestEmailValue(event.target.value);
                    }}
                     error={
                       formik.touched.email && Boolean(formik.errors.email)
                     }
                     helperText={formik.touched.email && formik.errors.email}
                     name="email"
                     label="E-Mail"
                   />
                 </Grid>
                 <div style={{ padding: "10px 0" }}>
                 <Grid
                   sx={{ "& > :not(style)": { width: "30ch",padding: "0.5rem 0"  }, }}
                  
                   item
                   xl={3}
                   lg={3}
                   md={6}
                   sm={12}
                   xs={12}
                 
                 >
                   
                  
                     
        <TextField
          size="small"
          variant="outlined"
          value={formik.values.client_type}
          label="Client Type"
          name="client_type"
          onChange={formik.handleChange}
          error={
            formik.touched.client_type &&
            Boolean(formik.errors.client_type)
          }
          helperText={
            formik.touched.client_type && formik.errors.client_type
          }
          select
        >
          {clienttypeList.map(option => {
          return (
            <MenuItem  value={option.id}>
                  {option.name}<br/>
            </MenuItem>
          )
})}
         
        </TextField>

                 </Grid>
                 </div>
               </Grid>
             </div>
             <div className="d-flex justify-content-between pt-2">
               <p>* All fields are mandatory</p>
               <button
                 // isSubmiting
                 type="submit"
                 disabled={onsumbmitEmailValue==latestEmailValue}
                 //  onClick={() => dispatch(updateRoute("productsPage"))}
                 className="AddBtn"
               >
                 Next
               </button>
             </div>
             
           </div>
          
         </form>
         }
          
        </div>
        {/* <Popup
        
        /> */}
      </Layout>
  } </>
  );
};
export default SearchClient;
