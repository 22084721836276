import { validationErrorFormatter } from '../../utils/utils';
import { UI_ACTION_TYPES } from './ui.types';
import { getFormattedAcceptedRejectedData, removeRecordingItem, getFormattedInteractionsByTypeData } from './ui.utils';

const INITIAL_STATE = {
    showLoader: false,
    fileChoosen: null,

    currentTableData: null,
    isFormInvalid: false,
    forwardData: {},
    editFormValues: null,
    editFormValuesFetching: false,

    isGlobalSnackBarShown: false,
    globalSnackBarMessage: null,
    globalSnackBarType: 'default',

    filesSelectedFromAttachmentModal: [],

    isAttachmentModalOpen: false,

    totalCapDriveFileSize: 0,
    totalLocalFileSize: 0,
    totalAttachmentFileSize: 0,

    filesSelectedFromAttachmentModalWithActionName: {},

    recordedData: [],

    editProfileSettingsFetching: false,
    editProfileSettingsErrorMsg: null,
    editProfileSettingsSuccessMsg: null,
    editErrorData: null,

    fetchProfileSettingsFetching: false,
    fetchProfileSettingsErrorMsg: null,
    fetchProfileSettingsSuccessMsg: null,
    profileSettingsDetails: null,

    isClientVsDataLoading : false,
    clientVsData: null,
    clientVsDataErrMsg : null,

    isGetClientsReferringFetching : false,
    clientsReferringData: null,
    getClientsReferringErrMsg : null,

    isRecentlyAddedClientsFetching : false,
    recentlyAddedClients: null,
    recentlyAddedClientsErrMsg : null,

    isGetTopReferrersFetching : false,
    topReferrers: null,
    topReferrersErrMsg : null,
    
    isGetAdherenceToCppFetching : true,
    adherenceToCpp : null,
    adherenceToCppErrMsg : null,

    isGetAverageAgeFetching : false,
    averageAgeData : null,
    averageAgeDataErrMsg : null,

    isStaffListFetching : false,
    staffList : null,
    staffListErrMsg : null,

    isGetAcceptedRejectedRatioFetching : false,
    acceptedRejectedRatio : null,
    acceptedRejectedRatioErrorMsg : null

};

const uiReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {
    case UI_ACTION_TYPES.CHOOSE_FILE:
        return {
            ...state,
            fileChoosen: payload
        };

    case UI_ACTION_TYPES.SAVE_MATERIAL_TABLE_DATA:
        return {
            ...state,
            currentTableData: payload
        };

    case UI_ACTION_TYPES.SHOW_GLOBAL_SNACKBAR:
        return {
            ...state,
            isGlobalSnackBarShown: true,
            globalSnackBarMessage: payload.message,
            globalSnackBarType: payload.globalSnackBarType
        };

    case UI_ACTION_TYPES.CLOSE_GLOBAL_SNACKBAR:
        return {
            ...state,
            isGlobalSnackBarShown: false,
            globalSnackBarMessage: null,
            globalSnackBackType: 'default'
        };
    case UI_ACTION_TYPES.IS_FORM_INVALID:
        return {
            ...state,
            isFormInvalid: payload,
        };

    case UI_ACTION_TYPES.SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL: {
        return {
            ...state,
            filesSelectedFromAttachmentModal: [...state.filesSelectedFromAttachmentModal, ...payload]
        };
    }

    case UI_ACTION_TYPES.REMOVE_INDIVIGUAL_FILE_ITEMS: {
        return {
            ...state,
            filesSelectedFromAttachmentModal: payload
        };
    }

    // if there is action name set new files :: only dependant on mail replay,replayall,forward

    case UI_ACTION_TYPES.SET_ACTION_NAME:
        return {
            ...state,
            actionName: payload
        };
    case UI_ACTION_TYPES.SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL_WITH_ACTION_NAME:

        return {
            ...state,
            filesSelectedFromAttachmentModalWithActionName: {
                [payload.actionName]: [...payload.files]
            }
        };

        // if there is action name set new files :: only dependant on mail replay,replayall,forward

    case UI_ACTION_TYPES.OPEN_CLOSE_ATTACHMENT_MODAL: {
        return {
            ...state,
            isAttachmentModalOpen: payload,
        };
    }

    case UI_ACTION_TYPES.SET_TOTAL_FILE_SIZE_OF_DRIVE_AND_LOCAL_FILES:
    {
        return {
            ...state,
            totalCapDriveFileSize: payload.totalCapDriveFileSize,
            totalLocalFileSize: payload.totalLocalFileSize,
            totalAttachmentFileSize: payload.totalAttachmentFileSize
        };
    }

    case UI_ACTION_TYPES.SET_RECORDED_DATA: {
        return {
            ...state,
            recordedData: [
                ...state.recordedData,
                payload
            ],
        };
    }

    case UI_ACTION_TYPES.REMOVE_RECORDING_ITEM: {
        return {
            ...state,
            recordedData: removeRecordingItem(state.recordedData, payload)
        };
    }

    case UI_ACTION_TYPES.EDIT_PROFILE_SETTINGS_START:
        return {
            ...state,
            editProfileSettingsFetching: true,
        };
    case UI_ACTION_TYPES.EDIT_PROFILE_SETTINGS_SUCCESS:
        return {
            ...state,
            editProfileSettingsSuccessMsg: payload.message,
            editProfileSettingsFetching: false,
        };
    case UI_ACTION_TYPES.EDIT_PROFILE_SETTINGS_FAILURE:
        return {
            ...state,
            editProfileSettingsFetching: false,
            editProfileSettingsErrorMsg: payload.message === 'Validation error' ? null : payload.message, // message null if validation error else this will also show up
            editErrorData: validationErrorFormatter(payload.data),
        };
    case UI_ACTION_TYPES.CLEAR_EDIT_PROFILE_SETTINGS_DATA:
        return {
            ...state,
            editErrorData: null
        };
    case UI_ACTION_TYPES.CLEAR_EDIT_PROFILE_SETTINGS_ERROR_MSG:
        return {
            ...state,
            editProfileSettingsErrorMsg: null
        };
    case UI_ACTION_TYPES.CLEAR_EDIT_PROFILE_SETTINGS_SUCCESS_MSG:
        return {
            ...state,
            editProfileSettingsSuccessMsg: null
        };

    case UI_ACTION_TYPES.FETCH_PROFILE_SETTINGS_START:
        return {
            ...state,
            fetchProfileSettingsFetching: true,
        };
    case UI_ACTION_TYPES.FETCH_PROFILE_SETTINGS_SUCCESS:
        return {
            ...state,
            fetchProfileSettingsSuccessMsg: payload.message,
            profileSettingsDetails: payload.data,
            fetchProfileSettingsFetching: false,
        };
    case UI_ACTION_TYPES.FETCH_PROFILE_SETTINGS_FAILURE:
        return {
            ...state,
            fetchProfileSettingsFetching: false,
            fetchProfileSettingsErrorMsg: payload.message
        };

    case UI_ACTION_TYPES.GET_CLIENT_VS_DATA_START:
        return {
            ...state,
            isClientVsDataLoading: true,
        };
    case UI_ACTION_TYPES.GET_CLIENT_VS_DATA_SUCCESS:
        return {
            ...state,
            isClientVsDataLoading: false,
            clientVsData : payload.data
        };
    case UI_ACTION_TYPES.GET_CLIENT_VS_DATA_FAILURE:
        return {
            ...state,
            isClientVsDataLoading: false,
            clientVsDataErrMsg : null
        };

    case UI_ACTION_TYPES.GET_CLIENTS_REFERRING_DATA_START:
        return {
            ...state,
            isGetClientsReferringFetching: true,
        };
    case UI_ACTION_TYPES.GET_CLIENTS_REFERRING_DATA_SUCCESS:
        return {
            ...state,
            isGetClientsReferringFetching : false,
            clientsReferringData : payload.data,
        };
    case UI_ACTION_TYPES.GET_CLIENTS_REFERRING_DATA_FAILURE:
        return {
            ...state,
            isGetClientsReferringFetching: false,
            getClientsReferringErrMsg : payload.message
        };

    case UI_ACTION_TYPES.GET_RECENTLY_ADDED_CLIENTS_START:
        return {
            ...state,
            isRecentlyAddedClientsFetching: true,
        };
    case UI_ACTION_TYPES.GET_RECENTLY_ADDED_CLIENTS_SUCCESS:
        return {
            ...state,
            isRecentlyAddedClientsFetching: false,
            recentlyAddedClients : payload.data
        };
    case UI_ACTION_TYPES.GET_RECENTLY_ADDED_CLIENTS_FAILURE:
        return {
            ...state,
            isRecentlyAddedClientsFetching: false,
            recentlyAddedClientsErrMsg : payload.message
        };

    case UI_ACTION_TYPES.GET_TOP_REFERRERS_START:
        return {
            ...state,
            isGetTopReferrersFetching: true,
        };
    case UI_ACTION_TYPES.GET_TOP_REFERRERS_SUCCESS:
        return {
            ...state,
            isGetTopReferrersFetching : false,
            topReferrers: payload.data,
        };
    case UI_ACTION_TYPES.GET_TOP_REFERRERS_FAILURE:
        return {
            ...state,
            isGetTopReferrersFetching: false,
            topReferrersErrMsg : payload.message
        };

    case UI_ACTION_TYPES.GET_ADHERENCE_TO_CPP_START:
        return {
            ...state,
            isGetAdherenceToCppFetching: true,
        };
    case UI_ACTION_TYPES.GET_ADHERENCE_TO_CPP_SUCCESS:
        return {
            ...state,
            isGetAdherenceToCppFetching: false,
            adherenceToCpp : payload.data
        };
    case UI_ACTION_TYPES.GET_ADHERENCE_TO_CPP_FAILURE:
        return {
            ...state,
            isLoading: false,
            adherenceToCppErrMsg : payload.message
        };

    case UI_ACTION_TYPES.GET_ACCEPTED_REJECTED_RATIO_START:
        return {
            ...state,
            isGetAcceptedRejectedRatioFetching: true,
        };
    case UI_ACTION_TYPES.GET_ACCEPTED_REJECTED_RATIO_SUCCESS:
        return {
            ...state,
            isGetAcceptedRejectedRatioFetching: false,
            acceptedRejectedRatio : getFormattedAcceptedRejectedData(payload.data)
        };
    case UI_ACTION_TYPES.GET_ACCEPTED_REJECTED_RATIO_FAILURE:
        return {
            ...state,
            isGetAcceptedRejectedRatioFetching: false,
            acceptedRejectedRatioErrorMsg : payload.message
        };

    case UI_ACTION_TYPES.GET_AVERAGE_AGE_OF_CLIENTS_START:
        return {
            ...state,
            isGetAverageAgeFetching: true,
        };
    case UI_ACTION_TYPES.GET_AVERAGE_AGE_OF_CLIENTS_SUCCESS:
        return {
            ...state,
            isGetAverageAgeFetching: false,
            averageAgeData : payload.data
        };
    case UI_ACTION_TYPES.GET_AVERAGE_AGE_OF_CLIENTS_FAILURE:
        return {
            ...state,
            isGetAverageAgeFetching: false,
            averageAgeDataErrMsg : payload.message
        };

    case UI_ACTION_TYPES.GET_INTERACTIONS_BY_TYPE_START:
        return {
            ...state,
            isInteractionByTypeFetching: true,
        };
    case UI_ACTION_TYPES.GET_INTERACTIONS_BY_TYPE_SUCCESS:
        return {
            ...state,
            isInteractionByTypeFetching: false,
            interactionByTypeData : getFormattedInteractionsByTypeData(payload.data)
        };
    case UI_ACTION_TYPES.GET_INTERACTIONS_BY_TYPE_FAILURE:
        return {
            ...state,
            isInteractionByTypeFetching: false,
            interactionByTypeDataErrMsg : payload.message
        };

    case UI_ACTION_TYPES.FETCH_STAFF_BASED_ON_FILTER_START:
        return {
            ...state,
            isStaffListFetching : true,
        };
    case UI_ACTION_TYPES.FETCH_STAFF_BASED_ON_FILTER_SUCCESS:
        return {
            ...state,
            isStaffListFetching : false,
            staffList: payload.data,
        };
    case UI_ACTION_TYPES.FETCH_STAFF_BASED_ON_FILTER_FAILURE:
        return {
            ...state,
            isStaffListFetching : false,
            staffListErrMsg : payload.message
        };

    case UI_ACTION_TYPES.REMOVE_ERROR_MESSAGES:
        return {
            ...state,
            [payload] : null
        };

    default:
        return state;
    }
};

export default uiReducer;

