import React from 'react'
import MailIcon from '@mui/icons-material/Mail';
import './DashboardContent.scss'
import { formatNumber, isNOTNullOrUndefined } from '../../utils/utils';
export const CustomTreeComponent = (props) => {
  const { root, depth, x, y, width, height, index, colors, aum, email, client_id, color, name, size, value, onTileClick,advisor, first_name, last_name } = props;



  // Apply minimum width and height
  const rectWidth = width;
  const rectHeight = height;

  const isWiderThanMin = width > 100
  const isShorterThanMin = height < 100
  const proportionHeightFix = isShorterThanMin ? 10 : 0
  const isWidthOrHeightTooSmall = width < 100 || height < 100;
  const fontSizeFactor = isShorterThanMin || isWidthOrHeightTooSmall? 0.06 : 0.08; // You can adjust this factor for the desired font size scaling
  const averageDimension = (width + height) / 2;
  const fontSizeFormatted = averageDimension * fontSizeFactor;
  const fontSize = fontSizeFormatted > 10 ? 10 : fontSizeFormatted;
  // const fontSize = 12
  const textSpacing = 12;
  const spacingOffset = (10 - fontSize) * textSpacing / 10;

 

  const fontColor =  "#fff"
  const mailIconWidth = 20; // Set the width of the MailIcon
  const halfWidth = width / 2
  const halfIconWidth = mailIconWidth / 4
  const iconX = x + halfWidth - halfIconWidth;
  const iconY = y + height / 2 + 25 - proportionHeightFix;
  const getStrokeColor = (backgroundColor) => {
    if (!backgroundColor || backgroundColor.length < 7) {

        return '#000'; // You can set any default stroke color here
    }

    const slicedColor = backgroundColor.slice(1); // Remove the '#' from the color string
    const r = parseInt(slicedColor.slice(0, 2), 16);
    const g = parseInt(slicedColor.slice(2, 4), 16);
    const b = parseInt(slicedColor.slice(4, 6), 16);

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        // If parsing the color components fails, return a default stroke color
        return '#000'; // You can set any default stroke color here
    }

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? '#000' : '#fff'; // Choose stroke color based on brightness
};

const strokeColor = getStrokeColor(color);

  return (
    <g onClick={() => onTileClick({ email: email, client_id: client_id,advisor:advisor,first_name:first_name })} style={{ cursor: 'pointer' , overflow:'hidden' }}>
      <rect
        x={x}
        y={y}
        width={rectWidth}
        height={rectHeight}
        style={{
          fill: color,
          stroke: "none",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
          overflow: 'hidden',
        }}
      />

      {isWiderThanMin ? <text
        fontFamily='Poppins'
        x={x + width / 2}
        y={y + height / 2 + spacingOffset - proportionHeightFix - 10}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
        fontWeight={10}
         style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      
      >
        {name}

      </text> : null}


      {!isWiderThanMin ? <text
        fontFamily='Poppins'
        x={x + width / 2}
        y={y + height / 2 + spacingOffset - proportionHeightFix -15}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
        fontWeight={10}
         style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      
      >
        {first_name}

      </text> : null}

      {!isWiderThanMin ? <text
        fontFamily='Poppins'
        x={x + width / 2}
        y={y + height / 2 + spacingOffset - proportionHeightFix - 6}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
        fontWeight={10}
         style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      
      >
        {last_name}
      </text>
        : null}

      <text
        x={x + width / 2}
        y={y + height / 2 + spacingOffset + 8 - proportionHeightFix}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
        fontWeight={395}
      >
        {/* £ {formatNumber(size)} */}
        £ {formatNumber(aum)}
      </text>
      <text
        x={x + width / 2}
        y={y + height / 2 + spacingOffset + 24 - proportionHeightFix}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
        fontWeight={395}
      >
        AUM
      </text>
      {isNOTNullOrUndefined(email) ? <g
        style={{ cursor: "pointer" }}>
        {/* Render the MailIcon */}
        <foreignObject x={iconX} y={iconY} width={mailIconWidth} height={mailIconWidth}>
          <MailIcon style={{ fontSize: fontSize + 2, color: strokeColor }} fill={fontColor} />
        </foreignObject>
      </g> : null}

    </g>
  );
};