import {
  CHOOSE_PRODUCT,
  FEE_TYPE,
  REASON_FUNCTION,
  RECOMMENDED_PRODUCTS,
  SEARCH_CLIENT_RESULTS,
  SEARCH_PRODUCTS,
  SELECTED_CLIENT,
  SELECTED_CLIENT_PRODUCTS,
  SUMMARY_PRODUCTS,
  SUSPENSE_ACCOUNT_IDS,
  MONTHLY_ISSUE,
} from "./FeeManagementClient.types";

const INITIAL_STATE = {
  searchedClients: [],
  selectedClient: null,
  selectedClientProducts:[],
  searchProducts:[],
  feeType:[],
  recommendedProducts:[],
  summaryProducts:[],
  chooseProductList:[],
  reasonForFunction:[],
  suspenseAccountIds:{
    
  },
  monthlyIssue:new Date(),
};

export const FeeManagementReducer = (state = INITIAL_STATE, action,subData) => {
  switch (action.type) {
    case SEARCH_CLIENT_RESULTS:
      state.searchedClients = action.data;
      return {
        ...state,
      };
    case SELECTED_CLIENT:
      state.selectedClient = action.data;
      return {
        ...state,
      };
    case SELECTED_CLIENT_PRODUCTS:
      state.selectedClientProducts = action.data;
      return {
        ...state,
      };
    case SEARCH_PRODUCTS:
      state.searchProducts = action.data;
      return {
        ...state,
      };
    case FEE_TYPE:
      state.feeType = action.data;
      return {
        ...state,
      };
    case RECOMMENDED_PRODUCTS:
      state.recommendedProducts = action.data;
      return {
        ...state,
      };
    case SUMMARY_PRODUCTS:
      state.summaryProducts = action.data;
      return {
        ...state,
      };
    case CHOOSE_PRODUCT:
      state.chooseProductList = action.data;
      return {
        ...state,
      };
    case REASON_FUNCTION:
      state.reasonForFunction = action.data;
      return {
        ...state,
      };
    case SUSPENSE_ACCOUNT_IDS:
      return {
        ...state,
        suspenseAccountIds : action.data
      };
    case MONTHLY_ISSUE:
      return {
        ...state,
        monthlyIssue : action.data
      };
    default:
      return state;
  }
};
