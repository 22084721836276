import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import CircularLoader from '../CircularLoader/CircularLoader';
import './TimeLine.scss';
import { Grid } from '@material-ui/core';

const TimeLine = ({
    items,
    grade,
    showListItemTail = true,
    showTimeIcon = false,
    enableLink = true,
    loading = false
}) => {
    const history = useHistory();
    const handleRedirect = (page_url=null) => {
        if(enableLink && page_url && page_url?.redirect_url !=""){
            history.push(page_url.redirect_url,{ 
                clientId  : page_url?.redirect_data?.clientId,
                activeStep : page_url?.redirect_data?.position,
            });
        }
    };
    return (
        <Timeline align="left" className="timelineWrapper">
            {loading ? (
                <div className="innerLoader">
                    <CircularLoader size={20} />
                </div>
                ):(
                    (items && items.length > 0) 
                    ?
                    items.map((item, index) => {
                        const firstItem =  index === 0 ? true : false;
                        const lastItem = index === items.length - 1;
                        return ( <> {(item.compliance_feedback || item.administrator_feedback || item.compliance_remark)?<Grid container spacing={2}>
                            <Grid item xs={item.compliance_feedback || item.administrator_feedback?5:12}>
                            <TimelineItem key={index} className="timelineItem">

                                <TimelineSeparator>
                                    {
                                        firstItem && <TimelineConnector /> 
                                    }
                                    <TimelineDot className={`${item?.pending ? 'itemIconPending' : 'itemIconDone'}`}>
                                        {(item?.pending) ? (
                                            <DoneIcon />
                                        ) : (
                                            <DoneAllIcon />
                                        )}
                                    </TimelineDot>
                                    {
                                        showListItemTail 
                                            ?
                                            <TimelineConnector />
                                            :
                                            <>
                                                {
                                                    !lastItem && <TimelineConnector />
                                                }
                                            </>
                                    }
                                </TimelineSeparator>
                                <TimelineContent className={firstItem ? 'accordionContentAdjust' : ''}>
                                    <Typography className={`itemHeading ${item?.page_url?.redirect_url ? 'redirectLink' : ''}`} onClick={() => {handleRedirect(item?.page_url);}}>
                                        {item.activity_status ? item.activity_status : item.task_collection}
                                    </Typography>
                                    <Typography className="itemSubHeading">
                                        <CalendarTodayIcon className="itemSubIcon" />
                                        {!showTimeIcon && moment(item.create_time).format('DD MMM YYYY hh:mm a')}
                                        {
                                            showTimeIcon 
                                        && 
                                        <>
                                            {moment(item.create_time).format('DD MMM YYYY')}
                                            <AccessTimeIcon className="itemSubIcon"  style={{marginLeft:"25px"}}/>
                                            {moment(item.create_time).format('hh:mm a')}
                                        </>
                                        }
                                       
                                    </Typography>
                                    
                                </TimelineContent>
                                
                            </TimelineItem> </Grid> 
                              <Grid item xs={2}>  
                   {item.compliance_remark && grade && <Typography style={index===0 ?{"marginTop": "16px"}:{"marginTop": "6px"}}className="itemSubHeading grade">Grade{" "}:<span className={`itemSubHeadingGrade ${item.compliance_remark==1 && "green" ||item.compliance_remark==2 && "red"|| item.compliance_remark==3 && "orange"}`}> {grade[item.compliance_remark-1]}</span></Typography>}
                            </Grid>
                            <Grid item xs={5}>
                                    <Typography  style={index===0 ?{"marginTop": "16px"}:{"marginTop": "6px"}} className="itemSubHeadingFeedback">
                                    { (item.compliance_feedback || item.administrator_feedback)?
                                   item.compliance_feedback || item.administrator_feedback
                                   :"No FeedBack"}</Typography>
                                    </Grid>
                            </Grid>: <TimelineItem key={index} className="timelineItem">

<TimelineSeparator>
    {
        firstItem && <TimelineConnector /> 
    }
    <TimelineDot className={`${item?.pending ? 'itemIconPending' : 'itemIconDone'}`}>
        {(item?.pending) ? (
            <DoneIcon />
        ) : (
            <DoneAllIcon />
        )}
    </TimelineDot>
    {
        showListItemTail 
            ?
            <TimelineConnector />
            :
            <>
                {
                    !lastItem && <TimelineConnector />
                }
            </>
    }
</TimelineSeparator>
<TimelineContent className={firstItem ? 'accordionContentAdjust' : ''}>
   
    <Typography className={`itemHeading ${item?.page_url?.redirect_url ? 'redirectLink' : ''}`} onClick={() => {handleRedirect(item?.page_url);}}>
        {item.activity_status ? item.activity_status : item.task_collection}
    </Typography>
    <Typography className="itemSubHeading">
        <CalendarTodayIcon className="itemSubIcon" />
        {!showTimeIcon && moment(item.create_time).format('DD MMM YYYY hh:mm a')}
        {
            showTimeIcon 
        && 
        <>
            {moment(item.create_time).format('DD MMM YYYY')}
            <AccessTimeIcon className="itemSubIcon"  style={{marginLeft:"25px"}}/>
            {moment(item.create_time).format('hh:mm a')}
        </>
        }
       
   
    </Typography>
    
</TimelineContent>

</TimelineItem>}</>)
                    })
                       
                    :
                    <p>No time line entries yet.</p>
                )
            }
        </Timeline>
    );
};

export default TimeLine;
