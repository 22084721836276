export const UI_ACTION_TYPES = {
    SAVE_MATERIAL_TABLE_DATA: 'SAVE_MATERIAL_TABLE_DATA',

    SHOW_GLOBAL_SNACKBAR: 'SHOW_GLOBAL_SNACKBAR',
    CLOSE_GLOBAL_SNACKBAR: 'CLOSE_GLOBAL_SNACKBAR',

    IS_FORM_INVALID: 'IS_FORM_INVALID',

    SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL: 'SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL',

    REMOVE_INDIVIGUAL_FILE_ITEMS: 'REMOVE_INDIVIGUAL_FILE_ITEMS',

    OPEN_CLOSE_ATTACHMENT_MODAL: 'OPEN_CLOSE_ATTACHMENT_MODAL',

    SET_TOTAL_FILE_SIZE_OF_DRIVE_AND_LOCAL_FILES: 'SET_TOTAL_FILE_SIZE_OF_DRIVE_AND_LOCAL_FILES',

    SET_ACTION_NAME: 'SET_ACTION_NAME',
    SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL_WITH_ACTION_NAME: 'SET_FILES_SELECTED_FROM_ATTACHMENT_MODAL_WITH_ACTION_NAME',

    SET_RECORDED_DATA: 'SET_RECORDED_DATA',
    REMOVE_RECORDING_ITEM: 'REMOVE_RECORDING_ITEM',

    FETCH_PROFILE_SETTINGS_START: 'FETCH_PROFILE_SETTINGS_START',
    FETCH_PROFILE_SETTINGS_SUCCESS: 'FETCH_PROFILE_SETTINGS_SUCCESS',
    FETCH_PROFILE_SETTINGS_FAILURE: 'FETCH_PROFILE_SETTINGS_FAILURE',

    EDIT_PROFILE_SETTINGS_START: 'EDIT_PROFILE_SETTINGS_START',
    EDIT_PROFILE_SETTINGS_SUCCESS: 'EDIT_PROFILE_SETTINGS_SUCCESS',
    EDIT_PROFILE_SETTINGS_FAILURE: 'EDIT_PROFILE_SETTINGS_FAILURE',
    CLEAR_EDIT_PROFILE_SETTINGS_DATA: 'CLEAR_EDIT_PROFILE_SETTINGS_DATA',
    CLEAR_EDIT_PROFILE_SETTINGS_ERROR_MSG: 'CLEAR_EDIT_PROFILE_SETTINGS_ERROR_MSG',
    CLEAR_EDIT_PROFILE_SETTINGS_SUCCESS_MSG: 'CLEAR_EDIT_PROFILE_SETTINGS_SUCCESS_MSG',

    GET_CLIENT_VS_DATA_START : 'GET_CLIENT_VS_DATA_START',
    GET_CLIENT_VS_DATA_SUCCESS : 'GET_CLIENT_VS_DATA_SUCCESS',
    GET_CLIENT_VS_DATA_FAILURE : 'GET_CLIENT_VS_DATA_FAILURE',

    GET_CLIENTS_REFERRING_DATA_START : 'GET_CLIENTS_REFERRING_DATA_START',
    GET_CLIENTS_REFERRING_DATA_SUCCESS : 'GET_CLIENTS_REFERRING_DATA_SUCCESS',
    GET_CLIENTS_REFERRING_DATA_FAILURE : 'GET_CLIENTS_REFERRING_DATA_FAILURE',

    GET_RECENTLY_ADDED_CLIENTS_START : 'GET_RECENTLY_ADDED_CLIENTS_START',
    GET_RECENTLY_ADDED_CLIENTS_SUCCESS : 'GET_RECENTLY_ADDED_CLIENTS_SUCCESS',
    GET_RECENTLY_ADDED_CLIENTS_FAILURE : 'GET_RECENTLY_ADDED_CLIENTS_FAILURE',

    GET_TOP_REFERRERS_START : 'GET_TOP_REFERRERS_START',
    GET_TOP_REFERRERS_SUCCESS : 'GET_TOP_REFERRERS_SUCCESS',
    GET_TOP_REFERRERS_FAILURE : 'GET_TOP_REFERRERS_FAILURE',

    GET_ADHERENCE_TO_CPP_START : 'GET_ADHERENCE_TO_CPP_START',
    GET_ADHERENCE_TO_CPP_SUCCESS : 'GET_ADHERENCE_TO_CPP_SUCCESS',
    GET_ADHERENCE_TO_CPP_FAILURE : 'GET_ADHERENCE_TO_CPP_FAILURE',

    GET_ACCEPTED_REJECTED_RATIO_START : 'GET_ACCEPTED_REJECTED_RATIO_START',
    GET_ACCEPTED_REJECTED_RATIO_SUCCESS : 'GET_ACCEPTED_REJECTED_RATIO_SUCCESS',
    GET_ACCEPTED_REJECTED_RATIO_FAILURE : 'GET_ACCEPTED_REJECTED_RATIO_FAILURE',

    GET_AVERAGE_AGE_OF_CLIENTS_START : 'GET_AVERAGE_AGE_OF_CLIENTS_START',
    GET_AVERAGE_AGE_OF_CLIENTS_SUCCESS : 'GET_AVERAGE_AGE_OF_CLIENTS_SUCCESS',
    GET_AVERAGE_AGE_OF_CLIENTS_FAILURE : 'GET_AVERAGE_AGE_OF_CLIENTS_FAILURE',

    GET_INTERACTIONS_BY_TYPE_START : 'GET_INTERACTIONS_BY_TYPE_START',
    GET_INTERACTIONS_BY_TYPE_SUCCESS : 'GET_INTERACTIONS_BY_TYPE_SUCCESS',
    GET_INTERACTIONS_BY_TYPE_FAILURE : 'GET_INTERACTIONS_BY_TYPE_FAILURE',

    FETCH_STAFF_BASED_ON_FILTER_START : 'FETCH_STAFF_BASED_ON_FILTER_START',
    FETCH_STAFF_BASED_ON_FILTER_SUCCESS : 'FETCH_STAFF_BASED_ON_FILTER_SUCCESS',
    FETCH_STAFF_BASED_ON_FILTER_FAILURE : 'FETCH_STAFF_BASED_ON_FILTER_FAILURE',

    REMOVE_ERROR_MESSAGES : 'REMOVE_ERROR_MESSAGES'
};