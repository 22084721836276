import React, { memo } from 'react'
import './NotAuthorizedMessage.scss';


const NotAuthorizedMessage = ({ children, centered}) => {
    return (
        <div className={`
            notAuthorizedMessageWrapper
            ${centered ? 'centerContent' : ''}
        `}
        >
            <p className="notAuthorizedMessageContent">
                {children}
            </p>
        </div>
    )
}

export default memo(NotAuthorizedMessage);
