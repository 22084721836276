import React from 'react';

import Select from 'react-select';

 const SelectFieldReact=({options,onChange,value}) => {
  return (
      <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
        //   borderColor: state.isFocused ? 'grey' : 'red',
        }),
      }}
        className="basic-single"
        classNamePrefix="select"
        name="color"
        options={options}
        // getOptionValue={(option) => `${option['value']}`}
        // getOptionLabel={(option) => `${option.label}`}
        onChange={onChange}
        value={options.find(i => i.value === value)}
      />
  );
};


export default  SelectFieldReact