import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { checkPermissions } from '../utils/utils';

function usePermissionCheck(permissionList=[], clientDetails=null) {

    const currentUserData       = useSelector(state => state.auth.currentUserData);
    const currentUserPermission = useSelector(state => state.auth.currentUserData?.permission_list);
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        if( currentUserPermission && (
            (checkPermissions(currentUserPermission, ['IS-SUPERADMIN'])) ||
            (checkPermissions(currentUserPermission, ['IS-ADVISOR']) && clientDetails && clientDetails?.created_by && parseInt(clientDetails.created_by) === parseInt(currentUserData.id) ) ||
            (!checkPermissions(currentUserPermission, ['IS-ADVISOR']) && checkPermissions(currentUserPermission, permissionList)))
        ){
            setHasPermission(true)
        }else{
            setHasPermission(false)
        }
    });
    return hasPermission;
}

export default usePermissionCheck;