import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import './Modal.scss';
import CloseIcon from '@material-ui/icons/Close';






const Modal = ({ open, children,disableEnforceFocus=false, handleClose, size, className, disableBackdropClick=false, keepMounted=false, showDefaultCloseIcon=false, onCloseAction, conditionalAction=false  }) => {

    const handleCloseWithAction = async () => {
        if (conditionalAction ) {
            await onCloseAction();
        }
        handleClose();
    }
    return (

            <Dialog
                disableBackdropClick={disableBackdropClick}
                open={typeof open === "boolean" ? open : null} // modal close click on backdrop issue hack
                onClose={handleCloseWithAction}
                fullWidth={true}
                disableEnforceFocus
                maxWidth={size}
                className={`customModal ${className}`}
                keepMounted={keepMounted}
            >
                { 
                    showDefaultCloseIcon 
                    && 
                    <button className="defaultCloseIcon cursor-pointer noStyleButton" onClick={handleCloseWithAction}>
                        <CloseIcon className="icon"/>
                    </button>
                }
                <div className="customModalWrapper">
                    {children}
                </div>
            </Dialog>

    );
}



export default Modal;
