import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileBrowseWithDragAndDrop = ({
    placeholder,
    onDrop = () => null,
    onDropAccepted = () => null,
    className = '',
    usePresetTheme = true,
    accept,
    multiple = true,
    onDropRejected,
    maxSize = null,
    children = null
}) => {

    const classNameData = `
        customFileDropZone
        ${usePresetTheme ? 'customFileDropZonePresetThemeeee' : ''}
        ${className}
    `;

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        accept: accept ? accept : '', // if not passed accepts all file types
        multiple: multiple,
        onDrop: onDrop,
        onDropAccepted: onDropAccepted,
        onDropRejected,
        maxSize: maxSize
    });

    if (children)
    {
        return children(getRootProps({ className: classNameData }), getInputProps(), isDragActive);
    }
    else
    {
        return (
            <div {...getRootProps({ className: classNameData })}>
                <input {...getInputProps()} />
                {
                    isDragActive ? <p> Drop file here.</p> : placeholder
                }
            </div>
        );
    }
};

export default FileBrowseWithDragAndDrop;
