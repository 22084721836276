import React from 'react';
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from '@material-ui/core/styles';
import { getError, stringCaseSwitcher } from '../../utils/utils';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@material-ui/lab';


const useStyles = makeStyles(theme => {
    return {
        root: {
            '& label.Mui-focused': {
                color: theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.secondary.main
                },
            },
        }
    }
});

const filter = createFilterOptions();


const SearchableSelectEmail = ({
    field: { name, value, ...otherFieldProps },
    form: { touched, errors, values, setFieldValue, status },
    onChange,
    label,
    variant = 'outlined',
    options,
    optionLabel,
    size = "small",
    required,
    onInputChange,
    jointAccount,
    loading,
    withIcon = false,
    resultIcon: ResultIcon,
    creatable = false,
    selectOnFocus = false,
    onBlur,
    clearOnBlur = false,
    onClear = () => null,
    creatableLabel = true,
    disabled = false,
    convertToCase = "",
    noOptionsText = null,
    maxLength = null,
    getRelativeId,
    customHelperText
}) => {

    const color = useStyles();
    const incomingValue = value;
    const errorText =customHelperText || getError(name, { touched, status, errors });
    const isError = (errorText &&!customHelperText) ? true : false;
    
    return (

        <Autocomplete
            {...otherFieldProps}
            className={`SearchableSelect ${color.root}`}
            size={size}
            id={name}
            disableClearable
            options={options.map((option) => (option.client_fullname+" - ")+option.email)}
            
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    variant={variant}
                    error={isError}
                    helperText={errorText}
                    inputProps={{
                        ...params.inputProps,
                        value: ( params.inputProps.value),
                        autoComplete: 'off', // disable autocomplete and autofill
                        maxLength: maxLength
                    }}
                />
            )}
            onBlur={onBlur}
            onInputChange={(e, value, reason) => {
                const casedString = stringCaseSwitcher(convertToCase, value);
                if (onInputChange && reason !== 'reset') {
                    onInputChange(e, casedString, reason)
                }

                // when clear icon is clicked 
                if (reason === 'clear') {
                    onClear();
                }
            }}
            getOptionLabel={(option) => {
        
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return stringCaseSwitcher(convertToCase, option);
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return stringCaseSwitcher(convertToCase, option.inputValue);
                }
                return stringCaseSwitcher(convertToCase, option[optionLabel]);

            }}
            onChange={(e, newValue) => {
               // const indexOfFirst = newValue.indexOf("-");
               const aa = newValue.new_value == undefined ? newValue : newValue.new_value; 
               // const indexOfFirst = newValue.indexOf("-")==undefined?newValue.new_value.indexOf("-"):newValue.indexOf("-");
               const indexOfFirst = aa.indexOf("-");
                var nameValue = aa.substring(0, indexOfFirst);
                var emailValue = aa.slice(indexOfFirst+1)
                var trimEmailValue=emailValue.trim()
                const filteredArray = options.filter(item => item.email == trimEmailValue);
                if(jointAccount==true){
                   getRelativeId(filteredArray[0].id) 
                }
                const cc = nameValue=="" ? emailValue : nameValue
                const value = cc || '';  // make sure to give number,nullable if validating
                setFieldValue(name, value);

                // Running the custom on change function if passed
                if (onChange) {
                    onChange(e, newValue);
                }
            }}

            filterOptions={(options, params) => {
                const filtered = filter(options, params);
            
                let optionList = [];
                (filtered.length > 0) && filtered.forEach((item) => {
                    optionList.push(item[optionLabel])
                })
            
                // Suggest the creation of a new value if creatable is true and inputValue is in options
                let formattedInputValue = params.inputValue ? params.inputValue.toString().trim() : '';
                if (formattedInputValue !== '' && creatable === true && !optionList.includes(stringCaseSwitcher(convertToCase, formattedInputValue))) {
                    var newOptionLabel = creatableLabel ? `Add "${stringCaseSwitcher(convertToCase, formattedInputValue)}"` : stringCaseSwitcher(convertToCase, formattedInputValue);
                    filtered.push({
                        inputValue: stringCaseSwitcher(convertToCase, formattedInputValue),
                        [optionLabel]: newOptionLabel,
                        id: stringCaseSwitcher(convertToCase, formattedInputValue),
                        new_value: stringCaseSwitcher(convertToCase, formattedInputValue)
                    });
                }
            
                return filtered;
            }}
            loading={loading}
            selectOnFocus={selectOnFocus}
                clearOnBlur={clearOnBlur}
                value={incomingValue || ''}
                disabled={disabled}
                noOptionsText={noOptionsText? noOptionsText : React.ReactNode}
                creatable={false}
        />

    )
}

export default SearchableSelectEmail;