import { CALENDAR_ACTION_TYPES } from './calendar.types';
import { fetchClientsWithClientNameService, addEventService, fetchEventDataService, markParticipationService, editEventService, DeleteEventService, fetchEventListService, fetchNextMeetingSchedule } from '../../services/calendarService';



const fetchClientsWithClientNameAsync = (clientName) => {
    return dispatch => {
        fetchClientsWithClientNameService(dispatch, clientName)
    }
}



export const clearAddEventErrorMessage = () => ({
    type : CALENDAR_ACTION_TYPES.CLEAR_ADD_EVENT_ERROR_MESSAGE,
})



export const clearAddEditEventSuccessMessage  = () => ({
    type : CALENDAR_ACTION_TYPES.CLEAR_ADD_EDIT_EVENT_SUCCESS_MESSAGE
})


export const addEventAsync = (eventData) => {
    return dispatch => {
        addEventService(dispatch, eventData)
    }
}


export const editEventAsync = (eventData, eventId) => {
    return dispatch => {
        editEventService(dispatch, eventData, eventId)
    }
}

export const clearEditEventErrorMessage = () => ({
    type : CALENDAR_ACTION_TYPES.CLEAR_EDIT_EVENT_ERROR_MESSAGE
})



export const fetchEventDataAsync = (eventId) => {
    return dispatch => {
        fetchEventDataService(dispatch,eventId)
    }
}


export const markParticipationAsync = (eventId, option) => {
    return dispatch => {
        markParticipationService(dispatch, eventId, option)
    }
}

export const removeEventData = () => ({
    type: CALENDAR_ACTION_TYPES.REMOVE_EVENT_DATA,
})

export const deleteOrCancelEvent = (eventId) => {
    return dispatch => {
        DeleteEventService(dispatch, eventId)
    }
}

export const fetchEventListAsync = (interval) => {
    return dispatch => {
        fetchEventListService(dispatch, interval)
    }
}

export const getClientMeeting = (clientId) => {
    return dispatch => {
        fetchNextMeetingSchedule(dispatch, clientId)
    }
}