import appConfig from '../../appConfig.json';
import axios from 'axios';
import _ from 'lodash';

export const excludeTransferredProduct = (recommendedProducts) => {
    let actualProducts = [];
    let transferredArray = [];
    if(!_.isEmpty(recommendedProducts)){
        let transferredItems = recommendedProducts.map(element => element.map_transfer_from);
        transferredItems.forEach(element => {
            transferredArray.push(...element)
        });
        if(!_.isEmpty(transferredItems)){
            recommendedProducts.forEach((item)=>{
                if(!transferredArray.includes(item.id)){
                    actualProducts.push(item);
                }
            })
        }else{
            actualProducts = recommendedProducts;
        }
    }
    return actualProducts;
}
