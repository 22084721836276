import React from 'react';
import { KeyboardDatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { getError } from '../../utils/utils';
import { IconButton, InputAdornment } from '@material-ui/core';


const secondaryDark = '#179daf!important';
const themeGrayLight = '#e6e6e6';
const secondaryColor = '#1ac7de';
const theme = createTheme({
    overrides: {
        MuiPickersClock: {
            clock: {
                backgroundColor: '#EDEDED'
            },
            pin : {
                backgroundColor: secondaryColor,
            }
        },
        MuiPickersClockPointer : {
            pointer : {
                backgroundColor: secondaryColor
            },
            thumb : {
                backgroundColor: secondaryDark,
                borderColor :   secondaryDark,
            }
        },
        MuiButton: {
            textPrimary: {
                color: secondaryColor,
            }
        },
        //header
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: secondaryColor,
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: secondaryColor,
                '&:hover' : {
                    backgroundColor: secondaryDark,
                }
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: 'red',
            },
        },
        MuiOutlinedInput:{
            root: {
                '&.Mui-disabled' : {
                    background : themeGrayLight
                },
                '&.Mui-focused fieldset': {
                    borderColor: `${secondaryColor}!important`,
                },
            }
        },
        MuiFormLabel : {
            '&.Mui-focused legend': {
                borderColor: `${secondaryColor}!important`,
            },
        }
    }
});


// Note : if datepicker needs to be opended conditionaly also pass progOpen as true from the component

const DatePicker = ({
    field: { name, value },
    form: { values, setFieldValue , touched, status, errors},
    onChange,
    label,
    variant = 'outlined',
    size = "small",
    showTodayButton = true,
    disablePast = false,
    disableFuture = false,
    required,
    icon = true,
    className,
    fullWidth = true,
    format = "dd / MM / yyyy",
    progOpen=false,
    open,
    onOpen,
    onClose,
    clearable=false,
    autoOk=true,
    showToday=true,
    disabled=false
    ,labelShorter=false,
    numberofShorter=null,  //to make label short for small fields
}) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const errorText = getError(name, { touched, status, errors });
    const isError = (errorText) ? true : false;
    const getLabel = () => {
        if (labelShorter && !isFocused) {
            return `${label.substring(0, numberofShorter)}...`;
        }
        return label;
    };
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
                <MuiDatePicker
                    className={`
                    customDatePicker
                    ${className}
        
                `}
                    fullWidth={fullWidth}
                    placeholder='dd/mm/yyyy'
                    name={name}
                    autoOk={autoOk}
                    label={getLabel()}
                    clearable={clearable}
                    value={showToday ? value : (value ? value : null)}
                    onChange={(value) => {
                        setFieldValue(name, value);
                        // Running the custom on change function if passed
                        if (onChange)
                        {
                            onChange(value);
                        }
                    }}
                    onFocus={handleFocus}  // Track focus
                    onBlur={handleBlur}    // Track blur
                    inputVariant={variant}
                    format={format}
                    size={size}
                    {...(isError && !disabled ? { error:isError  } : {})}
                    {...(isError && !disabled ? { helperText: errorText } : {})}
                    InputProps={icon ? {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton> <CalendarTodayIcon /> </IconButton>
                            </InputAdornment>
                        )
                    } : null}
                    required={required}
                    showTodayButton={showTodayButton}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    {...{ ...( progOpen && { open,onOpen, onClose })}} // only passing this props if progOpen is set to true
                    disabled={disabled}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>

    )
}

export default DatePicker;
