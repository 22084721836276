import { CALENDAR_ACTION_TYPES } from '../redux/calendar/calendar.types';
import { axiosPost, axiosGet, axiosPut, axiosDelete, handleOnError, handleOnSuccess, handleActionStart } from '../utils/api';
import { CLIENT_NEXT_MEETING } from '../utils/endPoint';



export const fetchClientsWithClientNameService = async (dispatch, clientName) => {
    try
    {
        handleActionStart(CALENDAR_ACTION_TYPES.FETCH_CLIENTS_WITH_CLIENT_NAME_START);
        handleOnSuccess(CALENDAR_ACTION_TYPES.FETCH_CLIENTS_WITH_CLIENT_NAME_SUCCESS);
    }
    catch(err)
    {
        handleOnSuccess(CALENDAR_ACTION_TYPES.FETCH_CLIENTS_WITH_CLIENT_NAME_FAILURE);
    }
}



/*
    Service to add an event
*/
export const addEventService = async (dispatch, eventData) => {
    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.ADD_EVENT_START));

        const eventStatus = await axiosPost('event/', eventData );
        dispatch(handleOnSuccess(eventStatus,CALENDAR_ACTION_TYPES.ADD_EVENT_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,CALENDAR_ACTION_TYPES.ADD_EVENT_FAILURE));
    }
}

export const editEventService = async (dispatch, eventData, eventId) => {
    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.EDIT_EVENT_START));
        const eventStatus = await axiosPut(`event/${eventId}/`, eventData );
        dispatch(handleOnSuccess(eventStatus,CALENDAR_ACTION_TYPES.EDIT_EVENT_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,CALENDAR_ACTION_TYPES.EDIT_EVENT_FAILURE));
    }
}



/*
    Service to fetch event data
*/
export const fetchEventDataService = async (dispatch, eventId) => {
    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.FETCH_EVENT_DATA_START));
        const data = await axiosGet(`event/${eventId}/`);
        dispatch(handleOnSuccess(data, CALENDAR_ACTION_TYPES.FETCH_EVENT_DATA_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, CALENDAR_ACTION_TYPES.FETCH_EVENT_DATA_FAILURE));
    }
}

/*
    Service to mark participation
*/
export const markParticipationService = async (dispatch, eventId, option) => {

    const postData = {event:eventId, response_marked:option};
    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.MARK_PARTICIPATION_START));
        const data = await axiosPost('mark-rsvp/', postData);
        dispatch(handleOnSuccess(data, CALENDAR_ACTION_TYPES.MARK_PARTICIPATION_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, CALENDAR_ACTION_TYPES.MARK_PARTICIPATION_FAILURE));
    }
}

/*
    Service to delete event
*/
export const DeleteEventService = async (dispatch, eventId) => {

    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.DELETE_EVENT_START));
        const data = await axiosDelete(`event/${eventId}`);
        dispatch(handleOnSuccess(data, CALENDAR_ACTION_TYPES.DELETE_EVENT_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, CALENDAR_ACTION_TYPES.DELETE_EVENT_FAILURE));
    }
}

/*
    Service to fetch event list
*/
export const fetchEventListService = async (dispatch, interval) => {
    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.FETCH_EVENT_LIST_START));
        const data = await axiosGet('event/', {
            params: {...interval}
        });
        dispatch(handleOnSuccess(data, CALENDAR_ACTION_TYPES.FETCH_EVENT_LIST_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, CALENDAR_ACTION_TYPES.FETCH_EVENT_LIST_FAILURE));
    }
}

export const fetchNextMeetingSchedule = async (dispatch, clientId) => {
    try {
        dispatch(handleActionStart(CALENDAR_ACTION_TYPES.FETCH_MEETING_SCHEDULE_START));
        const data = await axiosGet(CLIENT_NEXT_MEETING(clientId));
        dispatch(handleOnSuccess(data, CALENDAR_ACTION_TYPES.FETCH_MEETING_SCHEDULE_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, CALENDAR_ACTION_TYPES.FETCH_MEETING_SCHEDULE_FAILURE));
    }
}