import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotAuthorizedPage from '../../pages/NotAuthorizedPage/NotAuthorizedPage';
import { checkPermissions } from '../../utils/utils';


const AuthorizationHOC = (allowedPermissions, allowedRoles) => (WrappedComponent) => {
    class WithAuthorization extends Component {
        render() {
          //  if (this.props.currentUserData && checkPermissions(this.props.currentUserData.permission_list, allowedPermissions, this.props.currentUserData.logged_in_user_type, allowedRoles))
          //  {
                return <WrappedComponent {...this.props} />
          //  }
          //  else
           // {
           //     return <NotAuthorizedPage/>
          //  }
        }
    }
    const mapStateToProps = (state) => ({
        currentUserData : state.auth.currentUserData,
    })
    return connect(mapStateToProps)(withRouter(WithAuthorization)); 
}

export default AuthorizationHOC;
