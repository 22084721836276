export const SELECT_CLIENT = "selectClient"
export const SEARCH_CLIENT = "searchClient"
export const ADD_CLIENT = "addClient"
export const SEARCH_CLIENT_REUSLTS = "searchClientResults"
export const FEE_MANAGEMENT = "dashboard"
export const MONTHLY_ISSUED = "monthlyIssued"
export const PENDING_ISSUED = "pendingIssued"
export const PRODUCT_SUMMARY = "productSummary"
export const PRODUCT_LIST = "productList"
export const FEE_MATCHING = "feeMatching"
export const UPDATE_VALUE = "pendingIssue"
export const LIST_PRODUCT="listProduct"
export const FEE_SPLIT ="feeSplit"
