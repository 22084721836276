import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './MultiSelect.scss';
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from '@material-ui/core/styles';
import { getError } from '../../utils/utils';
import { isArray } from 'lodash';




const filter = createFilterOptions();
const useStyles = makeStyles(theme => {
    return {
        root: {
            '& label.Mui-focused': {
                color: theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.secondary.main
                },
            },
        },
        tag: {
            position: "relative",
            zIndex: 0,
            "& .MuiChip-root" : {
                backgroundColor: '#F3FDFF',
                color: 'red',
            },
            "& .MuiChip-label": {
                color: "rgba(0, 0, 0, 0.87)"
            },
            "& .MuiChip-deleteIcon": {
                color: "#32c2d9"
            },
            "&:after": {
                content: '""',
                right: 10,
                top: 6,
                height: 12,
                width: 12,
                position: "absolute",
                backgroundColor: "white",
                zIndex: -1
            }
        }
    }
});



const MultiSelect = ({
    field: { name, value, ...otherFieldProps },
    form: { touched, errors, values, setFieldValue, status },
    onChange,
    label,
    variant = 'outlined',
    options,
    optionLabel,
    size = 'small',
    required,
    loading,
    onInputChange,
    onClear = () => null,
    className = '',
    closeIcon = null,
    popupIcon = null,
    creatable = true,
    placeholder=true,
    customAddMessage = "Send to",
    onBlur,
    clearOnBlur = false,
    selectOnFocus = true,
    filterSelectedOptions=false,
    getOptionsSelectedBasedOnOptionLabel=false, // if ids are generated from front end you need to set getOptionsSelectedBasedOnOptionLabel to true else setting filterSelectedOptions to true wont have any effect
    checkDefaultValue = false,
    disabled=false,
    groupByField=null
}) => {

    const customStyles = useStyles();
    const errorText = getError(name, { touched, status, errors });
    const isError = (errorText) ? true : false;

    return (
        <Autocomplete
            className={`multiselect ${className} ${customStyles.root} ${customStyles.tag}`}
            multiple
            name={name}
            value={checkDefaultValue ? (isArray(value) ? value : []) : value}
            {...otherFieldProps}
            onChange={(e, newValue) => {
                setFieldValue(name, newValue);
                // Running the custom on change function if passed
                if (onChange)
                {
                    onChange(e, newValue);
                }
            }}
            required={required}
            id={name}
            options={options}
            onBlur={onBlur}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    placeholder={placeholder ? label : null}
                    error={isError}
                    helperText={errorText}
                />
            )}
            size={size}
            loading={loading}
            getOptionLabel={(option) => {
                return option[optionLabel];
            }}
            filterSelectedOptions={filterSelectedOptions}
            getOptionSelected={(option, value) => {
                if(getOptionsSelectedBasedOnOptionLabel)
                {
                    if (option[optionLabel] === value[optionLabel])
                    {
                        return true
                    }
                }
                else
                {
                    if (option.id === value.id)
                    {
                        return true
                    }
                }
            }}
            onInputChange={(e, value, reason) => {

                if (onInputChange && reason !== 'reset')
                {
                    onInputChange(e, value, reason)
                }
                // when clear icon is clicked 
                if (reason === 'clear')
                {
                    onClear();
                }
            }}

            // for showing new item in option list if item searched in not prasent
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value if creatable is true
                if (params.inputValue !== '' && creatable === true)
                {
                    filtered.push({
                        inputValue: params.inputValue,
                        [optionLabel]: `${params.inputValue}`,
                        id: params.inputValue,
                        new_value: params.inputValue
                    });
                }

                return filtered;
            }}
            renderOption={(option, { inputValue }) => {
                const matches = match(option[optionLabel], inputValue);
                const parts = parse(option[optionLabel], matches);
                return (
                    <>
                        <div className="searchableSelectResults">
                            {parts.map((part, index) => (
                                <span key={index} className="highLighter" style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </>
                );
            }}
            closeIcon={closeIcon}
            popupIcon={popupIcon}
            disabled={disabled}
            groupBy={(options) => groupByField ? options[groupByField] : ''}
        />
    )
}

export default MultiSelect
