import React from 'react';
import './DashboardItem.scss';
import Paper from '../../Mui/Paper/Paper';

const DashboardItem = ({
    itemHeader = "Test Header",
    children,
    intractable = false,
    withPadding = true,
}) => {
    return (
        <Paper className="dashboardItem">
            {
                intractable
                    ?
                    children
                    :
                    <>
                        <div className="dashboardItemHeaderWrapper">
                            <h1 className="customHead dashboardItemHeader">{itemHeader}</h1>
                        </div>
                        <div className={`
                        dashboardItemContentWrapper 
                        ${withPadding ? 'withPadding' : 'withOutPadding'}
                    `}>
                            {children}
                        </div>
                    </>
            }
        </Paper>
    );
};

export default DashboardItem;
