import { UI_ACTION_TYPES } from "../redux/ui/ui.types";
import { axiosGet, axiosPut, handleActionStart, handleOnError, handleOnSuccess } from "../utils/api";

/*
    Service to fetch profile settings 
*/
export const fetchProfileSettingsService = async (dispatch, advisorId) => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.FETCH_PROFILE_SETTINGS_START));
        const data = await axiosGet(`advisor-profile/?user_id=${advisorId}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.FETCH_PROFILE_SETTINGS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.FETCH_PROFILE_SETTINGS_FAILURE));
    }
};

/*
    Service to edit profile settings
*/
export const editProfileSettingsService = async (dispatch, newProfileSettingsDetails) => {
    try
    {
        const { newFormData, currentUserId, advisorIdFromResponse } = newProfileSettingsDetails;
        dispatch(handleActionStart(UI_ACTION_TYPES.EDIT_PROFILE_SETTINGS_START));
        const data = await axiosPut(`advisor-profile/${advisorIdFromResponse}/?user_id=${currentUserId}`, newFormData);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.EDIT_PROFILE_SETTINGS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.EDIT_PROFILE_SETTINGS_FAILURE));
    }
};

/*
    Service to fetch clients vs details in dashboard
*/
export const getClientsVsDataService = async (dispatch, advisorId = '') => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_CLIENT_VS_DATA_START));
        const data = await axiosGet(`check-newclients/?advisor=${advisorId}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_CLIENT_VS_DATA_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_CLIENT_VS_DATA_FAILURE));
    }
};

/*
    Service to fetch average clients for dashboard
*/
export const getAverageClientsService = async (dispatch, filterData) => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_AVERAGE_AGE_OF_CLIENTS_START));
        const data = await axiosGet(`average-age/?advisor=${filterData}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_AVERAGE_AGE_OF_CLIENTS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_AVERAGE_AGE_OF_CLIENTS_FAILURE));
    }
};

/*
    Service to fetch clients referring data for dashboard
*/
export const getClientsReferringDataService = async (dispatch, filterData) => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_CLIENTS_REFERRING_DATA_START));
        const data = await axiosGet(`clients-refer/?advisor=${filterData}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_CLIENTS_REFERRING_DATA_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_CLIENTS_REFERRING_DATA_FAILURE));
    }
};

/*
    Service to fetch adherance to cpp data for dashboard
*/
export const getAdherenceToCPPDataService = async (dispatch, filterData) => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_ADHERENCE_TO_CPP_START));
        const data = await axiosGet(`redington/?advisor=${filterData}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_ADHERENCE_TO_CPP_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_ADHERENCE_TO_CPP_FAILURE));
    }
};

/*
    Service to get interactions by type for dashboard
*/
export const getInteractionsByTypeService = async (dispatch, filterData) => {
    try
    {
        const { advisorId, frequencyType } = filterData;
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_INTERACTIONS_BY_TYPE_START));
        let url = `interaction-type/?advisor=${advisorId}`;
        if(frequencyType)
        {
            url = `interaction-type/?advisor=${advisorId}&${frequencyType}=true`;
        }
        const data = await axiosGet(url);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_INTERACTIONS_BY_TYPE_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_INTERACTIONS_BY_TYPE_FAILURE));
    }
};

/*
    Service to fetch recently added clients for dashboard
*/
export const getRecentlyAddedClientsService = async (dispatch, filterData) => {
    try
    {
        const { advisorId, frequencyType } = filterData;
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_RECENTLY_ADDED_CLIENTS_START));
        let url = `clients/?recently_added_client_list=true&advisor=${advisorId}`;
        if(frequencyType)
        {
            url = `clients/?recently_added_client_list=true&advisor=${advisorId}&${frequencyType}=true`;
        }
        const data = await axiosGet(url);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_RECENTLY_ADDED_CLIENTS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_RECENTLY_ADDED_CLIENTS_FAILURE));
    }
};

/*
    Service to get accepted rejected ratio for dashboard
*/
export const getAcceptedRejectedRatioService = async (dispatch, filterData) => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_ACCEPTED_REJECTED_RATIO_START));
        const data = await axiosGet(`accept-ratio/?advisor=${filterData}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_ACCEPTED_REJECTED_RATIO_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_ACCEPTED_REJECTED_RATIO_FAILURE));
    }
};

/*
    Service to get top referrers data for dashboard
*/
export const getTopReferrersService = async (dispatch, filterData) => {
    try
    {
        dispatch(handleActionStart(UI_ACTION_TYPES.GET_TOP_REFERRERS_START));
        const data = await axiosGet(`top-referer/?advisor=${filterData}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.GET_TOP_REFERRERS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, UI_ACTION_TYPES.GET_TOP_REFERRERS_FAILURE));
    }
};

/**
 * Fetch staff list based on filter
 */
export const fetchStaffBasedOnFilterService = async (dispatch, filterString) => {
    try {
        dispatch(handleActionStart(UI_ACTION_TYPES.FETCH_STAFF_BASED_ON_FILTER_START));
        const data = await axiosGet(`staffs/${filterString}`);
        dispatch(handleOnSuccess(data, UI_ACTION_TYPES.FETCH_STAFF_BASED_ON_FILTER_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,UI_ACTION_TYPES.FETCH_STAFF_BASED_ON_FILTER_FAILURE));
    }
};