import React , { useEffect, useState }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import {getActivityFlow} from '../../redux/client/client.actions';
import TimeLine from '../TimeLine/TimeLine';
import './ProcessStageAccordion.scss';
import { checkPermissions } from '../../utils/utils';
import appConfig from '../../appConfig.json';
import NotAuthorizedMessage from '../NotAuthorizedMessage/NotAuthorizedMessage';
import usePermissionCheck from '../../hooks/usePermissionCheck';
import { fetchGET } from '../../services/FeeMatchingService';
import { Grid } from '@material-ui/core';
import { Box } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';




const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 45,
    '&$expanded': {
      minHeight: 45,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);



export default function ProcessStageAccordion(props) {


  const [expanded, setExpanded] = useState('panel1');
  const {clientId}  = props;
  const dispatch    = useDispatch();
  const clientActivity    = useSelector(state => state.client.clientActivity);
  const preContractLength = clientActivity.PRE_CONTRACT ? clientActivity.PRE_CONTRACT.length : 0;
  const atpLength         = clientActivity.ATP ? clientActivity.ATP.length : 0;
  const postContractLength= clientActivity.POST_CONTRACT ? clientActivity.POST_CONTRACT.length : 0;
  const currentUserData   = useSelector(state => state.auth.currentUserData);
  const clientDetails     = useSelector(state => state.client?.clientDetails);
  const isLoadingClientActivity = useSelector(state => state.client.isLoadingClientActivity);
  const permissionList    = useSelector(state => state.auth.currentUserData?.permission_list);

  /**
   * Permission check for different activities
   * Advisor's check and SuperAdmin's checks are done here
   */
  const listPreContractAccess = true;//usePermissionCheck(['LIST-PRE-CONTRACTING-TIMELINE'], clientDetails);
  const listAtpAccess         = true;//usePermissionCheck(['LIST-ATP-TIMELINE'], clientDetails);
  const listPostContractAccess= true;//usePermissionCheck(['LIST-POST-CONTRACTING-TIMELINE'], clientDetails);
  const clickPreContractAccess= usePermissionCheck(['CLICK-PRE-CONTRACTING-TIMELINE'], clientDetails);
  const clickAtpAccess= usePermissionCheck(['CLICK-ATP-TIMELINE'], clientDetails);
  const clickPostContractAccess= usePermissionCheck(['CLICK-POST-CONTRACTING-TIMELINE'], clientDetails);
  const [recentUpdate,setRecentUpdate]=useState([])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const transformData = (responseData) => {
    const headingsOrder = [
      "Personal Information",
      "Occupation Information",
      "Income & Expenditure Summary",
      "Net Worth Summary",
      "Plans & ATR",
    ];
  
    const transformedData = headingsOrder.map((heading) => {
      const content = responseData
        .filter((item) => item.category === heading)
        .map((item) => ({
          label: item.label,
          updated_time:item.updated_time,
          last_updated_field: item.last_updated_field,
        }));
  
      return {
        heading,
        content: content.length ? content : "No Recent Changes",
      };
    });
  
    return transformedData;
  };
  const [openItems, setOpenItems] = useState({});

  const toggleItem = (index) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
      dispatch(getActivityFlow(clientId))
  },[])
  const getLastUpdated=async()=>{
    const url=`surveyform-log/${clientId}`
     const response=await fetchGET(currentUserData.token,url)
     if(response.status && response?.data?.length){
      const transformedData = await transformData(response.data);
        setRecentUpdate(transformedData)
     }
     else{
      setRecentUpdate([])
     }
  }
  useEffect(()=>{
    getLastUpdated()
  },[])
  const getStyleForActivity = (option) => {
    var style = "";
    switch(option){
      case "PRE":
        if(preContractLength > 0 && atpLength == 0){
          style = "accordionHeadActive"
        }else if(preContractLength > 0 && atpLength > 0){
          style = "accordionHeadCompleted"
        }
      break;
      case "ATP":
        if(preContractLength > 0 && atpLength > 0 && postContractLength == 0){
          style = "accordionHeadActive"
        }else if(preContractLength > 0 && atpLength > 0 && postContractLength > 0){
          style = "accordionHeadCompleted"
        }
      break;
      //need some rework here
      case "POST":
        if(atpLength > 0 && postContractLength > 0){
          style = "accordionHeadActive"
        }else if(postContractLength > 0){
          style = "accordionHeadCompleted"
        }
      break;
      default:
        style = "accordionHead";
      break;
    }
    return style;
  }

  return (
    <div style={{width:"100%"}} className="processStageWrapper">
      <Accordion square={false} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordionContainer customScrollbar">
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} className={`accordionHead accordionHeadFirst ${getStyleForActivity("PRE")}`}>
            {(preContractLength > 0 && atpLength > 0) ? (
              <DoneAllIcon />
            ) : (
              <DoneIcon />
            )}
          <Typography>Pre-Contracting Phase</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
        { listPreContractAccess ?
          <TimeLine items={clientActivity.PRE_CONTRACT} enableLink={ clickPreContractAccess ? true : false } loading={isLoadingClientActivity}/>
        :
          <NotAuthorizedMessage centered>{appConfig.messages.not_authorized_section_message}</NotAuthorizedMessage>
        }
        </AccordionDetails>
      </Accordion>

      <Accordion square={false} expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordionContainer customScrollbar">
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />} className={`accordionHead ${getStyleForActivity("ATP")}`}>
          {(atpLength > 0 && postContractLength > 0) ? (
            <DoneAllIcon />
          ) : (
            <DoneIcon />
          )}
          <Typography>ATP</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
		    { listAtpAccess ?
          <TimeLine items={clientActivity.ATP} enableLink={ clickAtpAccess ? true : false } loading={isLoadingClientActivity}/>
        :
         <NotAuthorizedMessage centered>{appConfig.messages.not_authorized_section_message}</NotAuthorizedMessage>
        }
        </AccordionDetails>
      </Accordion>

      <Accordion square={false} expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordionContainer customScrollbar">
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon />} className={`accordionHead ${getStyleForActivity("POST")} ${expanded !== 'panel3' ? 'accordionHeadLast':''}`}>
          {(postContractLength > 0) ? (
            <DoneAllIcon />
          ) : (
            <DoneIcon />
          )}
          <Typography>Post-Contracting Phase</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
		    { listPostContractAccess ?
			    <TimeLine items={clientActivity.POST_CONTRACT} enableLink={ clickPostContractAccess ? true : false } loading={isLoadingClientActivity}/>
			  :
			    <NotAuthorizedMessage centered>{appConfig.messages.not_authorized_section_message}</NotAuthorizedMessage>
        }
        </AccordionDetails>
      </Accordion>
      <Accordion square={false} expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordionContainer customScrollbar">
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon />} className={`accordionHead ${getStyleForActivity("POST")} ${expanded !== 'panel3' ? 'accordionHeadLast':''}`}>
         <DoneIcon />
          
          <Typography>Recently Updated</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
		       {recentUpdate?.length>0? <Box sx={{ flexGrow: 1 }}>
           { recentUpdate.map((item, index)=><><div key={index}>
                <ul key={index} style={{ paddingLeft: '1em' }}>
                  <div className="subheading" onClick={() => toggleItem(index)}>
                    {openItems[index] ? <ArrowDropDownIcon style={{color:"32c2d9"}} fontSize='large'/> : <ArrowRightIcon style={{color:"32c2d9",}} fontSize="large"/>} {item.heading}
                  </div>
                  {openItems[index] && (
                    <div className="content">
                     {(typeof item.content === "object" || typeof item.content === 'function') && (item.content !== null)
  ? item.content.map((contentItem, contentIndex) => (
    <ul key={contentIndex} style={{ paddingLeft: '2em' }}>
      <strong>{contentItem.label}:</strong> <br />
      <p style={{ paddingLeft: '1em' }}>{contentItem.last_updated_field} - {moment(contentItem.updated_time).format("DD/MM/YYYY")}</p>
    </ul>
  ))
  : <ul><strong>{item.content}</strong></ul>
}
                    </div>
                  )}
              </ul>
            </div>
           
       {/*<Grid container spacing={2}> <Grid item xs={6}>
          <strong>Category:</strong> <div>{updated?.label}{updated?.last_updated}</div>
        </Grid>
        <Grid item xs={6}>
          <strong>Updated on:</strong> <div>{new Date(updated?.label).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}</div>
        </Grid>
        <Grid item xs={12} >
          <strong>Label:</strong> <div>{updated?.last_updated}</div>
        </Grid>
      </Grid><hr style={{ border: '1px solid black', marginTop: '20px' }} /> */}
      </>)}
    </Box>:"No Recent Updates"}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
