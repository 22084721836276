import { INSTRUMENT_ACTION_TYPES } from './instrument.types';
import { formatInstrumentList, formattedRecommendedItems } from './instrument.utils';

const INITIAL_STATE = {
    isLoadingRecentProviders: false,
    isLoadingSearchProviders: false,
    isLoadingInstrumentList: false,
    isLoadingAddInstrument: false,
    isLoadingClientInstrument: false,
    isLoadingInstrumentCount: false,
    isLoadingInstrumentTypeUpdate: false,
    isLoadingDocumentUpload: false,
    isLoadingEmailPreview: false,
    isLoadingBulkEmailSend: false,
    isLoadingLoaDoc: false,
    isLoadingDeleteLoa: false,
    recentProviders: [],
    searchProviders: [],
    instrumentList: [],
    clientInstruments: [],
    clientInstrumentCount: 0,
    emailPreview: [],
    bulkEmail: [],
    loaDocDetails: [],
    deleteLoa: [],

    isReminderListFetching: false,
    reminderListData: [],
    reminderListErrorMsg: null,

    isSaveSnoozeDataFetching: false,
    saveSnoozeDataErrorMsg: null,
    saveSnoozeSuccessMsg: null,

    isFilteredReminderDataFetching: false,
    filteredReminderData: null,
    filteredReminderErrorMsg: null,

    isRemoveReminderEndDateFetching: false,
    removeReminderEndDateErrorMsg: null,
    newReminderData: null,
    removeReminderEndDateSuccessMsg: null,

    fetchPendingWithData: true,
    fetchPendingWithSuccessMsg: null,
    pendingWithData: [],
    fetchPendingWithErrorMsg: null,

    documentUploadResponse: null,
    isLoadingBulkReminderSend: false,
    bulkReminder: [],
    productAddedTime: null,

    isPickingPossibleProductsLoading: false,
    pickingPossibleProductsSuccessMsg: null,
    pickingPossibleProductsErrorMsg: null,

    isPossibleRecommendedItemsFetching: false,
    possibleRecommendedItems: null,
    possibleRecommendedItemsErrorMsg: null,

    saveProductsForDraftRecommendationFetching: false,
    saveProductsForDraftRecommendationSuccessMsg: null,
    saveProductsForDraftRecommendationErrorMsg: null,

    isProductComparisonDataFetching : false,
    productComparisonData : null,
    isLoadingUpdateExtractedData: false,

    isLoadingProductDelete : false,
    productDeleteMessage : null,
    productDeleteErrorMessage: null,

    reminderListCount : null,
    reminderListOffset : null,

    docUploadCounter : 0,

    checkedProducts:[],
    is_updated_backdata_page:null,
    providerstatementinfo_id:null,
    policy_no_in_missing_data:null,
}

const instrumentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {

        case INSTRUMENT_ACTION_TYPES.FETCH_RECENTLY_ADDED_INSTRUMENTS_START:
            return {
                ...state,
                isLoadingRecentProviders: true,
            }
        case "saveSelectedProductList":
            return {
                ...state,
                checkedProducts: payload
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_RECENTLY_ADDED_INSTRUMENTS_SUCCESS:
            return {
                ...state,
                isLoadingRecentProviders: false,
                recentProviders: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_RECENTLY_ADDED_INSTRUMENTS_FAILURE:
            return {
                ...state,
                isLoadingRecentProviders: false,
                recentProvidersErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_SEARCH_INSTRUMENTS_START:
            return {
                ...state,
                isLoadingSearchProviders: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_SEARCH_INSTRUMENTS_SUCCESS:
            return {
                ...state,
                isLoadingSearchProviders: false,
                searchProviders: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_SEARCH_INSTRUMENTS_FAILURE:
            return {
                ...state,
                isLoadingSearchProviders: false,
                searchProvidersErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_INSTRUMENTS_START:
            return {
                ...state,
                isLoadingInstrumentList: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_INSTRUMENTS_SUCCESS:
            return {
                ...state,
                isLoadingInstrumentList: false,
                instrumentList: formatInstrumentList(payload.data)
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_INSTRUMENTS_FAILURE:
            return {
                ...state,
                isLoadingInstrumentList: false,
                instrumentListErrorMessage: payload.message,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_INSTRUMENT_LIST:
            return {
                ...state,
                isLoadingInstrumentList: false,
                instrumentList: [],
                searchProviders: [],
            }

        case INSTRUMENT_ACTION_TYPES.POST_INSTRUMENTS_START:
            return {
                ...state,
                isLoadingAddInstrument: true,
            }
        case INSTRUMENT_ACTION_TYPES.POST_INSTRUMENTS_SUCCESS:
            return {
                ...state,
                isLoadingAddInstrument: false,
                productAddedTime: new Date()
            }
        case INSTRUMENT_ACTION_TYPES.POST_INSTRUMENTS_FAILURE:
            return {
                ...state,
                isLoadingAddInstrument: false,
                addInstrumentErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_START:
            return {
                ...state,
                isLoadingClientInstrument: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_SUCCESS:
            return {
                ...state,
                isLoadingClientInstrument: false,
                clientInstruments: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_FAILURE:
            return {
                ...state,
                isLoadingClientInstrument: false,
                clientInstruments: [],
                clientInstrumentErrorMessage: payload.message,
            }
        case INSTRUMENT_ACTION_TYPES.UPDATE_FROM_BACK_DATA:
            return {
                ...state,
                is_updated_backdata_page:payload.data.is_updated_backdata_page,
                providerstatementinfo_id:payload.data.providerstatementinfo_id,
                policy_no_in_missing_data:payload.data.policy_no_in_missing_data,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_UPDATE_FROM_BACK_DATA:
            return {
                ...state,
                is_updated_backdata_page:null,
                providerstatementinfo_id:null,
                policy_no_in_missing_data:null,
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_COUNT_START:
            return {
                ...state,
                isLoadingInstrumentCount: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_COUNT_SUCCESS:
            return {
                ...state,
                isLoadingInstrumentCount: false,
                clientInstrumentCount: payload.data[0]?.instrument_count
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_INSTRUMENT_COUNT_FAILURE:
            return {
                ...state,
                isLoadingInstrumentCount: false,
                clientInstrumentCountErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.UPDATE_CLIENT_INSTRUMENT_TYPE_START:
            return {
                ...state,
                isLoadingInstrumentTypeUpdate: true,
            }
        case INSTRUMENT_ACTION_TYPES.UPDATE_CLIENT_INSTRUMENT_TYPE_SUCCESS:
            return {
                ...state,
                isLoadingInstrumentTypeUpdate: false,
            }
        case INSTRUMENT_ACTION_TYPES.UPDATE_CLIENT_INSTRUMENT_TYPE_FAILURE:
            return {
                ...state,
                isLoadingInstrumentTypeUpdate: false,
                clientInstrumentTypeUpdateErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.DOCUMENT_UPLOAD_START:
            return {
                ...state,
                isLoadingDocumentUpload: true,
                docUploadCounter : state.docUploadCounter + 1
            }
        case INSTRUMENT_ACTION_TYPES.DOCUMENT_UPLOAD_SUCCESS:
            return {
                ...state,
                isLoadingDocumentUpload: false,
                documentUploadResponse: payload.message,
                docUploadCounter : state.docUploadCounter - 1
            }
        case INSTRUMENT_ACTION_TYPES.DOCUMENT_UPLOAD_FAILURE:
            return {
                ...state,
                isLoadingDocumentUpload: false,
                documentUploadErrorMessage: (payload.data.hasOwnProperty('file') && payload.data?.file.length > 0) ? payload.data?.file[0] : null,
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_EMAIL_PREVIEW_START:
            return {
                ...state,
                isLoadingEmailPreview: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_EMAIL_PREVIEW_SUCCESS:
            return {
                ...state,
                isLoadingEmailPreview: false,
                emailPreview: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_EMAIL_PREVIEW_FAILURE:
            return {
                ...state,
                isLoadingEmailPreview: false,
                emailPreviewErrorMessage: payload.message,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_EMAIL_TEMPLATE_PREVIEW:
            return {
                ...state,
                isLoadingEmailPreview: false,
                emailPreview: [],
            }

        case INSTRUMENT_ACTION_TYPES.POST_BULK_EMAIL_START:
            return {
                ...state,
                isLoadingBulkEmailSend: true,
            }
        case INSTRUMENT_ACTION_TYPES.POST_BULK_EMAIL_SUCCESS:
            return {
                ...state,
                isLoadingBulkEmailSend: false,
                bulkEmail: payload
            }
        case INSTRUMENT_ACTION_TYPES.POST_BULK_EMAIL_FAILURE:
            return {
                ...state,
                isLoadingBulkEmailSend: false,
                bulkEmailErrorMessage: payload.message,
                bulkEmailErrorItems: payload.data
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_LOA_START:
            return {
                ...state,
                isLoadingLoaDoc: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_LOA_SUCCESS:
            return {
                ...state,
                isLoadingLoaDoc: false,
                loaDocDetails: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_CLIENT_LOA_FAILURE:
            return {
                ...state,
                isLoadingLoaDoc: false,
                viewLoaErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.DELETE_CLIENT_LOA_START:
            return {
                ...state,
                isLoadingDeleteLoa: true,
            }
        case INSTRUMENT_ACTION_TYPES.DELETE_CLIENT_LOA_SUCCESS:
            return {
                ...state,
                isLoadingDeleteLoa: false,
                deleteLoa: payload
            }
        case INSTRUMENT_ACTION_TYPES.DELETE_CLIENT_LOA_FAILURE:
            return {
                ...state,
                isLoadingDeleteLoa: false,
                deleteLoaErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.CLEAR_CLIENT_LOA:
            return {
                ...state,
                isLoadingLoaDoc: false,
                loaDocDetails: [],
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_BULK_EMAIL_ERROR:
            return {
                ...state,
                bulkEmail: [],
                bulkEmailErrorMessage: null,
                bulkEmailErrorItems: []
            }

        case INSTRUMENT_ACTION_TYPES.CLEAR_DOCUMENT_UPLOAD_ERROR:
            return {
                ...state,
                documentUploadErrorMessage: null
            }

        case INSTRUMENT_ACTION_TYPES.CLEAR_DELETE_LOA_ERROR:
            return {
                ...state,
                deleteLoa: []
            }



        case INSTRUMENT_ACTION_TYPES.SAVE_SNOOZE_DATA_START:
            return {
                ...state,
                isSaveSnoozeDataFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.SAVE_SNOOZE_DATA_SUCCESS:
            return {
                ...state,
                isSaveSnoozeDataFetching: false,
                saveSnoozeSuccessMsg: payload.message
            }
        case INSTRUMENT_ACTION_TYPES.SAVE_SNOOZE_DATA_FAILURE:
            return {
                ...state,
                isSaveSnoozeDataFetching: false,
                saveSnoozeDataErrorMsg: payload.message
            }
        case INSTRUMENT_ACTION_TYPES.REMOVE_SAVE_SNOOZE_DATA_SUCCESS_MSG:
            return {
                ...state,
                saveSnoozeSuccessMsg: null
            }
        case INSTRUMENT_ACTION_TYPES.REMOVE_SAVE_SNOOZE_DATA_ERROR_MSG:
            return {
                ...state,
                saveSnoozeDataErrorMsg: null
            }

        case INSTRUMENT_ACTION_TYPES.FETCH_REMINDER_LIST_START:
            return {
                ...state,
                isReminderListFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_REMINDER_LIST_SUCCESS:
            return {
                ...state,
                isReminderListFetching: false,
                reminderListData: payload.data,
                reminderListCount: payload?.count ? payload.count : 0,
                reminderListOffset: payload?.offset ? payload.offset : 0,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_REMINDER_LIST_FAILURE:
            return {
                ...state,
                isReminderListFetching: false,
                reminderListErrorMsg: payload.message
            }


        case INSTRUMENT_ACTION_TYPES.FETCH_FILTERED_REMINDER_DATA_START:
            return {
                ...state,
                isFilteredReminderDataFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_FILTERED_REMINDER_DATA_SUCCESS:
            return {
                ...state,
                isFilteredReminderDataFetching: false,
                reminderListData: payload.data,
                reminderListCount: payload?.count ? payload.count : 0,
                reminderListOffset: payload?.offset ? payload.offset : 0,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_FILTERED_REMINDER_DATA_FAILURE:
            return {
                ...state,
                isFilteredReminderDataFetching: false,
                filteredReminderErrorMsg: payload.message
            }


        case INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_END_DATE_START:
            return {
                ...state,
                isRemoveReminderEndDateFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_END_DATE_SUCCESS:
            return {
                ...state,
                isRemoveReminderEndDateFetching: false,
                newReminderData: payload.data,
                removeReminderEndDateSuccessMsg: payload.message
            }
        case INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_END_DATE_FAILURE:
            return {
                ...state,
                isRemoveReminderEndDateFetching: false,
                removeReminderEndDateErrorMsg: payload.message,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_NEW_REMINDER_DATA:
            return {
                ...state,
                newReminderData: null
            }
        case INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_ERROR_MSG:
            return {
                ...state,
                removeReminderEndDateErrorMsg: null
            }
        case INSTRUMENT_ACTION_TYPES.REMOVE_REMINDER_SUCCESS_MSG:
            return {
                ...state,
                removeReminderEndDateSuccessMsg: null
            }



        case INSTRUMENT_ACTION_TYPES.FETCH_PENDING_WITH_DATA_START:
            return {
                ...state,
                fetchPendingWithData: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_PENDING_WITH_DATA_SUCCESS:
            return {
                ...state,
                fetchPendingWithData: false,
                fetchPendingWithSuccessMsg: payload.message,
                pendingWithData: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_PENDING_WITH_DATA_FAILURE:
            return {
                ...state,
                fetchPendingWithData: false,
                fetchPendingWithErrorMsg: payload.message
            }

        case INSTRUMENT_ACTION_TYPES.CLEAR_DOCUMENT_UPLOAD_MESSAGE:
            return {
                ...state,
                documentUploadResponse: null
            }
        case INSTRUMENT_ACTION_TYPES.POST_BULK_REMINDER_START:
            return {
                ...state,
                isLoadingBulkReminderSend: true,
            }
        case INSTRUMENT_ACTION_TYPES.POST_BULK_REMINDER_SUCCESS:
            return {
                ...state,
                isLoadingBulkReminderSend: false,
                bulkReminder: payload,
            }
        case INSTRUMENT_ACTION_TYPES.POST_BULK_REMINDER_FAILURE:
            return {
                ...state,
                isLoadingBulkReminderSend: false,
                bulkReminder: payload,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_BULK_REMINDER_ERROR:
            return {
                ...state,
                bulkReminder: []
            }

        case INSTRUMENT_ACTION_TYPES.PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START:
            return {
                ...state,
                isPickingPossibleProductsLoading: true,
            }
        case INSTRUMENT_ACTION_TYPES.PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS:
            return {
                ...state,
                isPickingPossibleProductsLoading: false,
                pickingPossibleProductsSuccessMsg: payload.message,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS_MSG:
            return {
                ...state,
                pickingPossibleProductsSuccessMsg: null,
            }

        case INSTRUMENT_ACTION_TYPES.PICK_POSSIBLE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE:
            return {
                ...state,
                isPickingPossibleProductsLoading: false,
                pickingPossibleProductsErrorMsg: payload.message
            }


        case INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_START:
            return {
                ...state,
                isPossibleRecommendedItemsFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_SUCCESS:
            return {
                ...state,
                isPossibleRecommendedItemsFetching: false,
                possibleRecommendedItems: formattedRecommendedItems(payload.data)
            }
        case INSTRUMENT_ACTION_TYPES.FETCH_POSSIBLE_RECOMMENDED_PRODUCTS_FAILURE:
            return {
                ...state,
                isPossibleRecommendedItemsFetching: false,
                possibleRecommendedItemsErrorMsg: payload.message
            }

        case INSTRUMENT_ACTION_TYPES.SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_START:
            return {
                ...state,
                saveProductsForDraftRecommendationFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_SUCCESS:
            return {
                ...state,
                saveProductsForDraftRecommendationFetching: false,
                saveProductsForDraftRecommendationSuccessMsg: payload.message
            }
        case INSTRUMENT_ACTION_TYPES.SAVE_PRODUCTS_FOR_DRAFT_RECOMMENDATION_FAILURE:
            return {
                ...state,
                saveProductsForDraftRecommendationFetching: false,
                saveProductsForDraftRecommendationErrorMsg: payload.message
            }


        case INSTRUMENT_ACTION_TYPES.GET_PRODUCT_COMPARISON_DATA_START:
            return {
                ...state,
                isProductComparisonDataFetching: true,
            }
        case INSTRUMENT_ACTION_TYPES.GET_PRODUCT_COMPARISON_DATA_SUCCESS:
            return {
                ...state,
                isProductComparisonDataFetching: false,
                productComparisonData: payload.data
            }
        case INSTRUMENT_ACTION_TYPES.GET_PRODUCT_COMPARISON_DATA_FAILURE:
            return {
                ...state,
                isProductComparisonDataFetching: false,
            }

        case INSTRUMENT_ACTION_TYPES.POST_EXTRACTED_DATA_START:
            return {
                ...state,
                isLoadingUpdateExtractedData: true,
            }
        case INSTRUMENT_ACTION_TYPES.POST_EXTRACTED_DATA_SUCCESS:
            return {
                ...state,
                isLoadingUpdateExtractedData: false,
            }
        case INSTRUMENT_ACTION_TYPES.POST_EXTRACTED_DATA_FAILURE:
            return {
                ...state,
                isLoadingUpdateExtractedData: false,
                UpdateExtractedDataErrorMsg: payload.message
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_UPDATE_EXTRACTION_ERROR:
            return {
                ...state,
                UpdateExtractedDataErrorMsg: null
            }

        case INSTRUMENT_ACTION_TYPES.DELETE_PRODUCT_START:
            return {
                ...state,
                isLoadingProductDelete: true,
            }
        case INSTRUMENT_ACTION_TYPES.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoadingProductDelete: false,
                productDeleteMessage: payload.message
            }
        case INSTRUMENT_ACTION_TYPES.DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoadingProductDelete: false,
                productDeleteErrorMessage: payload.message,
            }

        case INSTRUMENT_ACTION_TYPES.CLEAR_INSTRUMENT_SUMMARY_LIST:
            return {
                ...state,
                isPossibleRecommendedItemsFetching: false,
                possibleRecommendedItems: null,
            }

        case INSTRUMENT_ACTION_TYPES.CLEAR_CLIENT_INSTRUMENTS:
            return {
                ...state,
                clientInstruments: [],
                clientInstrumentCount: 0,
            }
        case INSTRUMENT_ACTION_TYPES.CLEAR_PRODUCT_COMPARISON_DATA:  
            return {
                ...state,
                productComparisonData: null
            }

        default:
            return state;
    }

}


export default instrumentReducer;