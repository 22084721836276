import React from 'react';
import { useHistory } from "react-router-dom";
import './TitleWithViewMore.scss';

const   TitleWithViewMore = ({
    title,
    viewMore,
    viewMoreLink,
    params = {}
}) => {
    const history = useHistory();
    return (
        <>
            <h5 style={{flexDirection:"row"}} className="bottomSectionHeading bottomSectionHeading--mod addedByMeHeading ">
                {title}
                { viewMore ?
                    <span className="viewMore" onClick={() => history.push({
                        pathname: viewMoreLink,
                        state: params
                    })}>View More</span>
                    : null
                }
            </h5>
        </>
    )
}

export default TitleWithViewMore;