import { REFERRAL_MNGMT_ACTION_TYPES } from '../redux/referralManagement/referralManagement.types';
import { axiosPost, axiosGet, handleOnError, handleOnSuccess,handleOnSuccessParallel, handleActionStart, axiosPatch ,axiosGett} from '../utils/api';
import { fetchPost } from './FeeMatchingService';


/* 
    Service to list all clients
*/
export const listClientsService = async (dispatch, parameters = {}) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.LIST_CLIENTS_START));
        const clientsData = await axiosGet('clients/', {
            params: parameters
        });

        dispatch(handleOnSuccess(clientsData, REFERRAL_MNGMT_ACTION_TYPES.LIST_CLIENTS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.LIST_CLIENTS_FAILURE))
    }
}

/*
    Service to add client
*/
export const addClientsService = async (dispatch, clientsData) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.ADD_CLIENT_START));
        const addClientStatus = await axiosPost('clients/', clientsData);
        dispatch(handleOnSuccess(addClientStatus, REFERRAL_MNGMT_ACTION_TYPES.ADD_CLIENT_SUCCESS));
        
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.ADD_CLIENT_FAILURE));
        
    }
}
/*
    Service to add parallel client
*/
export const addParallelClientsService = async (dispatch, clientsData) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.ADD_PARALLEL_CLIENT_START));
        const addClientStatus = await axiosPost('add-client-parallelly/', clientsData);
        dispatch(handleOnSuccessParallel(addClientStatus, REFERRAL_MNGMT_ACTION_TYPES.ADD_PARALLEL_CLIENT_SUCCESS));
        
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.ADD_PARALLEL_CLIENT_FAILURE));
        
    }
}


/* 
    Service for fetching companies list
    will receive : company name, id
*/
export const fetchCompaniesService = async (dispatch, searchString, isAdvisorCompany=false) => {
    let url = 'companies/';
    if(isAdvisorCompany){
        url = url + '?is_client_company=false';
    }
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_COMPANIES_START));
        const companyListData = await axiosGet(url, {
            params: {
                search: searchString
            }
        });
        dispatch(handleOnSuccess(companyListData, REFERRAL_MNGMT_ACTION_TYPES.FETCH_COMPANIES_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_COMPANIES_FAILURE));
    }
}

export const fetchClientType = async (dispatch) => {
    let url = 'clienttype/';
   try
   {
       dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_TYPE_START));
       const clientTypeData = await axiosGett(url);
       dispatch(handleOnSuccess(clientTypeData, REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_TYPE_SUCCESS));
   }
   catch (err)
   {
       dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_TYPE_FAILURE));
   }
}

/*
    Service to fetch referrer name autosuggest
*/
export const fetchReferrerNameService = async (dispatch, searchString) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_REFERRER_NAME_START));
        const referrerNameData = await axiosGet('users/', {
            params: {
                client_name: searchString,
                is_staff: 'False'  // this is not boolean and is string
            }
        });
        dispatch(handleOnSuccess(referrerNameData, REFERRAL_MNGMT_ACTION_TYPES.FETCH_REFERRER_NAME_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_REFERRER_NAME_FAILURE));
    }
}


/*
    Service for editing client details
*/
export const editClientService = async (dispatch, newClientData, clientId) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.EDIT_CLIENT_START));
        const editStatus = await axiosPatch(`clients/${clientId}/`, newClientData);
        dispatch(handleOnSuccess(editStatus, REFERRAL_MNGMT_ACTION_TYPES.EDIT_CLIENT_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.EDIT_CLIENT_FAILURE));
    }
}


/*
    Service for fetching client details by providing id
*/
export const fetchClientDetailsWithIdService = async (dispatch, clientId) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_DETAILS_WITH_ID_START));
        const clientData = await axiosGet(`clients/${clientId}/`);
        dispatch(handleOnSuccess(clientData, REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_DETAILS_WITH_ID_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_CLIENT_DETAILS_WITH_ID_FAILURE));
    }
}


/*
    Service to fetch recently added referrals or clients
*/
export const fetchRecentlyAddedClientsService = async (dispatch) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_CLIENTS_START));
        const data = await axiosGet(`clients/`, {
            params: {
                recently_added_client_list: true,
                limit: 10
            }
        });
        dispatch(handleOnSuccess(data, REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_CLIENTS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_CLIENTS_FAILURE));
    }
}


/*
    Service to fetch recently added clients done by advisor
*/
export const fetchRecentlyAddedByAdvisorService = async (dispatch) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_BY_ADVISOR_START));
        const data = await axiosGet(`clients/`, {
            params: {
                client_list_added_by_me: true,
                limit: 10
            }
        });
        dispatch(handleOnSuccess(data, REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_BY_ADVISOR_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_RECENTLY_ADDED_BY_ADVISOR_FAILURE));
    }
}



/*
    Service to search clients based on params given ( search form with inputs )
*/
export const searchClientsService = async (dispatch, paramsData) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.SEARCH_CLIENTS_START));
        const data = await axiosGet('clients/', {
            params: {
                ...paramsData
            }
        });
        dispatch(handleOnSuccess(data, REFERRAL_MNGMT_ACTION_TYPES.SEARCH_CLIENTS_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.SEARCH_CLIENTS_FAILURE));
    }
}

/*
    Service to fetch all advisors
*/
export const fetchAdvisorsService = async (dispatch) => {
    try
    {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.FETCH_ADVISOR_START));
        const data = await axiosGet('users/', {
            params: {
                is_staff: 'True',
                type:'Advisor',
                company : 'True'
            }
        });
        dispatch(handleOnSuccess(data, REFERRAL_MNGMT_ACTION_TYPES.FETCH_ADVISOR_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.FETCH_ADVISOR_FAILURE));
    }
}

/*
    Service to send bulk client data
*/
export const bulkAddClientService = async (dispatch, clientsData,userToken,handleRedirection) => {
   
    const controller = new AbortController();
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_START));
        
   fetchPost(userToken,'clients/', clientsData,true,{"content-type": "application/json"},{ signal: controller.signal}).then((addClientStatus)=>{

            dispatch(handleOnSuccess({data:{status:addClientStatus.status,data:[...addClientStatus.data],message:addClientStatus.message},status:addClientStatus.status_code}, REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_SUCCESS));
            //  handleRedirection()
        }).catch((err)=>{

        if(err.name === 'AbortError'){
            setTimeout(()=>{
                handleRedirection()
        dispatch(handleOnSuccess({data:{status:true,data:[],message:"Clients added successfully"},status:true}, REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_SUCCESS));
              },60000)
             }
        else{
        dispatch(handleOnError(err.response, REFERRAL_MNGMT_ACTION_TYPES.BULK_ADD_CLIENT_FAILURE));
     }
        })
        
    setTimeout(() => controller.abort(),59000)
}



/*
    Service to send bulk emails to referrals
*/
export const sendBulkReferralEmailsService = async (dispatch, selectedClientDetails) => {
    try {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.SEND_BULK_REFERRAL_EMAIL_START));
        const data = await axiosPost('mail/1/Bulk_email/?type=welcome_mail',{
            client_list : selectedClientDetails
        });
        dispatch(handleOnSuccess(data,REFERRAL_MNGMT_ACTION_TYPES.SEND_BULK_REFERRAL_EMAIL_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,REFERRAL_MNGMT_ACTION_TYPES.SEND_BULK_REFERRAL_EMAIL_FAILURE));
    }
}




/*
    Service to get client profile summery
    
*/
export const getClientDataSummeryService = async (dispatch) => {
    try {
        dispatch(handleActionStart(REFERRAL_MNGMT_ACTION_TYPES.GET_CLIENT_PROFILE_SUMMERY_START));
        const data = await axiosGet('profile-details/');
        dispatch(handleOnSuccess(data, REFERRAL_MNGMT_ACTION_TYPES.GET_CLIENT_PROFILE_SUMMERY_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,REFERRAL_MNGMT_ACTION_TYPES.GET_CLIENT_PROFILE_SUMMERY_FAILURE));
    }
}