import {
    fetchSurveyForm,
    fetchPartnerSurveyForm,
    postSurveyForm,
    updateSurveyForm,
    fetchSearchableList,
    getSurveyStatus,
    saveSoundRecordingService,
    getEmailSearch,
    getPreviusAmount,
    getSoundRecordingService,
    deleteSoundRecordingService,
    fetchCountryList,
    surveyButtonClickService
    } from '../../services/surveyFormService';
import { SURVEY_ACTION_TYPES } from './surveyForms.types';
import { API_HOSTNAME } from '../../utils/constants';



export const getPartnerSurveyForms = (clientId) => {
    return dispatch => {
        fetchPartnerSurveyForm(dispatch, clientId)
    }
}

export const getSurveyForms = (clientId) => {
    return dispatch => {
        fetchSurveyForm(dispatch, clientId)
    }
}
export const modifySurveyForms = (modifiedSurveyForm) => {
    return dispatch => {
        updateSurveyForm(dispatch, modifiedSurveyForm)
    }
}
export const postSurveyForms = (clientId, formData, currentCategory) => {
    return dispatch => {
        postSurveyForm(dispatch, clientId, formData, currentCategory)
    }
}
export const getSearchableList = (fieldItem, value, accessToken='', isOffline) => {

    let url = fieldItem.search_api_url;
    if(url && url !=="" && !isOffline){
        let searchKeyReplacedURL = url.replace("{{search_key}}", value);
        let apiUrl = searchKeyReplacedURL.replace("{{domain}}/", API_HOSTNAME);
        var headers = accessToken ? {Authtoken: 'Token ' + accessToken} : '';
        // console.log("apiUrl: ",API_HOSTNAME, apiUrl)
        return dispatch => {
            fetchSearchableList(dispatch, apiUrl, headers, {"fieldName" : fieldItem.field_name})
        }
    }
    return dispatch => {
        console.log("No api url found")
    }
}
export const getEmailSearchableList = (nameInput) => {
    return dispatch => {
        getEmailSearch(dispatch,nameInput)
    }
}

export const getPreviusAmountDetails = (instrument_id) => {
    return dispatch => {
        getPreviusAmount(dispatch,instrument_id)
    }
}

export const getSurveyStatusData = (clientId) => {
    return dispatch => {
        getSurveyStatus(dispatch, clientId)
    }
}

export const saveSoundRecordingAsync = (soundDetails) => {
    return dispatch => {
        saveSoundRecordingService(dispatch, soundDetails)
    }
}


export const getSoundRecordingAsync = (soundDetails) => {
    return dispatch => {
        getSoundRecordingService(dispatch, soundDetails)
    }
}
export const saveDocConversionSurveyForm = (docArray) => {
    return dispatch => dispatch({
        type: SURVEY_ACTION_TYPES.FETCH_DOC_CONVERSION_SURVEY_FORM,
        payload: {data:docArray},
})}
export const saveDocConversionSurveyFormStart = () => {
    return ({
        type: SURVEY_ACTION_TYPES.FETCH_DOC_CONVERSION_SURVEY_FORM_START,
})}

export const deleteSoundRecordingAsync = (recordingId) => {
    return dispatch => {
        deleteSoundRecordingService(dispatch, recordingId)
    }
}

export const clearRecordingErrorMessage = () => ({
    type: SURVEY_ACTION_TYPES.CLEAR_SOUND_RECORDING_MESSAGES
})

export const clearSurveyData = () => ({
    type: SURVEY_ACTION_TYPES.CLEAR_SURVEY_DATA
})
export const clearDocConversionSurveyForm = () => ({
    type: SURVEY_ACTION_TYPES.CLEAR_FETCH_DOC_CONVERSION_SURVEY_FORM
})
export const clearPartnerSurveyData=()=>({
    type: SURVEY_ACTION_TYPES.CLEAR_PARTNER_SURVEY_DATA
})
export const getCountryList = (searchKey="") => {
    return dispatch => {
        fetchCountryList(dispatch, searchKey)
    }
}

export const surveyButtonClick = (fieldItem, clientId, accessToken='', isOffline) => {

    let url = fieldItem.search_api_url;
    if(url && url !=="" && !isOffline){
        let clientIdReplacedURL = url.replace("{{client_id}}", clientId);
        let apiUrl = clientIdReplacedURL.replace("{{domain}}", API_HOSTNAME);
        var headers = accessToken ? {Authtoken: 'Token ' + accessToken} : '';
        return dispatch => {
            surveyButtonClickService(dispatch, apiUrl, headers, {"fieldName" : fieldItem.field_name})
        }
    }
    return dispatch => {
        console.log("No api url found")
    }
}

export const clearButtonClickMessage = () => ({
    type: SURVEY_ACTION_TYPES.CLEAR_BUTTON_CLICK_MESSAGES
})