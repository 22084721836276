import React, { forwardRef } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AlarmIcon from '@material-ui/icons/Alarm';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { NavLink , Link } from 'react-router-dom';
import './Button.scss';
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles((theme) => createStyles({
    defaultButtonStyles : {
        width: '100%'
    },
    secondaryButton : {
        color : theme.palette.common.white,
        background : theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#FFF'
        }
    },
    primaryButton : {
        color : theme.palette.common.white,
        background : theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#FFF'
        }
    },
}));




const Button = forwardRef(({
    buttonType = 'button',
    variant = "contained",
    type,
    bgColor,
    linkTo,
    children,
    disableElevation,
    disabled=false,
    onClick,
    size = "medium",
    icon: ButtonIcon,
    color = 'white',
    customStyle={ width : 'auto'},
    width,
    className,
    isLoading,
    uppercase,
    to=null,
    pageType,
    linkRequired = true,
    disableRipple=false,
    disableHoverEffect=false,
    disableFocusRipple=false,
    ...otherProps
}, ref) => {



    const classes = useStyles();
    // Note:: If custom button needed send styles as object
    // deciding what class to apply for button ( not done for icon button)
    let buttonClass;
    if(bgColor === 'secondary')
    {
        buttonClass = classes.secondaryButton;
    }
    else if(bgColor === 'primary')
    {
        buttonClass = classes.primaryButton;
    }  

    // changing color for button navlink 
    const buttonNavLinkStyle = {
        color : color
    }


    const customStyles = {
        ...customStyle,
        width : width,
        textTransform : uppercase ? 'uppercase' : 'none'
    }


    if (buttonType === 'button')
    {
        return (
            
            <MuiButton
                variant={variant}
                type={type}
                disableElevation={disableElevation}
                disabled={disabled}
                size={size}
                className={`
                    customButton
                    ${classes.defaultButtonStyles} 
                    ${buttonClass}
                    ${className}
                `}
                onClick={()=>{
                    if(onClick)
                    {
                        onClick()
                    }
                }}
                style={customStyles}
                {...otherProps}
                ref={ref}
            >
                {
                    isLoading &&  <CircularProgress className="loader"/>
                }
               
                {children}
            </MuiButton>
        )
    }
    else if (buttonType === 'link')
    {
        return (
            <Link 
                className={`navLinkButton ${className}`}
                style={buttonNavLinkStyle}
                {...{ ...( linkTo ? { to : linkTo } : { to : to } )}} // if linkTo is passed set linkTo as prop else set to as prop 
                {...otherProps}
                ref={ref}
            >
                {children}
            </Link>
        )
    }
    else if (buttonType === 'iconButton')
    {
        return (
            <IconButton
                onClick={onClick}
                disabled={disabled}
                size={size}
                type={type}
                className={`iconButton ${className} ${disableHoverEffect ? 'disableHoverEffect' : ''}`}
                disableRipple={disableRipple}
                disableFocusRipple={disableFocusRipple}
                {...otherProps}
                ref={ref}
            >

                {/* ButtonIcon is a custom renamed component,AlarmIcon is set as default icon */}
                {
                    ButtonIcon
                        ?
                        <ButtonIcon />
                        :
                        <AlarmIcon />
                }
            </IconButton>
        )
    }
    else if(buttonType === 'navlink')
    {
        if(linkRequired == false){
            return (
                <a className={`navLinkButton ${className ? className : ''}`} style={buttonNavLinkStyle}>{children}</a>
            )
        }else{
            return (
                <NavLink 
                    activeClassName="active" 
                    className={`navLinkButton ${className ? className : ''}`}  
                    to={linkTo} 
                    style={buttonNavLinkStyle}
                    {...otherProps}
                    ref={ref}
                >
                    {children}
                </NavLink>
            )
        }
    }
    
        
})

export default Button;
