export const SURVEY_ACTION_TYPES = {
    FETCH_SURVEYFORM_START : 'FETCH_SURVEYFORM_START',
    FETCH_SURVEYFORM_SUCCESS : 'FETCH_SURVEYFORM_SUCCESS',
    FETCH_SURVEYFORM_FAILURE : 'FETCH_SURVEYFORM_FAILURE',

    FETCH_EMAIL_START: 'FETCH_EMAIL_START',
    FETCH_EMAIL_SUCCESS: 'FETCH_EMAIL_SUCCESS',
    FETCH_EMAIL_FAILURE: 'FETCH_EMAIL_FAILURE',

    FETCH_PREVIUS_AMOUNT_START: 'FETCH_PREVIUS_AMOUNT_START',
    FETCH_PREVIUS_AMOUNT_SUCCESS: 'FETCH_PREVIUS_AMOUNT_SUCCESS',
    FETCH_PREVIUS_AMOUNT_FAILURE: 'FETCH_PREVIUS_AMOUNT_FAILURE',
     
    CLEAR_FETCH_DOC_CONVERSION_SURVEY_FORM:"CLEAR_FETCH_DOC_CONVERSION_SURVEY_FORM",
    FETCH_DOC_CONVERSION_SURVEY_FORM:"FETCH_DOC_CONVERSION_SURVEY_FORM",
    FETCH_DOC_CONVERSION_SURVEY_FORM_START:"FETCH_DOC_CONVERSION_SURVEY_FORM_START",
    
    FETCH_PARTNER_SURVEYFORM_START : 'FETCH_PARTNER_SURVEYFORM_START',
    FETCH_PARTNER_SURVEYFORM_SUCCESS : 'FETCH_PARTNER_SURVEYFORM_SUCCESS',
    FETCH_PARTNER_SURVEYFORM_FAILURE : 'FETCH_PARTNER_SURVEYFORM_FAILURE',
    
    POST_SURVEYFORM_START : 'POST_SURVEYFORM_START',
    POST_SURVEYFORM_SUCCESS : 'POST_SURVEYFORM_SUCCESS',
    POST_SURVEYFORM_FAILURE : 'POST_SURVEYFORM_FAILURE',

    MODIFY_SURVEYFORM_START: 'MODIFY_SURVEYFORM_START',
    MODIFY_SURVEYFORM_SUCCESS: 'MODIFY_SURVEYFORM_SUCCESS',
    MODIFY_SURVEYFORM_FAILURE: 'MODIFY_SURVEYFORM_FAILURE',

    GET_SELECTABLE_START: 'GET_SELECTABLE_START',
    GET_SELECTABLE_SUCCESS: 'GET_SELECTABLE_SUCCESS',
    GET_SELECTABLE_FAILURE: 'GET_SELECTABLE_FAILURE',

    FETCH_SURVEYSTATUS_START: 'FETCH_SURVEYSTATUS_START',
    FETCH_SURVEYSTATUS_SUCCESS: 'FETCH_SURVEYSTATUS_SUCCESS',
    FETCH_SURVEYSTATUS_FAILURE: 'FETCH_SURVEYSTATUS_FAILURE',


    SAVE_SOUND_RECORDING_START : 'SAVE_SOUND_RECORDING_START',
    SAVE_SOUND_RECORDING_SUCCESS : 'SAVE_SOUND_RECORDING_SUCCESS',
    SAVE_SOUND_RECORDING_FAILURE : 'SAVE_SOUND_RECORDING_FAILURE',


    GET_SOUND_RECORDING_START : 'GET_SOUND_RECORDING_START',
    GET_SOUND_RECORDING_SUCCESS : 'GET_SOUND_RECORDING_SUCCESS',
    GET_SOUND_RECORDING_FAILURE : 'GET_SOUND_RECORDING_FAILURE',

    DELETE_SOUND_RECORDING_START: "DELETE_SOUND_RECORDING_START",
    DELETE_SOUND_RECORDING_SUCCESS: "DELETE_SOUND_RECORDING_SUCCESS",
    DELETE_SOUND_RECORDING_FAILURE: "DELETE_SOUND_RECORDING_FAILURE",

    CLEAR_SOUND_RECORDING_MESSAGES: "CLEAR_SOUND_RECORDING_MESSAGES",
    CLEAR_SURVEY_DATA : "CLEAR_SURVEY_DATA",
    CLEAR_PARTNER_SURVEY_DATA : "CLEAR_PARTNER_SURVEY_DATA",

    GET_COUNTRY_LIST_START : "GET_COUNTRY_LIST_START",
    GET_COUNTRY_LIST_SUCCESS : "GET_COUNTRY_LIST_SUCCESS",
    GET_COUNTRY_LIST_FAILURE : "GET_COUNTRY_LIST_FAILURE",

    POST_SURVEY_BUTTON_CLICK_START: "POST_SURVEY_BUTTON_CLICK_START",
    POST_SURVEY_BUTTON_CLICK_SUCCESS: "POST_SURVEY_BUTTON_CLICK_SUCCESS",
    POST_SURVEY_BUTTON_CLICK_FAILURE: "POST_SURVEY_BUTTON_CLICK_FAILURE",

    CLEAR_BUTTON_CLICK_MESSAGES: "CLEAR_BUTTON_CLICK_MESSAGES",

}