import { DRAFT_ACTION_TYPES } from "../redux/draft/draft.types";
import {
  DRAFT_REC_SUMMARY_GET,
  DRAFT_FEE_DETAILS_GET,
  DRAFT_FUND_DETAILS_GET,
  DRAFT_REASON_DETAILS_GET,
  DRAFT_NOTES_GET,
  DRAFT_COMMENTS_GET,
  DRAFT_COMMENTS_PUT,
  DRAFT_INSTRUMENT_FEE_GET,
  DRAFT_INSTRUMENT_FEE_PUT,
  DRAFT_INSTRUMENT_FEE_POST,
  DRAFT_CLONE_RECOMMENDATION,
  DRAFT_DELETE_RECOMMENDATION,
  DRAFT_SR_UPLOAD,
  DRAFT_SR_GET,
  DRAFT_CHECKLIST_GET,
  DRAFT_CHECKLIST_PUT,
  DRAFT_SR_GENERATE,
  DRAFT_DOCUMENTS_GET,
  CONFIRM_CLIENT_POST,
  COMPLIANCE_CHECKLIST_GET,
  ADMINISTRATOR_CHECKLIST_GET,
  COMPLIANCE_CHECKLIST_PUT,
  ADMINISTRATOR_CHECKLIST_PUT,
  DRAFT_NOTES_ANSWER_PUT,
  SUB_TOTAL_ANNUAL_GET,
  DRAFT_CLIENT_TASK_LIST_RECOMMEND_GET,
} from "../utils/endPoint";
import {
  axiosPost,
  axiosGet,
  axiosPut,
  axiosDelete,
  axiosPatch,
  handleOnError,
  handleOnSuccess,
  handleActionStart,
  axiosGett,
} from "../utils/api";
import axios from "axios";
// import { CHECK_LIST_STATIC_NAMES } from '../utils/constants';

export const fetchRecommendationList = async (dispatch, clientId, taskId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_RECOMMENDATION_START));
    const data = await axiosGet(DRAFT_REC_SUMMARY_GET(clientId, taskId));
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_RECOMMENDATION_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        DRAFT_ACTION_TYPES.FETCH_RECOMMENDATION_FAILURE
      )
    );
  }
};
export const fetchClientTaskInstrumentsRecomendedList = async (dispatch, taskId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_CLIENT_TASK_RECOMMENDATION_START));
    const data = await axiosGet(DRAFT_CLIENT_TASK_LIST_RECOMMEND_GET(taskId));
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_CLIENT_TASK_RECOMMENDATION_SUCCESS)
    );
  } catch (err) {
    dispatch(
      handleOnError(
        err.response,
        DRAFT_ACTION_TYPES.FETCH_CLIENT_TASK_RECOMMENDATION_FAILURE
      )
    );
  }
};
export const fetchSubTotalAnnual = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_SUB_TOTAL_ANNUAL_START));
    let extraConfig= {params:{
      "client_id": clientId
    }}
    const data = await axiosGett(SUB_TOTAL_ANNUAL_GET(),extraConfig);
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_SUB_TOTAL_ANNUAL_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        DRAFT_ACTION_TYPES.FETCH_SUB_TOTAL_ANNUAL_FAILURE
      )
    );
  }
};
export const fetchFeeDetails = async (dispatch) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_FEE_START));
    const data = await axiosGet(DRAFT_FEE_DETAILS_GET);
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_FEE_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(handleOnError(err.response, DRAFT_ACTION_TYPES.FETCH_FEE_FAILURE));
  }
};
export const fetchFundDetails = async (dispatch,Product_Type) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_FUND_START));
    const data = await axiosGet(DRAFT_FUND_DETAILS_GET(Product_Type));
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_FUND_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.FETCH_FUND_FAILURE)
    );
  }
};
export const fetchReasonDetails = async (
  dispatch,
  functionIds,
  productType
) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_REASON_START));
    const data = await axiosGet(
      DRAFT_REASON_DETAILS_GET(functionIds, productType)
    );
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_REASON_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.FETCH_REASON_FAILURE)
    );
  }
};
export const fetchDraftNotes = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_NOTES_START));
    const data = await axiosGet(DRAFT_NOTES_GET(clientId));
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_NOTES_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.FETCH_NOTES_FAILURE)
    );
  }
};
export const fetchDraftComments = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.FETCH_COMMENT_START));
    const data = await axiosGet(DRAFT_COMMENTS_GET(clientId));
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.FETCH_COMMENT_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.FETCH_COMMENT_FAILURE)
    );
  }
};
export const putDraftComments = async (dispatch, draftId, postData) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.PUT_COMMENT_START));
    const data = await axiosPut(DRAFT_COMMENTS_PUT(draftId), postData);
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.PUT_COMMENT_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.PUT_COMMENT_FAILURE)
    );
  }
};
export const fetchInstrumentFee = async (dispatch, instrumentId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.GET_INSTRUMENT_FEE_START));
    const data = await axiosGet(DRAFT_INSTRUMENT_FEE_GET(instrumentId));
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.GET_INSTRUMENT_FEE_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.GET_INSTRUMENT_FEE_FAILURE)
    );
  }
};
export const putInstrumentFee = async (dispatch, instrumentId, postData) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.PUT_INSTRUMENT_FEE_START));
    const data = await axiosPut(
      DRAFT_INSTRUMENT_FEE_PUT(instrumentId),
      postData
    );
    if(data.data.status){
      await axiosPost(DRAFT_INSTRUMENT_FEE_POST(instrumentId))
    }
   
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.PUT_INSTRUMENT_FEE_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.PUT_INSTRUMENT_FEE_FAILURE)
    );
  }
};
export const cloneInstrument = async (dispatch, postData,instrumentId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.CLONE_INSTRUMENT_START));
    const data = await axiosPost(DRAFT_CLONE_RECOMMENDATION, postData);
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.CLONE_INSTRUMENT_SUCCESS)
    );
    // if(data.data.status){
    //   const updateInstrumentData={
    //     add_new_recommendation: "True",
    //     recommended_product_list: [data.data.id],
    // }
    // await axiosPut(
    //     DRAFT_INSTRUMENT_FEE_PUT(instrumentId),
    //     updateInstrumentData
    //   );

    // }
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.CLONE_INSTRUMENT_FAILURE)
    );
  }
};
export const deleteInstrument = async (dispatch, instrumentId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.DELETE_INSTRUMENT_START));
    const data = await axiosDelete(DRAFT_DELETE_RECOMMENDATION(instrumentId));
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.DELETE_INSTRUMENT_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.DELETE_INSTRUMENT_FAILURE)
    );
  }
};
export const uploadSRDocument = async (
  dispatch,
  clientDoc,
  fromDraftDocs,
  isTaskDoc
) => {
  let uploadResponse = "";
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.SR_UPLOAD_START));
    uploadResponse = await axiosPost(
      DRAFT_SR_UPLOAD(fromDraftDocs, isTaskDoc),
      clientDoc
    );
    dispatch(
      handleOnSuccess(uploadResponse, DRAFT_ACTION_TYPES.SR_UPLOAD_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(handleOnError(err.response, DRAFT_ACTION_TYPES.SR_UPLOAD_FAILURE));
  }
};
export const fetchSR = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.GET_SR_START));
    const data = await axiosGet(DRAFT_SR_GET(clientId));
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.GET_SR_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(handleOnError(err.response, DRAFT_ACTION_TYPES.GET_SR_FAILURE));
  }
};
// export const fetchChecklist = async (dispatch, clientId, useFor, taskId) => {
//     try {
// const useForLowercased = useFor.toLowerCase();
// dispatch(handleActionStart(DRAFT_ACTION_TYPES.GET_CHECKLIST_START));
// let data;
// if(useForLowercased === CHECK_LIST_STATIC_NAMES.advisorchecklist)
// {
//     data = await axiosGet(DRAFT_CHECKLIST_GET(clientId, taskId));
// }
// else if(useForLowercased === CHECK_LIST_STATIC_NAMES.complaincechecklist)
// {
//     data = await axiosGet(COMPLIANCE_CHECKLIST_GET(clientId, taskId));
// }
// else if(useForLowercased === CHECK_LIST_STATIC_NAMES.administratorchecklist)
// {
//     data = await axiosGet(ADMINISTRATOR_CHECKLIST_GET(clientId, taskId));
// }
// dispatch(handleOnSuccess(data,DRAFT_ACTION_TYPES.GET_CHECKLIST_SUCCESS));
//     }
//     catch(err) {
//         console.dir(err)
//         dispatch(handleOnError(err.response,DRAFT_ACTION_TYPES.GET_CHECKLIST_FAILURE));
//     }
// }
export const getDataUsingPostcode = async (userToken,postcode) => {

      return await fetch(
        `${process.env.REACT_APP_BASE_URL}AddressAutoPopulate?postcode=${postcode}`,
    
        {
          method: "GET",
          mode: "cors",
          headers: {
            authToken: `Token ${userToken}`,
          },
        }
      )
      .then((res) => res.json())
      .then((data) => data);
    
  }
export const fetchChecklist = async (userToken, clientId) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}client-checklist/?client_id=${clientId}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};
export const fetchPolicyValue = async (userToken, client_id) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}PolicyDetails/?client_id=${client_id}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
};

export const putChecklistStatus = async (dispatch, postData, useFor) => {
  let { clientId, checklistId, colorCode } = postData;
  try {
    const useForLowercased = useFor.toLowerCase();
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.PUT_CHECKLIST_START));
    // let data;
    // if(useForLowercased === CHECK_LIST_STATIC_NAMES.advisorchecklist)
    // {
    //     data = await axiosPut(DRAFT_CHECKLIST_PUT(checklistId, clientId), {colour_code:colorCode});
    // }
    // else if(useForLowercased === CHECK_LIST_STATIC_NAMES.complaincechecklist)
    // {
    //     data = await axiosPut(COMPLIANCE_CHECKLIST_PUT(checklistId, clientId), {colour_code:colorCode});
    // }
    // else if(useForLowercased === CHECK_LIST_STATIC_NAMES.administratorchecklist)
    // {
    //     data = await axiosPut(ADMINISTRATOR_CHECKLIST_PUT(checklistId, clientId), {colour_code:colorCode});
    // }
    // dispatch(handleOnSuccess(data,DRAFT_ACTION_TYPES.PUT_CHECKLIST_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.PUT_CHECKLIST_FAILURE)
    );
  }
};
export const generateSRDocument = async (
  dispatch,
  clientId,
  templateId = 4
) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.POST_GENERATE_SR_START));
    const data = await axiosPost(DRAFT_SR_GENERATE(templateId), {
      client: clientId,
    });
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.POST_GENERATE_SR_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.POST_GENERATE_SR_FAILURE)
    );
  }
};
export const fetchDraftDocuments = async (dispatch, clientId) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.GET_DRAFT_DOC_START));
    const data = await axiosGet(DRAFT_DOCUMENTS_GET(clientId,3,3));
    dispatch(handleOnSuccess(data, DRAFT_ACTION_TYPES.GET_DRAFT_DOC_SUCCESS));
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.GET_DRAFT_DOC_FAILURE)
    );
  }
};
export const postConfirmClient = async (dispatch, postData) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.POST_CLIENT_CONFIRM_START));
    const data = await axiosPost(CONFIRM_CLIENT_POST, postData);
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.POST_CLIENT_CONFIRM_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(
        err.response,
        DRAFT_ACTION_TYPES.POST_CLIENT_CONFIRM_FAILURE
      )
    );
  }
};

export const putDRnotesAnswer = async (
  dispatch,
  clientId,
  drNoteId,
  postData
) => {
  try {
    dispatch(handleActionStart(DRAFT_ACTION_TYPES.PUT_NOTES_ANSWER_START));
    const data = await axiosPut(
      DRAFT_NOTES_ANSWER_PUT(clientId, drNoteId),
      postData
    );
    dispatch(
      handleOnSuccess(data, DRAFT_ACTION_TYPES.PUT_NOTES_ANSWER_SUCCESS)
    );
  } catch (err) {
    console.dir(err);
    dispatch(
      handleOnError(err.response, DRAFT_ACTION_TYPES.PUT_NOTES_ANSWER_FAILURE)
    );
  }
};
