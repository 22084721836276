import React from 'react';
import { useSelector } from 'react-redux';
import DashboardMaster from '../../components/Dashboard/DashboardMaster/DashboardMaster';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import AuthorizationHOC from '../../hoc/AuthorizationHOC/AuthorizationHOC';
import Layout from '../../hoc/LayoutHOC/LayoutHOC';
import useDocTitle from '../../hooks/useDocTitle';
import ProfileMangementContent from '../../components/ProfileManagementDashbaordContent/ProfileMangementContent';

const ProfileManagementDashboard = () => {
    useDocTitle('Profile Management - CAP');
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const clientName = `${currentUserData.first_name} ${currentUserData.last_name}`;
    return (
        <Layout padding={2}>
            {
                currentUserData
				&&
				<PageWrapper
				    breadCrumbData={null}
				    heading={null}
                    bgWhite
				    className="dashboardPage"
				>
				    <ProfileMangementContent />
                    {/* <p>Hello</p> */}
				</PageWrapper>
            }
        </Layout>
    );
};

export default AuthorizationHOC(['DASHBOARD-VIEW'])(ProfileManagementDashboard);
