export const PERSISTED_REDUCER_ACTION_TYPES = {
    SAVE_SURVEY_VALUES : 'SAVE_SURVEY_VALUES',
    UPDATE_SAVED_SURVEY_VALUES : 'UPDATE_SAVED_SURVEY_VALUES',
    CLEAR_SAVED_SURVEY_VALUES : 'CLEAR_SAVED_SURVEY_VALUES',

    SET_ASSETS_SECTION_FORM_STATUS : 'SET_ASSETS_SECTION_FORM_STATUS',
    CLEAR_ASSETS_SECTION_FORM_STATUS : 'CLEAR_ASSETS_SECTION_FORM_STATUS',
    REMOVE_FROM_SAVED_SURVEY_VALUES : 'REMOVE_FROM_SAVED_SURVEY_VALUES',

    SAVE_LOCAL_VALUES: 'SAVE_LOCAL_VALUES',
    CLEAR_LOCAL_VALUES: 'CLEAR_LOCAL_VALUES',
    SAVE_UPDATED_CATEGORY_OFFLINE: 'SAVE_UPDATED_CATEGORY_OFFLINE',
    CLEAR_UPDATED_CATEGORY_OFFLINE: 'CLEAR_UPDATED_CATEGORY_OFFLINE',
    SAVE_UPDATED_CATEGORY_ONLINE: 'SAVE_UPDATED_CATEGORY_ONLINE',
    CLEAR_UPDATED_CATEGORY_ONLINE: 'CLEAR_UPDATED_CATEGORY_ONLINE'
}
