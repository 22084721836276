import { SURVEY_ACTION_TYPES } from '../redux/surveyForms/surveyForms.types';
import {
    SURVEY_FORMS,
    SURVEY_PARTNER_FORMS,
    SURVEY_FORMS_POST,
    SURVEY_STATUS,
    SOUND_RECORDING_POST,
    SOUND_RECORDING_GET,
    SOUND_RECORDING_DELETE,
    SURVEY_COUNTRYLIST_GET,
    } from '../utils/endPoint';
import {
    axiosPost,
    axiosGet,
    axiosPut,
    axiosDelete,
    axiosPatch,
    handleOnError,
    handleOnSuccess,
    handleActionStart
    } from '../utils/api';
import axios from 'axios';


export const fetchPartnerSurveyForm = async (dispatch, clientId) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.FETCH_PARTNER_SURVEYFORM_START));
        const data = await axiosGet(SURVEY_PARTNER_FORMS(clientId));
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.FETCH_PARTNER_SURVEYFORM_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.FETCH_PARTNER_SURVEYFORM_FAILURE));
    }
}

export const fetchSurveyForm = async (dispatch, clientId) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.FETCH_SURVEYFORM_START));
        const data = await axiosGet(SURVEY_FORMS(clientId));
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.FETCH_SURVEYFORM_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.FETCH_SURVEYFORM_FAILURE));
    }
}
export const updateSurveyForm = async (dispatch, modifiedSurveyForm) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.MODIFY_SURVEYFORM_START));
        const data = {status:200, data:{status_code: "200", status: true, message: "Modified", data: modifiedSurveyForm}}
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.MODIFY_SURVEYFORM_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.MODIFY_SURVEYFORM_FAILURE));
    }
}
export const postSurveyForm = async (dispatch, clientId, formData, categoryId) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.POST_SURVEYFORM_START));
        const data = await axiosPost(SURVEY_FORMS_POST(clientId, categoryId), formData );
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.POST_SURVEYFORM_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.POST_SURVEYFORM_FAILURE));
    }
}
export const fetchSearchableList = async (dispatch, searchUrl, headers="", optional) => {
    try{
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.GET_SELECTABLE_START));
        const data = await axios.get(searchUrl, {headers});
        var handleSuccess = handleOnSuccess(data, SURVEY_ACTION_TYPES.GET_SELECTABLE_SUCCESS);
        var responseObj = {...handleSuccess, ...optional};
        dispatch(responseObj);
    }
    catch(err){
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.GET_SELECTABLE_FAILURE));
    }
}
export const getSurveyStatus = async (dispatch, clientId) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.FETCH_SURVEYSTATUS_START));
        const data = await axiosGet(SURVEY_STATUS(clientId));
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.FETCH_SURVEYSTATUS_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.FETCH_SURVEYSTATUS_FAILURE));
    }
}
/*
    Service to email search
*/

export const getEmailSearch = async (dispatch,nameInput) => {
    let url = "emaillist"
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.FETCH_EMAIL_START));
        const data = await axiosGet(url, {
            params:{
                name : nameInput
            }
        })
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.FETCH_EMAIL_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.FETCH_EMAIL_FAILURE));
    }
}
/*
    Service to previous amount
*/

export const getPreviusAmount = async (dispatch,instrument_id) => {
    let url = "policiespreviousfund"
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.FETCH_PREVIUS_AMOUNT_START));
        const data = await axiosGet(url, {
            params:{
             instrument_id : instrument_id
            }
        })
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.FETCH_PREVIUS_AMOUNT_SUCCESS));

    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.FETCH_PREVIUS_AMOUNT_FAILURE));
    }
}

/*
    Service to save recording
*/
export const saveSoundRecordingService = async (dispatch, soundDetails) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.SAVE_SOUND_RECORDING_START));
        const data = await axiosPost(SOUND_RECORDING_POST, soundDetails);
        dispatch(handleOnSuccess(data,SURVEY_ACTION_TYPES.SAVE_SOUND_RECORDING_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response,SURVEY_ACTION_TYPES.SAVE_SOUND_RECORDING_FAILURE));
    }
}


/*
    Service to get sound recording
*/
export const getSoundRecordingService = async (dispatch, soundDetails) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.GET_SOUND_RECORDING_START));
        const { advisor_id, client_id, category_id } = soundDetails;
        const data = await axiosGet(SOUND_RECORDING_GET(client_id, advisor_id, category_id));
        dispatch(handleOnSuccess(data,SURVEY_ACTION_TYPES.GET_SOUND_RECORDING_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response,SURVEY_ACTION_TYPES.GET_SOUND_RECORDING_FAILURE));
    }
}

export const deleteSoundRecordingService = async (dispatch, recordingId) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.DELETE_SOUND_RECORDING_START));
        const data = await axiosDelete(SOUND_RECORDING_DELETE(recordingId));
        dispatch(handleOnSuccess(data, SURVEY_ACTION_TYPES.DELETE_SOUND_RECORDING_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response,SURVEY_ACTION_TYPES.DELETE_SOUND_RECORDING_FAILURE));
    }
}

export const fetchCountryList = async (dispatch, searchKey) => {
    try {
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.GET_COUNTRY_LIST_START));
        const data = await axiosGet(SURVEY_COUNTRYLIST_GET(searchKey));
        dispatch(handleOnSuccess(data,SURVEY_ACTION_TYPES.GET_COUNTRY_LIST_SUCCESS));
    }
    catch(err) {
        console.dir(err)
        dispatch(handleOnError(err.response,SURVEY_ACTION_TYPES.GET_COUNTRY_LIST_FAILURE));
    }
}

export const surveyButtonClickService = async (dispatch, apiUrl, headers="", optional) => {
    try{
        dispatch(handleActionStart(SURVEY_ACTION_TYPES.POST_SURVEY_BUTTON_CLICK_START));
        const data = await axios.get(apiUrl, {headers});
        var handleSuccess = handleOnSuccess(data, SURVEY_ACTION_TYPES.POST_SURVEY_BUTTON_CLICK_SUCCESS);
        var responseObj = {...handleSuccess, ...optional};
        dispatch(responseObj);
    }
    catch(err){
        console.dir(err)
        dispatch(handleOnError(err.response, SURVEY_ACTION_TYPES.POST_SURVEY_BUTTON_CLICK_FAILURE));
    }
}