import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { getError } from '../../utils/utils';
import { FormControl, FormGroup, FormHelperText, FormLabel, makeStyles } from '@material-ui/core';




const useStyles = makeStyles(theme => {
    return {
        checkBoxError: {
            '& .MuiFormControlLabel-label' : {
                color : theme.palette.error.main
            }
        }
    }
});




const CheckBox = ({
    field: { name, value, ...otherFieldProps },
    form: { touched, errors, setFieldValue, status },
    label,
    labelText=null,
    onChange=null,
    hideErrorMessage=false,
    errorColorOnLabel=false,
    className='',
    ...props
}) => {

    const classes = useStyles();
    const errorText = getError(name, { touched, status, errors });
    const isError = (errorText) ? true : false;


    return (
        <FormControl component="fieldset" error={isError} className={className}>
            {labelText && <FormLabel component="legend">{labelText}</FormLabel>}
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox 
                        checked={value} 
                        onChange={(e) => {
                            setFieldValue(name, e.target.checked);
                            // Running the custom on change function if passed
                            if (onChange)
                            {
                                onChange(e, e.target.checked);
                            }
                        }}
                        name={name} 
                    />}
                    label={label}
                    className={`customCheckBox ${(errorColorOnLabel && isError) ? classes.checkBoxError : ''}`}
                />
            </FormGroup>
            {(isError && hideErrorMessage === false) && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    )
}

export default CheckBox;


