import { ControlCameraOutlined } from "@material-ui/icons";

export const getProviderListMonthlyIssued = async (userToken,month,year,isSuperAdmin,advisorId,providerId,providerPageNo,searchProviders) => {
  try{
    if(month && year){
      let param = '';
      let api   = isSuperAdmin ? 'provider-monthly' : 'advisor/providers-monthly'  
      param     = advisorId ? `${param}&advisor=${advisorId}` : '';
      param     = providerId ? `${param}&provider=${providerId}` : param;
      return await fetch(
        `${process.env.REACT_APP_BASE_URL}${api}/?month=${month}&year=${year}${param}&pg_no=${providerPageNo}&search=${searchProviders}`,
    
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authtoken: `Token ${userToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => data);
    }
  }
  catch(err){
    console.log(err);
  }
  }
export const getAdvisorListMonthlyIssued = async (userToken, month,year,providerId,advisorpageNo,searchAdvisor) => {
  if(month && year){
    let param = '';
    param     = providerId ? `${param}&provider=${providerId}` : '';
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}advisor-monthly/?month=${month}&year=${year}${param}&pg_no=${advisorpageNo}&search=${searchAdvisor}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
}
export const getDownload = async (userToken,start_date,end_date,switchTable,formData) => {
  let api   = switchTable?"export_to_csv_by_provider/":"export_to_csv_by_advisor/"
  let param = '';
  param     = `${param}start_date=${start_date}&end_date=${end_date}` ;
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}${api}?${param}`,

    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(formData),
      headers: {
        Authtoken: `Token ${userToken}`,
        "content-type": "application/json",
      },    
    }
  )
    .then((res) => res.blob())
    .then(blob => {
      // Create a temporary URL for the downloaded file
      const url = URL.createObjectURL(blob);
      
      // Create a temporary anchor element to initiate download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'filename.xlsx';
      a.click();
      
      // Release the temporary resources
      URL.revokeObjectURL(url);
    })

}
export const fetchReportGET = async (userToken, fetchurl,queryParams={}) => {

  const query = new URLSearchParams(queryParams);
  const fullUrl = `${process.env.REACT_APP_BASE_URL}${fetchurl}?${query.toString()}`;

return await fetch(
  `${fullUrl}`,
  // &amount=${amount}
  {
    method: "GET",
    headers: {
      Authtoken: `Token ${userToken}`,
    },
  }
)
.then((res) => res.blob())
.then(blob => {
  // Create a temporary URL for the downloaded file
  const url = URL.createObjectURL(blob);
  
  // Create a temporary anchor element to initiate download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'complianceReport.xlsx';
  a.click();
  
  // Release the temporary resources
  URL.revokeObjectURL(url);
})
}
export const getReportAdvisor = async (userToken,providerId,startDate,end_date,switchTable) => {
  let api   = switchTable?'advisor-list-reports-serviceprovider-toggle':"superuser/provider-list-reports-by-advisor" 
  let param = '';
  param     = switchTable ? `${param}provider=${providerId}&start_date=${startDate}&end_date=${end_date}` : `${param}advisor=${providerId}&start_date=${startDate}&end_date=${end_date}` ;
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}${api}?${param}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        Authtoken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);

}
export const getReportClient = async (userToken,providerId,startDate,end_date,isSuperAdmin,advisorId,pgno) => {
  let api   = isSuperAdmin ? 'client-list-reports-superuser-login' : 'advisor/client-list-reports-advisor-login'  
  let param = '';
  param     = isSuperAdmin ? `${param}&advisor=${advisorId}&start_date=${startDate}&end_date=${end_date}&pg_no=${pgno}` : `${param}&start_date=${startDate}&end_date=${end_date}&pg_no=${pgno}` ;
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}${api}?provider=${providerId}${param}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        Authtoken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);

}

export const getMissingClient = async (userToken, policy_number, startDate, end_date, isSuperAdmin, advior_name, pgno,selectedOption) => {
  let api = isSuperAdmin ? 'missing_dtls_superadmin_client' : 'advisor/client-list-reports-advisor-login';
  let param = '';
  param = isSuperAdmin ? `${param}&start_date=${startDate}&end_date=${end_date}&offset=${pgno}&advisor=${advior_name}${selectedOption}` : `${param}&start_date=${startDate}&end_date=${end_date}&offset=${pgno}`;
  
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}${api}?advisor=${advior_name}:${param}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        authToken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
}
export const getReportProviderSelect = async (userToken,search,switchTable) => {
  let api   = switchTable ? 'instrument-providers/' : 'advisor-search-fee-split/'  
  let param = '';
  param     = switchTable ? `${param}&search=${search}` : `${param}&name=${search}` ;
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}${api}?search=${param}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        Authtoken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);

}

export const getReportCompanySelect = async (userToken,search,switchTable) => {
  let api   =   "advisor-companies" ; 
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}${api}`,

    {
      method: "GET",
      mode: "cors",
      headers: {
        Authtoken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);

}
export const getReportProvider = async (userToken,date,end_date,provider_list,isSuperAdmin,switchTable,pgno,selectedId) => {
  let api   = isSuperAdmin ?switchTable? "superuser/provider-list-reports-by-provider":"advisor-list-reports-advisor-toggle": "advisor/provider-list-reports-advisor-login"  
   
  let url =   `${process.env.REACT_APP_BASE_URL}${api}?start_date=${date}&end_date=${end_date}${provider_list}&pg_no=${pgno}`;
  if (selectedId) {
    url += `&related_company=${selectedId}`;
  }
    return await fetch( url,  {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
    }

    export const getReportMissingDetail = async (userToken,date,end_date,provider_list,pgno,selectedId) => {
      let api   =  "missing_dtls_superadmin_advisor"  
       
      let url = `${process.env.REACT_APP_BASE_URL}${api}?start_date=${date}&end_date=${end_date}${provider_list}&pg_no=${pgno}`;
      if (selectedId) {
        url += `&related_company=${selectedId}`;
      }
        return await fetch( url,  {
            method: "GET",
            mode: "cors",
            headers: {
              authToken: `Token ${userToken}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => data);
        }

        export const getReportDistribution = async (userToken, date, end_date, selectedId,advisor) => {
          let api = "advisor_distribution_details";
            let url = `${process.env.REACT_APP_BASE_URL}${api}?start_date=${date}&end_date=${end_date}${advisor}`;
          
          // Conditionally add the related_company parameter
          if (selectedId) {
            url += `&related_company=${selectedId}`;
          }
        
          return await fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
              authToken: `Token ${userToken}`,
            },
          })
          .then((res) => res.json())
          .then((data) => data);
        };
        
            export const getReportReconciliation = async (userToken,date,end_date,selectedId) => {
              let api   =  "report-reconciliation-distributed"  
               
              let url =   `${process.env.REACT_APP_BASE_URL}${api}/?start_date=${date}&end_date=${end_date}`;
              if (selectedId) {
                url += `&related_company=${selectedId}`;
              }
                return await fetch( url,  {
                    method: "GET",
                    mode: "cors",
                    headers: {
                      authToken: `Token ${userToken}`,
                    },
                  }
                )
                  .then((res) => res.json())
                  .then((data) => data);
                }

                export const getReconciliationItem= async (userToken,date,end_date,pgno,selectedId) => {
                  let api   =  "report_reconciliation"  
                   
                  let url = `${process.env.REACT_APP_BASE_URL}${api}?start_date=${date}&end_date=${end_date}&pg_no=${pgno}`;
                  if (selectedId) {
                    url += `&related_company=${selectedId}`;
                  }
                   return await fetch( url, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                          authToken: `Token ${userToken}`,
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => data);
                    }

                   

             
                    export const getReconciliationExport = async (userToken, rawData) => {  
                      const api = "export-csv";  
                    return await fetch(  
                      `${process.env.REACT_APP_BASE_URL}${api}`,
               
                      {
                        method: "POST",
                        mode: "cors",
                        body: JSON.stringify(rawData),
                        headers: {
                          Authtoken: `Token ${userToken}`,
                          "content-type": "application/json",
                        },    
                      }
                    )
                      .then((res) => res.blob())
                      .then(blob => {
                        // Create a temporary URL for the downloaded file
                        const url = URL.createObjectURL(blob);
                        
                        // Create a temporary anchor element to initiate download
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'distributed_rec.xlsx';
                        a.click();
                        
                        // Release the temporary resources
                        URL.revokeObjectURL(url);
                      })
                  
                  };
                  


            export const getReportDistributionClient = async (userToken,advisorId,startDate,end_date) => {
              let api   =  "client_distribution_details"  
                return await fetch(
                  `${process.env.REACT_APP_BASE_URL}${api}?advisor=${advisorId}&start_date=${startDate}&end_date=${end_date}`,
              
                  {
                    method: "GET",
                    mode: "cors",
                    headers: {
                      authToken: `Token ${userToken}`,
                    },
                  }
                )
                  .then((res) => res.json())
                  .then((data) => data);
                }
    
        export const postMissinNotifiction = async (userToken, fetchurl,advisor,policy_number,month_year,client_name,provider_filename,formData) => {
          return await fetch(
            `${process.env.REACT_APP_BASE_URL}${fetchurl}?advisor=${advisor}&client_name=${client_name}&policy_number=${policy_number}&uploaded_date=${month_year}&provider=${provider_filename}`,
        
            {
              method: "POST",
              body:   formData,
              headers: {
                Authtoken: `Token ${userToken}`,
              
              },
            }
          )
            .then((res) => res.json())
            .then((data) => data);
        }

        export const mapAdvisor = async (userToken, fetchurl,advisor,clientId,formData) => {
          return await fetch(
            `${process.env.REACT_APP_BASE_URL}${fetchurl}?statement_id=${advisor}&advisor_id=${clientId}`,
        
            {
              method: "PUT",
              body:   formData,
              headers: {
                Authtoken: `Token ${userToken}`,
              
              },
            }
          )
            .then((res) => res.json())
            .then((data) => data);
        }
export const getClientListMonthlyIssued = async (userToken,month,year,isSuperAdmin, advisorId,providerId,pgno,order,searchClients) => {
  if(month && year){
      let param = '';
      let api   = isSuperAdmin ? 'clientlist-monthly' : 'advisor/provider-monthly-clientlist'  
      param     = advisorId ? `${param}&advisor=${advisorId}` : '';
      param     = providerId ? `${param}&provider=${providerId}` : '';
      return await fetch(
        `${process.env.REACT_APP_BASE_URL}${api}/?month=${month}&year=${year}${param}&offset=${pgno}&order=${order}&search=${searchClients}`,
    
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authtoken: `Token ${userToken}`,
          },
        }
      )
      .then((res) => res.json())
      .then((data) => data);
    }
  }
  export const getProviderListPendingIssued = async (userToken, advisorId, month, year,pageNo,search,clientOrder) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}provider-client-suspence-acc/?advisor=${advisorId}&month=${month}&year=${year}&offset=${pageNo}&search=${search}&order=${clientOrder}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }


  export const getProviderSuspenseUnderAdvisor = async (userToken,advisorId, month, year) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}advisor/pendingissue-SP-List/?advisor=${advisorId}&month=${month}&year=${year}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      } 
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getProviderSuspenseUnderSuperUser = async (userToken, month, year,errorPageNo) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}get_sp_pending_issued_for_super_user?month=${month}&year=${year}&pg_no=${errorPageNo}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      } 
    )
      .then((res) => res.json())
      .then((data) => data);
  }


  export const getAdvisorListMaster = async (userToken, providerId, month, year,pgno,search,order) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}advisor-client-suspence-acc/?provider=${providerId}&month=${month}&year=${year}&offset=${pgno}&search=${search}&order=${order}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }


  export const getClientSuspenseUnderAdvisor = async (userToken,providerId, month, year,pageNo,search,order) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}suspense-account-clients-of-provider/?provider=${providerId}&month=${month}&year=${year}&offset=${pageNo}&search=${search}&order=${order}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }


  export const getAdvisorSuspenseUnderSuperUser = async (userToken, month, year) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}get_advisor_pending_issued_for_super_user?month=${month}&year=${year}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getWrittenBusinessdateslist = async (userToken) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}writtenBusinessdateslist`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getPendingSPWB = async (userToken, advisorId, month, year) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}superuser/writtenbusiness-SP-List?advisor=${advisorId}&month=${month}&year=${year}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getPendingADWB = async (userToken, month, year,AdvisorPageNo) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}superuser/WB-advisor-List?month=${month}&year=${year}&pg_no=${AdvisorPageNo}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getPendingWB1 = async (userToken, month, year,pgno) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}writtenbusiness-SP-pendingissued?month=${month}&year=${year}&pg_no=${pgno}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getClientProviderWB = async (userToken, providerId, month, year,pgno) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}WrittenbusinessAdvisorsClientlist?provider_id=${providerId}&month=${month}&year=${year}&pg_no=${pgno}`,
      
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getServiceProviderWB = async (userToken, month, year,pageNo) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}writtenBusinessProvider?month=${month}&year=${year}&pg_no=${pageNo}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getAdvisorWBList = async (userToken, providerId, month, year) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}writtenBusinessAdvisorList?provider_id=${providerId}&month=${month}&year=${year}`,
  
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const getAdvisorproviderWBList = async (userToken, advisorId,providerId, month, year,pgno) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}WrittenbusinessAdvisorsClientlist?advisor_id=${advisorId}&provider_id=${providerId}&month=${month}&year=${year}&pg_no=${pgno}`,
      
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const uploadBankStatement = async (userToken, formData,downloadTemplate) => {
    let switchAPI = downloadTemplate ? downloadTemplate : "FeestatementUpload"
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${switchAPI}`,
  
      {
        method: "POST",
        body: formData,
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const uploadBankStatementProgressCheck = async (userToken, queryParams={}) => {
    let url ="FeestatementUploadProvider"
    const query = new URLSearchParams(queryParams);
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${url}?${query.toString()}`,
  
      {
        method: "GET",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchGet = async (userToken, fetchurl) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "GET",
        headers: {
          Authtoken: 'Token ' + `${userToken}`,
          type: 'application/json'
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchGetEmail =  async (userToken, fetchurl,formData) => {

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authtoken: `Token ${userToken}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchGett = async (userToken, fetchurl,pageNo,search) => {
  
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?offset=${pageNo}&search=${search}`,
  
      {
        method: "GET",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchGettt = async (userToken, fetchurl,id) => {
  
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?client_id=${id}`,
  
      {
        method: "GET",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchGetReport = async (userToken, fetchurl,id) => {
  
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?client_id=${id}`,
  
      {
        method: "GET",
      //   headers: {
      //     Authtoken: `Token ${userToken}`,
      //   },
       }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
   export const fetchGetttt = async (userToken, fetchurl,id,type,options={}) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?client_type=${type}&client_id=${id}`,
      {
        ...options,
        method: "GET",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPatch = async (userToken, fetchurl) => {
  
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "PATCH",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPost = async (userToken, fetchurl,formData,isJson,extraConfigs={},options={}) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        ...options,
        method: "POST",
        body:  isJson ? JSON.stringify(formData) : formData,
        headers: {
          Authtoken: `Token ${userToken}`,
          ...extraConfigs
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchGET = async (userToken, fetchurl,queryParams={}) => {

      const query = new URLSearchParams(queryParams);
      const fullUrl = `${process.env.REACT_APP_BASE_URL}${fetchurl}?${query.toString()}`;
    
    return await fetch(
      `${fullUrl}`,
      // &amount=${amount}
      {
        method: "GET",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }


  export const fatchPaymentType = async (userToken, fetchurl,) => {

   
    const fullUrl = `${process.env.REACT_APP_BASE_URL}${fetchurl}`;
  
  return await fetch(
    `${fullUrl}`,
    // &amount=${amount}
    {
      method: "GET",
      headers: {
        Authtoken: `Token ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data);
}
  export const manualInvoiceDel = async (userToken, fetchurl,expectationId,formData) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?fee_expectation_id=${expectationId}`,
  
      {
        method: "PUT",
        body:   formData,
        headers: {
          Authtoken: `Token ${userToken}`,
        
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }

  export const fetchUpdatingInitialfeeSuspencePost = async (userToken, fetchurl,instrument_id,initial_fee,ongoing_fee,income_issue_id,reason) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?income_issue_id=${income_issue_id}&reason=${reason}&ongoing_fee=${ongoing_fee}&initial_fee=${initial_fee}&instrument_id=${instrument_id}`,
      // &amount=${amount}
      {
        method: "POST",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
   export const fetchPosttt = async (userToken, fetchurl,id,reminder,month,day,year) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?client_id=${id}&reminder=${reminder}&month=${month}&day=${day}&year=${year}`,
  
      {
        method: "POST",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPostt = async (userToken, fetchurl,reason,id,month,year) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}?ongoingfee=${reason}&policynumber=${id}&month=${month}&year=${year}`,
  
      {
        method: "POST",
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPut =  async (userToken, fetchurl,formData, isJson=true) => {
    // console.log(formData,isJson)
    
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "PUT",
        mode: "cors",
        body:isJson ? JSON.stringify(formData) : formData,
        headers: {
          Authtoken: `Token ${userToken}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPutt =  async (userToken, fetchurl,formData) => {
    
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify(formData),
        headers: {
          Authtoken: `Token ${userToken}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPutForm =  async (userToken, fetchurl,formData) => {
    // console.log(formData,isJson)
    
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "PUT",
        mode: "cors",
        body: formData,
        headers: {
          Authtoken: `Token ${userToken}`,
          // "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchDelete = async (userToken, fetchurl,formData, isJson = true) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "DELETE",
        body: isJson ? JSON.stringify(formData) : formData,
        headers: {
          Authtoken: `Token ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }
  export const fetchPostJson = async (userToken, fetchurl,formData,isJson = true) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "POST",
        body: isJson ? JSON.stringify(formData) : formData,
        headers: {
          Authtoken: `Token ${userToken}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }