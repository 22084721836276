import { ACCEPTED_REJECTED_TYPES } from "../../utils/constants";
import moment from 'moment';

export const removeRecordingItem = ( currentRecordings  , id) => {
    const filteredRecordingsData = currentRecordings.filter( recordingItem => recordingItem.recording_id !== id);
    return filteredRecordingsData;
};

export const getFormattedAcceptedRejectedData = (payload) => {
    return [
        {
            name: `${payload.accept_ratio || 0}% Accepted`,
            value: payload.accept_ratio,
            type : ACCEPTED_REJECTED_TYPES.ACCEPTED
        },
        {
            name: `${payload.reject_ratio || 0}% Rejected`,
            value: payload.reject_ratio,
            type : ACCEPTED_REJECTED_TYPES.REJECTED
        }
    ];
};

export const getFormattedInteractionsByTypeData = (data) => {
    const formattedChartData = data.map( chartItem => {
        const startDate = chartItem.start_date;
        const endDate = chartItem.end_date;
        const startDateFormatted = moment(startDate).format('DD');
        const endDateFormatted = moment(endDate).format('DD');

        const monthNameFromStartDate = moment(startDate).format('MMMM');
        const monthNameFromEndDate = moment(endDate).format('MMMM');
        const startMonthName = monthNameFromStartDate.substring(0, 3);
        const endMonthName = monthNameFromEndDate.substring(0, 3);
    
        if(chartItem.type === 'week')
        {
            let monthNameForWeekView = `${startMonthName} ${startDateFormatted} - ${endDateFormatted}`;
            if(monthNameFromStartDate !== monthNameFromEndDate)
            {
                monthNameForWeekView = `${startMonthName} ${startDateFormatted} - ${endMonthName} ${endDateFormatted}`;
            }
            return {
                ...chartItem,
                month_name : monthNameForWeekView
            };
        }
        else if(chartItem.type === 'day')
        {
            return {
                ...chartItem,
                month_name : `${startMonthName} ${startDateFormatted} `,
            };
        }
        else
        {
            return chartItem;
        }
    });
    return formattedChartData;
};