import { CALENDAR_ACTION_TYPES } from './calendar.types';
import { validationErrorFormatter } from '../../utils/utils';



const INITIAL_STATE = {
    isEventDataFetching: false,
    fetchEventDataErrorMsg : null,
    eventData : null,

    addEventLoading: false,
    addEventData : null,
    addEventError : null,
    eventList: [],
    isEventFetching:false,
    isDeleting:false,

    newEditData : null,
    isEditFetching : false,

    isLoadingNextMeeting: false,
    nextMeeting: {}

}



const calendarReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {

        case CALENDAR_ACTION_TYPES.ADD_EVENT_START:
            return {
                ...state,
                addEventLoading: true,
            }
        case CALENDAR_ACTION_TYPES.ADD_EVENT_SUCCESS:
            return {
                ...state,
                addEventLoading: false,
                addEventData: payload.data,
                addEventSuccessMessage : payload.message
            }

        case CALENDAR_ACTION_TYPES.CLEAR_ADD_EDIT_EVENT_SUCCESS_MESSAGE : 
        return {
            ...state,
            addEventSuccessMessage : null,
            editEventSuccessMessage : null,
        }

        case CALENDAR_ACTION_TYPES.ADD_EVENT_FAILURE:
            return {
                ...state,
                addEventLoading: false,
                addEventErrorMessage: payload.message,
                addEventErrorFields : payload.data
            }

        case CALENDAR_ACTION_TYPES.CLEAR_ADD_EVENT_ERROR_MESSAGE : {
            return {
                ...state,
                addEventErrorMessage: null,
            }
        }


        case CALENDAR_ACTION_TYPES.FETCH_EVENT_DATA_START:
        return {
            ...state,
             isEventDataFetching: true,
        }
        case CALENDAR_ACTION_TYPES.FETCH_EVENT_DATA_SUCCESS:
        return {
            ...state,
            isEventDataFetching: false,
            eventData : payload.data
        }
        case CALENDAR_ACTION_TYPES.FETCH_EVENT_DATA_FAILURE:
        return {
            ...state,
            isEventDataFetching: false,
            fetchEventDataErrorMsg : payload.message
        }
        case CALENDAR_ACTION_TYPES.REMOVE_EVENT_DATA:
        return {
            ...state,
            eventData : null
        }

        case CALENDAR_ACTION_TYPES.MARK_PARTICIPATION_START:
        return {
            ...state,
             isRSVPFetching: true,
        }
        case CALENDAR_ACTION_TYPES.MARK_PARTICIPATION_SUCCESS:
        return {
            ...state,
            isRSVPFetching: false,
        }
        case CALENDAR_ACTION_TYPES.MARK_PARTICIPATION_FAILURE:
        return {
            ...state,
            isRSVPFetching: false,
            fetchEventDataErrorMsg : payload.message
        }
        case CALENDAR_ACTION_TYPES.DELETE_EVENT_START:
        return {
            ...state,
             isDeleting: true,
        }
        case CALENDAR_ACTION_TYPES.DELETE_EVENT_SUCCESS:
        return {
            ...state,
            isDeleting: false,
        }
        case CALENDAR_ACTION_TYPES.DELETE_EVENT_FAILURE:
        return {
            ...state,
            isDeleting: false,
            DeletingErrorMsg : payload.message
        }

        case CALENDAR_ACTION_TYPES.FETCH_EVENT_LIST_START:
        return {
            ...state,
             isEventFetching: true,
        }
        case CALENDAR_ACTION_TYPES.FETCH_EVENT_LIST_SUCCESS:
        return {
            ...state,
            isEventFetching: false,
            eventList : payload.data
        }
        case CALENDAR_ACTION_TYPES.FETCH_EVENT_LIST_FAILURE:
        return {
            ...state,
            isEventFetching: false,
            DeletingErrorMsg : payload.message
        }



        case CALENDAR_ACTION_TYPES.EDIT_EVENT_START:
        return {
            ...state,
            isEditFetching : true
        }
        case CALENDAR_ACTION_TYPES.EDIT_EVENT_SUCCESS:
        return {
            ...state,
            newEditData : payload.data,
            isEditFetching : false,
            editEventSuccessMessage : payload.message
        }
        case CALENDAR_ACTION_TYPES.EDIT_EVENT_FAILURE:
        return {
            ...state,
            isEditFetching : false,
            editErrorMessage : payload.message
        }


        case CALENDAR_ACTION_TYPES.CLEAR_EDIT_EVENT_ERROR_MESSAGE:
        return {
            ...state,
            editErrorMessage : null
        }

        case CALENDAR_ACTION_TYPES.FETCH_MEETING_SCHEDULE_START:
            return {
                ...state,
                isLoadingNextMeeting: true,
            }
        case CALENDAR_ACTION_TYPES.FETCH_MEETING_SCHEDULE_SUCCESS:
            return {
                ...state,
                isLoadingNextMeeting: false,
                nextMeeting: payload.data
            }
        case CALENDAR_ACTION_TYPES.FETCH_MEETING_SCHEDULE_FAILURE:
            return {
                ...state,
                isLoadingNextMeeting: false,
                nextMeetingErrorMessage: payload.message,
            }



        default:
            return state;
    }
}


export default calendarReducer;