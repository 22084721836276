import { toLowercaseAndReplaceSpaces, stringifyPathForInitialvalues } from '../../utils/utils';
import appConfig from '../../appConfig.json';
import axios from 'axios';
import _ from 'lodash';


export const pluckAssetSectionData = (surveyFormData) => {
    let assetsSectionObject;
    surveyFormData.forEach((category) => {
        const categoryName = toLowercaseAndReplaceSpaces(JSON.parse(category.category).category_slug_name);
        if (categoryName === toLowercaseAndReplaceSpaces(appConfig.survey_forms.category_slug_name))
        {
            category.data && category.data.length > 0 && category.data.forEach((subcategory) => {
                const subcategoryName = toLowercaseAndReplaceSpaces(subcategory.subcategory_slug_name);
                if (subcategoryName === toLowercaseAndReplaceSpaces(appConfig.survey_forms.subcategory_slug_name))
                {
                    assetsSectionObject = subcategory.subcategory_data[0].sub_labels;
                }
            })
        }
    })
    return assetsSectionObject;
}


// Takes first element in array , replaces answer to ""
export const pluckStructureToPush = (surveyFormAssetSectionSubLabelsArray) => {
    let dataReceived = [];
    dataReceived = surveyFormAssetSectionSubLabelsArray;
    dataReceived.forEach(questions => {
        questions.index = questions.index + 1;
        questions.sub_label_data && questions.sub_label_data.length > 0 && questions.sub_label_data.forEach(questions => {
            questions.answer = 'xyz';
            questions.sub_labels && questions.sub_labels.length > 0 && questions.sub_labels.forEach(subLabelItem => {
                subLabelItem.sub_label_data && subLabelItem.sub_label_data.length > 0 && subLabelItem.sub_label_data.forEach(subQuestions => {
                    subQuestions.answer = 'xyz'
                })
            })
        })
    })
    return dataReceived[0];
}



export const getAnsweredCount = (surveyStatusData) => {
    let answerCount = 0;
    surveyStatusData && (surveyStatusData.length > 0) && surveyStatusData.forEach((ansCategory) => {
        answerCount = answerCount + parseInt(ansCategory.total_answered_labels)
    })
    return answerCount;
}
export const getEmailList = (emailList)=>{
    // console.log("emailList sakthi",emailList);
}

export const getClientList = (clientList)=>{
    // console.log("clientList sakthi",clientList);
} 
export const getPreviusAmount = (clientList)=>{
    // console.log("clientList sakthi",clientList);
} 


export const addBlobUrl = (recordingList) => {
    const newList = recordingList;
    newList.forEach(async recordItem => {
        recordItem.recording_blob = await makeBlob(recordItem.recording_blob)
    })
    return recordingList;
}


export const makeBlob = (blobUrl) => {
    const selectedPersonWhoReferredData = axios.get(blobUrl,
        {
            //headers: {Authtoken: 'token '+authToken,},
            responseType: 'blob'
        }
    ).then((response) => {
        return URL.createObjectURL(new Blob([response.data], { type: 'audio/wav' }))
    });
    return selectedPersonWhoReferredData
}


export const getSavedValuesOnLoad = (initData) => {
    let savedValues = [];
    if(initData){
        initData.map((category) => {

            if(category.data.length > 0){
                category.data.map((subcategory) => {

                    if(subcategory.subcategory_data && subcategory.subcategory_data.length > 0){
                        subcategory.subcategory_data.map((questions, index) => {

                            /**
                             * Save to local, for field with has_local_data
                             */
                            if(questions.mapfield_to && questions.mapfield_to.length > 0 && questions.answer != ""){
                                savedValues.push( { valueSavedFor : questions.mapfield_to , value  : questions.answer,  valueSavedFrom : questions.field_name })
                            }
                            // Save to local, for field with has_local_data - End

                            if(questions.is_repeat){


                            }else{
                                if(questions.sub_labels && questions.sub_labels.length > 0){
                                    questions.sub_labels.map((subQuestions) => {

                                        if(subQuestions.sub_label_data && subQuestions.sub_label_data.length > 0){
                                            subQuestions.sub_label_data.map((subLabelData) => {

                                                if(subLabelData.is_repeat){

                                                }else{

                                                    /**
                                                     * Save to local, for field with has_local_data
                                                     */
                                                    if(subLabelData.mapfield_to && subLabelData.mapfield_to.length > 0 && subLabelData.answer != ""){
                                                         savedValues.push( { valueSavedFor : subLabelData.mapfield_to , value  : subLabelData.answer,  valueSavedFrom : subLabelData.field_name })
                                                    }
                                                    // Save to local, for field with has_local_data - End
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            }
        })
    }
    return savedValues;
}

export const getCheckedValues = (initData) => {
    let checkedValues = [];
    let checkComponentTypes = ['Text&Checkbox', 'Select&Checkbox', 'Searchable&Checkbox', 'MultiSelect&Checkbox']
    if(initData){
        initData.map((category) => {

            if(category.data.length > 0){
                category.data.map((subcategory) => {

                    if(subcategory.subcategory_data && subcategory.subcategory_data.length > 0){
                        subcategory.subcategory_data.map((questions, index) => {
                            if(checkComponentTypes.includes(questions.component_type) && (questions.answer != "" && (questions.answer == 0 || questions.answer === 'Unknown'))){
                                var checkField = stringifyPathForInitialvalues(questions.field_name);
                                checkedValues.push({fieldName: questions.field_name, checkboxFieldName: checkField});
                            }
                            if(questions.is_repeat){

                                (questions.sub_labels.length > 0) && questions.sub_labels.map((subLabel, subIndex) => {
                                    (subLabel.sub_label_data.length > 0) && subLabel.sub_label_data.forEach((sublabelData, sublabelDataIndex)=>{
                                        var repeatFieldName = `${questions.field_name}[${subIndex}].sub_label_data[${sublabelDataIndex}].answer`;
                                        if(checkComponentTypes.includes(sublabelData.component_type) && (sublabelData.answer !="" && (sublabelData.answer == 0 || sublabelData.answer === 'Unknown'))){
                                            var checkField = stringifyPathForInitialvalues(repeatFieldName);
                                            checkedValues.push({fieldName: repeatFieldName, checkboxFieldName: checkField});
                                        }
                                    })
                                })

                            }else{
                                if(questions.sub_labels && questions.sub_labels.length > 0){
                                    questions.sub_labels.map((subQuestions) => {

                                        if(subQuestions.sub_label_data && subQuestions.sub_label_data.length > 0){
                                            subQuestions.sub_label_data.map((subQuestionSublabel) => {

                                                if(subQuestionSublabel.is_repeat){

                                                    (subQuestionSublabel.sub_labels.length > 0) && subQuestionSublabel.sub_labels.map((subSubLabels, subLabelIndex) => {
                                                        (subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
                                                            var repeatFieldName = `${subQuestionSublabel.field_name}[${subLabelIndex}].sub_label_data[${subSublabelDataIndex}].answer`;
                                                            if(checkComponentTypes.includes(subSublabelData.component_type) && (subSublabelData.answer !="" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown'))){
                                                                var checkField = stringifyPathForInitialvalues(repeatFieldName);
                                                                checkedValues.push({fieldName: repeatFieldName, checkboxFieldName: checkField});
                                                            }
                                                        })
                                                    })

                                                }else{

                                                    if(checkComponentTypes.includes(subQuestionSublabel.component_type) && (subQuestionSublabel.answer === 0 || subQuestionSublabel.answer === 'Unknown')){
                                                        var checkField = stringifyPathForInitialvalues(subQuestionSublabel.field_name);
                                                        checkedValues.push({fieldName: subQuestionSublabel.field_name, checkboxFieldName: checkField});
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            }
        })
    }
    return checkedValues;
}


export const getSurveyButtonResponse = (payloadData, fieldName, stateSurveyButton) => {
    if(payloadData.status){
        let updatedResponse = [...stateSurveyButton];
        var newObj = {"field_name":fieldName, "answer": "off"}

        const itemToBeUpdated = stateSurveyButton.find(valueItem => {
            if(valueItem.field_name === newObj.field_name){
                valueItem.answer = newObj.answer;
                return valueItem;
            }
        });
        // if value doesn't exist it will be undefined so push it
        if(itemToBeUpdated === undefined){
            updatedResponse.push(newObj)
        }
        return updatedResponse;
    }
    return stateSurveyButton
}