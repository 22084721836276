/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Table from '../../Table/Table';
import { Grid, LinearProgress, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import DashboardItem from '../DashboardItem/DashboardItem';
import { ACCEPTED_REJECTED_TYPES, DASHBOARD_FREQUENCY_VALUES } from '../../../utils/constants';
import { first, isEmpty, debounce } from 'lodash';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, Pie, PieChart } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { getClientsVsDataAsync, getClientsReferringDataAsync, getRecentlyAddedClientsAsync, getTopReferrersAsync, getAdheranceToCPPDataAsync, getAcceptedRejectedRatioAsync, getAverageClientsAsync, getInteractionsByTypeAsync, fetchStaffBasedOnFilterAsync } from '../../../redux/ui/ui.actions';
import { Field, Form, Formik } from 'formik';
import SearchableSelect from '../../SearchableSelect/SearchableSelect';
import CircularLoader from '../../CircularLoader/CircularLoader';
import './DashboardMaster.scss';
import { checkPermissions } from '../../../utils/utils';
import { COLOR_CODES } from '../../../utils/constants';
import { useShowDashboardErrors } from './DashboardMasterHooks';


const DashboardMaster = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchStaffBasedOnFilterAsync(`?type=Advisor&is_advisor=true`));
    }, [dispatch]);
    const isFirstRender = useRef(true);
    const firstAdvisor = useRef(null);
    const [isStaffListEmpty, setIsStaffListEmpty] = useState(false);
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const {
        clientVsData,
        isClientVsDataLoading,
        clientsReferringData,
        isGetClientsReferringFetching,
        recentlyAddedClients,
        isRecentlyAddedClientsFetching,
        topReferrers,
        isGetTopReferrersFetching,
        adherenceToCpp,
        isGetAdherenceToCppFetching,
        acceptedRejectedRatio,
        isGetAcceptedRejectedRatioFetching,
        averageAgeData,
        isGetAverageAgeFetching,
        interactionByTypeData,
        isInteractionByTypeFetching,
        staffList,
    } = useSelector(state => state.ui);

    // when staff list is available call dashboard api with id from staff list
    useEffect(() => {
        if (staffList && staffList.length > 0 && isFirstRender.current === true && currentUserData) {
            setIsStaffListEmpty(false);
            // initially getting chart data with first advisor from the list, this same advisor is set as initial advisor for all forms
            const firstAdvisorItem = first(staffList);
            let firstAdvisorItemId = firstAdvisorItem.id;

            // for advisor logins all charts should show data based on currently logged advisor id 
            if (checkPermissions(currentUserData.permission_list, ['IS-ADVISOR'])) {
                firstAdvisorItemId = currentUserData.staff_id;
            }

            const firstFrequencyItem = first(DASHBOARD_FREQUENCY_VALUES).id;
            dispatch(getClientsVsDataAsync(firstAdvisorItemId));
            dispatch(getClientsReferringDataAsync(firstAdvisorItemId));
            dispatch(getTopReferrersAsync(firstAdvisorItemId));
            dispatch(getAdheranceToCPPDataAsync(firstAdvisorItemId));
            dispatch(getAcceptedRejectedRatioAsync(firstAdvisorItemId));
            dispatch(getAverageClientsAsync(firstAdvisorItemId));
            dispatch(getRecentlyAddedClientsAsync({
                advisorId: firstAdvisorItemId,
                frequencyType: firstFrequencyItem
            }));
            dispatch(getInteractionsByTypeAsync({
                advisorId: firstAdvisorItemId,
                frequencyType: firstFrequencyItem
            }));

            firstAdvisor.current = { id: firstAdvisorItemId, name: `${firstAdvisorItem.user.first_name} ${firstAdvisorItem.user.last_name}` };
            isFirstRender.current = false;
        }
    }, [currentUserData, dispatch, staffList]);

    const advisorListForAutoComplete = staffList ? staffList.map(item => {
        return { id: item.id, name: `${item.user.first_name} ${item.user.last_name}` };
    }) : [];

    const isChartFilterableWithAdvisor = !isEmpty(currentUserData) && checkPermissions(currentUserData.permission_list, ['FILTER-CHART-WITH-ADVISOR']);


    const getAcceptedRejectedPieChartColor = (entry) => {
        if(entry.type === ACCEPTED_REJECTED_TYPES.REJECTED)
        {
            return COLOR_CODES.dashboardOrange;
        }
        else if(entry.type === ACCEPTED_REJECTED_TYPES.ACCEPTED)
        {
            return COLOR_CODES.dashboardBlue;
        }
        else
        {
            return COLOR_CODES.dashboardBlue;
        }
    }

    const dashboardData = () => {
        const handleAdvisorSearchDebounced = debounce((reason, inputText) => {
            if (reason !== 'clear') {
                let url = `?type=Advisor&is_advisor=true`;
                if (inputText) {
                    url = `?type=Advisor&is_advisor=true&staff_name=${inputText}`;
                }
                dispatch(fetchStaffBasedOnFilterAsync(url));
            }
        }, 500);
        return (
            <div className="dashboardContent pt-2">
                <Grid container spacing={2}>
                    {/* -----------------------  No of clients vs number of new clients starts ----------------------- */}

                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isClientVsDataLoading && <LinearProgress className="lineLoader" color="secondary" />}
                            <div className="dashboardItemHeaderWrapper intractable">
                                <Grid container alignItems="center">
                                    <Grid item xs={isChartFilterableWithAdvisor ? 6 : 12}>
                                        <h1 className="customHead dashboardItemHeader">Number of Clients vs New Clients</h1>
                                    </Grid>
                                    {
                                        isChartFilterableWithAdvisor
                                        &&
                                        <Grid container item xs={6} >
                                            <Grid item xs={12}>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={{ advisor: firstAdvisor.current }}
                                                    onSubmit={(values) => {
                                                        if (values.advisor.id) dispatch(getClientsVsDataAsync(values.advisor.id));
                                                    }}
                                                >
                                                    {({ submitForm }) => (
                                                        <Form>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="advisor"
                                                                label="Advisor"
                                                                className="eventModalItem"
                                                                options={advisorListForAutoComplete}
                                                                optionLabel="name"
                                                                clearOnBlur
                                                                noOptionsText="No options found"
                                                                onChange={submitForm}
                                                                onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                            />
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                            <div className="dashboardItemContentWrapper setMinHeight center">
                                {
                                    clientVsData
                                        ?
                                        <ResponsiveContainer width="100%" height={377}>
                                            <BarChart
                                                data={clientVsData}
                                                margin={{
                                                    top: 25,
                                                    right: 30,
                                                    left: 15,
                                                    bottom: 50,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="name"
                                                    label={{ value: 'Month', offset: -20, position: 'insideBottom' }}
                                                />
                                                <YAxis
                                                    label={{ value: 'Count', angle: -90, position: 'left', offset: 0 }}
                                                    allowDecimals={false}
                                                />
                                                <Tooltip />
                                                <Legend wrapperStyle={{ bottom : '18px', left : '50px'}} />
                                                <Bar dataKey="existing_client" fill={COLOR_CODES.dashboardBlue} name="Clients" />
                                                <Bar dataKey="new_client" fill="#F1CC3B" name="New Clients" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        :
                                        <CircularLoader />
                                }
                            </div>
                        </DashboardItem>
                    </Grid>

                    {/* -----------------------  No of clients vs number of new clients ends ----------------------- */}

                    {/* -----------------------  Average age of clients  starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isGetAverageAgeFetching && <LinearProgress className="lineLoader" color="secondary" />}
                            <div className="dashboardItemHeaderWrapper intractable">
                                <Grid container alignItems="center">
                                    <Grid item xs={isChartFilterableWithAdvisor ? 6 : 12}>
                                        <h1 className="customHead dashboardItemHeader">Average Age of Clients</h1>
                                    </Grid>
                                    {
                                        isChartFilterableWithAdvisor
                                        &&
                                        <Grid container item xs={6} >
                                            <Grid item xs={12}>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={{ advisor: firstAdvisor.current }} onSubmit={(values) => {
                                                        if (values.advisor.id) dispatch(getAverageClientsAsync(values.advisor.id));
                                                    }}>
                                                    {({ submitForm }) => (
                                                        <Form>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="advisor"
                                                                label="Advisor"
                                                                className="eventModalItem"
                                                                options={advisorListForAutoComplete}
                                                                optionLabel="name"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                                onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                            />
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>

                            </div>
                            <div className="dashboardItemContentWrapper setMinHeight center">
                                {
                                    averageAgeData
                                        ?
                                        <ResponsiveContainer width="100%" height={377}>
                                            <BarChart
                                                data={averageAgeData}
                                                margin={{
                                                    top: 25,
                                                    right: 30,
                                                    left: 15,
                                                    bottom: 50,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="label_name"
                                                    label={{ value: 'Age', offset: -20, position: 'insideBottom' }}
                                                    tick={{ fontSize: 10 }}
                                                    interval={0}
                                                />
                                                <YAxis
                                                    label={{ value: 'Count', angle: -90, position: 'left', offset: 0 }}
                                                    domain={[0, 100]}
                                                    allowDecimals={false}
                                                />
                                                <Tooltip />

                                                <Bar dataKey="age_of_client" fill={COLOR_CODES.dashboardBlue} name="Count" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        :
                                        <CircularLoader />
                                }

                            </div>
                        </DashboardItem>
                    </Grid>
                    {/* -----------------------  Average age of clients  ends ----------------------- */}

                    {/* -----------------------  Clients referring you starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isGetClientsReferringFetching && <LinearProgress className="lineLoader" color="secondary" />}
                            <div className="dashboardItemHeaderWrapper intractable">
                                <Grid container alignItems="center">
                                    <Grid item xs={isChartFilterableWithAdvisor ? 6 : 12}>
                                        <h1 className="customHead dashboardItemHeader">Clients Referring You (%)</h1>
                                    </Grid>
                                    {
                                        isChartFilterableWithAdvisor
                                        &&
                                        <Grid container item xs={6} >
                                            <Grid item xs={12}>
                                                <Formik enableReinitialize initialValues={{ advisor: firstAdvisor.current }} onSubmit={(values) => {
                                                    if (values.advisor.id) dispatch(getClientsReferringDataAsync(values.advisor.id));
                                                }}>
                                                    {({ submitForm }) => (
                                                        <Form>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="advisor"
                                                                label="Advisor"
                                                                className="eventModalItem"
                                                                options={advisorListForAutoComplete}
                                                                optionLabel="name"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                                onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                            />
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                            <div className="dashboardItemContentWrapper">
                                <div className="percentageTextWrapper">
                                    <h1>{clientsReferringData}</h1>
                                </div>
                            </div>

                        </DashboardItem>
                    </Grid>
                    {/* -----------------------  Clients referring you ends ----------------------- */}

                    {/* -----------------------  Adherence to CIP starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isGetAdherenceToCppFetching && <LinearProgress className="lineLoader" color="secondary" />}
                            <div className="dashboardItemHeaderWrapper intractable">
                                <Grid container alignItems="center">
                                    <Grid item xs={isChartFilterableWithAdvisor ? 6 : 12}>
                                        <h1 className="customHead dashboardItemHeader">Adherence to CIP (%)</h1>
                                    </Grid>
                                    {
                                        isChartFilterableWithAdvisor
                                        &&
                                        <Grid container item xs={6} >
                                            <Grid item xs={12}>
                                                <Formik enableReinitialize initialValues={{ advisor: firstAdvisor.current }} onSubmit={(values) => {
                                                    if (values.advisor.id) dispatch(getAdheranceToCPPDataAsync(values.advisor.id));
                                                }}>
                                                    {({ submitForm }) => (
                                                        <Form>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="advisor"
                                                                label="Advisor"
                                                                className="eventModalItem"
                                                                options={advisorListForAutoComplete}
                                                                optionLabel="name"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                                onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                            />
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </div>
                            <div className="dashboardItemContentWrapper">
                                <div className="percentageTextWrapper">
                                    <h1>{adherenceToCpp}</h1>
                                </div>
                            </div>

                        </DashboardItem>
                    </Grid>
                    {/* -----------------------  Adherence to CIP ends ----------------------- */}

                    {/* -----------------------  Interactions by type starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isInteractionByTypeFetching && <LinearProgress className="lineLoader" color="secondary" />}

                            <div className="dashboardItemHeaderWrapper intractable">
                                <Grid container alignItems="center">
                                    <Grid item xs={isChartFilterableWithAdvisor ? 4 : 6}>
                                        <h1 className="customHead dashboardItemHeader">Interactions by Type </h1>
                                    </Grid>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{ advisor: firstAdvisor.current, frequency: first(DASHBOARD_FREQUENCY_VALUES) }}
                                        onSubmit={(values) => {
                                            if (values.advisor?.id && !isEmpty(values.frequency)) {
                                                dispatch(getInteractionsByTypeAsync({
                                                    advisorId: values.advisor.id,
                                                    frequencyType: values.frequency.id
                                                }));
                                            }
                                        }}
                                    >
                                        {({ submitForm }) => (
                                            <Grid item xs={isChartFilterableWithAdvisor ? 8 : 6}>
                                                <Form>
                                                    <Grid container item xs={12} spacing={1}>
                                                        {
                                                            isChartFilterableWithAdvisor
                                                            &&
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    component={SearchableSelect}
                                                                    name="advisor"
                                                                    label="Advisor"
                                                                    className="eventModalItem"
                                                                    options={advisorListForAutoComplete}
                                                                    optionLabel="name"
                                                                    clearOnBlur
                                                                    noOptionsText="No options found."
                                                                    onChange={submitForm}
                                                                    onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                                />
                                                            </Grid>
                                                        }

                                                        <Grid item xs={isChartFilterableWithAdvisor ? 6 : 12}>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="frequency"
                                                                label="Frequency"
                                                                className="eventModalItem"
                                                                options={DASHBOARD_FREQUENCY_VALUES}
                                                                optionLabel="labelText"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </Form>
                                            </Grid>
                                        )}
                                    </Formik>
                                </Grid>

                            </div>
                            <div className="dashboardItemContentWrapper setMinHeight center">
                                {
                                    interactionByTypeData
                                        ?
                                        <ResponsiveContainer width="100%" height={377}>
                                            <BarChart
                                                data={interactionByTypeData}
                                                margin={{
                                                    top: 25,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 50,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="month_name"
                                                    label={{ value: 'Month', offset: -20, position: 'insideBottom' }}
                                                    interval={3}
                                                    // angle={-45}
                                                    // textAnchor="end" 
                                                    // tick={{ fontSize : '12px'}}
                                                />
                                                <YAxis
                                                    label={{ value: 'Count', angle: -90, position: 'left', offset: -20 }}
                                                    allowDecimals={false}
                                                />
                                                <Tooltip />
                                                <Legend wrapperStyle={{
                                                    bottom: 20,
                                                    left: 20
                                                }} />
                                                
                                                <Bar dataKey="first_meeting_count" stackId="a" fill={COLOR_CODES.dashboardBlue} name="First Meetings" />
                                                <Bar dataKey="second_meeting_count" stackId="a" fill={COLOR_CODES.dashboardPink} name="Second & Third Meetings" />
                                                <Bar dataKey="client_meeting_count" stackId="a" fill={COLOR_CODES.dashboardGreen} name="Client Meetings" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        :
                                        <CircularLoader />

                                }

                            </div>

                        </DashboardItem>

                    </Grid>
                    {/* -----------------------  Interactions by type ends ----------------------- */}

                    {/* -----------------------  recently added clients starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                            withPadding={true}
                        >
                            {isRecentlyAddedClientsFetching && <LinearProgress className="lineLoader" color="secondary" />}

                            <div className="dashboardItemHeaderWrapper">
                                <Grid container alignItems="center">
                                    <Grid item xs={isChartFilterableWithAdvisor ? 4 : 6}>
                                        <h1 className="customHead dashboardItemHeader">Recently Added Clients</h1>
                                    </Grid>

                                    <Formik enableReinitialize initialValues={{ advisor: firstAdvisor.current, frequency:  first(DASHBOARD_FREQUENCY_VALUES) }} onSubmit={(values) => {
                                        if (values.advisor?.id && !isEmpty(values.frequency)) {
                                            dispatch(getRecentlyAddedClientsAsync({
                                                advisorId: values.advisor.id,
                                                frequencyType: values.frequency.id
                                            }));
                                        }
                                    }}>
                                        {({ submitForm }) => (
                                            <Grid item xs={isChartFilterableWithAdvisor ? 8 : 6}>
                                                <Form>
                                                    <Grid container item xs={12} spacing={1}>
                                                        {
                                                            isChartFilterableWithAdvisor
                                                            &&
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    component={SearchableSelect}
                                                                    name="advisor"
                                                                    label="Advisor"
                                                                    className="eventModalItem"
                                                                    options={advisorListForAutoComplete}
                                                                    optionLabel="name"
                                                                    clearOnBlur
                                                                    noOptionsText="No options found."
                                                                    onChange={submitForm}
                                                                    onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                                />
                                                            </Grid>
                                                        }

                                                        <Grid item xs={isChartFilterableWithAdvisor ? 6 : 12}>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="frequency"
                                                                label="Frequency"
                                                                className="eventModalItem"
                                                                options={DASHBOARD_FREQUENCY_VALUES}
                                                                optionLabel="labelText"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </Form>
                                            </Grid>
                                        )}
                                    </Formik>

                                </Grid>
                            </div>

                            <div className={`dashboardItemContentWrapper setMinHeight ${recentlyAddedClients ? '' : 'center'}`}>
                                {
                                    recentlyAddedClients
                                        ?
                                        <div className="responsiveTable customScrollbar">

                                            <Table blueHeader tableWrapperClassName="customScrollbar responsiveTable" size="small" removeLastRowBorder={true}>
                                                <TableHead className="tableHead">
                                                    <TableRow>
                                                        <TableCell className="customTh">
                                                            #
                                                        </TableCell>
                                                        <TableCell className="customTh">
                                                            Name
                                                        </TableCell>
                                                        <TableCell className="customTh">
                                                            Referred by
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        recentlyAddedClients.length > 0
                                                            ?
                                                            recentlyAddedClients.map((item, i) => {
                                                                const referredUserFirstName = item.referred_user_first_name;
                                                                return (
                                                                    <TableRow key={i}>
                                                                        <TableCell className="customTd">
                                                                            {i + 1}
                                                                        </TableCell>
                                                                        <TableCell className="customTd">
                                                                            {item.user.first_name} {item.user.last_name}
                                                                        </TableCell>
                                                                        <TableCell className="customTd">
                                                                            {
                                                                                (referredUserFirstName === null || referredUserFirstName == "")
                                                                                    ?
                                                                                    'New'
                                                                                    :
                                                                                    <> {item.referred_user_first_name} {item.referred_user_last_name}</>
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                            :
                                                            <TableRow><TableCell align="center" colSpan={3} className="customTd"> No data to display.</TableCell></TableRow>
                                                    }
                                                </TableBody>

                                            </Table>

                                        </div>
                                        :
                                        <CircularLoader />
                                }
                            </div>

                        </DashboardItem>
                    </Grid>
                    {/* -----------------------  recently added clients ends ----------------------- */}

                    {/* -----------------------  Accepted rejected ratio starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isGetAcceptedRejectedRatioFetching && <LinearProgress className="lineLoader" color="secondary" />}

                            <div className="dashboardItemHeaderWrapper intractable">
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <h1 className="customHead dashboardItemHeader">Accepted Rejected Ratio</h1>
                                    </Grid>
                                    {
                                        isChartFilterableWithAdvisor
                                        &&

                                        <Grid container item xs={6} >
                                            <Grid item xs={12}>
                                                <Formik enableReinitialize initialValues={{ advisor: firstAdvisor.current }} onSubmit={(values) => {
                                                    if (values.advisor.id) dispatch(getAcceptedRejectedRatioAsync(values.advisor.id));
                                                }}>
                                                    {({ submitForm }) => (
                                                        <Form>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="advisor"
                                                                label="Advisor"
                                                                className="eventModalItem"
                                                                options={advisorListForAutoComplete}
                                                                optionLabel="name"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                                onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                            />
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>

                                    }

                                </Grid>

                            </div>
                            <div className="dashboardItemContentWrapper setMinHeight center">
                                {
                                    acceptedRejectedRatio
                                        ?
                                        <ResponsiveContainer width="100%" height={377}>
                                            <PieChart width="100%" height="100%" >
                                                <Pie
                                                    data={acceptedRejectedRatio}
                                                    cy={160}
                                                    innerRadius={90}
                                                    outerRadius={120}
                                                    paddingAngle={0}
                                                    dataKey="value"
                                                >
                                                    {acceptedRejectedRatio.map((entry, index) => {
                                                        return (
                                                            <Cell key={`cell-${index}`} fill={getAcceptedRejectedPieChartColor(entry)} />
                                                        )
                                                    })}
                                                </Pie>
                                                <Legend
                                                    wrapperStyle={{
                                                        bottom: 48,
                                                        left: 20
                                                    }}
                                                />
                                            </PieChart>
                                        </ResponsiveContainer>
                                        :
                                        <CircularLoader />
                                }
                            </div>

                        </DashboardItem>
                    </Grid>
                    {/* -----------------------  Accepted rejected ratio ends ----------------------- */}

                    {/* -----------------------  Top referrers starts ----------------------- */}
                    <Grid item xs={12} lg={6}>
                        <DashboardItem
                            intractable
                        >
                            {isGetTopReferrersFetching && <LinearProgress className="lineLoader" color="secondary" />}

                            <div className="dashboardItemHeaderWrapper">
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <h1 className="customHead dashboardItemHeader">Top Referrers</h1>
                                    </Grid>
                                    {
                                        isChartFilterableWithAdvisor
                                        &&
                                        <Grid container item xs={6} >
                                            <Grid item xs={12}>
                                                <Formik enableReinitialize initialValues={{ advisor: firstAdvisor.current }} onSubmit={(values) => {
                                                    if (values.advisor.id) dispatch(getTopReferrersAsync(values.advisor.id));
                                                }}>
                                                    {({ submitForm }) => (
                                                        <Form>
                                                            <Field
                                                                component={SearchableSelect}
                                                                name="advisor"
                                                                label="Advisor"
                                                                className="eventModalItem"
                                                                options={advisorListForAutoComplete}
                                                                optionLabel="name"
                                                                clearOnBlur
                                                                noOptionsText="No options found."
                                                                onChange={submitForm}
                                                                onInputChange={(event, inputText, reason) => handleAdvisorSearchDebounced(reason, inputText)}
                                                            />
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </div>

                            <div className={`dashboardItemContentWrapper setMinHeight ${topReferrers ? '' : 'center'}`}>
                                {
                                    topReferrers
                                        ?
                                        <div className="responsiveTable">
                                            <Table blueHeader tableWrapperClassName="customScrollbar" size="small" removeLastRowBorder={true}>
                                                <TableHead className="tableHead">
                                                    <TableRow>
                                                        <TableCell className="customTh">
                                                            #
                                                        </TableCell>
                                                        <TableCell className="customTh">
                                                            Name
                                                        </TableCell>
                                                        <TableCell className="customTh">
                                                            Count
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        topReferrers.length > 0
                                                            ?
                                                            topReferrers.map((item, i) => {
                                                                return (
                                                                    <TableRow key={i}>
                                                                        <TableCell className="customTd">
                                                                            {i + 1}
                                                                        </TableCell>
                                                                        <TableCell className="customTd">
                                                                            {item.name}
                                                                        </TableCell>
                                                                        <TableCell className="customTd">
                                                                            {item.count}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                            :
                                                            <TableRow><TableCell align="center" colSpan={3} className="customTd"> No data to display.</TableCell></TableRow>
                                                    }

                                                </TableBody>
                                            </Table>
                                        </div>
                                        :
                                        <CircularLoader />
                                }
                            </div>

                        </DashboardItem>
                    </Grid>
                    {/* -----------------------  Top referrers ends ----------------------- */}
                </Grid>
            </div>
        );
    };

    // Display all errors related to API calls in dashboard page
    useShowDashboardErrors();

    return isStaffListEmpty ? 'No advisors found to show dashboard details.' : dashboardData();
};

export default DashboardMaster;

