import React from 'react';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import './Composer.scss';



const toolbarItemsData = ['heading', 'bold', 'Strike', 'Subscript', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
    'Undo', 'Redo', 'Underline', 'RemoveFormat', 'CopyFormatting', 'JustifyLeft'];



const defaultConfig = {
    mediaEmbed: {
        previewsInData: true,
    },
    removePlugins: [],
    toolbar: toolbarItemsData,
	extraPlugins: [customElementStylesPreservingPlugin],
}




const Composer = ({
    editor=ClassicEditor,
    innerPadding=null,
    className=null,
    disabled=false,
    config=defaultConfig,
    onChange=()=>null,
    onBlur=()=>null,
    onFocus=()=>null,
    data=null,
    height='200px',
    onInit,
    ...otherFields
}) => {




    
    return (
        <div className={`composerWrapper ${className} `}>
            <CKEditor
                editor={editor}
                data={data}
                config={config}
                disabled={disabled}
                onInit={editor => {
                    const data = editor.getData();
                    if(onInit)
                    {
                        onInit(editor);
                    }
                    // for setting height of composer area
                    editor.editing.view.change(writer => {
                        writer.setStyle(
                          "height",
                          height,
                          editor.editing.view.document.getRoot()
                        );
                    });
                }}
                onChange={(event, editor) => {
                    onChange(event, editor);
                }}
                onBlur={(editor)=>{
                    onBlur(editor);
                }}
                onFocus={(editor)=>{
                    onFocus(editor);
                }}
            />
        </div>

    )
}

export default Composer;




export function customElementStylesPreservingPlugin(editor) {

	// Allow <div> elements in the model.
	editor.model.schema.register('div', {
		allowWhere: '$block',
		allowContentOf: '$root'
	});
	editor.model.schema.register('p', {
		allowWhere: '$block',
		allowContentOf: '$block'
	});
	editor.model.schema.register('img', {
		allowWhere: '$block',
		allowContentOf: '$block'
	});





	// Allow <div> elements in the model to have all attributes.
	editor.model.schema.addAttributeCheck(context => {
		if (context.endsWith('div'))
		{
			return true;
		}
	});
	editor.model.schema.addAttributeCheck(context => {
		if (context.endsWith('p'))
		{
			return true;
		}
	});
	editor.model.schema.addAttributeCheck(context => {
		if (context.endsWith('img'))
		{
			return true;
		}
	});




	// The view-to-model converter converting a view <div> with all its attributes to the model.
	editor.conversion.for('upcast').elementToElement({
		view: 'div',
		model: (viewElement, { writer: modelWriter }) => {
			return modelWriter.createElement('div', viewElement.getAttributes());
		},
	});
	editor.conversion.for('upcast').elementToElement({
		view: 'p',
		model: (viewElement, { writer: modelWriter }) => {
			return modelWriter.createElement('p', viewElement.getAttributes());
		},
	});
	editor.conversion.for('upcast').elementToElement({
		view: 'img',
		model: (viewElement, { writer: modelWriter }) => {
			return modelWriter.createElement('img', viewElement.getAttributes());
		},
	});




	// The model-to-view converter for the <div> element (attributes are converted separately).
	editor.conversion.for('downcast').elementToElement({
		model: 'div',
		view: 'div'
	});

	editor.conversion.for('downcast').elementToElement({
		model: 'p',
		view: 'p'
	});

	editor.conversion.for('downcast').elementToElement({
		model: 'img',
		view: 'img'
	});





	// The model-to-view converter for <div> attributes.
	// Note that a lower-level, event-based API is used here.
	editor.conversion.for('downcast').add(dispatcher => {
		dispatcher.on('attribute', (evt, data, conversionApi) => {
			// Convert <div> attributes only.
			if (data.item.name != 'div' && data.item.name != 'p' && data.item.name != 'img')
			{
				return;
			}

			const viewWriter = conversionApi.writer;
			const viewDiv = conversionApi.mapper.toViewElement(data.item);

			// In the model-to-view conversion we convert changes.
			// An attribute can be added or removed or changed.
			// The below code handles all 3 cases.
			if (data.attributeNewValue)
			{
				viewWriter.setAttribute(data.attributeKey, data.attributeNewValue, viewDiv);
			} else
			{
				viewWriter.removeAttribute(data.attributeKey, viewDiv);
			}
		});
	});
}






/**
 * A plugin that converts custom attributes for elements that are wrapped in <figure> in the view.
 */
export class CustomFigureAttributes {
	/**
	 * Plugin's constructor - receives an editor instance on creation.
	 */
	constructor(editor) {
		// Save reference to the editor.
		this.editor = editor;
	}

	/**
	 * Sets the conversion up and extends the table & image features schema.
	 *
	 * Schema extending must be done in the "afterInit()" call because plugins define their schema in "init()".
	 */
	afterInit() {
		const editor = this.editor;

		// Define on which elements the CSS classes should be preserved:
		// setupCustomClassConversion('img', 'image', editor);
		setupCustomClassConversion('table', 'table', editor);
		editor.conversion.for('upcast').add(upcastCustomClasses('figure'), { priority: 'low' });

		// Define custom attributes that should be preserved.
		// setupCustomAttributeConversion('img', 'image', 'id', editor);
		setupCustomAttributeConversion('table', 'table', 'style', editor);
		editor.model.schema.extend('tableCell', { allowAttributes: '__style' });
		editor.conversion.for('upcast').attributeToAttribute({
			model: {
				key: '__style',
				name: 'tableCell'
			},
			view: 'style'
		});
		editor.conversion.for('downcast').add(dispatcher => {
			dispatcher.on('attribute:__style:tableCell', (evt, data, conversionApi) => {
				conversionApi.consumable.consume(data.item, evt.name);

				const viewElement = conversionApi.mapper.toViewElement(data.item);

				conversionApi.writer.setAttribute('style', data.attributeNewValue, viewElement);
			});
		});

	}


	
}




/**
 * Sets up a conversion that preserves classes on <img> and <table> elements.
 */
function setupCustomClassConversion(viewElementName, modelElementName, editor) {
	// The 'customClass' attribute stores custom classes from the data in the model so that schema definitions allow this attribute.
	editor.model.schema.extend(modelElementName, { allowAttributes: ['customClass'] });

	// Defines upcast converters for the <img> and <table> elements with a "low" priority so they are run after the default converters.
	editor.conversion.for('upcast').add(upcastCustomClasses(viewElementName), { priority: 'low' });

	// Defines downcast converters for a model element with a "low" priority so they are run after the default converters.
	// Use `downcastCustomClassesToFigure` if you want to keep your classes on <figure> element or `downcastCustomClassesToChild`
	// if you would like to keep your classes on a <figure> child element, i.e. <img>.
	editor.conversion.for('downcast').add(downcastCustomClassesToFigure(modelElementName), { priority: 'low' });
	// editor.conversion.for( 'downcast' ).add( downcastCustomClassesToChild( viewElementName, modelElementName ), { priority: 'low' } );
}

/**
 * Sets up a conversion for a custom attribute on the view elements contained inside a <figure>.
 *
 * This method:
 * - Adds proper schema rules.
 * - Adds an upcast converter.
 * - Adds a downcast converter.
 */
function setupCustomAttributeConversion(viewElementName, modelElementName, viewAttribute, editor) {
	// Extends the schema to store an attribute in the model.
	const modelAttribute = `custom${viewAttribute}`;

	editor.model.schema.extend(modelElementName, { allowAttributes: [modelAttribute] });

	editor.conversion.for('upcast').add(upcastAttribute(viewElementName, viewAttribute, modelAttribute));
	editor.conversion.for('downcast').add(downcastAttribute(modelElementName, viewElementName, viewAttribute, modelAttribute));
}

/**
 * Creates an upcast converter that will pass all classes from the view element to the model element.
 */
function upcastCustomClasses(elementName) {
	return dispatcher => dispatcher.on(`element:${elementName}`, (evt, data, conversionApi) => {
		const viewItem = data.viewItem;
		const modelRange = data.modelRange;

		const modelElement = modelRange && modelRange.start.nodeAfter;

		if (!modelElement)
		{
			return;
		}

		// The upcast conversion picks up classes from the base element and from the <figure> element so it should be extensible.
		const currentAttributeValue = modelElement.getAttribute('customClass') || [];

		currentAttributeValue.push(...viewItem.getClassNames());

		conversionApi.writer.setAttribute('customClass', currentAttributeValue, modelElement);
	});
}

/**
 * Creates a downcast converter that adds classes defined in the `customClass` attribute to a <figure> element.
 *
 * This converter expects that the view element is nested in a <figure> element.
 */
function downcastCustomClassesToFigure(modelElementName) {
	return dispatcher => dispatcher.on(`insert:${modelElementName}`, (evt, data, conversionApi) => {
		const modelElement = data.item;

		const viewFigure = conversionApi.mapper.toViewElement(modelElement);

		if (!viewFigure)
		{
			return;
		}

		// The code below assumes that classes are set on the <figure> element.
		conversionApi.writer.addClass(modelElement.getAttribute('customClass'), viewFigure);
	});
}

/**
 * Creates a downcast converter that adds classes defined in the `customClass` attribute to a <figure> child element.
 *
 * This converter expects that the view element is nested in a <figure> element.
 */
function downcastCustomClassesToChild(viewElementName, modelElementName) {
	return dispatcher => dispatcher.on(`insert:${modelElementName}`, (evt, data, conversionApi) => {
		const modelElement = data.item;

		const viewFigure = conversionApi.mapper.toViewElement(modelElement);

		if (!viewFigure)
		{
			return;
		}

		// The code below assumes that classes are set on the element inside the <figure>.
		const viewElement = findViewChild(viewFigure, viewElementName, conversionApi);

		conversionApi.writer.addClass(modelElement.getAttribute('customClass'), viewElement);
	});
}

/**
 * Helper method that searches for a given view element in all children of the model element.
 *
 * @param {module:engine/view/item~Item} viewElement
 * @param {String} viewElementName
 * @param {module:engine/conversion/downcastdispatcher~DowncastConversionApi} conversionApi
 * @return {module:engine/view/item~Item}
 */
function findViewChild(viewElement, viewElementName, conversionApi) {
	const viewChildren = Array.from(conversionApi.writer.createRangeIn(viewElement).getItems());

	return viewChildren.find(item => item.is('element', viewElementName));
}

/**
 * Returns the custom attribute upcast converter.
 */
function upcastAttribute(viewElementName, viewAttribute, modelAttribute) {
	return dispatcher => dispatcher.on(`element:${viewElementName}`, (evt, data, conversionApi) => {
		const viewItem = data.viewItem;
		const modelRange = data.modelRange;

		const modelElement = modelRange && modelRange.start.nodeAfter;

		if (!modelElement)
		{
			return;
		}

		conversionApi.writer.setAttribute(modelAttribute, viewItem.getAttribute(viewAttribute), modelElement);
	});
}

/**
 * Returns the custom attribute downcast converter.
 */
function downcastAttribute(modelElementName, viewElementName, viewAttribute, modelAttribute) {
	return dispatcher => dispatcher.on(`insert:${modelElementName}`, (evt, data, conversionApi) => {
		const modelElement = data.item;

		const viewFigure = conversionApi.mapper.toViewElement(modelElement);
		const viewElement = findViewChild(viewFigure, viewElementName, conversionApi);

		if (!viewElement)
		{
			return;
		}

		conversionApi.writer.setAttribute(viewAttribute, modelElement.getAttribute(modelAttribute), viewElement);
	});
}

