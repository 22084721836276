export const RECENT_PROVIDERS = 'instrument-providers/?recently_added_provider_list=true';
export const SEARCH_PROVIDERS = (searchKey) => 'instrument-providers/?search='+searchKey;
export const LIST_INSTRUMENTS = (providerId, clientId) => 'instruments/?provider_id='+providerId+'&client_id='+clientId;
export const ADD_INSTRUMENTS  = 'client-instruments-info/';
export const CLIENT_INSTRUMENT_INFO = (clientId,fee_management=false) => `client-instruments-info/?client_id=${clientId}${fee_management?"&fee_management=True":""}`;
export const CLIENT_INSTRUMENT_TYPE_UPDATE = (instrumentId) => 'client-instruments-info/'+instrumentId+'/';
export const CLIENT_INSTRUMENT_COUNT = (clientId) => 'client-instruments-info/count/?client_id='+clientId;
export const CLIENT_LOA_UPLOAD = (instrumentId, fromDraftDocs=3, isTaskDoc) => 'docs/?client_instrument_id='+instrumentId+'&draft_doc='+fromDraftDocs+'&task_doc='+isTaskDoc;;
export const CLIENT_LOA_REPLACE = (docId, instrumentId, fromDraftDocs=false, isTaskDoc) => 'docs/'+docId+'/?client_instrument_id='+instrumentId+'&draft_doc='+fromDraftDocs+'&task_doc='+isTaskDoc;
export const CLIENT_LOA_DOWNLOAD = (instrumentId, templateId) => 'template/'+templateId+'/download/?client_instrument_id='+instrumentId;
export const CLIENT_LOA_DELETE = (instrumentId, loaId) => 'docs/'+loaId+'/?client_instrument_id='+instrumentId;
export const CLIENT_LOA_VIEW = (loaId) => 'docs/'+loaId+'/';
export const CLIENT_EMAIL_PREVIEW = (instrumentId, emailTemplateId) => 'template/'+emailTemplateId+'/?type=loa_mail&client_instrument_id='+instrumentId;
export const CLIENT_BULK_EMAIL = (mailId) => 'mail/'+mailId+'/Bulk_email/';
export const CLIENT_DETAILS = (clientId) => 'clients/'+clientId+'/?profile_data=true';
export const CLIENT_DESCRIPTION = (clientId) => 'task-description/?client_id='+clientId;
export const CLIENT_VULNERABLE_STATUS = (clientId) => 'vulneralbe-status/'+clientId;
export const CLIENT_DOCUMENTS = (clientId, fetchRecent = false, task_id = null) => {
  let url = 'docs/?client_id='+clientId+'&fetch_recent_docs='+fetchRecent
  if(task_id)
  {
    url = url+'&task='+task_id;
  }
  return url;
}
export const CLIENT_ACTIVITY_FLOW = (clientId) => 'activity-flow/?client_id='+clientId;
export const CLIENT_NEXT_MEETING = (clientId) => 'get-next-meeting/?client='+clientId;
export const SURVEY_FORMS = (clientId) => 'surveyform/?client_id='+clientId;
export const SURVEY_PARTNER_FORMS = (clientId) => 'surveyform/?client_id='+clientId;
export const SURVEY_FORMS_POST = (clientId, categoryId) => 'surveyform/?client_id='+clientId+'&updated_category_id='+categoryId;
export const SURVEY_STATUS = (clientId) => 'client-categories/?client_id='+clientId;
export const SOUND_RECORDING_POST = 'audio-extraction/';
export const SOUND_RECORDING_GET = (clientId, advisorId, category_id) => 'audio-extraction/?client_id='+clientId+'&advisor_id='+advisorId+'&category_id='+category_id;
export const SOUND_RECORDING_DELETE = (recordingId) => 'audio-extraction/'+recordingId+'/';
export const CLIENT_BULK_REMINDER = (mailId) => 'mail/'+mailId+'/Bulk_email/?sent_reminder=true';
export const SURVEY_COUNTRYLIST_GET = (searchKey) => 'countries/?search='+searchKey;
export const SUB_TOTAL_ANNUAL_GET = () => 'ongoingfeecal';
export const DRAFT_REC_SUMMARY_GET = (clientId, taskId = null) => {
  let url = 'instrument-recommended/?client_id='+clientId
  if(taskId)
  {
    url = url+'&task='+taskId;
  }
  return url;
}
export const DRAFT_CLIENT_TASK_LIST_RECOMMEND_GET = (taskId) => {
  let url = `clienttaskInstrumentsrecomended/?client_task_id=${taskId}`
  return url;
}
export const DRAFT_FEE_DETAILS_GET = 'fee-details/';
export const DRAFT_FUND_DETAILS_GET =(Product_Type) => 'fund-details/?Product_Type=' + Product_Type;
export const DRAFT_REASON_DETAILS_GET = (functionIds, productType) => 'reason-details/?function='+functionIds+'&product_type='+productType;
export const DRAFT_NOTES_GET = (clientId) => 'draft-recommendation-details/?client_id='+clientId;
export const DRAFT_COMMENTS_GET = (clientId) => 'draft-recommendation/?client_id='+clientId;
export const DRAFT_COMMENTS_PUT = (draftId) => 'draft-recommendation/'+draftId+'/';
export const DRAFT_INSTRUMENT_FEE_GET = (instrumentId) => 'instrument-recommended/'+instrumentId+'/';
export const DRAFT_INSTRUMENT_FEE_PUT = (instrumentId) => 'instrument-recommended/'+instrumentId+'/';
export const DRAFT_INSTRUMENT_FEE_POST = (instrumentId) => 'Deletingmissingdetails?instrument_id='+instrumentId;
export const DRAFT_CLONE_RECOMMENDATION = 'instrument-recommended/';
export const DRAFT_DELETE_RECOMMENDATION = (instrumentId) => 'instrument-recommended/'+instrumentId+'/';
export const DRAFT_SR_DOWNLOAD = (templateId) => 'template/'+templateId+'/download/';
export const DRAFT_SR_UPLOAD = (fromDraftDocs = false, isTaskDoc) => 'docs/?draft_doc='+fromDraftDocs+'&task_doc='+isTaskDoc;
export const DRAFT_SR_GET = (clientId) => 'docs/?client_id='+clientId+'&sr=true';
export const DRAFT_CHECKLIST_GET = (clientId, taskId) => `client-checklist/?client_id=${clientId}&task_id=${taskId}`;
export const ADMINISTRATOR_CHECKLIST_GET = (clientId, taskId) => `client-checklist/?client_id=${clientId}&is_administrator=true&task_id=${taskId}`;
export const COMPLIANCE_CHECKLIST_GET = (clientId, taskId) => `client-checklist/?client_id=${clientId}&is_compliance=true&task_id=${taskId}`;
export const DRAFT_CHECKLIST_PUT = (checklistId, clientId) => 'client-checklist/'+checklistId+'/?client_id='+clientId;
export const ADMINISTRATOR_CHECKLIST_PUT = (checklistId, clientId) => 'client-checklist/'+checklistId+'/?client_id='+clientId+'&is_administrator=true';
export const COMPLIANCE_CHECKLIST_PUT = (checklistId, clientId) => 'client-checklist/'+checklistId+'/?client_id='+clientId+'&is_compliance=true';
export const DRAFT_SR_GENERATE = (templateId) => 'template/'+templateId+'/generate_sr/';
export const EXTRACTED_DATA_POST = 'save_extracted/?update=True';
export const DRAFT_DOCUMENTS_GET = (clientId) => 'draft-recommendation-documents/?client_id='+clientId;
export const CONFIRM_CLIENT_POST = 'client-tasks/';
export const CLIENT_PRODUCT_DELETE = (instrumentId) => 'client-instruments-info/'+instrumentId+'/';
export const DRAFT_NOTES_ANSWER_PUT = (clientId, drNoteId) => 'draft-recommendation-details/'+drNoteId+'/?client_id='+clientId;
export const CHECK_CLIENT_EMAIL_EXIST = 'users/exist/';
export const CHECK_CLIENT_PHONE_EXIST = 'clients/exist/';
export const PUSH_ERROR_LOG_TO_SERVER = 'error-log/';
export const REMINDER_NOTIFICATION ='ReminderNotification/';