import { all, call } from 'redux-saga/effects';
import { getMailsFromFolderStart, getMailDetailsStart } from './mail/mail.saga';




export default function* rootSaga() {
    yield all([
        call(getMailsFromFolderStart),
        call(getMailDetailsStart)
    ])
}