import { PERSISTED_REDUCER_ACTION_TYPES } from './persistedReducer.types';
import { saveNewValues, updateSavedValues, removeOneFromSavedValues, checkAndAddCategoryId } from './persistedReducer.utils';


const INITIAL_STATE = {
    savedValues: [],
    assetSectionFormStatus : { moveToNextCategory : true , assetsDirty : false },
    savedLocalValues:{},
    offlineUpdatedCategory:[],
    onlineUpdatedCategory:[]
}
const persistedReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {
        case PERSISTED_REDUCER_ACTION_TYPES.SAVE_SURVEY_VALUES:
            return {
                ...state,
                savedValues: saveNewValues(payload, state.savedValues)
            }

        case PERSISTED_REDUCER_ACTION_TYPES.UPDATE_SAVED_SURVEY_VALUES:
            return {
                ...state,
                savedValues: updateSavedValues(payload, state.savedValues)
            }

        case PERSISTED_REDUCER_ACTION_TYPES.CLEAR_SAVED_SURVEY_VALUES:
            return {
                ...state,
                savedValues: []
            }


        case PERSISTED_REDUCER_ACTION_TYPES.SET_ASSETS_SECTION_FORM_STATUS:
            return {
                ...state,
                assetSectionFormStatus: payload
            }
        case PERSISTED_REDUCER_ACTION_TYPES.CLEAR_ASSETS_SECTION_FORM_STATUS:
            return {
                ...state,
                assetSectionFormStatus: { moveToNextCategory : true , assetsDirty : false }
            }

        case PERSISTED_REDUCER_ACTION_TYPES.REMOVE_FROM_SAVED_SURVEY_VALUES:
            return {
                ...state,
                savedValues: removeOneFromSavedValues(payload, state.savedValues)
            }

        case PERSISTED_REDUCER_ACTION_TYPES.SAVE_LOCAL_VALUES:
            return {
                ...state,
                savedLocalValues: {...state.savedLocalValues, ...payload}
            }
        case PERSISTED_REDUCER_ACTION_TYPES.CLEAR_LOCAL_VALUES:
            return {
                ...state,
                savedLocalValues: {}
            }
        case PERSISTED_REDUCER_ACTION_TYPES.SAVE_UPDATED_CATEGORY_OFFLINE:
            return {
                ...state,
                offlineUpdatedCategory: checkAndAddCategoryId(payload, state.offlineUpdatedCategory)
            }
        case PERSISTED_REDUCER_ACTION_TYPES.CLEAR_UPDATED_CATEGORY_OFFLINE:
            return {
                ...state,
                offlineUpdatedCategory: []
            }

        case PERSISTED_REDUCER_ACTION_TYPES.SAVE_UPDATED_CATEGORY_ONLINE:
            return {
                ...state,
                onlineUpdatedCategory: checkAndAddCategoryId(payload, state.onlineUpdatedCategory)
            }
        case PERSISTED_REDUCER_ACTION_TYPES.CLEAR_UPDATED_CATEGORY_ONLINE:
            return {
                ...state,
                onlineUpdatedCategory: []
            }



        default:
            return state;
    }
}


export default persistedReducer;



