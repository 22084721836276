import React from 'react'
import { Table as MuiTable } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import './Table.scss';



const Table = ({ 
	children, 
	size = "medium", 
	className, 
	scrollable=false,
	maxHeight="300px",
	stickyHeader=false,
	disablePresetTheme = false, 
	blueHeader = false,
	borderedTable = false,
	removeLastRowBorder = true,
	tableWrapperClassName = "",
	tableContainerClassName = "",
}) => {
	return (
		<div className={`customTable ${disablePresetTheme ? '' : 'customTableStyles'} ${tableWrapperClassName ? tableWrapperClassName : ''}`}>
			<TableContainer className={tableContainerClassName} {...(scrollable?{style:{maxHeight:maxHeight,overflowY:"auto"}}:{})} >
				<MuiTable 
				{...(stickyHeader?{stickyHeader:true,"aria-label":"sticky table"}:{})}
					size={size} 
					className={`
						${className ? className : ''} 
						${blueHeader ? 'blueHeader' : ''} 
						${borderedTable ? 'borderedTable' : ''} 
						${removeLastRowBorder ? 'removeLastRowBorder' : ''} 
					`}>
					{children}
				</MuiTable>
			</TableContainer>
		</div>
	)
}

export default Table;
