import React , { useEffect, useRef, useState }from 'react';
import { useDispatch } from 'react-redux';
import './ClientProfile.scss';
import ClientInfoBar from '../../ClientInfoBar/ClientInfoBar';
import Grid from '@material-ui/core/Grid';
import userIcon from '../../../assets/images/Image 2@3x.png';
import pendingActions from '../../../assets/icons/pendingActions.svg';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import instrumentIcon from '../../../assets/icons/investment.svg';
import ProcessStageAccordion from '../../../components/ProcessStageAccordion/ProcessStageAccordion';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getClientInstrumentCount } from '../../../redux/instrument/instrument.actions';
import { getClientMeeting } from '../../../redux/calendar/calendar.actions';
import { getClientDetails, getClientDocuments, getClientVulnerableStatus } from '../../../redux/client/client.actions';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SurveyStatus from '../../SurveyStatus/SurveyStatus';
import { isEmpty } from 'lodash';
import CircularLoader from '../../CircularLoader/CircularLoader';
import Paper from '../../Mui/Paper/Paper';
import appConfig from '../../../appConfig.json';
import usePermissionCheck from '../../../hooks/usePermissionCheck';
import usePrevious from '../../../hooks/usePrevious';


const ClientProfile = ({clientProfileOnly=false}) => {

    const history   = useHistory();
    const dispatch  = useDispatch();
    var clientId    = history.location.state?.clientId ?? useSelector(state => state.client.clientDetails.id);
    // const clientId    = useSelector(state => state.client.clientDetails.id)
    const clientInstrumentCount     = useSelector(state => state.instrument.clientInstrumentCount)
    const isLoadingInstrumentCount  = useSelector(state => state.instrument.isLoadingInstrumentCount)
    const clientDetails = useSelector(state => state.client?.clientDetails);
    const clientEmail   = useSelector(state => state.client?.clientDetails?.user?.email);
 
    const { enqueueSnackbar }   = useSnackbar();
    const nextMeetingSchedule   = useSelector(state => state.calendar.nextMeeting);
    const clientTaskId          = useSelector(state => state.client?.clientDetails?.current_task_id);
    const isEditFetching        = useSelector(state => state.calendar.isEditFetching);
    const isDeleting            = useSelector(state => state.calendar.isDeleting);
    const isLoadingNextMeeting  = useSelector(state => state.calendar.isLoadingNextMeeting);
    const isLoadingClientDetails= useSelector(state => state.client?.isLoadingClientDetails);
    const vulnerableStatusData=useSelector(state => state.client?.clientVulnerableStatus);
    const isLoadingClientVulnerableStatus=useSelector(state => state.client?.isLoadingClientVulnerableStatus);
    // Get previous state of a variable
    const prevState = usePrevious({isEditFetching, isDeleting});
    /**
     * Permission check for different activities
     * Advisor's check and SuperAdmin's checks are done here
     */
    let productListViewAccess = usePermissionCheck(['PRODUCT-LIST-VIEW'], clientDetails);
    let productAddViewAccess  = usePermissionCheck(['ADD-PRODUCT'], clientDetails);
    let calendarViewAccess    = usePermissionCheck(['CALENDAR-VIEW'], clientDetails);
    let remindersViewAccess   = usePermissionCheck(['REMINDERS-VIEW'], clientDetails);
    let taskDetailsViewAccess = usePermissionCheck(['TASK-DETAILS'], clientDetails);

    useEffect(() => {
        // Redirect to client-management page if client id is not present
        if(!clientId){
            history.push("/client-management", {
                      clientId : clientId
                    });
        }
        dispatch(getClientDetails(clientId))
        dispatch(getClientDocuments(clientId))
        dispatch(getClientVulnerableStatus(clientId))
        if(!clientProfileOnly && clientId){
            dispatch(getClientInstrumentCount(clientId))
            // dispatch(getClientMeeting(clientId))
        }
    },[])

    // useEffect(()=>{
    //     if(clientId && !clientDetails.id){
    //         dispatch(getClientDetails(clientId))
    //     }
    // },[clientId])

    // Section for re-fetch
    // useEffect(()=>{
    //     if( (isEditFetching == false && (prevState?.isEditFetching && prevState?.isEditFetching !== isEditFetching)) ||
    //         (isDeleting == false && (prevState?.isDeleting && prevState?.isDeleting !== isDeleting))
    //     ){
    //         dispatch(getClientMeeting(clientId))
    //     }
    // },[isEditFetching, isDeleting])

    const setErrorToast = (errMessage) => {

        enqueueSnackbar(errMessage, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
    }


    return (
        <div className="clientProfilePageContent">
            {(isLoadingClientDetails || isLoadingClientVulnerableStatus) ? (
                <Paper className="clientInfoLoader">
                    <CircularLoader size={25} />
                </Paper>
            ) : (
            <>
            <ClientInfoBar
                mb={0}
                src={userIcon}
                square={true}
                elevation={0}
                vulnerable={(Object.keys(vulnerableStatusData)?.length>0 && vulnerableStatusData?.vulnerable_answer=="Yes")?true:false}
                restricted={(Object.keys(vulnerableStatusData)?.length>0 && vulnerableStatusData?.restricted_answer=="Yes")?true:false}
                vulnerableStatusData={vulnerableStatusData}
                name={clientDetails?.user?.first_name+" "+clientDetails?.user?.last_name}
                email={clientDetails?.user?.email}
                phone={clientDetails?.user?.phone_number}
                joinedOn={clientDetails.joining_date ? moment(clientDetails.joining_date).format("D MMM YYYY") : 'No Join date'}
                refferedBy={clientDetails.referred_user_first_name+" "+clientDetails.referred_user_last_name}
                status="Pre-contracting"
                profession={clientDetails.job_title}
                location="Edinburgh, United Kingdom"
                niNumber={clientDetails.ni_number}
                netWorth={clientDetails?.net_worth}
            />

            {!clientProfileOnly &&
            <Grid container className="clientProfileAboutSection">
                <Grid item container sm={6} xs={12} className="aboutContainer">
                    <Grid item container xs={12} className="buttonContainer">
                        <Grid item xs={6} className="buttonContainerInner">
                            { (isLoadingInstrumentCount) ? (
                                    <CircularLoader size={20} className="productCountLoader"/>
                                ) : (
                                <div className="buttonContainerInnerContent">
                                    <img src={instrumentIcon} width={24} alt="instrument"/>
                                    {(clientInstrumentCount && clientInstrumentCount > 0) ? (
                                        <div onClick={() => {
                                                if(productListViewAccess){
                                                    history.push('/client-management/products-list', {clientId:clientId})
                                                }else{
                                                    setErrorToast(appConfig.messages.not_authorized_action_message);
                                                }
                                            }}>
                                            <span className="productLink1">Product List</span>
                                            <span className="productLink2">
                                                {clientInstrumentCount} added so far
                                            </span>
                                        </div>
                                    ) : (
                                        <span
                                            className="productLink2"
                                            onClick={() => {
                                                if(productAddViewAccess){
                                                    history.push('/client-management/products-add', {clientId:clientDetails?.id ?? clientId})
                                                }else{
                                                    setErrorToast(appConfig.messages.not_authorized_action_message);
                                                }
                                            }}>
                                            Add Products
                                        </span>
                                    )}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={6} className="calendarLinkContainer">
                            { (isEditFetching || isDeleting || isLoadingNextMeeting) ? (
                                <CircularLoader size={20} className="nextMeetingLoader"/>
                            ) : (
                                <div className="calendarLink">
                                    <QuestionAnswerIcon />
                                    {nextMeetingSchedule.event_date ? (
                                        <div onClick={() => {
                                            if(calendarViewAccess){
                                                history.push('/calendar', {jumpToEventId:nextMeetingSchedule.event_id, isNewMeeting:false})
                                            }else{
                                                setErrorToast(appConfig.messages.not_authorized_action_message);
                                            }
                                        }}>
                                        <span className="productLink1">Next Meeting Scheduled</span>
                                        <span className="productLink2">
                                            {moment(nextMeetingSchedule.event_date).format("D MMM YYYY hh:mm a")}
                                        </span>
                                        </div>
                                    ):(
                                        <span className="calendarLinkText" onClick={() => {
                                            if(calendarViewAccess){
                                                history.push('/calendar', {clientEmail:clientEmail, isNewMeeting:true})
                                            }else{
                                                setErrorToast(appConfig.messages.not_authorized_action_message);
                                            }
                                        }}>
                                            Schedule Meeting
                                        </span>
                                    )}
                                </div>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="surveyStatusSection">
                        <SurveyStatus clientId={clientId}/>
                    </Grid>

                    <Grid item container xs={12} className="buttonContainerBottom">
                        <Grid item xs={6} className="trackButtonContainer">
                            <div className="trackButtonContent" onClick={()=>{
                                if(taskDetailsViewAccess){
                                    if(clientTaskId){
                                        history.push(`/task-details/${clientTaskId}`, {clientId:clientDetails.id})
                                    }else{
                                        setErrorToast(appConfig.messages.task_not_created_message);
                                    }
                                }else{
                                    setErrorToast(appConfig.messages.not_authorized_action_message);
                                }
                            }}>
                                <AssignmentTurnedInIcon />
                                    <span className="trackButtonText">
                                        Task Assignment & Track
                                    </span>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="pendingActionContainer">
                            {
                                !isEmpty(clientDetails)
                                &&
                                <div className="pendingActionContent" onClick={() => {
                                        if(remindersViewAccess){
                                            history.push('/reminders', {clientId:clientDetails.user.id , fullName : `${clientDetails.user.first_name} ${clientDetails.user.last_name}`})
                                        }else{
                                            setErrorToast(appConfig.messages.not_authorized_action_message);
                                        }
                                    }
                                }>
                                    <img src={pendingActions} width={27} alt="Pending actions"/>
                                    <div>
                                        {(typeof clientDetails.reminder_count == 'number' && clientDetails.reminder_count === 0) ?
                                            (
                                                <span className="pendingButtonText">
                                                    Pending Actions
                                                </span>
                                            ) : (
                                                <span className="pendingButtonText2">
                                                {(typeof clientDetails.reminder_count == 'number' && clientDetails.reminder_count > 0) ? `${clientDetails.reminder_count} ${clientDetails.reminder_count > 1 ? 'Pending Actions' : 'Pending Action'}` : 'No Pending Actions'}
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container sm={6} xs={12} style={{padding:"0 14px"}}>
                    <ProcessStageAccordion clientId={clientId}/>
                </Grid>
            </Grid>
            }
            </>
            )}
        </div>
    )
}

export default ClientProfile;