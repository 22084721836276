import { MAIL_ACTION_TYPES } from '../redux/mail/mail.types';
import { axiosPost, axiosGet, handleOnError, handleOnSuccess, handleActionStart, axiosPut } from '../utils/api';
import { getMailDetailsAsync, getMailsFromFolderAsync, removeDeletedMailFromList } from '../redux/mail/mail.action';





/*
    Service to get list of folders folders fetched are ( inbox, mail list ) 
*/
export const getMailFolderListService = async (dispatch) => {
    try
    {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.GET_MAIL_FOLDER_LIST_START));
        const data = await axiosGet('mailfolder/');
        dispatch(handleOnSuccess(data, MAIL_ACTION_TYPES.GET_MAIL_FOLDER_LIST_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.GET_MAIL_FOLDER_LIST_FAILURE));
    }
}


/*
    Service to search mail recipients , eg : used in autocomplete of mail to sections
*/
export const searchMailRecipientsService = async (dispatch, searchTerm) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.SEARCH_MAIL_RECIPIENTS_START));
        const data = await axiosGet(`mail-recipients/?key=${searchTerm}`);
        dispatch(handleOnSuccess(data, MAIL_ACTION_TYPES.SEARCH_MAIL_RECIPIENTS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.SEARCH_MAIL_RECIPIENTS_FAILURE));
    }
}



/*
    Service to send replay for a mail
*/
export const sendMailReplayService = async (dispatch, replayData, folderDetails) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.SEND_MAIL_REPLAY_START));
        const sendReplayStatus = await axiosPost('mail/', replayData);
        dispatch(handleOnSuccess(sendReplayStatus, MAIL_ACTION_TYPES.SEND_MAIL_REPLAY_SUCCESS));

        // for refreshing right side as soon as replay is success
        const { data : { status, ...others } } = sendReplayStatus;
        const { mailId, isInitiatedFromOutSideClickModal } = folderDetails;
        if(status && isInitiatedFromOutSideClickModal === false)
        {
            dispatch(getMailDetailsAsync(mailId));
        }
    }
    catch(err) {
        dispatch(handleOnError(err.response, MAIL_ACTION_TYPES.SEND_MAIL_REPLAY_FAILURE));
    }
}



/*
    Service to send a mail
*/
export const composeMailService = async (dispatch, composeMailData, folderDetails) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.COMPOSE_MAIL_START));
        const composeMailStatusData = await axiosPost('mail/', composeMailData );
        dispatch(handleOnSuccess(composeMailStatusData, MAIL_ACTION_TYPES.COMPOSE_MAIL_SUCCESS));
        const { data : { status, ...others } } = composeMailStatusData;
        const { mailId, folderId, messageDraftStatus, isInitiatedFromOutSideClickModal } = folderDetails;


        // only refresh left and right side if its draft and when this api call is not called when draft is being saved on outside click ( not when draft is saved on clicking )
        if(status && messageDraftStatus && isInitiatedFromOutSideClickModal === false)
        {
            const paramsForMailList = {
                "folder": folderDetails.folderId,
                "limit": folderDetails.limit,
                "offset": folderDetails.offset
            }
            dispatch(getMailsFromFolderAsync(paramsForMailList));
            dispatch(removeDeletedMailFromList(mailId));
            dispatch(getMailDetailsAsync(mailId));
        }

    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.COMPOSE_MAIL_FAILURE));
    }
}




/*
    Service to fetch mail categories
*/
export const fetchMailCategoriesService = async (dispatch) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.FETCH_MAIL_CATEGORIES_START));
        const data = await axiosGet('template-categories/?type=1');
        dispatch(handleOnSuccess(data,MAIL_ACTION_TYPES.FETCH_MAIL_CATEGORIES_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, MAIL_ACTION_TYPES.FETCH_MAIL_CATEGORIES_FAILURE));
    }
}


/*
    Service to fetch mail templates
*/
export const fetchMailTemplatesService = async (dispatch,categoryId) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.FETCH_MAIL_TEMPLATES_START));
        const data = await axiosGet(`template/?category_id=${categoryId}`);
        dispatch(handleOnSuccess(data,MAIL_ACTION_TYPES.FETCH_MAIL_TEMPLATES_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.FETCH_MAIL_TEMPLATES_FAILURE));
    }
}




/*
    Service to get internal files and folders from drive
*/
export const getInternalDriveFoldersService = async (dispatch) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.GET_INTERNAL_DRIVE_FOLDERS_START));
        const data = await axiosGet('docs/?get-attachment=true');
        dispatch(handleOnSuccess(data, MAIL_ACTION_TYPES.GET_INTERNAL_DRIVE_FOLDERS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.GET_INTERNAL_DRIVE_FOLDERS_FAILURE));
    }
}



/*
    Service to mark mail as read, this will unset the read count
*/
export const markMailAsReadService = async (dispatch, conversationId) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.MARK_MAIL_AS_READ_START));
        const data = await axiosPost('/mark-mail-read/', {
            conversation_id : conversationId
        });
        dispatch(handleOnSuccess(data, MAIL_ACTION_TYPES.MARK_MAIL_AS_READ_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.MARK_MAIL_AS_READ_FAILURE));
    }
}



/*
    Service to move a mail to folder, eg: deleting mails moves mail to delete folder
*/
export const moveMailToFolderService = async (dispatch, folderDetails, getState) => {
    try {
        const { noOfMailsInFolder , ...otherDetails } = folderDetails;
        dispatch(handleActionStart(MAIL_ACTION_TYPES.MOVE_MAIL_TO_FOLDER_START));
        const moveMailStatusData = await axiosPost('move-to-folder/',{ ...otherDetails }); // noOfMailsInFolder is not needed as param in api
        dispatch(handleOnSuccess(moveMailStatusData,MAIL_ACTION_TYPES.MOVE_MAIL_TO_FOLDER_SUCCESS));

        const { data : { status, ...others } } = moveMailStatusData;

        if(status)
        {
            const { mail_id, isDeletingFromDeleteFolder } = folderDetails;
            const paramsForMailList = {
                "folder": otherDetails.currentFolderId,
                "limit": otherDetails.limit,
                "offset": otherDetails.offset
            }
            dispatch(getMailsFromFolderAsync(paramsForMailList));
            dispatch(getMailDetailsAsync(mail_id));
            dispatch(removeDeletedMailFromList(mail_id));
        }
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.MOVE_MAIL_TO_FOLDER_FAILURE));
    }
}   




/*
    Service to fetch outlook settings
*/
export const fetchOutlookSettingsService = async (dispatch) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.FETCH_OUTLOOK_SETTINGS_START));
        const data = await axiosGet('outlook-cred/');
        dispatch(handleOnError(data, MAIL_ACTION_TYPES.FETCH_OUTLOOK_SETTINGS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response, MAIL_ACTION_TYPES.FETCH_OUTLOOK_SETTINGS_FAILURE));
    }
}



/*
    Service to save outlook settings client secret and application id
*/
export const saveOutlookSettingsService = async (dispatch, outlookDetails) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.SAVE_OUTLOOK_SETTINGS_START));
        const { client_id, client_secret, user, outlook_email } = outlookDetails;
        const data = await axiosPost('outlook-cred/', {
            client_id : client_id,
            client_secret : client_secret,
            user : user,
            outlook_email : outlook_email
        });
        dispatch(handleOnError(data,MAIL_ACTION_TYPES.SAVE_OUTLOOK_SETTINGS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.SAVE_OUTLOOK_SETTINGS_FAILURE));
    }
}



/*
    Service to edit outlook settings
*/
export const editOutlookSettingsService = async (dispatch,outlookDetails) => {
    try {
        dispatch(handleActionStart(MAIL_ACTION_TYPES.EDIT_OUTLOOK_SETTINGS_START));
        const { client_id, client_secret, user, outlook_email, id} = outlookDetails;
        const data = await axiosPut(`outlook-cred/${id}/`, {
            client_id : client_id,
            client_secret : client_secret,
            outlook_email : outlook_email,
            user : user,
        });
        dispatch(handleOnSuccess(data,MAIL_ACTION_TYPES.EDIT_OUTLOOK_SETTINGS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,MAIL_ACTION_TYPES.EDIT_OUTLOOK_SETTINGS_FAILURE));
    }
}