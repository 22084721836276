import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker as MuiTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { InputAdornment, IconButton } from '@material-ui/core';




const useStyles = makeStyles(theme => {
    return {
        root: {
            '& label.Mui-focused': {
                color: theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.secondary.main
                },
            },
        }
    }
});


const secondaryDark = '#179daf!important';
const themeGrayLight = '#e6e6e6';
const secondaryColor = '#1ac7de';
const theme = createTheme({
    overrides: {
        MuiPickersClock: {
            clock: {
                backgroundColor: '#EDEDED'
            },
            pin : {
                backgroundColor: secondaryColor,
            }
        },
        MuiPickersClockPointer : {
            pointer : {
                backgroundColor: secondaryColor
            },
            thumb : {
                backgroundColor: secondaryDark,
                borderColor :   secondaryDark,
            }
        },
        MuiButton: {
            textPrimary: {
                color: secondaryColor,
            }
        },
        //header
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: secondaryColor,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: "white",
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: secondaryColor,
                '&:hover' : {
                    backgroundColor: secondaryDark,
                }
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: 'red',
            },
        },

        MuiOutlinedInput:{
            root: {
                '&.Mui-disabled' : {
                    '& fieldset' : {
                        borderColor: themeGrayLight,
                    }
                },

                '&.Mui-focused fieldset': {
                    borderColor: `${secondaryColor}!important`,
                },
            }
        }
    }
});













const TimePicker = ({
    field: { name, value },
    form: { values, setFieldValue, errors },
    onChange,
    label,
    variant = 'outlined',
    size = "small",
    required,
    icon = true,
    className,
    disabled
}) => {

    const customStyles = useStyles();

    const errorText = errors[name];
    const isError = (errorText) ? true : false;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
            <MuiTimePicker
                className={`
                    ${className}
                    ${customStyles.root}
                `}
                fullWidth
                name={name}
                label={label}
                value={value}
                onChange={(value) => {
                    setFieldValue(name, value);
                    // Running the custom on change function if passed
                    if (onChange)
                    {
                        onChange(value);
                    }
                }}
                inputVariant={variant}
                size={size}
                error={isError}
                helperText={errorText}
                required={required}
                InputProps={icon ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton> <AccessAlarmIcon /> </IconButton>
                        </InputAdornment>
                    )
                } : null}
                disabled={disabled}
            />
            </ThemeProvider>
            
        </MuiPickersUtilsProvider>

    )
}

export default TimePicker;
